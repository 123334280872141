import { matchesConstants } from '../_constants';

export function getMatches(state = {}, action) {
  switch (action.type) {
    case matchesConstants.GETMATCHES_REQUEST:
      return {
        loading: true
      };
    case matchesConstants.GETMATCHES_SUCCESS:
      return {
        items: action.getMatches
      };
    case matchesConstants.GETMATCHES_FAILURE:
      return { 
        error: "There was an error processing this request."
      };
      default:
      return state
  }
};

export function saveMatch(state = {}, action) {
  switch (action.type) {
    case matchesConstants.SAVEMATCH_REQUEST:
    return {
      loading: true
    };
  case matchesConstants.SAVEMATCH_SUCCESS:
    return {
      favorites: localStorage.getItem('favorite'),
    };
  case matchesConstants.SAVEMATCH_FAILURE:
    return { 
      error: "There was an error processing this request."
    };
  default:
    return state
  }
};

export function discardMatch(state = {}, action) {
  switch (action.type) {
    case matchesConstants.DISCARDMATCH_REQUEST:
    return {
      loading: true
    };
  case matchesConstants.DISCARDMATCH_SUCCESS:
    return {
      favorites: localStorage.getItem('favorite'),
    };
  case matchesConstants.DISCARDMATCH_FAILURE:
    return { 
      error: "There was an error processing this request."
    };
  default:
    return state
  }
}