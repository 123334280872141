import { whitelabelConstants } from '../_constants';
import { whitelabelService } from '../_services';
import { alertActions } from '.';

export const whitelabelActions = {
    setWhitelabel,
};

function setWhitelabel(whitelabel) {
    // alert("WH ACTION: " + organization_Name);
    return dispatch => {
        dispatch(request());
        whitelabelService.setWhitelabel(whitelabel)
            .then(
                whitelabel => { 
                    dispatch(success(whitelabel));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.loginError("There was an error processing your request."));
                }
            );
    };

    function request(whitelabel) { return { type: whitelabelConstants.SETWHITELABEL_REQUEST, whitelabel } }
    function success(whitelabel) { return { type: whitelabelConstants.SETWHITELABEL_SUCCESS, whitelabel } }
    function failure(error) { return { type: whitelabelConstants.SETWHITELABEL_FAILURE, error } }
}