import React from 'react';

export const TruncateFunding = (value)  => {
    if (value) {
        var newValue = value;
        if (value >= 1000) {
            const valueString = value.toString();
            var suffixes = ["", "K", "M", "B","T"];
            var suffixNum = 0;
            if ((valueString).length%3 == 0) {
                suffixNum = Math.floor( ((value/10).toString()).length/3 );
            } else {
                suffixNum = Math.floor( (valueString).length/3 );
            }

            var idx = valueString.length - 3*(suffixNum);
            var val = valueString[idx];
            if (val == 0) {
                val = valueString.slice(0, idx);
            } else {
                let val2 = valueString[idx + 1];
                if (val2 != 0) {
                    val += val2;
                }
                val = valueString.slice(0, idx) + "." + val;
            }
            newValue = val + suffixes[suffixNum];
        }
        return <p id="fundingAmount" style={{marginBottom: 0}}>${newValue}</p>
    } 
    else {
        return <p id="fundingAmount">Varies</p>
    }
}