import { authHeader } from '../_helpers';

export const algoliaService = {
    getSavedSearches,
    saveQuery,
    removeQuery,
    exportSearchResults
};

function getSavedSearches(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/algolia/get_saved_searches', requestOptions)
    .then(handleResponse)
    .then(queries => {
        localStorage.setItem('savedQueries', JSON.stringify(queries));
        
        return queries;
    });
}

function saveQuery(query, title, user_Id) {
    var searchQuery = {
        "Query_Title": title,
        "Query_Body": query,
        "User_Id": user_Id
    };

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(searchQuery)
    };

    return fetch('https://refactor.opengrants.io/api/algolia/save_search_query', requestOptions).then(handleResponse);
}

function removeQuery(query) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(query)
    };

    return fetch('https://refactor.opengrants.io/api/algolia/remove_search_query', requestOptions).then(handleResponse);
}

function exportSearchResults(query) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/algolia/export_search_results?query=' + JSON.stringify(query), requestOptions)
        .then(async (data) => {
            var content = await data.blob();
            console.log(content)
            return content;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok ) {
            if (response.status == "401") {
                window.location.href="/login";
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}