import {stripeConstants} from '../_constants';

export function createStripeAccount(state={status:"", loading:false}, action) {
    switch (action.type) {
        case stripeConstants.CONNECT_REQUEST:
            return {status: "", loading: true};
        case stripeConstants.CONNECT_SUCCESS:
            return {status: "success", loading: false};
        case stripeConstants.CONNECT_FAILURE:
            return {status: "failure", loading: false};
        default:
            return state;
    }
};

export function handleStripeToken(state={status:"", loading:"false", billingAddress: {}}, action) {
    switch (action.type) {
        case stripeConstants.TOKEN_REQUEST:
            return {loading: true};
        case stripeConstants.TOKEN_SUCCESS:
            return {status: "success", loading: false, billingAddress: action.billingAddress};
        case stripeConstants.TOKEN_FAILURE:
            return {status: "failure", loading: false};
        default:
            return state;
    }
};

export function getBillingAddress(state={status:"", loading:"false", billingAddress: {}}, action) {
    switch (action.type) {
        case stripeConstants.BILLING_ADDRESS_REQUEST:
            return {loading: true};
        case stripeConstants.BILLING_ADDRESS_SUCCESS:
            return {status: "success", loading: false, billingAddress: action.billingAddress};
        case stripeConstants.BILLING_ADDRESS_FAILURE:
            return {status: "failure", loading: false};
        default:
            return state;
    }
};

export function purchaseProduct(state={purchaseSuccess: false, message: "", statusType: "", loading: false}, action) {
    switch (action.type) {
        case stripeConstants.PURCHASE_PRODUCT_REQUEST:
            return {purchaseSuccess: false, message: "", statusType: "", loading: true};
        case stripeConstants.PURCHASE_PRODUCT_SUCCESS:
            return {purchaseSuccess: true, message: action.result.message, statusType: action.result.statusType, loading: false}
        case stripeConstants.PURCHASE_PRODUCT_FAILURE:
            return {purchaseSuccess: false, message: action.result.message, statusType: action.result.statusType, loading: false}
        case stripeConstants.PURCHASE_PRODUCT_RESET:
            return {purchaseSuccess: "reset", message: "", statusType: "", loading: false}
        default: 
            return state;
    }
}

export function updateBilling(state={status: "", loading: false}, action) {
    switch (action.type) {
        case stripeConstants.UPDATE_BILLING_REQUEST:
            return {status: false, loading: true};
        case stripeConstants.UPDATE_BILLING_SUCCESS:
            return {status: true, loading: false}
        case stripeConstants.UPDATE_BILLING_FAILURE:
            return {status: false, loading: false}
        case stripeConstants.UPDATE_BILLING_RESET:
            return {status: "reset", loading: false}
        default: 
            return state;
    }
}

export function cancelSubscription(state={status: "", loading: false}, action) {
    switch (action.type) {
        case stripeConstants.CANCEL_SUBSCRIPTION_REQUEST:
            return {status: false, loading: true};
        case stripeConstants.CANCEL_SUBSCRIPTION_SUCCESS:
            return {status: true, loading: false}
        case stripeConstants.CANCEL_SUBSCRIPTION_FAILURE:
            return {status: false, loading: false}
        case stripeConstants.CANCEL_SUBSCRIPTION_RESET:
            return {status: "reset", loading: false}
        default: 
            return state;
    }
}

export function updateSubscription(state={status: "", loading: false}, action) {
    switch (action.type) {
        case stripeConstants.UPDATE_SUBSCRIPTION_REQUEST:
            return {status: false, loading: true};
        case stripeConstants.UPDATE_SUBSCRIPTION_SUCCESS:
            return {status: true, loading: false}
        case stripeConstants.UPDATE_SUBSCRIPTION_FAILURE:
            return {status: false, loading: false}
        case stripeConstants.UPDATE_SUBSCRIPTION_RESET:
            return {status: "reset", loading: false}
        default: 
            return state;
    }
}

export function paywallAlert(state={status: ""}, action) {
    switch (action.type) {
        case stripeConstants.PAYWALL_ALERT:
            return {status: true}
        default:
            return state;
    }
}