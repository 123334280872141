import React from 'react';
import { categories } from '../../../_reducers/search.reducer';

class CreateInputs extends React.Component {
    constructor(props) {
        super(props);
        const {selectedCategories} = this.props;
        // let keys = Object.keys(selectedCategories);
        let inputValues = {};
        // for (var i = 0; i < keys.length; i++) {
        //     inputValues[keys[i]] = '';
        // }

        this.state = {
            currentActiveSelection: 0,
            categories: selectedCategories,
            ...inputValues
        }
    }

    //update category input, reset active selection
    handleChange = (e) => {
        const {id, value} = e.target;
        let {currentActiveSelection} = this.state;
        currentActiveSelection = 0;
        
        this.setState({
            ...this.state,
            [id]: value,
            currentActiveSelection: currentActiveSelection
        });
    }

    handleKeyUp = (e) => {
        const {key} = e;
        const {id} = e.target;

        //show element options
        this.showOptions(id);

        //update active selection
        if (key === "ArrowUp") {
            this.updateActiveOption(id, "up");
        } 
        //update active selection
        else if (key === "ArrowDown") {
            this.updateActiveOption(id, "down");
        } 
        //create tag
        else if (key=="Enter") {
            var optionContainer = document.getElementById(id+"_options");
            var options = optionContainer.children;

            if (options.length > 0) {
                var optionContainer = document.getElementById(id+"_options");
                var activeOption = optionContainer.getElementsByClassName("optionList-active")[0];
                var idx = activeOption.attributes.index.value;
                
                const {multiple} = this.props;
                if (multiple) {
                    this.setState({
                        ...this.state,
                        [id]: '',
                    });
                } else {
                    let options = this.getOptions(id);
                    let option_value = options[idx];
                    this.setState({
                        ...this.state,
                        [id]: option_value,
                    });
                }
                this.hideOptions(id, 0);

                const {updateSelectedCategories} = this.props;
                updateSelectedCategories(id, idx);
            }
        } 
        // else {
        //     this.showOptions(id);
        // }
    }

    //show options on input focus
    handleOnFocus = (e) => {
        var {id} = e.target;
        this.showOptions(id);
    }

    //show options on click focus
    handleOnClick = (e) => {
        var {id} = e.target;
        this.showOptions(id);
    }

    //hide when input out of focus
    handleOnBlur = (e) => {
        var {id} = e.target;
        this.hideOptions(id);
    }

    //clear input value
    clearInput = (option_id) => {
        var input = document.getElementById(option_id);
        input.value = "";
    }

    //update active option selection
    updateActiveOption = (id, action) => {
        var optionContainer = document.getElementById(id+"_options");
        var activeOption = optionContainer.getElementsByClassName("optionList-active")[0];
        var idx = 0;

        if (action === "up") {
            var prevSib = activeOption.previousElementSibling;
            if (prevSib !== null && prevSib !== undefined) {
                prevSib.classList.add("optionList-active");
                idx = prevSib.attributes.index.value;

                if (activeOption) {
                    activeOption.classList.remove("optionList-active");
                }

                //scroll up
                if (prevSib.offsetTop < optionContainer.scrollTop) {
                    optionContainer.scrollTop = prevSib.offsetTop;
                } else if (prevSib.offsetTop + prevSib.offsetHeight > optionContainer.scrollTop + optionContainer.offsetHeight) {
                    optionContainer.scrollTop = (prevSib.offsetTop + prevSib.offsetHeight) - optionContainer.offsetHeight;
                }
            }

        } else if (action === "down") {
            var nextSib = activeOption.nextElementSibling;
            if (nextSib !== null && nextSib !== undefined) {
                nextSib.classList.add("optionList-active");
                idx = nextSib.attributes.index.value;

                if (activeOption) {
                    activeOption.classList.remove("optionList-active");
                }

                //scroll down
                if (nextSib.offsetTop + nextSib.offsetHeight > optionContainer.scrollTop + optionContainer.offsetHeight) {
                    optionContainer.scrollTop = nextSib.offsetTop + nextSib.offsetHeight - optionContainer.offsetHeight;
                } else if (nextSib.offsetTop + nextSib.offsetHeight < optionContainer.scrollTop) {
                    optionContainer.scrollTop = nextSib.offsetTop;
                }
            }
        }
    }

    //get list of options
    getOptions = (option_id) => {
        const {categoryList} = this.props;
        return categoryList;
        // return categoryList[option_id];
    }
    
    //hide option list
    hideOptions = (option_id, waitTime) => {
        setTimeout(function() {
            var optionContainer = document.getElementById(option_id+"_options");
            if (optionContainer != undefined) {
                optionContainer.style.display = "none";
            }
        }, waitTime);
    }

    //show option list
    showOptions = (option_id) => {
        var optionContainer = document.getElementById(option_id+"_options");
        if (optionContainer.style.display !== "block") {
            optionContainer.style.display = "block";
            optionContainer.scrollTop = 0;
            var activeOption = optionContainer.getElementsByClassName("optionList-active")[0];
                
            if (activeOption) {
                activeOption.classList.remove("optionList-active");
            }
            if (optionContainer.children.length > 0) {
                optionContainer.children[0].classList.add("optionList-active");
            }
        } 

        if (document.getElementById(option_id + '_options').style.top.includes("-") || document.getElementById(option_id + '_options').offsetHeight + document.getElementById(option_id + '_container').offsetTop + document.getElementById(option_id + '_container').offsetHeight + 20 > document.getElementById('app').offsetHeight) {
            var height = document.getElementById('app').offsetHeight - document.getElementById(option_id + '_container').offsetTop - document.getElementById(option_id + '_container').offsetHeight - 20;
            if (height < 300 && height > 150) {
                document.getElementById(option_id + '_options').style.maxHeight =  height + "px";
                document.getElementById(option_id + '_options').style.top = "";
            }
            // else if (height < 150) {
            //     document.getElementById(option_id + '_options').style.maxHeight = "150px";
            //     document.getElementById(option_id + '_options').style.top =  "-" + 
            //         (document.getElementById(option_id + '_options').offsetHeight - 
            //             (document.getElementById(option_id).offsetHeight - document.getElementById(option_id).offsetTop)) +
            //             "px";
            // }
        }

        var bodyRect = document.body.getBoundingClientRect();
        var rect = document.getElementById(option_id + '_options').getBoundingClientRect();

        var currentWindowPosition = bodyRect.height + document.body.scrollTop;
        var bottomPosition = document.body.scrollTop + rect.y + rect.height;

        if (bottomPosition > currentWindowPosition) {
            document.body.scrollTop = document.body.scrollTop + (bottomPosition - currentWindowPosition) + 20;
        }
    }

    //create option element
    createOption = (option_id, idx) => {
        let options = this.getOptions(option_id);
        if (options === null) {
            return null;
        }
        if (idx < 0 || idx > options.length) {
            return null;
        }
        let option_value = options[idx];
        
        var div = document.createElement("div");
        div.innerText = option_value;
        div.className = "filterList";
        div.setAttribute("index", idx);
        div.onclick = this.handleOptionClick

        return div;
    }

    handleOptionClick = (e) => {
        var currentElement = e.target;
        let {id} = currentElement;
        if (!id) {
            currentElement = currentElement.parentElement;
        }
        id = currentElement.parentElement.id;
        var option_id = id.split("_options")[0];
        const idx = currentElement.attributes.index.value;
        var options = this.getOptions(option_id);
        
        const {multiple} = this.props;
        if (multiple) {
            this.setState({
                ...this.state,
                [option_id]: '',
            });
        } else {
            let option_value = options[idx];
            this.setState({
                ...this.state,
                [option_id]: option_value,
            });
        }
        this.hideOptions(option_id, 0);

        const {updateSelectedCategories} = this.props;
        updateSelectedCategories(option_id, idx);
    }

    render() {
        const {currentActiveSelection} = this.state;
        const {submitted, categoryList, requiredInput, selectedCategories, reset, option_id} = this.props;
        var option_ids = Object.keys(selectedCategories);
        
        //create options for inputs
        var idx = 0;
        let options = null;
        if (categoryList) {
            var inputValue = this.state[option_id];
            options = categoryList.map((category, index) => {
                let optionClass = "";
                if (!inputValue 
                    || inputValue === "" 
                    // || (reset && category.toLowerCase().includes(inputValue.toLowerCase())) 
                    // || (!reset && category.substr(0, inputValue.length).toLowerCase() === inputValue.toLowerCase())
                    || category.substr(0, inputValue.length).toLowerCase() === inputValue.toLowerCase()
                    ) {
                    if (idx === currentActiveSelection) {
                        optionClass = "optionList-active";
                    }
                    idx++;

                    // const cat = (catgeoryValue, input) => {
                    //     // var idxStart = catgeoryValue.toLowerCase().indexOf(input);
                    //     // var input = input.length;
                        
                    //     return(
                    //         <div>
                    //             {/* {catgeoryValue.substr(0,idxStart)}
                    //             <strong>{catgeoryValue.substr(idxStart,input)}</strong>
                    //             {catgeoryValue.substr(idxStart+input)} */}
                    //             {catgeoryValue}
                    //         </div>
                    //     )
                    // }
                    return (
                        <div id={index} index={index} className={optionClass} onMouseDown={this.handleOptionClick} key={option_id+"_"+index}>{category}</div>
                    )
                }
            });
        }

        return (
            <div>
                <input 
                    id={option_id} 
                    className="form-control" 
                    autoComplete="off" 
                    value={inputValue} 
                    onChange={this.handleChange} 
                    onKeyUp={this.handleKeyUp} 
                    onFocus={this.handleOnFocus} 
                    onClick={this.handleOnClick} 
                    onBlur={this.handleOnBlur}
                    placeholder="Start typing to select..."
                />
                <div hidden id={option_id + "_options"} className="optionList">
                    {options}
                </div>
                {requiredInput && requiredInput.includes(option_id) ? 
                    submitted && selectedCategories.length === 0 && <p className="error">This field is required</p> : "" }
            </div>
        )
    }
}

export { CreateInputs };