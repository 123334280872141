import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { settingsActions } from '../../_actions';
import { authenticateActions } from '../../_actions';
import { personalprofileService } from '../../_services';
import { Previews } from './ImageUpload';
import { Loader } from '../Shared/Loader';
import './ImageUpload.jsx';
import '../../Styles/SettingsStyles/Profile.css';

import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import Class from "@material-ui/icons/Class";

import ReactPaginate from 'react-paginate';

const styles = {
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    }
  };

class ProfileList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            profiles: [],
            page: 0,
            loading: true
        }
    }

    componentDidMount = async() => {
        this.getAllProfiles();
    }

    getAllProfiles = async (page) => {
        var results = await personalprofileService.getAllProfiles(page);
        this.setState({
            profiles: results.profiles,
            total_profiles: results.total_profiles,
            loading: false
        });
    }

    handlePageClick = (data) => {
        console.log(data);
        let selected = data.selected;
        this.getAllProfiles(selected);
    }

    openProfile = (profile) => {
        var username = "";
        if (profile.userName && profile.userName != profile.email) {
            username = profile.userName;
        } else {
            if (profile.name && profile.name != profile.email) {
                username += profile.name;
            } 
            if (profile.surname && profile.surname != profile.email) {
                username += username ? "-" + profile.surname : profile.surname;
            } 
            username += username != "" ? "-" + profile.people_Id : profile.people_Id;
        }

        var a = document.createElement("a");
        a.href = '/profiles/'+username;
        a.target = 'blank';
        a.click();
    }

    render() {
        const { profiles, page, loading }  = this.state;
        console.log(this.state);
        console.log(profiles.length);

        let userProfiles = null;
        if (profiles) {
            userProfiles = profiles.map((profile) => {
                return (
                    <div key={profile.email}>
                        <div className="row">
                            <div className="profile_list">
                                <Button color="info" style={{float: "right"}} onClick={() => {this.openProfile(profile)}}>
                                    View Profile
                                </Button>
                                <div>
                                    {profile.headshot && <img src={profile.headshot} style={{height: "100px", width: "100px", display: "inline-block", borderRadius: "50%"}} />}
                                    {!profile.headshot && <div className="profile-circle" style={{position:"relative", height: "100px", width: "100px", lineHeight: "100px", fontSize: "45px"}}>{profile.surname[0]}</div>}
                                    <div style={{display: "inline-block", marginLeft: "20px", verticalAlign: "top", marginTop: "20px"}}>
                                        <p><strong>{profile.name} {profile.surname}</strong></p>
                                        <p>{profile.title}</p>
                                        <p>{!profile.bio || profile.bio.length < 50 ? profile.bio : profile.bio.substring(0, 50) + "..."}</p>
                                    </div>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }

        return (
            <div id="mainSettings">
                <h1>People</h1>
                {userProfiles} 
                <div style={{margin: "10px", marginTop:"20px"}}>
                    <ReactPaginate 
                        pageCount={profiles ? profiles.length : 0}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={this.handlePageClick}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </div>     
            </div>
        );
    }
}

function mapStateToProps(state) {
}

const connectedRegisterPage = connect(mapStateToProps)(ProfileList);
export { connectedRegisterPage as ProfileList };