import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EmailSignup } from './EmailSignup';
import { layoutActions } from '../../../_actions';
import '../../../Styles/Main.css';
import '../../../Styles/Footer.css';

class Footer extends Component {
    componentDidMount() {
        this.props.dispatch(layoutActions.getStats());
    }
    static displayName = Footer.name;

    render() {
        const { layout } = this.props;
        return (
            <footer>
                <div id="footerMain" className="row">
                    <div id="footer-3" className="col-md-3">
                        <h3>opengrants.io</h3>
                        <hr align="left" />
                        <div className="navigationLinks">
                            <a href="https://kb.opengrants.io/en/articles/3737428-about-us">About Us</a>
                            <a href="https://hubs.ly/H0n7zxv0">How It Works</a>
                            <a href="https://kb.opengrants.io">Knowledge Base</a>
                            <a href="https://experience.opengrants.io/contact-opengrants">Contact Us</a>
                            <a href="https://jobs.opengrants.io">Jobs</a>
                        </div>
                    </div>
                    <div id="footer-3" className="col-md-3">
                        <h3>Account Access</h3>
                        <hr align="left" />
                        <div className="navigationLinks">
                            <a href="/Login">Login</a>
                            <a href="/Register">Register</a>
                        </div>
                    </div>
                    <div id="footer-3" className="col-md-3">
                        <h3>Follow</h3>
                        <hr align="left" />
                        <div className="navigationLinks">
                            <a href="https://twitter.com/opengrants_io">Twitter</a>
                            <a href="https://www.linkedin.com/company/opengrantsio/">LinkedIn</a>
                            <a href="https://medium.com/@opengrants.io">Medium</a>
                            <a href="https://experience.opengrants.io/press-kit">Press Kit</a>
                            <a href="https://experience.opengrants.io/startup-watch">Startup Watch</a>
                        </div>
                    </div>
                    <EmailSignup />
                </div>
                <div className="row">
                    <div id="footer-3" className="col-md-3">
                        <h3 id="fundingCount"><span id="totalFunding">{layout.items && layout.items[0]}</span> IN FUNDING</h3>
                    </div>
                    <div id="footer-3" className="col-md-3">
                        <h3 id="grantCount"><span id="totalGrants">{layout.items && layout.items[1]}</span> TOTAL GRANTS</h3>
                    </div>
                </div>
                <div id="footerRow" className="row">
                    <hr id="footerHR" />
                    <div id="copyright">
                        <a id="privacy" href="https://www.opengrants.io/opengrants-legal/">Privacy Policy</a>
                        <a href="https://www.opengrants.io/opengrants-legal/">Terms and Conditions</a>
                        <div>
                            <p>&#x24B8;	2020 <a id="OpenGrants">Open Grants</a> - All Rights Reserved. Provided by <a id="EgeriaCorp">Egeria Corporation</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

function mapProps(state) {
    const { layout } = state;
    return {
        layout
    };
}

const connectedFooter = connect(mapProps)(Footer);
export { connectedFooter as Footer };