import React from 'react';
import { connect } from 'react-redux';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import { Loader } from '../../Shared/Loader';
import '../../../Styles/Organization.css';
import { Registration_AutoSuggestion } from '../../Account/RegisterPage';
import { Previews } from './OrganizationImageUpload';
import { OrganizationList, OrganizationView } from '.';
import ReactTooltip from "react-tooltip";
import { EditOrganizationIndustries } from './EditOrganizationIndustries';
import { EditOrganizationTechnologies } from './EditOrganizationTechnologies';
import { EditOrganizationImpact } from './EditOrganizationImpact';
import { EditOrganizationNAICS } from './EditOrganizationNAICS';
import { EditOrganizationSAM } from './EditOrganizationSAM';
import { EditOrganization8A } from './EditOrganization8A';
import { EditOrganizationWOB } from './EditOrganizationWOB';
import { EditOrganizationVOB } from './EditOrganizationVOB';
import { EditOrganizationHubzone } from './EditOrganizationHubzone';
import { EditOrganizationSB } from './EditOrganizationSB';
import { EditOrganizationDUNS } from './EditOrganizationDUNS';
import { organizationService, locationsService } from '../../../_services';


const options = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
];

class EditOrganizationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_Logo: true,
        }

        this.removePreview = this.removePreview.bind(this);
    }

    removePreview() {
        this.setState({
            show_Logo: false,
        })
    }

    render() {
        const { submitting, submitted, whitelabel,
            org, organization_namesInput,
            organizationNameError, organizationStateError, organizationCountryError,
            state, country
        }  = this.props;
        const { organization } = this.props.org;
        const { show_Logo } = this.state;


        return (
            <div>
                <div id="mainSettings">
                    {submitting && <Loader />}
                    <div className="row">
                        <div>
                            <Button 
                                onClick={this.props.hideAddEditOrganization} 
                                style={{marginBottom: 30, background: whitelabel ? whitelabel.button_Color : "#29a"}}
                            >
                                ← &nbsp;&nbsp;Back
                            </Button>
                        </div>
                        <div style={{paddingLeft: 15, paddingRight: 15, maxWidth: 500}}>
                            <h2>Company Logo</h2>
                            <Previews removePreview={this.removePreview} />
                            {organization.logo_URL && show_Logo && <img src={organization.logo_URL} width="250" style={{marginTop: "2.5%"}}/>}
                        </div>
                        <div className="col-lg-6">
                            <div className="text_section">
                                <h2>Company Name</h2>
                                <input id="organization_Name" name="organization_namesInput" value={organization_namesInput} required onChange={this.props.updateOrganizationName} />
                                {organizationNameError && <p style={{color:"indianred", fontWeight: 600, fontSize: 14}}>This organization name already exists.</p>}
                            </div>
                            <div className="text_section">
                                <h2>Website</h2>
                                <input name="website_URL" value={organization.website_URL ? organization.website_URL : ""} onChange={this.props.handleChange}/>
                            </div>
                            <div className="text_section">
                                <h2>Company Description</h2>
                                <textarea id="organizationTextArea" name="description" value={organization.description ? organization.description : ''} onChange={this.props.handleChange}></textarea>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="text_section">
                                <h2>Company Address</h2>
                                <label style={{marginTop: 10}}>Street Address</label>
                                <input name="address" value={organization.address} onChange={this.props.handleChange}/>
                                <label style={{marginTop: 10}}>City</label>
                                <input name="city" value={organization.city} onChange={this.props.handleChange}/>
                                <label style={{marginTop: 10}}>State</label>
                                <Registration_AutoSuggestion 
                                    style={{display: "inline-block"}}
                                    categories={{"state": state ? state : []}} 
                                    categoryName={"state"}
                                    currentSelectedCategories={[organization.state ? organization.state : ""]}
                                    handleCategorySelection={this.props.handleLocationSelection}
                                    handleCategoryInput={this.props.handleLocationInput}
                                    />
                                    {organizationStateError && <p style={{color:"red"}}>Please select a valid input.</p>}
                                <label style={{marginTop: 10}}>ZipCode</label>
                                <input name="zipCode" value={organization.zipCode} onChange={this.props.handleChange}/>
                                <label style={{marginTop: 10}}>Country</label>
                                <Registration_AutoSuggestion 
                                    style={{display: "inline-block"}}
                                    categories={{"country": country ? country : []}} 
                                    categoryName={"country"}
                                    currentSelectedCategories={[organization.country ? organization.country : ""]}
                                    handleCategorySelection={this.props.handleLocationSelection}
                                    handleCategoryInput={this.props.handleLocationInput}
                                />
                                {organizationCountryError && <p style={{color:"red"}}>Please select a valid input.</p>}
                            </div>
                            <div className="text_section">
                                <h2>Phone Number</h2>
                                <input name="phone_Number" value={organization.phone_Number} onChange={this.props.handleChange}/>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style={{marginTop: 75}}/>
                <div id="mainSettings">
                    <div className="row" style={{marginTop: "5%"}}>
                        <h1 id="dashHeader">Advanced Settings</h1>
                        <div className="col-lg-6">
                            <EditOrganizationSAM 
                                org={org} 
                                options={options}
                                handleSelectionChange={this.props.handleSelectionChange}
                            />
                            <EditOrganization8A 
                                org={org} 
                                options={options}
                                handleSelectionChange={this.props.handleSelectionChange}
                            />
                            <EditOrganizationWOB 
                                org={org} 
                                options={options}
                                handleSelectionChange={this.props.handleSelectionChange}
                            />
                            <EditOrganizationVOB 
                                org={org} 
                                options={options}
                                handleSelectionChange={this.props.handleSelectionChange}
                            />
                            <EditOrganizationHubzone 
                                org={org} 
                                options={options}
                                handleSelectionChange={this.props.handleSelectionChange}
                            />
                            <EditOrganizationSB
                                org={org} 
                                options={options}
                                handleSelectionChange={this.props.handleSelectionChange}
                            />
                        </div>
                        <div id="advancedSettingsTextAreas" className="col-lg-6">
                            <EditOrganizationDUNS
                                org={org}
                                handleChange={this.props.handleChange}
                            />
                            <EditOrganizationNAICS
                                org={org} 
                                handleCategoryInput={this.props.handleCategoryInput}
                                handleCategorySelection={this.props.handleCategorySelection}
                            />
                            <EditOrganizationIndustries 
                                org={org} 
                                handleCategoryInput={this.props.handleCategoryInput}
                                handleCategorySelection={this.props.handleCategorySelection}
                            />
                            <EditOrganizationTechnologies
                                org={org} 
                                handleCategoryInput={this.props.handleCategoryInput}
                                handleCategorySelection={this.props.handleCategorySelection}
                            />
                            <EditOrganizationImpact
                                org={org} 
                                handleCategoryInput={this.props.handleCategoryInput}
                                handleCategorySelection={this.props.handleCategorySelection}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        organization,
        whitelabel,
    };
}

const connectedEditOrganizationForm = connect(mapStateToProps)(EditOrganizationForm);
export { connectedEditOrganizationForm as EditOrganizationForm };