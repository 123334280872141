import React, {useEffect, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import '../../../Styles/SettingsStyles/ImageUpload.css';
import { baseStyle, activeStyle, acceptStyle, rejectStyle} from '../../../Styles/SettingsStyles/ImageUploadStyles.js';

export function Previews(props) {
  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      acceptedFiles.forEach((file) => {
        imageUpload(file);
        props.removePreview();
      })
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject
  ]);

  const imageUpload = (file) => {
    getBase64(file).then(base64 => {
      localStorage.setItem('organizationLogo', base64);
      console.debug("file stored",base64);
    });
  };
  
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file);
  });
}

  const thumbs = files.map(file => (
    <div className='previewThumb' key={file.name}>
      <div className='previewThumbInner'>
        <img id="previewThumbImg"
          src={file.preview}
        />
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="container" id="imgUploadContainer">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        {isDragActive ? "Drop your image here." : 'Click me or drag a file to upload!'}
      </div>
      <aside className='thumbsContainer'>
        {thumbs}
      </aside>
    </section>
  );
}