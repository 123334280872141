import React, { useReducer, Fragment } from 'react';
import { Registration_AutoSuggestion } from '.';

export class Registration_ReferralSource extends React.Component {
    constructor(props) {
        super(props);
        const {user, nav} = this.props;

        const referralSelection = user.Referral;
        let otherReferral = "";
        if (referralSelection.toLowerCase().includes("other")) {
            otherReferral = referralSelection.split(" - ")[1];
        }
        this.state = {
            user: user,
            nav: nav,
            submitted: false,
            referralSelection: referralSelection,
            otherReferral: otherReferral,
            themeColor: ''
        }
    }

    componentDidMount() {
        this.setState({
            themeColor: localStorage.getItem('themeColor')
        })
    }

    handleNavigation = (type) => {
        var {nav} = this.state;
        var user = Object.assign({}, this.state.user);
        var valid = true;
        if (type === 'next') {
            this.setState({
                submitted: true
            });
            nav++;
        } else if (type === "prev") {
            nav--;
        }

        if (valid) {
            let referralSelection = this.state.referralSelection;
            if (referralSelection.toLowerCase().includes("other")) {
                referralSelection = "Other - ";
            }
            user.Referral = referralSelection + this.state.otherReferral;
            this.props.handleFormInputs({user, nav});
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState(
            {
                [name]: value 
            }
        );
    }

    render() {
        const {referralSelection} = this.state;
        const optionList = ["Select", "Google/Search", "Friend or colleague", "Linkedin", "Facebook", "Twitter", "Other"];
        const options = optionList.map(optionValue =>{
            return (
                <Fragment key={optionValue}>
                    {!optionValue.toLowerCase().includes("select") ?
                        !optionValue.toLowerCase().includes("other") ? 
                            <option value={optionValue}>{optionValue}</option>
                            : <option value="Other - ">{optionValue}</option>
                        : <option value="">{optionValue}</option>}
                </Fragment>
            )
        });
        
        return (
            <div id="form_3" className="forms align-left">
                <h1 className="regHeader" style={{color: this.state.themeColor}}>Who referred you?</h1>
                <hr/>
                    <h2>
                        <strong>These fields are optional.</strong>
                    </h2>
                <div>
                    <div>
                        <div className="question_section">
                            <label required>Referral (optional)</label>
                            <select name="referralSelection" 
                            value={referralSelection.toLowerCase().includes("selected") ? 
                                optionList[0] 
                                : referralSelection} 
                            onChange={this.handleChange}>
                                {options}
                            </select>
                        </div>
                        {this.state.referralSelection.toLowerCase().includes("other") &&
                            <div className="question_section">
                                <label required>Please specify (optional)</label>
                                <input name="otherReferral" 
                                    disabled={this.state.referralSelection.toLowerCase().includes("other") ? false : true}
                                    onChange={this.handleChange}
                                    value={this.state.otherReferral}></input>
                            </div>
                        }
                    </div>
                    <div>
                        {/* <button className="backButton" onClick={() => this.handleNavigation('prev')}>Back</button>
                        <button className="nextButton" onClick={() => this.handleNavigation('next')}>Next</button> */}
                        <button 
                            className="backButton" 
                            onClick={() => this.handleNavigation('prev')}
                            style={{color: this.state.themeColor, borderColor: this.state.themeColor}}
                            >
                                Back
                            </button>
                        <button 
                            className="nextButton" 
                            onClick={() => this.handleNavigation('next')}
                            style={{background: this.state.themeColor, border: this.state.themeColor}}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}