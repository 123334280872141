import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import {StripeBilling, StripeCart, StripeBillingUpdated, StripeItem} from '.';
import '../../../Styles/StripeProduct.css';
import { Loader } from '../../Shared/Loader';
import { StripeOrderSummary } from './StripeOrderSummary';
import {userservice} from '../../../_services';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";

export class StripeMultipleCheckout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            stripeError: false,
            updateCard: false,
            errorMessage: ""
        }
    }

    componentDidMount() {
        const path = window.location.pathname;

        if (path.toLowerCase() != "/register" && path.toLowerCase() != "/login" && !path.toLowerCase().includes("/opengrants_"))
        {
            const pathSplit = path.split('/').pop();
            this.setState({
                linked_Listing_Id: pathSplit
            });
        }
    };

    redirectToPage = () => {
        const listing_Id = this.state.linked_Listing_Id;
        var page_redirect = localStorage.getItem('redirectPageTo');
        if (!page_redirect) {
            if (this.props.productType.includes("opengrants_") || !listing_Id) {
                page_redirect = '/dashboard';
            }
            else {
                page_redirect = listing_Id;
            }
        }

        var user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token)
            window.location.href = page_redirect;
        else {
            window.location.href = '/login';
        }
    }

    enableBillingSubmission = () => {
        document.getElementById('placeOrder').disabled = false;
    }

    updateStripeError = (stripeError) => {
        this.setState({
            stripeError
        });
    }

    updateBillingInfo = (type) => {
        if (type === 'card') {
            this.setState({
                updateCard: true
            });
        }
        else if (type === 'address') {
            this.setState({
                updateAddress: true
            });
        } else {
            this.setState({
                updateCard: false,
                updateAddress: false
            });
        }
    }

    updateErrorMessage = (message) => {
        this.setState({
            errorMessage: message
        });
    }

    render() {
        const path = window.location.pathname;
        const {stripeError, updateCard} = this.state;
        const { consolidatedProducts, selectedPrices,
            selectedPriceIds, selectedRecurringPriceIds,
            products, page, allPrices, prices, total,
            discount, discountStatus, invoiceDiscount,
            allowRemove,
            invoiceId } = this.props.checkoutState;
        const {
            purchaseSuccess,
            loading,
            message,
            statusType } = this.props.purchaseProduct;
        const {productType, updatePlan} = this.props;
        const {errorMessage} = this.state;
        let user = localStorage.getItem("user");

        //set selected products
        var selectedPris =
        <StripeCart
            allowRemove={true}
            total={total}
            selectedPrices={selectedPrices}
            consolidatedProducts={consolidatedProducts}
            handleCoupon={this.props.handleCoupon}
            discount={discount}
            discountStatus={discountStatus}
            invoiceDiscount={invoiceDiscount}
            handleCoupon={this.props.handleCoupon}
            removeFromCart={this.props.removeFromCart}
            updateSelectedProduct={this.props.updateSelectedProduct}
            productType={this.props.productType} />

        //set unselected products
        var selectedProducts = Object.keys(selectedPrices);
        var allProducts = Object.keys(consolidatedProducts);
        var otherPrices = null
        if (selectedProducts.length !== allProducts.length) {
            otherPrices = allProducts.map((productId) => {
                // if (!selectedProducts.includes(productId))
                //     return (
                //         <StripeAddOn key={"otherPrices_"+productId}
                //             prices={consolidatedProducts[productId]}
                //             selectedPriceIds={selectedPriceIds}
                //             selectedRecurringPriceIds={selectedRecurringPriceIds}
                //             updateSelectedProduct={this.props.updateSelectedProduct}
                //             consolidatedProducts={consolidatedProducts} />
                //     )
                if ((selectedProducts.length == 0) || (selectedProducts.length > 0 && !selectedProducts.includes(productId)))
                    return (
                        <Fragment key={"keys_"+productId} >
                            <StripeItem
                                allowRemove={false}
                                selectedPrices={selectedPrices}
                                prices={consolidatedProducts[productId]}
                                removeFromCart={this.props.removeFromCart}
                                updateSelectedProduct={this.props.updateSelectedProduct}
                                consolidatedProducts={this.props.consolidatedProducts}
                                discount={discount} />
                        </Fragment>
                    )
            });
        }

        let productClass = "stripe-product overflow";
        // if ((!invoiceId && !user) || !user) {
        //     productClass += " grey-background height-full";
        // }

        let successMessage = "";
        if (purchaseSuccess == "reset") {
            if (updatePlan) {
                this.props.updateBillingInfo();
            }

            var prods = Object.keys(selectedPrices);
            var i = 0;

            successMessage = prods.map((key) => {
                i+=1;
                return (
                    <p key={"message_"+i} className="gap-bottom">{selectedPrices[key].product.metadata["purchaseSuccessMessage"]}</p>
                )
            });
        }

        if (loading) this.enableBillingSubmission();

        return (
            <div className={productClass}>
                {/* {purchaseSuccess && !invoiceId && user && productType === "portal_standard" && */}
                {!updatePlan && purchaseSuccess && productType && productType.includes("opengrants_") &&
                    <div className='payment-form background-transparent center margin-move-left-40'>
                        <div className='adjustable-width-sm background-white pop'>
                            <h1>Welcome to Portal!</h1>
                            <hr></hr>
                            <p>You'll now be directed to your funding dashboard where you can search and save programs, view your matches and a whole lot more!</p>
                            <p>If you have any questions, please click the chat icon or email us at
                            <a className="og-color" href="mailto:support@opengrants.io"> support@opengrants.io</a>.</p>
                            {/* <button id="purchaseSuccessButton" onClick={this.redirectToPage}>Continue</button> */}
                            <Button
                                id="purchasSuccessButton"
                                color="secondary"
                                onClick={this.redirectToPage}
                                style={{minWidth:200}}
                            >
                                Continue
                            </Button>
                        </div>
                    </div>
                }

                {/* {purchaseSuccess && productType !== "portal_standard" && */}
                {purchaseSuccess && productType && !productType.includes("opengrants_") &&
                    <div className='payment-form background-transparent center margin-move-left-40'>
                        <div className='adjustable-width-sm background-white pop'>
                        <h2 className="gap-bottom">Thank You!</h2>
                        <div>
                            {successMessage}
                            <p>If you have any questions or concerns, email
                            <a className="og-color" href="mailto:support@opengrants.io"> support@opengrants.io</a>.</p>
                            <p>We look forward to working with you.</p>
                        </div>
                        <Button
                            id="purchasSuccessButton"
                            color="secondary"
                            onClick={() => {window.location.href = "/search"}}
                            style={{minWidth:200}}
                        >
                            Continue
                        </Button>
                        </div>
                    </div>}

                {productType === "grantwriter" &&
                    <h1 id="checkoutHireAGW">Hire a Grant Writer</h1>
                }
                {/* {productType === "portal_standard" && */}
                {/* {productType && productType.includes("opengrants_") &&
                    <div>
                        <h1 id="checkoutPortalTitle">Portal</h1>
                        <h1 id="signUpTagLine">
                            <span>The easy way to <span style={{color: "teal", textDecoration: "underline"}}>find, win and manage grants.</span></span>
                        </h1>
                        <hr id="portalPaywallTitle" />
                        <h1 id="checkoutTry14">Try Portal for 14 days for $1.</h1>
                    </div>
                } */}
                <div className="adjustable-width">
                    {(!purchaseSuccess || (user && purchaseSuccess)) &&
                    <div id={path == '/checkout/grantwriter' ? "stripePaywallCheckoutGrantWriter" : "stripePaywallCheckout"}>
                        <div className="col-md-12">
                            <div className="error-color gap-bottom col-md-8">
                                {message && <h3 className="font-sm" style={{color: "red"}}>{message}</h3>}
                                {errorMessage && <h3 className="font-sm" style={{color: "red"}}>{errorMessage}</h3>}
                            </div>
                            {stripeError && <div className="error-color gap-bottom col-md-8" role="alert">Something went wrong. In order for stripe to work properly, please refresh the page after enabling javascript and disabling any ad blocker. If this problem persists, text (707) 247-8773. You can also email support at support@opengrants.io.</div>}
                        </div>
                        <div className="col-md-8">
                            {selectedPris}

                            {otherPrices &&
                                <div className="payment-form gap-top background-white pop gap-bottom">
                                    <h2>Add On Services</h2>
                                    {otherPrices}
                                </div>
                            }

                            {allPrices &&
                                <StripeBilling
                                    purchaseProductWithMethod={this.props.purchaseProductWithMethod}
                                    errorMessage={statusType === "card" ? message : ""}
                                    handleCoupon={this.props.handleCoupon}
                                    discount={discount}
                                    discountStatus={discountStatus}
                                    invoiceId={invoiceId}
                                    updateStripeError={this.updateStripeError}
                                    updateBillingInfo={this.updateBillingInfo}
                                    updateCard={updateCard} />
                            }
                        </div>
                        <div className="col-md-4">
                            <div>
                                <StripeOrderSummary
                                    total={total}
                                    selectedPrices={selectedPrices}
                                    handleCoupon={this.props.handleCoupon}
                                    discount={discount}
                                    discountStatus={discountStatus}
                                    invoiceDiscount={invoiceDiscount}
                                    consolidatedProducts={consolidatedProducts}
                                    billedToday={this.props.billedToday}
                                    productType={this.props.productType} 
                                    updateBillingInfo={this.updateBillingInfo}
                                    updateErrorMessage={this.updateErrorMessage}
                                />
                            </div>
                            <div className='payment-form'>
                                <img className="gap-top gap-bottom" src="https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/powered_by_stripe.png" />
                            </div>
                        </div>
                        {loading && <Loader />}
                    </div>
                    }

                    {purchaseSuccess && invoiceId &&
                    <div className='payment-form col-md-12'>
                        <StripeBillingUpdated />
                    </div>
                    }
                </div>
            </div>
        );
    }
}
