import React, { Fragment } from 'react';
import '../../../Styles/SettingsStyles/Billings.css';

export class StripeItem extends React.Component {
    constructor(props) {
        super(props);
    }

    updateSelectedProduct = (price) => {
        let productName = price.product.name;
        var amount = price.unit_amount;
        const productPrice = (amount/100).toFixed(2);

        let el = document.getElementById(productName+productPrice);
        if (el)
            el.click();
    }

    render() {
        const {allowRemove, prices, selectedPrices, invoiceDiscount, discount} = this.props;
        let displayPrices = null;
        let total = 0;
        let productId = "";
        let productName = "";
        let productDescription = null;
        let productTitle = "";
        let displayDiscount = null;
        let priceGroup = "";

        if (prices.length > 0) displayPrices = prices.map((price) => {
            if (productId === "") productId = price.product.id;
            if (productName === "") productName = price.product.name;
            if (priceGroup === "") priceGroup = price.product.metadata["group"];
            // const description = price.product.description;
            // const description = price.product.metadata && price.product.metadata["description"] ? price.product.metadata["description"] : "";
            productTitle = price.product.name;
            const description = price.product.metadata && price.product.metadata["description"] ? price.product.metadata["description"] : price.product.description;
            if (description && !productDescription) 
            {
                let i = 0;
                const descriptionSplit = description.split('\\n');
                productDescription = descriptionSplit.map((line) => {
                    if (line.includes('\\b')) {
                        let lineSplit = line.split('\\b');
                        let bulletPoints = lineSplit.map((line) => {
                            if (line != "")
                            {i+=1;
                            return (
                            <li key={"key_bullet_"+i}>
                                {line}
                            </li>
                            )}
                        });
                        i+=1;
                        return (
                            <Fragment key={"key_list_"+i}>
                                <br/>
                                <ul>
                                    {bulletPoints}
                                </ul>
                            </Fragment>
                        )
                    }
                    i+=1;
                    return (
                        <p key={"key_"+ i}>
                            {line}
                            {/* <br/> */}
                        </p>
                    )
                })
            } 
            const interval = price.recurring ? price.recurring.interval : "";
            var amount = price.unit_amount;
            const productPrice = (amount/100).toFixed(2);
            var billingPrice = productPrice;
            if (interval === "year") {
                var billingSplit = (billingPrice/12).toFixed(2);
                if ((billingSplit*12).toFixed(2) === productPrice) billingPrice = billingSplit;
            }
            const productRecurringString = interval != "" ? " / month"  : "";
            const productPriceString = "$" + billingPrice + productRecurringString;

            // let checked = selectedPriceIds.includes(price.id) || selectedRecurringPriceIds.includes(price.id)
            let selected = selectedPrices[price.product.id];
            let checked = selected && (selectedPrices[price.product.id].id === price.id);
            let borderColor = checked ? "2px solid #999" : "1px solid #bbb";
            let total = productPrice;
            let couponLimit = "";
            let couponLimit_Other = "";
            let productPrice_Discount = 0;
            let discountMessage = "";

            //set discount
            if (discount) 
            // {
            //     //general discount
            //     if (!discount.product || discount.product === "") {
            //         let discountTotal = "";
    
            //         if (discount.type === "percent") {
            //             discountTotal = (total*discount.amount*0.01);
            //         } else if (discount.type === "amount") {
            //             discountTotal = discount.amount;
            //         }
                    
            //         if (discountTotal <= total) {
            //             total = total-discountTotal;
            //             let discountString = !discount.name.includes("off") ? 
            //                 discount.type === "amount" ? 
            //                 "Discount - " + discount.name + " ($" + discount.amount/100 + " off)" + ": " :
            //                 "Discount - " + discount.name + " (" + discount.amount + "% off)" + ": "
            //                 : "Discount - " + discount.name + ":";
            //                 displayDiscount = 
            //             <div className="radio-elements">
            //                 <label className="col-md-6">
            //                     {discountString}
            //                 </label>
            //                 <label className="col-md-6">
            //                     {"-$"+discountTotal/100}
            //                 </label>
            //             </div>
                        
            //             if (discount.repeat) {
            //                 if (parseInt(discount.repeat) >= 12) {
            //                     couponLimit = parseInt(discount.repeat)/12 + (parseInt(discount.repeat)/12 > 1 ? " years": " year");
            //                 } else {
            //                     couponLimit = discount.repeat + (parseInt(discount.repeat) > 1 ? " months": " month");
            //                 }
            //             }

            //             if (!price.product.metadata["trialPrice"]) {
            //                 priceTotal -= discountTotal;
            //             }
            //         } 
            //     } else 
                {
                    if (!(interval === "year" && parseInt(discount.repeat) < 12) && 
                    !(interval !== "month" && interval !== "year" && discount.repeat && discount.repeat != "once")) {

                    let amount = (price.unit_amount/100).toFixed(2);
                    let priceString = [];
                    if (discount.product.includes("prod")) {
                        if (discount.product.includes(",")) {
                            var productsSplit = discount.product.split(", ");
                            productsSplit.map((prod) => {
                                if (this.props.consolidatedProducts && this.props.consolidatedProducts[prod])
                                    this.props.consolidatedProducts[prod].map((pri) => {
                                        priceString.push(pri)
                                    });
                            });
                        } else {
                            priceString = this.props.consolidatedProducts[discount.product];
                        }

                        if (priceString.length > 0) {
                            discount.product = "";
                            priceString.map((price_id) => {
                                discount.product += price_id.id;
                            });
                        }
                    }

                    if ((!discount.product || discount.product === "") || (discount.product.includes('opengrants_test_coupon')) || (discount.product && discount.product !== "" && discount.product.includes(price.id))) {
                        let discountTotal = 0;

                        if (discount.type === "percent") {
                            if (!parseInt(discount.repeat) || parseInt(discount.repeat) >= 12 || interval === "month")
                                discountTotal = (total*discount.amount*0.01);
                            else {
                                discountTotal = (total/12*discount.amount*0.01*parseInt(discount.repeat));
                            }
                        } else if (discount.type === "amount") {
                            discountTotal = (discount.amount/100).toFixed(2);
                            if (billingPrice != productPrice) {
                                discountMessage = ((total - discountTotal)/12).toFixed(2) * 12 === (total - discountTotal) ? ((total - discountTotal)/12).toFixed(2) + " / month" : (total - discountTotal).toFixed(2) + " / year";
                            } else {
                                discountMessage = (total - discountTotal).toFixed(2) + " / " + interval;
                            }
                            // if (discount.repeat === "once") {
                            //     discountMessage += " for 1 " + interval;
                            // } else if (discount.repeat) {
                            //     discountMessage += " for " + discount.repeat + " " + interval;
                            // }
                        }
                        
                        if (parseInt(discountTotal) <= parseInt(amount)) {
                            total = total - discountTotal;
                            // if (discount.type === "amount") {
                            //     discountMessage = total + " / " + interval;
                            //     if (discount.repeat === "once") {
                            //         discountMessage += "for one " + interval;
                            //     } else if (discount.repeat) {
                            //         discountMessage += "for " + discount.repeat + " " + interval;
                            //     }
                            // }
                        
                            let discountString = !discount.name.includes("off") ? 
                                discount.type === "amount" ? 
                                "Discount - " + discount.name + " ($" + discount.amount/100 + " off)" + ": " :
                                "Discount - " + discount.name + " (" + discount.amount + "% off)" + ": "
                                : "Discount - " + discount.name + ":";
                                displayDiscount = 
                            <div className="radio-elements">
                                <label className="col-md-6">
                                    {discountString}
                                </label>
                                <label className="col-md-6">
                                    {"-$"+discountTotal/100}
                                </label>
                            </div>
                            
                            if (discount.repeat) {
                                if (discount.repeat === "once") {
                                    if (interval === "month") {
                                        couponLimit = "1 month";
                                        couponLimit_Other = couponLimit;
                                    } else if (interval === "year") {
                                        couponLimit = "1 year";
                                        couponLimit_Other = couponLimit;
                                    }
                                }
                                else if (parseInt(discount.repeat) >= 12) {
                                    couponLimit = parseInt(discount.repeat)/12 + (parseInt(discount.repeat)/12 > 1 ? " years": " year");
                                    couponLimit_Other = couponLimit;
                                } else {
                                    couponLimit = discount.repeat + (parseInt(discount.repeat) > 1 ? " months": " month");
                                    couponLimit_Other = "1 year";
                                    if (interval === "month")
                                        productPrice_Discount = (12 - parseInt(discount.repeat))*productPrice;
                                    else if (interval === "year")
                                        productPrice_Discount = (12 - parseInt(discount.repeat))*productPrice/12;
                                }
                            }
                        } 
                    }
                }
                }
            // } 
            // else if (invoiceDiscount) {
            //     let discountString = !invoiceDiscount.name.includes("off") ? 
            //     invoiceDiscount.type === "amount" ? 
            //         "Discount - " + invoiceDiscount.name + " ($" + invoiceDiscount.amount/100 + " off)" + ": " :
            //         "Discount - " + invoiceDiscount.name + " (" + invoiceDiscount.amount + "% off)" + ": "
            //         : "Discount - " + invoiceDiscount.name + ":";
            //     displayDiscount = 
            //     <div className="radio-elements">
            //         <label className="col-md-6">
            //             {discountString}
            //         </label>
            //         <label className="col-md-6">
            //             {"-$"+invoiceDiscount.amount/100}
            //         </label>
            //     </div>
            // }

            return (
            <Fragment key={productName+productPrice}>
            <div style={{alignSelf: "flex-end"}}>
                {interval === "year" && this.props.productType === "portal_standard" && <div style={{position: "relative", zIndex: 1}}>
                    <div style={{backgroundColor: "#4c85b7", color: "white", margin: "10px 5px 0px 8px", borderTopRightRadius: "5px", padding: "5px", position: "relative", paddingLeft: "15px"}}>
                        20% off Annual Plans
                        <div style={{backgroundColor: "#1c5587", color: "white", marginTop: 0, borderBottomRightRadius: "5px", position: "absolute", width: "50%", top: "6px", right: "0px", height: "30px", zIndex: "-1"}}></div>
                    </div>
                </div>}
                <div className="radio-elements" style={{cursor: "pointer", margin: 10, padding: 10, border: borderColor, position: "relative", marginTop: 0, zIndex: 1, backgroundColor: "white"}} onClick={() => this.updateSelectedProduct(price)}>
                    {(prices.length > 1 || !selected) && 
                    <div>
                        <input 
                            id={productName+productPrice} 
                            type='radio' 
                            defaultChecked={checked} 
                            name={"product_" + price.product.id}  
                            onChange={() => this.props.updateSelectedProduct(price)} />
                        <h2 className="inline bold">{interval === "month" ? "Monthly" : "Annual"}</h2>
                    </div>}
                    {amount !== 0 ? 
                        productPrice === total ? 
                            <label style={{cursor: "pointer"}}>US${billingPrice} {interval === "month" || billingPrice !== productPrice ? 
                                " / month" : interval === "year" ? " / year" : ""}</label> : 
                            <label style={{cursor: "pointer"}}><s style={{color: "red"}}><span style={{color: "#aaa"}}>US${billingPrice} {interval === "month" || billingPrice !== productPrice ? 
                            " / month" : interval === "year" ? " / year" : ""}</span></s></label>
                        : <label style={{cursor: "pointer"}}>Pending Scoping</label>}
                    {amount !== 0 && productPrice !== total &&
                    <div>
                        <label style={{cursor: "pointer", color: "red"}}>US${interval === "month" ? (total/1).toFixed(2) + " / month" : interval === "year" && discount && discount.type === "amount" ? discountMessage : interval === "year" ? billingPrice !== productPrice ? (productPrice/12*(1-discount.amount*0.01)).toFixed(2) + " / month" : (productPrice*(1-discount.amount*0.01)).toFixed(2) + " / year" : (total/1).toFixed(2) } {couponLimit ? " for " + couponLimit : ""}</label>
                        {/* {couponLimit.includes("month") && interval === "year" && 
                            <label style={{cursor: "pointer", color: "red"}}>After {couponLimit_Other}: US${interval === "month" ? (productPrice/1).toFixed(2) + " / month" : interval === "year" ? billingPrice !== productPrice ? (productPrice/12).toFixed(2) + " / month" : (productPrice/1).toFixed(2) + " / year" : (productPrice/1).toFixed(2) }</label>
                        } */}
                    </div>}
                    {amount !== 0 && interval ?
                        <label style={{cursor: "pointer"}}>Billed {interval === "month" ? "monthly" : "annually"} for US${((total)/1).toFixed(2)} {couponLimit ? interval === "month" ? " for " + couponLimit :  " for " + couponLimit_Other : ""}</label> :
                        <label style={{cursor: "pointer"}}>Billed Once for US${(total/1).toFixed(2)}</label> 
                    }
                    {amount !== 0 && interval === "month" && couponLimit &&
                    <label>After {couponLimit}: Billed {interval === "month" ? "monthly" : "annually"} for US${(productPrice/1).toFixed(2)}</label>
                    }
                    {amount !== 0 && interval === "year" && couponLimit &&
                    <label>After {couponLimit_Other}: Billed {interval === "month" ? "monthly" : "annually"} for US${(productPrice/1).toFixed(2)}</label>
                    }
                </div>
            </div>
            </Fragment>
            )
        });

        return (
            <div>
                {allowRemove && <a className="button-empty" onClick={() => this.props.removeFromCart(productId)}>x</a>}
                <div className="overflow indent-left indent-right">
                    {/* <p className="gap-bottom">{productDescription}</p> */}
                    <h2>{productTitle}</h2>
                    <div>{productDescription}</div>
                    {prices.length > 1 && <label>Select a Billing Cycle:</label>}
                    <div className="flex-1-1">
                        {displayPrices}
                    </div>
                    {priceGroup.includes("grantwriter default") && 
                        <div style={{marginTop: "30px"}}>
                            <label style={{marginBottom: "15px", fontSize: 15, color: "#555"}}>Is there anything else we should know about your company? Past grants awarded, existing government relationships, a brief description of your unique value or technology, etc. This optional step will help the grant writer better scope your options and prepare for your intro meeting.</label>
                            <textarea id="userNotes"className="radio-elements" style={{width: "100%", height: "100px", padding: 10, position: "relative", marginTop: 0, zIndex: 1, backgroundColor: "white"}}></textarea>
                        </div>
                    }
                    <hr className="line-sm" />
                </div>
            </div>
        );
    }
}