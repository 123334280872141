import { authenticateConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loading: true, user } : {};

export function setUser(state = initialState, action) {
  switch (action.type) {
    case authenticateConstants.SETUSER_REQUEST:
      return {
        loading: true,
        user: action.user
      };
    case authenticateConstants.SETUSER_SUCCESS:
      return {
        loading: false,
        user: action.user
      };
    case authenticateConstants.SETUSER_FAILURE:
      return{
        error: "There was an error processing your request."
      }
    case authenticateConstants.LOGOUT:
      return {};
    default:
      return state
  }
};

export function authenticateUser(state = {}, action) {
  switch (action.type) {
    case authenticateConstants.LOGINUSER_REQUEST:
      return {
        loading: true
      };
    case authenticateConstants.LOGINUSER_SUCCESS:
      return {
        items: action.user
      };
    case authenticateConstants.LOGINUSER_FAILURE:
      return { 
        error: "There was an error processing this request."
      };
      default:
      return state
  }
};

export function updatePassword(state = {}, action) {
  switch (action.type) {
    case authenticateConstants.UPDATEPASSWORD_REQUEST:
      return {
        loading: true
      };
    case authenticateConstants.UPDATEPASSWORD_SUCCESS:
      return {
        items: action.user
      };
    case authenticateConstants.UPDATEPASSWORD_FAILURE:
      return { 
        error: "There was an error processing this request."
      };
      default:
      return state
  }
};

export function confirmEmail(state = {registration: false, confirming: true, status: ""}, action) {
  console.log(action);
  switch (action.type) {
    case authenticateConstants.CONFIRMEMAIL_REGISTRATIONCOMPLETE:
      return {
        status: "complete_registration"
      }
    case authenticateConstants.CONFIRMEMAIL_REQUEST:
      return {
        status: "request"
      };
    case authenticateConstants.CONFIRMEMAIL_SUCCESS:
      return {
        status: "success",
      };
    case authenticateConstants.CONFIRMEMAIL_FAILURE:
      return { 
        status: "error"
      };
    case authenticateConstants.CONFIRMEMAIL_SENTFAILURE:
      return {
        status: "sent_failure",
      };
      case authenticateConstants.CONFIRMEMAIL_SENTSUCCESS:
        return {
          status: "sent_success",
          email: action.email
        };
    default:
      return state
  }
};