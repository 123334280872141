import React from 'react';
import { connect } from 'react-redux';
import { settingsActions } from '../../../_actions';
import { searchService } from '../../../_services';
import { Loader } from '../../Shared/Loader';
import { Focus_AutoSuggestion } from '../FocusAreas/Focus_Autosuggestion';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import '../../../Scripts/FocusAreas.js';
import '../../../Styles/SettingsStyles/Settings.css';
import '../../../Styles/SettingsStyles/Focus.css';

class Focus extends React.Component {
    constructor(props) {
        super(props);

        const {user} = this.props;
        this.state = {
            user,

            // categories: [],
            loading: true,
            submitted: false,
            submitting: false,
            updatedAlert: false,
            user: JSON.parse(localStorage.getItem('user')),
            
            categories: {
                category: [],
                category_Tags: [],
            },

            categoryInput: user.category,
            category_TagsInput: "",

            categoryError: false,
            category_TagsError: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        let categoryTags = await searchService.getAllCategoryTags();

        this.setState ({
            categories: {
                category_Tags: categoryTags,
            },
            loading: false,
            selected: this.props.user.category_Tags,
        })
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    checkTagValidity(tags, input) {
        var tag_is_valid = JSON.stringify(tags).includes(input);

        return tag_is_valid;
    }

    async handleSubmit(event) {
        this.setState({
            submitting: true,
        });
        
        event.preventDefault();
        const { dispatch } = this.props;
        var {categories, category_TagsInput, category_TagsError} = this.state;
        let user = Object.assign({}, this.state.user);

        if (category_TagsInput) {
            category_TagsError = true;
        }

        if (this.checkTagValidity(categories["category_Tags"], category_TagsInput) == true) {
            category_TagsError = false;
        }

        let submitted = true;
        let updatedAlert = false;
        if (!category_TagsError) {
            await dispatch(settingsActions.updateFocus(user));
            updatedAlert = true;

            setTimeout(() => {
                this.setState({
                    updatedAlert: false
                });
            }, 4000);
        }

        this.setState({
            submitted,
            updatedAlert,
            category_TagsError,
        });

        this.setState({
            submitting: false,
        });
    }

    handleCategorySelection = (categories) => {
        const {user} = this.state;
        this.setState({
            user: {
                ...user,
                ...categories
            }
        });
    }

    handleCategoryInput = (categoryInputs) => {
        this.setState(
            categoryInputs
        )
    }

    render() {
        const { user, loading, categories, submitted, categoryError, category_TagsError, submitting } = this.state;
        const { focus, whitelabel } = this.props;
        var userCategory = (user.category !== "" && user.category !== null) ? [user.category] : [];
        console.log(this.state);
        
        return (
            <div id="mainSettings">
                {submitting && <Loader />}
                <form id="preferences" className="settingsForm" onSubmit={this.handleSubmit}>
                    <input type="hidden" name="discriminator" value={ user.discriminator } />
                    <div id="preferenceBody">
                        <div className="question_section">
                            <p style={{fontSize:18, maxWidth:500}}>
                                Select the categories that best relate to your organization. These will be used to refine your matches and recommendations.
                            </p>
                            <label>Your Tags</label>
                            <Focus_AutoSuggestion 
                                categories={categories} 
                                categoryName="category_Tags"
                                currentSelectedCategories={user.category_Tags}
                                multiple={true}
                                addTags={true}
                                submitted={submitted}
                                errorInput={category_TagsError}
                                handleCategorySelection={this.handleCategorySelection}
                                handleCategoryInput={this.handleCategoryInput}/>
                        </div>
                    </div>
                    <div>
                        <hr style={{marginTop: 35}}/>
                            <h4 style={{fontWeight: 500}}>
                                <Button 
                                    color="info" 
                                    onClick={this.handleSubmit}
                                    style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                                >
                                    Update Preferences
                                    <ArrowForwardIos style={{float: "right", fontSize:32, marginTop: 0, marginLeft:10, marginRight:-10}}/>
                                </Button>
                            </h4>
                        <br />
                    </div>
                    {this.state.updatedAlert &&
                        <div>
                            <br /><br />
                            <div id="settingsUpdatedSuccess" className={`alert-success`}>Preferences have been updated.</div>
                        </div>
                    }
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, categoryList, focus, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        categoryList,
        user,
        focus,
        whitelabel,
    };
}

const connectedProfileFocusPage = connect(mapStateToProps)(Focus);
export { connectedProfileFocusPage as Focus };