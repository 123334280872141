import React from 'react';
import { connect } from 'react-redux';
import { organizationService } from '../../../_services';
import { Registration_AutoSuggestion } from '../../Account/RegisterPage';
import '../../../Styles/Organization.css';

class EditOrganizationIndustries extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            org: this.props.org,
            industries: [],
        }
    }

    componentDidMount() {
        this.getIndustries();
    }

    getIndustries = async() => {
        const {user} = this.props;
        var industries = await organizationService.getIndustries(user.id);

        this.setState({
            industries
        });
    }

    render() {
        const { industries }  = this.state;
        const { org } = this.props;

        let industries_Cat = {"industries": industries};

        return (
            <div style={{paddingBottom: 20}}>
                <h2>Industry</h2>
                <Registration_AutoSuggestion 
                    categories={industries_Cat} 
                    categoryName="industries"
                    currentSelectedCategories={org.industries}
                    // submitted={submitted}
                    handleCategorySelection={this.props.handleCategorySelection}
                    addTags={true}
                    multiple={true}
                    handleCategoryInput={this.props.handleCategoryInput}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        organization,
        whitelabel,
    };
}

const connectedEditOrganizationIndustries = connect(mapStateToProps)(EditOrganizationIndustries);
export { connectedEditOrganizationIndustries as EditOrganizationIndustries };