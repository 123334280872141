import React from 'react';
import { connect } from 'react-redux';
import '../../Styles/Algolia.css';

class HitResult extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props);
        this.state = {
          isFavorite: false,
          searchGroup: {
              search: "",
              user: this.props.user,
              categories: [],
              agencies: [],
              page: 1,
              totalResults: 0,
          },
      };
    }
    

    componentDidMount() {
      this.props.id_arr.push(this.props.listing.hit.objectID);
    }

    render() {
         return (
           <div id="searchHitItem"></div>
         );
       }
}

function mapStateToProps(state) {
    const { setUser } = state;
    const { user } = setUser;
    return {
        user,
    };
}

const connectedResult = connect(mapStateToProps)(HitResult);
export { connectedResult as HitResult };