import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from '../NavMenu';
import { Footer } from '../Footer';

export class Layout extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <NavMenu />
                <Container>
                    <div id="mainLayoutDiv" className="overflow">
                        {this.props.children}
                    </div>
                </Container>
                {/* <Footer /> */}
            </div>
        );
    }
}