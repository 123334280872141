import React, { Fragment } from 'react';
export class StripeProduct extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {prices} = this.props;
        let displayProducts = null;

        //display product
        if (prices) {
            let products = []; //list of products for displaying
            let idx = 0; //consolidated product index
            let productIdxs = {}; //list of consolidated product indexes
            let priceIdIdx = 0; //price index
            let priceIds = {}; // list of price indexes

            //consolidate products and its price(s)
            prices.map((price) => {
                var product = price.product
                if (!product.active) {
                    priceIdIdx+=1;
                    return null;
                }
                var priceId = price.id;
                var productRecurring = price.recurring ? price.recurring.interval : "";
                var productPrice = price.unit_amount/100;
                var productName = product.name;
                var productDescription = product.description;
                const productRecurringString = productRecurring != "" ? " / " + productRecurring : "";
                var productPriceString = {[priceId]: productPrice.toLocaleString() + productRecurringString};

                priceIds[priceId] = priceIdIdx;

                //add product
                if (productIdxs[productName] == undefined) {
                    productIdxs[productName] = idx;

                    products = [
                        ...products,
                        {
                            priceId: [priceId],
                            productPrice: [productPrice],
                            productName,
                            productDescription,
                            productRecurring,
                            productPriceString
                        }
                    ];
                    idx+=1;
                } 
                //update product
                else {
                    const productIdx = productIdxs[productName];
                    let product = products[productIdx];

                    product.priceId.push(priceId);
                    product.productPrice.push(productPrice);
                    product.productPriceString = {
                        ...product.productPriceString,
                        ...productPriceString
                    }

                    products = products.filter((product) =>
                      {
                          return product.productName != productName
                      }   
                    );
                    products.push(product);
                }
                priceIdIdx+=1;
            });

            //return products with its price(s)
            displayProducts = products.map((product) => {
                const productPriceIds = product.priceId;
                var productName = product.productName;
                var productDescription = product.productDescription;
                let productPriceString = product.productPriceString;
                let productPrice = product.proproductPriceStringductPrice;
                let displayPrices = productPriceIds.map((priceId) => {
                    return (
                    <Fragment key={"price_"+priceId}>
                        <p>${productPriceString[priceId]}</p>
                    </ Fragment>
                    )
                });

                return (
                    <div key={"prod_" + productName + productPrice} className="gap-bottom">
                        <label>{productName}</label>
                        <p>{productDescription}</p>
                        {displayPrices}
                    </div>
                )
            });
        }

        return (
            <div>
                {displayProducts}
            </div>
        )
    }
}