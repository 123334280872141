import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/Login.css';
import { setPartnerInfo } from '../../Shared/Partners';
import { authenticateActions } from '../../../_actions';
import { authenticateService } from '../../../_services';
import { Footer } from '../../Shared/Footer';
import TextField from '@material-ui/core/TextField';
// import {StripeCheckout_Paywall} from '../../Stripe/Stripe_Paywall';
import { StripeCheckout } from '../../Stripe/Stripe_Copy';

import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import { history } from "../../../_helpers";
import { setWhitelabel } from '../../../_reducers/whitelabel.reducer';
import { whitelabel_Organization } from '../../../App/Whitelabel';
import { Segment } from '../../../_pages/Shared/Segment';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.dispatch(authenticateActions.logout());

        this.state = {
            username: '',
            password: '',
            show_password: false,
            show_password_img: "show_password.png",
            submitted: false,
            partnerLogin: false,
            partnerLogo: '',
            leftPartnerLogin: '',
            //partnerButtons: '',
            partnerLoginSubmitButton: '',
            partnerLoginRegisterButton: '',
            partnerRegisterLink: '',
            linked_Listing_Id: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePasswordView = this.handlePasswordView.bind(this);
    }

    componentDidMount() {
        const path = window.location.pathname;

        if (path.toLowerCase() != "/login" && path != "/login/null" && path != "/login/grantwriter") {
            let pathSplit = path.split('/').pop();
            if (!pathSplit.includes("organization_requests") && !pathSplit.includes("organization_invitations")) {
                pathSplit = "/grants/" + pathSplit;
            } else {
                pathSplit = "/" + pathSplit;
            }
            this.setState({
                linked_Listing_Id: pathSplit
            });
        };

        document.body.scrollTo(0, 0);
    }

    handleRegister() {
        history.push('/register');
    }

    handlePasswordView(e) {
        this.setState(prevState => ({
            show_password: !prevState.show_password,
            show_password_img: this.state.show_password ? "show_password.png" : "hide_password.png"
        }));
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;
        const { dispatch } = this.props;
        if (username && password) {
            dispatch(authenticateActions.login(username, password, this.state.linked_Listing_Id));
        }
    }

    render() {
        const { loginAlert, paywallAlert, purchaseProduct, user, whitelabel } = this.props;
        const { username, password, submitted, show_password } = this.state;

        if (user && user.partnerClient) {
            window.location.href = user.partnerClient;
        }

        if (purchaseProduct.purchaseSuccess) {
            const { username, password } = this.state;
            if (username && password) {
                authenticateService.login(username, password);
                Segment.track(user, 'Login');
            }
        }
        return (
            <div>
                {(!paywallAlert || paywallAlert.status === "") &&
                    <div className="row" id="loginPage">
                        {/* {!whitelabel &&
                            <div
                                id="leftLogin"
                                className="col-sm-6 empty-style navigation"
                                style={{ backgroundImage: "linear-gradient(0deg, rgba(20, 45, 41, 0.25), #29a), url(https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_login_rocket.png)" }}
                            />
                        } */}
                        {whitelabel ?
                            <div
                                id="leftLogin"
                                className="col-sm-6 empty-style navigation"
                                style={{ backgroundImage: "linear-gradient(0deg, rgba(20, 45, 41, 0.25), " + whitelabel?.accent_Color + "80), url(https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_login_rocket.png)" }}
                            >
                                {whitelabel && whitelabel.organization_Name == "grantfinder.epri.com" &&
                                    <div>
                                        <h1 id="loginLogoLeft">
                                            opengrants.io
                                        </h1>
                                        <h1 id="navFooter">The easy way to find, win and manage grants.</h1>
                                    </div>
                                }
                                {whitelabel && whitelabel_Organization == "portal.opengrants.io" &&
                                    <div>
                                        <h1 id="loginLogoLeft">
                                            opengrants.io
                                        </h1>
                                        <h1 id="navFooter">The easy way to find, win and manage grants.</h1>
                                    </div>
                                }
                                {whitelabel && whitelabel_Organization == "grants.wiaseresearch.com" &&
                                    <h1 id="navFooter">Discover funding effortlessly.</h1>
                                }
                            </div>
                            :
                            <div
                                id="leftLogin"
                                className="col-sm-6 empty-style navigation"
                                style={{ backgroundImage: "linear-gradient(0deg, rgba(20, 45, 41, 0.25), #29a), url(https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_login_rocket.png)" }}
                            />
                        }
                        <div id="rightLogin" className="col-sm-6">
                            <form name="form" id="loginForm">
                                {whitelabel && whitelabel.organization_Name == "portal.opengrants.io" &&
                                    <h1 id="loginLogo">PORTAL</h1>
                                }
                                {whitelabel &&
                                    (whitelabel.organization_Name == "grants.wiaseresearch.com"
                                        || whitelabel.organization_Name == "grants.californiamobilitycenter.org"
                                        // || whitelabel.organization_Name == "fund.gbbp.org"
                                    )
                                    &&
                                    <h1
                                        style={{ fontFamily: "Open Sans", color: whitelabel.font_Color, fontWeight: 600, textAlign: "center" }}
                                    >
                                        Funding Portal
                                    </h1>
                                }
                                {whitelabel && whitelabel.organization_Name == "portal.prospectsv.org" &&
                                    <div>
                                        <img
                                            src="https://s3-us-west-1.amazonaws.com/grants.prospectsv.org/images/Prospect_SV_Logo.png"
                                            width="250"
                                            style={{ margin: "auto", display: "block", marginBottom: 15, marginTop: "-5%", paddingRight: 10 }}
                                        />
                                        <h1
                                            style={{ fontFamily: "Open Sans", color: whitelabel.font_Color, fontWeight: 600, textAlign: "center" }}
                                        >
                                            Ecosystem Portal
                                        </h1>
                                    </div>
                                }
                                {whitelabel && whitelabel.organization_Name == "fund.gbbp.org" &&
                                    <div>
                                        <img
                                            src="https://www.gbaglobal.org/wp-content/uploads/2018/04/GBA-logo-100.png"
                                            width="250"
                                            style={{ margin: "auto", display: "block", marginBottom: 15, paddingRight: 10 }}
                                        />
                                    </div>
                                }
                                {whitelabel && whitelabel.organization_Name == "grantfinder.epri.com" &&
                                    <div>
                                        <img
                                            src={whitelabel.banner_URL}
                                            width="450"
                                            style={{ margin: "auto", display: "block", marginBottom: 15, paddingRight: 10 }}
                                        />
                                    </div>
                                }
                                {whitelabel && whitelabel.organization_Name == "portal.chicostart.com" &&
                                    <div>
                                        <img
                                            src={whitelabel.logo_URL}
                                            width="450"
                                            style={{ margin: "auto", display: "block", marginBottom: 15, paddingRight: 10 }}
                                        />
                                    </div>
                                }
                                {whitelabel && whitelabel.organization_Name == "demo.opengrants.io" &&
                                    <div>
                                        <img
                                            src={whitelabel.logo_URL}
                                            width="450"
                                            style={{ margin: "auto", display: "block", marginBottom: 15, paddingRight: 10 }}
                                        />
                                    </div>
                                }
                                <hr id="loginPageHR" />
                                <h4 style={{ fontFamily: "open sans" }}>Please login to your account. <br />Don't have one yet? Click the Sign Up button to get started!</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <TextField
                                            required
                                            id="standard-basic"
                                            label="USERNAME / EMAIL"
                                            name="username"
                                            value={username}
                                            onChange={this.handleChange}
                                            style={{ width: "100%", marginBottom: 15 }}
                                        />
                                        {submitted && !username &&
                                            <div className="help-block">Username is required</div>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="password">
                                            <TextField
                                                required
                                                id="standard-basic"
                                                label="PASSWORD"
                                                name="password"
                                                value={password}
                                                onChange={this.handleChange}
                                                type={show_password === true ? "text" : "password"}
                                                style={{ width: "100%", marginBottom: 15 }}
                                            />
                                        </div>
                                        <div id="showPasswordDiv" style={{ marginTop: 10 }}>
                                            <img id="showPassword_login"
                                                src={"https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/" + this.state.show_password_img}
                                                onClick={this.handlePasswordView}
                                            />
                                        </div>
                                        {submitted && !password &&
                                            <div className="help-block">Password is required</div>
                                        }
                                    </div>
                                </div>
                                <div id="loginHelperButtons" className="row">
                                    <div className="checkbox">
                                        {/* <label asp-for="RememberMe">
                                    <input type="checkbox" name="RememberMe" />
                                    Remember me?
                                </label> */}
                                        <a id="forgotPassword" href="/forgot_password" style={{ float: "left", marginLeft: 15, marginTop: -15 }}>Forgot password</a>
                                    </div>
                                </div>
                                <div id="loginButtons" className="form-group">
                                    <br />
                                    <div id="loginButtonArea">
                                        <Button
                                            id="loginLogin"
                                            color="secondary"
                                            style={{ background: whitelabel ? whitelabel.button_Color : "#29a" }}
                                            onClick={this.handleSubmit}
                                        // style={{minWidth:200}}
                                        >
                                            Login
                                        </Button>
                                        <Button
                                            id="loginRegister"
                                            color="primary"
                                            style={{
                                                color: whitelabel ? whitelabel.button_Color : "#29a",
                                                borderColor: whitelabel ? whitelabel.button_Color + "80" : "#29a79f50"
                                            }}
                                            onClick={this.handleRegister}
                                        // style={{minWidth:200, float: "right"}}
                                        >
                                            Sign Up!
                                        </Button>
                                    </div>
                                    <br /><br />
                                </div>
                                <hr />
                                <div id="loginMoreInfo">
                                    <a href="https://www.opengrants.io/opengrants-legal/" target="_blank">Terms of Use </a>
                                    <a href="https://www.opengrants.io/opengrants-legal/" target="_blank">- Privacy Policy</a>
                                </div>
                                <br />
                                {loginAlert.message &&
                                    <div className={`alert ${loginAlert.type}`}>Please enter a valid username and password.</div>
                                }
                            </form>
                        </div>
                    </div>}
                {
                    paywallAlert && paywallAlert.status === true &&
                    <StripeCheckout productType="portal_standard" />
                }
                {whitelabel && whitelabel.organization_Name == "portal.opengrants.io" &&
                    <Footer />
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { loginAlert, paywallAlert, purchaseProduct, setWhitelabel } = state;
    const { loading, user } = state.setUser;
    const { whitelabel } = setWhitelabel;
    return {
        loginAlert,
        loading,
        paywallAlert,
        purchaseProduct,
        user,
        whitelabel,
    };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };
