import React, { useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '../../../Shared/Loader';
import { history } from '../../../../_helpers';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import ProgressBar from 'react-bootstrap/ProgressBar';

class ImpactFocusAreas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            focus_areas: [],
            loading: true,
        }
    }

    componentDidMount() {
        this.setState({
            focus_areas: {
                ...this.state.focus_areas,
                impact_arts_culture: false,
                impact_criminal_justice: false,
                impact_critical_human_needs: false,
                impact_culture_identity: false,
                impact_economic_development: false,
                impact_education: false,
                impact_global_development: false,
                impact_public_health: false,
                impact_science_technology: false,
                impact_sustainability_environment: false,
                impact_systems_change: false,
                impact_youth: false,
                impact_other: false
            }
        })
        
        document.body.scrollTop = 0;
    
        setTimeout(() => {
            this.setState({
            loading: false
            });
        }, 500);
    }

    useStyles = makeStyles((theme) => ({
            root: {
              display: 'flex',
            },
            formControl: {
              margin: theme.spacing(3),
            },
    }));
          
    CheckboxesGroup() {
        const classes = this.useStyles();
    };
          
    handleChange = (event) => {
        const category = [event.target.name];

        this.setState({ 
            ...this.state, 
            [event.target.name]: event.target.checked,
            focus_areas: {
                ...this.state.focus_areas,
                [category]: event.target.checked 
            }
        });
    };
    
    handleNavigation = async (type) => {
        var {user, focus_areas} = this.state;
        
        await localStorage.setItem('user', JSON.stringify(user));
        await localStorage.setItem('focus_areas', JSON.stringify(focus_areas));
        history.push('/quickstart/impact-groups');
    }

    render() {     
        const { loading } = this.state;
        const { whitelabel } = this.props;
        const progressBarColor = whitelabel ? whitelabel.accent_Color : "#29a";
        const now = 70;
        const progressInstance = <ProgressBar now={now} label={`${now}%`} />;

        return (
            <div>
                <style>{"\
                    .progress-bar{\
                    background:" + progressBarColor + ";\
                    }\
                    .#NSF_Category_Selector .MuiSvgIcon-root{\
                        fill:" + progressBarColor + ";\
                    }\
                "}</style>
                {loading && <Loader />}
                <div>{progressInstance}</div>
                <div id="fundingSlider1">
                    <h1 id="fundingSliderHeader" style={{marginBottom: "2%"}}>
                        Where are you generating impact?
                    </h1>
                </div>
                <hr style={{width: "7%", height: 5, background: "#999"}} />
                <div id="NSF_Category_Selector">
                    <FormControl component="fieldset" className="">
                        <FormGroup>
                            <div id="focusNSFArea" className="row">
                                <h3 id="checkAllH3">Check all areas that apply</h3>
                                <hr />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.impact_arts_culture} 
                                                    onChange={this.handleChange} 
                                                    name="impact_arts_culture" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Arts & Culture"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.impact_criminal_justice} 
                                                    onChange={this.handleChange} 
                                                    name="impact_criminal_justice" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Criminal Justice"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.impact_critical_human_needs} 
                                                    onChange={this.handleChange} 
                                                    name="impact_critical_human_needs" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Critical Human Needs"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.impact_culture_identity} 
                                                    onChange={this.handleChange} 
                                                    name="impact_culture_identity" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Culture & Identity"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.impact_economic_development} 
                                                    onChange={this.handleChange} 
                                                    name="impact_economic_development" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Economic Development"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.impact_education} 
                                                    onChange={this.handleChange} 
                                                    name="impact_education" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Education"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.impact_global_development} 
                                                    onChange={this.handleChange} 
                                                    name="impact_global_development" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Global Development"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.impact_public_health} 
                                                    onChange={this.handleChange} 
                                                    name="impact_public_health" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Public Health"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.impact_science_technology} 
                                                    onChange={this.handleChange} 
                                                    name="impact_science_technology" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Science & Technology"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.impact_sustainability_environment} 
                                                    onChange={this.handleChange} 
                                                    name="impact_sustainability_environment" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Sustainability & Environment"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.impact_systems_change} 
                                                    onChange={this.handleChange} 
                                                    name="impact_systems_change" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Systems Change"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.impact_youth} 
                                                    onChange={this.handleChange} 
                                                    name="impact_youth" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Youth"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.impact_other} 
                                                    onChange={this.handleChange} 
                                                    name="impact_other" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Other"
                                    />
                                <div id="NSFFocusContinueButton" className="continueButton">
                                    <button 
                                        className="signUpContinue"
                                        onClick={() => this.handleNavigation('next')}
                                        style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                                    >
                                        Continue  →
                                    </button>
                                </div>
                            </div>
                        </FormGroup>
                    </FormControl>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering, nav } = state.registration;
    const { whitelabel } = state.setWhitelabel;
    const { registrationAlert } = state;
    
    return {
        registering, 
        nav, 
        registrationAlert,
        whitelabel,
    };
}

const connectedImpactFocusAreas = withRouter(connect(mapStateToProps)(ImpactFocusAreas));
export { connectedImpactFocusAreas as ImpactFocusAreas };