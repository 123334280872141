import React from 'react';
import { connect } from 'react-redux';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import { organizationService } from '../../../_services';
import '../../../Styles/SettingsStyles/Profile.css';
import '../../../Styles/Organization.css';

class OrganizationInvitation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount = async() => {
        const user = JSON.parse(localStorage.getItem('user'));
        var pending_invitations = await organizationService.getUserOrganizationPendingInvitations(user.id);

        this.setState({
            pending_invitations
        });
    }

    confirmOrganizationInvitation = async(organization_id, user_id, confirmation) => {
        const{pending_invitations} = this.state;

        let pending_invitation = await organizationService.confirmOrganizationInvitation(organization_id, user_id, confirmation);

        if (pending_invitation) {
            var new_pending_invitations = pending_invitations.filter(invitation => invitation.organization_Id != organization_id);
            new_pending_invitations.push(pending_invitation);

            this.setState({
                pending_invitations: [...new_pending_invitations]
            });
        }
    }

    render() {
        const user = JSON.parse(localStorage.getItem('user'));
        const{pending_invitations} = this.state;
        console.log(this.state);
        var pending_invitation_list = null;
        if (pending_invitations) {
            pending_invitation_list = pending_invitations.map((invitation) => {
                return (
                    <div key={invitation.organization.name}>
                        <div style={{marginBottom: 20, marginTop: 40}}>
                                {invitation.organization.logo_URL && <img src={invitation.organization.logo_URL} style={{height: "60px", width: "60px", borderRadius: "50%", margin: "15px"}} />}
                                {!invitation.organization.logo_URL && <i className="material-icons" style={{float: "left", color: "black", fontSize: "60px", width: "60px", borderRadius: "50%"}}>business</i>}
                            <a href={"/organizations/" + invitation.organization_Id} target="_blank" className="linkListing" style={{color: "black", display: "inline-block", margin: 0}}>
                                <h2 style={{margin: "-10px 0 0 0", padding: 0}}>{invitation.organization.name}</h2>
                                <p>{invitation.organization.description}</p>
                            </a>
                            {!invitation.confirmation && <div style={{float: "right"}}>
                                <Button color="info" style={{marginRight: 20}} onClick={() => {this.confirmOrganizationInvitation(invitation.organization_Id, user.id, "Accepted")}}>Accept</Button>
                                <Button onClick={() => {this.confirmOrganizationInvitation(invitation.organization_Id, user.id, "Rejected")}}>Reject</Button>
                            </div>}
                            {invitation.confirmation && <div style={{float: "right"}}>
                                <Button>{invitation.confirmation}</Button>
                            </div>}
                        </div>
                    </div>
                );
            });
        }

        var pending_total = pending_invitations ? pending_invitations.filter(invitation => !invitation.confirmation).length : 0;

        return (
            <div>
                <h1 style={{textAlign: "center"}}>Confirm Organization Invitations</h1>
                <p style={{textAlign: "center"}}>The following organization(s) invited you to join their organization(s). Accepting the invitation will let you to connect your personal profile to their organization. You will also be able to edit the organization.</p>
                <p style={{marginTop: 40}}><strong>{pending_total}</strong> Pending Invitation(s)</p>
                {pending_invitation_list}
            </div>
        );
    }
}

function mapStateToProps(state) {
}

const connectedOrganizationInvitation = connect(mapStateToProps)(OrganizationInvitation);
export { connectedOrganizationInvitation as OrganizationInvitation };