import React from "react";
import GridItem from "../../_components/dashboardComponents/Grid/GridItem.js";
import GridContainer from "../../_components/dashboardComponents/Grid/GridContainer.js";
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import Label from "@material-ui/icons/Label";
import CustomTabs from "../../_components/dashboardComponents/CustomTabs/CustomTabs.js";
import Class from "@material-ui/icons/Class";
import { Profile } from './Profile';
import { Organization } from './OrganizationSettings/Organization';
import { Focus } from './FocusAreas/Focus';
import { Manage } from './Manage';
import {StripeBillingPage} from '../Stripe/Stripe_Copy';
import { history } from '../../_helpers/history';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

export default function UserProfile(props) {
  // const initVal = window.location.search.includes('val') ? parseInt(window.location.search.split("=")[1] , 10 ) : 0;
  const initVal = window.location.search.includes('val') ? window.location.search.split("=")[1] : "";
  
  const user = JSON.parse(localStorage.getItem('user'));
  const user_roles = user.userRoles;
  const { whitelabel, categoriesIndex } = props;


  return (
    <div style={{maxWidth:1000}}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
                initialValue={initVal}
                headerColor={whitelabel ? whitelabel.dashboard_Color : "#29a"}
                tabs={[
                  {
                    display: whitelabel && whitelabel.features.feature_Settings_Profile ? null : "none",
                    tabName: "Profile",
                    tabIcon: Class,
                    tabContent: (
                        <div>
                            <br />
                            <h1 id="dashHeader">Profile Attributes</h1>
                            <hr />
                            <Profile />
                        </div>
                    )
                  },
                    {
                      display: whitelabel && whitelabel.features.feature_Settings_Organizations ? null : "none",
                      tabName: "Organization",
                      tabIcon: Class,
                      tabContent: (
                          <div>
                              <br />
                              <Organization />
                          </div>
                      )
                    },
                  
                  {
                    display: whitelabel && whitelabel.organization_Name == "portal.chicostart.com" ? "none" : null,
                    tabName: "Categories",
                    tabIcon: Label,
                    tabContent: (
                        <div>
                            <br />
                            <h1 id="dashHeader">Edit Categories</h1>
                            <hr />
                            {user_roles == 'Client_Premium' &&
                              <Focus />
                            }
                            { user_roles != 'Client_Premium' &&
                              <div>
                                <p style={{fontSize: 16}}>Upgrade your account to select your relevant categories and receive customized recommendations.</p>
                                <br />
                                <Button
                                  onClick={() => {window.location.href='/settings?val=billing'}}
                                >
                                  UPGRADE ACCOUNT
                                </Button>
                                <br />
                                <br />
                              </div>
                            }

                        </div>
                    )
                  },
                  {
                    display: whitelabel && whitelabel.features.feature_Subscriptions ? null : "none",
                    tabName: "Billing",
                    tabIcon: Class,
                    tabContent: (
                        <div id="billingSettingsDiv">
                            <br />
                            <h1 id="dashHeader">Billing Information</h1>
                            <hr />
                            {/* <StripeManageBilling /> */}
                            <StripeBillingPage />
                        </div>
                    )
                  },
                  {
                    tabName: "Manage",
                    tabIcon: Class,
                    tabContent: (
                        <div>
                            {/* <br />
                            <h1 id="dashHeader">Update Email</h1>
                            <hr /> */}
                            <Manage />
                        </div>
                    )
                  },
                ]}
              />
        </GridItem>
      </GridContainer>
    </div>
  );
}
