import { authHeader } from '../_helpers';

export const whitelabelService = {
	setWhitelabel
};

function setWhitelabel(organization_name) {
	let settings
	if (organization_name === 'portal.chicostart.com') {
		settings = require('./serviceJSON/chicoStart.json')
		return new Promise((resolve, reject) => resolve(settings))
	} else if (organization_name === 'grantfinder.epri.com') {
		settings = require('./serviceJSON/epriStart.json')
		return new Promise((resolve, reject) => resolve(settings))
	} else if (organization_name === 'demo.opengrants.io') {
		settings = require('./serviceJSON/previewStart.json')
		return new Promise((resolve, reject) => resolve(settings))
	} else {
		const requestOptions = {
		    method: 'POST',
		    headers: authHeader(),
		};
	
		return fetch('https://refactor.opengrants.io/api/whitelabel/get_whitelabel_settings?organization_name=' + organization_name, requestOptions)
		    .then(handleResponse)
	}

}

function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status == "401") {
				window.location.href = "/login";
			}

			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		return data;
	});
}