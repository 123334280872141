import { listingConstants } from '../_constants';
import { listingService } from '../_services';
import {Segment} from '../_pages/Shared/Segment';

export const listingActions = {
    getListing
};

function getListing(listing_Id) {
    return dispatch => {
        dispatch(request(listing_Id));
        listingService.getListing(listing_Id)
            .then(
                listing => { 
                    const user = JSON.parse(localStorage.getItem('user'));
                    Segment.track(user, 'Listing', 
                    {
                        listing_action: 'view',
                        listing_id: listing.listing_Id,
                        listing_title: listing.title
                    });
                    dispatch(success(listing));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: listingConstants.GETLISTING_REQUEST } }
    function success(listing) { return { type: listingConstants.GETLISTING_SUCCESS, listing } }
    function failure(error) { return { type: listingConstants.GETLISTING_FAILURE, error } }
}