
export let Segment = {
    login: (user) => {
        window.analytics.identify(user.id);
    },

    register: (user) => {
        window.analytics.identify(user.id, {
            created: new Date(),
            email: user.email,
            name: user.name + " " + user.surname,
            firstName: user.name,
            lastName: user.surname,
            partner: user.partnerClient,
            referral_partner: user.partner,
            platform: user.whitelabel_Platform,
            plan: user.userRoles ? user.userRoles.filter(role => role.toLowerCase().includes('client'))[0] : ''
        });
    },

    track: (user, event, properties) => {    
        window.analytics.track(event, {
            plan: user.userRoles ? user.userRoles.filter(role => role.toLowerCase().includes('client'))[0] : '',
            ...properties
        })
    }
}