import React from 'react';
import { connect } from 'react-redux';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import '../../../Styles/Organization.css';

class PendingProfileList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { user, whitelabel,
            profile, transfer,
        }  = this.props;
        const { organization } = this.props.org;

        return (
            <div key={profile.username} style={{marginBottom: 20, marginLeft: 30, marginRight: 30, overflow: "auto"}}>
                {!profile.headshot && 
                    <div className="profile-circle" style={{position:"relative", height: "60px", width: "60px", lineHeight: "55px", fontSize: "30px", marginRight: 30, verticalAlign: "top"}}>
                        {profile.surname[0]}
                    </div>
                }
                {profile.headshot && 
                    <img src={profile.headshot} style={{height: 60, width: 60, marginRight: 30, borderRadius: "50%", verticalAlign: "top"}} /> 
                }
                <a href={"/profiles/" + profile.username} target="_blank" className="linkListing" style={{display: "inline-block", color: "black"}}>
                    <h3 style={{margin: 0}}>{profile.name} {profile.surname}</h3>
                    <p>{profile.title}</p>
                </a>
                {!transfer && <div style={{float: "right"}}>
                    <Button 
                        color="info"                                 
                        style={{margin: 20, background: whitelabel ? whitelabel.button_Color : "#29a"}} 
                        onClick={() => {this.props.confirmOrganizationRequest(organization.organization_Id, user.id, profile.username, "Accepted")}}
                    >
                        Accept
                    </Button>
                    <Button 
                        onClick={() => {this.props.confirmOrganizationRequest(organization.organization_Id, user.id, profile.username, "Rejected")}}
                        style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                    >
                        Reject
                    </Button>
                </div>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { setUser, organization, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        organization,
        whitelabel,
    };
}

const connectedPendingProfileList = connect(mapStateToProps)(PendingProfileList);
export { connectedPendingProfileList as PendingProfileList };