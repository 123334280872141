import { stripeConstants } from '../_constants';
import { authenticateService, stripeService } from '../_services';

export const stripeActions = {
    createStripeAccount,
    handleStripeToken,
    getBillingAddress,
    purchaseProduct,
    updateUserDetails,
    cancelSubscription,
    updateSubscription,
    cancelSubscriptionDowngrade
}

function createStripeAccount(code, state) {
    console.log("CREATING STRIPE ACCOUNT:", code, state);
    return dispatch => {
        dispatch(request());
        stripeService.createStripeAccount(code, state)
            .then(
                () => {
                    dispatch(success())
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function request() { return { type: stripeConstants.CONNECT_REQUEST } }
    function success() { return { type: stripeConstants.CONNECT_SUCCESS } }
    function failure(error) { return { type: stripeConstants.CONNECT_FAILURE, error } }
}

function handleStripeToken(billingAddress) {
    console.log("HANDLING STRIPE TOKEN:", billingAddress);
    return dispatch => {
        dispatch(request());
        stripeService.handleStripeToken(billingAddress)
            .then(
                billingAddress => {
                    dispatch(success(billingAddress));
                    dispatch(returnBilling(billingAddress));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function request() { return { type: stripeConstants.TOKEN_REQUEST } }
    function success(billingAddress) { return { type: stripeConstants.TOKEN_SUCCESS, billingAddress } }
    function failure(error) { return { type: stripeConstants.TOKEN_FAILURE, error } }
    function returnBilling(billingAddress) { return { type: stripeConstants.BILLING_ADDRESS_SUCCESS, billingAddress } }
}

function getBillingAddress(userId) {
    return dispatch => {
        dispatch(request());
        stripeService.getBillingAddress(userId)
            .then(
                billingAddress => {
                    dispatch(success(billingAddress));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function request() { return { type: stripeConstants.BILLING_ADDRESS_REQUEST } }
    function success(billingAddress) { return { type: stripeConstants.BILLING_ADDRESS_SUCCESS, billingAddress } }
    function failure(error) { return { type: stripeConstants.BILLING_ADDRESS_FAILURE, error } }
}

function purchaseProduct(productDetails) {
    return dispatch => {
        dispatch(request());
        stripeService.purchaseProduct(productDetails)
            .then(
                async result => {
                    let message = result.message;
                    if (message == "Ok") {
                        result.message = "";
                        if (result.userRoles) {
                            let user = JSON.parse(localStorage.getItem('user'));
                            user.userRoles = result.userRoles;
                
                            await authenticateService.refreshToken(user);

                            if (localStorage.getItem('planChange'))
                            {
                                await window.location.reload();
                            }
                        }
                        dispatch(success(result));
                        dispatch(reset());
                    } else {
                        dispatch(failure(result));
                    }
                },
            error => {
                dispatch(failure(error));
            }
        )
    }

    function request() {return {type: stripeConstants.PURCHASE_PRODUCT_REQUEST}}
    function success(result) {return {type: stripeConstants.PURCHASE_PRODUCT_SUCCESS, result}}
    function failure(result) {return {type: stripeConstants.PURCHASE_PRODUCT_FAILURE, result}}
    function reset() {return {type: stripeConstants.PURCHASE_PRODUCT_RESET}}
}

function updateUserDetails(productDetails) {
    return dispatch => {
        dispatch(request());
        stripeService.updateUserDetails(productDetails)
            .then(
                result => {
                    dispatch(success());
                    dispatch(reset());
                },
                error => {
                    dispatch(failure());
                }
            )
    }

    function request() {return {type: stripeConstants.UPDATE_BILLING_REQUEST}}
    function success() {return {type: stripeConstants.UPDATE_BILLING_SUCCESS}}
    function failure() {return {type: stripeConstants.UPDATE_BILLING_FAILURE}}
    function reset() {return {type: stripeConstants.UPDATE_BILLING_RESET}}
}

function cancelSubscription(subscriptionId, isFree) {
    return dispatch => {
        dispatch(request());
        stripeService.cancelSubscription(subscriptionId, isFree)
            .then(
                result => {
                    dispatch(success());
                    dispatch(reset());
                },
                error => {
                    dispatch(failure());
                }
            )
    }

    function request() {return {type: stripeConstants.CANCEL_SUBSCRIPTION_REQUEST}}
    function success() {return {type: stripeConstants.CANCEL_SUBSCRIPTION_SUCCESS}}
    function failure() {return {type: stripeConstants.CANCEL_SUBSCRIPTION_FAILURE}}
    function reset() {return {type: stripeConstants.CANCEL_SUBSCRIPTION_RESET}}
}

function cancelSubscriptionDowngrade(userId, subscriptionId) {
    return dispatch => {
        dispatch(request());
        stripeService.cancelSubscriptionDowngrade(userId, subscriptionId)
            .then(
                result => {
                    dispatch(success());
                    dispatch(reset());
                },
                error => {
                    dispatch(failure());
                }
            )
    }

    function request() {return {type: stripeConstants.CANCEL_SUBSCRIPTION_REQUEST}}
    function success() {return {type: stripeConstants.CANCEL_SUBSCRIPTION_SUCCESS}}
    function failure() {return {type: stripeConstants.CANCEL_SUBSCRIPTION_FAILURE}}
    function reset() {return {type: stripeConstants.CANCEL_SUBSCRIPTION_RESET}}
}

function updateSubscription(subscriptionId) {
    return dispatch => {
        dispatch(request());
        stripeService.updateSubscription(subscriptionId)
            .then(
                result => {
                    dispatch(success());
                    dispatch(reset());
                },
                error => {
                    dispatch(failure());
                }
            )
    }

    function request() {return {type: stripeConstants.UPDATE_SUBSCRIPTION_REQUEST}}
    function success() {return {type: stripeConstants.UPDATE_SUBSCRIPTION_SUCCESS}}
    function failure() {return {type: stripeConstants.UPDATE_SUBSCRIPTION_FAILURE}}
    function reset() {return {type: stripeConstants.UPDATE_SUBSCRIPTION_RESET}}
}