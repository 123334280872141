import React from 'react';
import { connect } from 'react-redux';
import ReactPasswordStrength from 'react-password-strength';
import { authenticateActions } from '../../_actions';
import { history } from '../../_helpers';
import { alertActions } from '../../_actions';
import {settingsService, registrationService} from '../../_services';
import { Loader } from '../Shared/Loader';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import Class from "@material-ui/icons/Class";
import { makeStyles } from "@material-ui/core/styles";
import '../../Styles/SettingsStyles/Manage.css';

const styles = {
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    }
  };
  
//   const useStyles = makeStyles(styles);
//   const classes = useStyles();

class Manage extends React.Component {
    constructor(props) {
        super(props);
        this.cardEl = React.createRef()

        const {user} = this.props;

        this.state = {
            oldpassword: '',
            newpassword: '',
            updatePassword: false,
            password_model: [],

            submitted: false,
            submitting: false,
            updatedAlert: false,
            passLength: 0,
            isValid: false,

            email: user.email,
            changeEmail: false,
            emailError: {
                format: true,
                present: false
            }
        };

        history.listen((location, action) => {
            // clear alert on location change
            this.props.dispatch(alertActions.updatePasswordClear());
        });

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    async handleSubmit(e) {
        e.preventDefault();

        const { password_model, password, oldpassword, newpassword, confirmpassword } = this.state;
        const { dispatch, user } = this.props;
        if (confirmpassword !== newpassword)
        {
            this.setState({
                submitted: true,
            });
        }
        else if (oldpassword && newpassword && confirmpassword) {
            password_model.oldpassword = oldpassword;
            password_model.newpassword = newpassword;

            this.setState({
                submitted: true,
                updatedAlert: true
            }, async () => {
                await settingsService.updatePassword(password_model.oldpassword, password_model.newpassword, user)
                .then(
                    user => {
                        //dispatch(userActions.login(user.email, password_model.newpassword));
                        dispatch(alertActions.updatePasswordSuccess(
                            "Your password has been succesfully updated."
                        ));
                        setTimeout(() => {
                            dispatch(alertActions.updatePasswordClear());                            
                        }, 3000);
                        this.setState({
                            submitted: false,
                            oldpassword: '',
                            newpassword: '',
                            confirmpassword: '',
                            updatePassword: false
                        }
                        // , () => {                            
                        //     document.getElementsByName('oldpassword')[0].value = '';
                        //     // document.getElementsByName('new_password')[0].getElementsByTagName('input')[0].defaultValue = '';
                        //     // document.getElementsByName('new_password')[0].getElementsByTagName('input')[0].value = '';
                        //     this.cardEl.current.clear();
                        //     document.getElementsByName('confirmpassword')[0].value = '';
                        // }
                        );
                    },
                    error => 
                    {
                        dispatch(alertActions.updatePasswordError(
                            ["There was an error updating your password. ",
                            "Verfiy your current password is correct ",
                            "and that your new password is different from your current one."]
                        ));
                        setTimeout(() => {
                            dispatch(alertActions.updatePasswordClear());                            
                        }, 3000);
                    }
                );
            });
        }
    }

    changeCallback = state => {
        this.setState(prevState => ({
            passLength: state.password.length,
            isValid: state.isValid,
            newpassword: state.password,
        }), () => {});
    }

    clear = () => {
        this.ReactPasswordStrength.clear()
    }

    handleEmailChange = async (e) => {
        e.preventDefault();

        let user = Object.assign({}, this.props.user);
        const{dispatch} = this.props;
        const {email} = this.state;
        user.email = email;
        
        let emailState = await registrationService.checkEmail(user);
        if (emailState.valid) {
            var success = await settingsService.updateEmail(user.id, email);
            if (success) {
                localStorage.setItem('user', JSON.stringify(user));
                dispatch(authenticateActions.setUser(user));  
                this.cancelUpdate();
                dispatch(alertActions.updatePasswordSuccess(
                    "Your email has been succesfully updated."
                ));
                setTimeout(() => {
                    dispatch(alertActions.updatePasswordClear());                            
                }, 3000);
            } else {
                dispatch(alertActions.updatePasswordError(
                    ["There was an error updating your email."]
                ));
                setTimeout(() => {
                    dispatch(alertActions.updatePasswordClear());                            
                }, 3000);
            }            
        } else {
            var format = true;
            var present = false;

            if (emailState.type === "format") {
                format = false;
            }
            else if (emailState.type === "present") {
                present = true;
            }

            this.setState({
                emailError: {
                    format,
                    present
                }
            });
        }
    }

    cancelUpdate = () => {
        const {user} = this.props;

        this.setState({
            changeEmail: false, 
            updatePassword: false,
            email: user.email,
                
            emailError: {
                format: true,
                present: false
            }
        });
    }

    render() {
        const { user, updatePasswordAlert, whitelabel } = this.props;
        const { submitted, password, oldpassword, newpassword, confirmpassword, updatePassword,
            submitting,
            email, changeEmail, emailError
        } = this.state;
        console.log(this.props);
        console.log(this.state);

        return (
            <div id="mainSettings" className="settingsForm">
                {submitting && <Loader />}
                {!changeEmail && !updatePassword && updatePasswordAlert.message &&
                    <div id="managePasswordAlert" className={`alert ${updatePasswordAlert.type}`}>{updatePasswordAlert.message}</div>
                }
                <div id="manageEmailForm" className="row">
                    <div className="col-md-12">
                        <form id="changeEmailForm" className="settingsForm" onSubmit={this.handleEmailChange}>
                            <div>
                                {!changeEmail && !updatePassword && 
                                    <div>
                                        <h2>Email</h2>
                                        <div>
                                            <p style={{display: "inline-block", marginRight: "15px"}}>{user.email}</p>
                                            <a style={{float: "right", fontSize:"14px"}} onClick={() => {this.setState({changeEmail: true, updatePassword: false})}}>Update</a>
                                        </div>
                                    </div>
                                }
                                {changeEmail && 
                                <div>
                                    <h2>Change Email</h2>
                                    <div style={{marginTop: "20px"}}>
                                        <input className="form-control" name="email" value={email} onChange={this.handleChange} />
                                        <div style={{marginTop: "15px"}}>
                                            {!emailError.format && 
                                                <p style={{color: "red"}}>Please enter a valid email</p>
                                            }
                                            {emailError.present && 
                                                <p style={{color: "red"}}>This email already exists</p>
                                            }
                                            <div style={{float: "right"}}>
                                                <Button onClick={this.cancelUpdate} style={{marginRight: "15px"}}>Cancel</Button>
                                                <Button 
                                                    color="info" 
                                                    onClick={this.handleEmailChange}
                                                    style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                <div id="managePasswordForm" className="row">
                    <div className="col-md-12" style={{marginBottom: "20px"}}>
                        <form id="changePasswordForm" className="settingsForm" onSubmit={this.handleSubmit}>
                            {!changeEmail && !updatePassword && 
                            <div>
                                <h2>Password</h2>
                                <div>
                                    <div>
                                        <p style={{display: "inline-block", marginRight: "15px"}}>**********</p>
                                        <a style={{float: "right", fontSize:"14px"}} onClick={() => {this.setState({changeEmail: false, updatePassword: true})}}>Update</a>
                                    </div>
                                </div>
                            </div>}
                            {updatePassword && !changeEmail && 
                            <div>
                                <h2>Change Password</h2>
                                <div style={{marginTop: "20px"}}>
                                    <div asp-validation-summary="All" className="text-danger"></div>
                                    <div className={'form-group' + (submitted && !oldpassword ? ' has-error' : '')}>
                                        <label name="OldPassword">Current Password</label>
                                        <input type="password" name="oldpassword" className="form-control" onChange={this.handleChange} />
                                        {submitted && !oldpassword &&
                                            <div className="help-block">Password is required</div>
                                        }
                                    </div>
                                    <div name='new_password' className={'form-group' + (submitted && !newpassword ? ' has-error' : '')}>
                                        <label>New Password</label>
                                        <ReactPasswordStrength
                                            ref={this.cardEl}
                                            className="customClass"
                                            minLength={8}
                                            minScore={2}
                                            scoreWords={['poor', 'weak', 'good!', 'strong!', 'stronger!!']}
                                            changeCallback={this.changeCallback}
                                            inputState={{ name: "newpassword", autoComplete: "off", className: "form-control", onChange: this.handleChange }}
                                        />
                                        {submitted && !newpassword &&
                                            <div className="help-block">New password is required</div>
                                        }
                                        {submitted && this.state.isValid === false &&
                                        <p className="error">Password is too weak.</p>}
                                    </div>
                                    <div className={'form-group' + (submitted && !confirmpassword ? ' has-error' : '')}>
                                        <label>Confirm Password</label>
                                        <input type="password" name="confirmpassword" className="form-control" onChange={this.handleChange} />
                                        {submitted && !confirmpassword && !newpassword &&
                                            <div className="help-block">Confirm new password is required</div>
                                        }
                                        {submitted && confirmpassword !== newpassword &&
                                            <div className="help-block">Passwords do not match.</div>
                                        }
                                    </div>
                                    <h4 style={{fontWeight: 500, float: "right"}}>
                                        <Button onClick={this.cancelUpdate} style={{marginRight: "15px"}}>Cancel</Button>
                                        <Button 
                                            color="info"
                                            style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                                            onClick={this.handleSubmit}
                                        >
                                            Update Password
                                            <ArrowForwardIos style={{fontSize:32, marginTop: 0, marginLeft:10, marginRight:-10}}/>
                                        </Button>
                                    </h4>
                                    <br />
                                    {/* <button id="manageFormButton" type="submit" className="btn btn-default">Update password</button> */}
                                </div>
                            </div>}
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, updatePasswordAlert, updatePassword, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        updatePasswordAlert,
        updatePassword,
        user,
        whitelabel,
    };
}

const connectedRegisterPage = connect(mapStateToProps)(Manage);
export { connectedRegisterPage as Manage };