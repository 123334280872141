import React from 'react';
import { categories } from '../../../_reducers/search.reducer';

class CreateTags extends React.Component {
    constructor(props) {
        super(props);
    }

    removeOptionTag = (optionValue) => {
        const {categoryName, handleCategorySelection, selectedCategories, option_id} = this.props;
        let currentSelectedCategories = selectedCategories.slice();
        currentSelectedCategories = currentSelectedCategories.filter(category => {
            return (category !== optionValue);
        });
        let categories = {};
        categories[option_id] = currentSelectedCategories;
        handleCategorySelection(categories);
    }

    render() {
        const {option_id, selectedCategories} = this.props;

        let tags = null;
        if (selectedCategories.length > 0)
            tags = selectedCategories.map((category) => {
                return (
                    <div key={"tags_" + category} className="categoryTag">
                        {category}
                        <div className="removeTag" onClick={() => this.removeOptionTag(category)}>X</div>
                    </div>
                )
            });  

        return (
            <>
            {selectedCategories.length > 0 && <div className="align-left">
                <a id="clearAll" className="showClearAll" onClick={() => this.props.resetSelectedCategories(option_id)}>Clear</a>
                <div id="tags">{tags}</div>
            </div>}
            </>
        )
    }
}

export { CreateTags };