import React from 'react';
import Select from 'react-select';
import ReactTooltip from "react-tooltip";
import { UncontrolledTooltip, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from "reactstrap"

const options = [
    { value: 'false', label: 'No' },
    { value: 'true', label: 'Yes' },
];

export class Registration_LegalDetails extends React.Component {
    constructor(props) {
        super(props);
        const {user, nav} = this.props;

        this.state = {
            dropDownOpen: {
                EIN_Number: false,
                DUNS_Number: false
            },
            arrowLeft: {
                EIN_Number: false,
                DUNS_Number: false
            },
            user: user,
            nav: nav,
            submitted: false,
            themeColor: localStorage.getItem('themeColor')
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                SAM_Registration: false,
                Hubzone_Owned: false,
                Woman_Owned: false,
                Veteran_Owned: false
            }
        }), () => {});
    }

    componentDidUpdate = () => {
        this.repositionTooltip("EIN_Number");
        this.repositionTooltip("DUNS_Number");
    }

    handleChange = (e) => {
        const {name, value} = e.target;
        this.setSelection(name, value);
    }

    setSelection = (name, value) => {
        let {user} = this.state;
        user[name] = value;
        this.setState({
            user: user
        })
    }

    handleSelectionChange(value, name) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value.value
            }
        });
    }

    handleNavigation = (type) => {
        var {user, nav} = this.state;
        if (type === 'next') {
            nav++;
        } else if (type === "prev") {
            nav--;
        }

        this.props.handleFormInputs({user, nav});
    }

    emptyPlaceholder = () => {};    

    toggleOpen = (tooltip) => {
        this.setState({
            dropDownOpen: {
                [tooltip]: !this.state.dropDownOpen[tooltip],
            }
        });
    }

    repositionTooltip = (element) => {
        if (this.state.dropDownOpen[element]) {
            let container = document.getElementsByName(element)[0].parentElement;
            let tooltipBounds = container.getElementsByClassName("dropdown-menu")[0].getBoundingClientRect();
            let tooltipRight = tooltipBounds.x + tooltipBounds.width;
            let windowRight = window.innerWidth;
            if (tooltipRight > windowRight) {
                let arrowLeft = Object.assign({}, this.state.arrowLeft);
                this.setState({
                    arrowLeft: {
                        ...arrowLeft,
                        [element]: true
                    }
                });
                container.getElementsByClassName("button-tooltip")[0].style.marginLeft = "-" + (tooltipRight - windowRight) + "px";
                let label = container.getElementsByTagName("label")[0].getBoundingClientRect();
                container.getElementsByClassName("button-tooltip")[0].style.marginTop = "-" + (tooltipBounds.y + tooltipBounds.width - label.y - 35 ) + "px";

                var tooltipArrowTop = container.getElementsByClassName("tooltip-arrow-left")[0].style.top - 7;
                container.getElementsByClassName("tooltip-arrow-left")[0].style.top = (tooltipArrowTop + tooltipBounds.height) + "px";
                container.getElementsByClassName("tooltip-arrow-left")[0].style.left = "5px";
            }

            else if (this.state.arrowLeft[element]) {
                var tooltipArrowTop = container.getElementsByClassName("tooltip-arrow-left")[0].style.top - 7;
                container.getElementsByClassName("tooltip-arrow-left")[0].style.top = (tooltipArrowTop + tooltipBounds.height) + "px";
                container.getElementsByClassName("tooltip-arrow-left")[0].style.left = "5px";
            }
        }
    }

    render() {
        const {user, submitted} = this.state;

        return (
            <div id="form_4" className="forms">
                <h1 className="regHeader" style={{color: this.state.themeColor}}>Let's review some legal details.</h1>
                <hr/>
                <h2>
                    This information is not required, but will help us understand what funding opportunities you qualify for.</h2>
                    <br />
                    <h2><strong>These fields are optional.</strong>
                    If you are not ready to answer these questions, please click 'Next'.
                </h2>
                <div>
                    <div>
                        <div className="question_section tooltips align-left">
                            <label>
                                EIN (optional) <span id="UncontrolledTooltipExample" className="info-circle" onMouseEnter={() => this.toggleOpen("EIN_Number")} onMouseLeave={() => this.toggleOpen("EIN_Number")}>?</span>
                                {/* <ReactTooltip place="top" type="dark" effect="solid" />
                                <i  id="EIN_number_info" 
                                    className="material-icons"
                                    data-tip="An Employer Identification Number (EIN) is also known as a Federal Tax Identification Number, and is used to identify a business entity. Generally, businesses need an EIN. You may apply for an EIN in various ways, and now you may apply online. This is a free service offered by the Internal Revenue Service and you can get your EIN immediately. You must check with your state to make sure you need a state number or charter.">
                                    info
                                </i> */}
                            </label>
                            <ButtonDropdown className="button-tooltip">
                                <Dropdown isOpen={this.state.dropDownOpen.EIN_Number} toggle={this.emptyPlaceholder}>
                                    <DropdownMenu>
                                        <DropdownItem>
                                            <h2>EIN Number</h2>
                                            <p>some tooltip sentences go here</p>
                                        </DropdownItem>
                                    </DropdownMenu>
                                    {this.state.dropDownOpen.EIN_Number && <div className="tooltip-arrow-left"></div>}
                                </Dropdown>
                            </ButtonDropdown>
                            {/* <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
                                Hello world!
                            </UncontrolledTooltip> */}
                            <input name="EIN_Number" value={user.EIN_Number} onChange={this.handleChange} />
                        </div>
                        <div className="question_section tooltips align-left">
                            <label required>DUNS Number (optional) <span id="UncontrolledTooltipExample" className="info-circle" onMouseEnter={() => this.toggleOpen("DUNS_Number")} onMouseLeave={() => this.toggleOpen("DUNS_Number")}>?</span></label>
                            <ButtonDropdown className="button-tooltip">
                                <Dropdown isOpen={this.state.dropDownOpen.DUNS_Number} toggle={this.emptyPlaceholder}>
                                    <DropdownMenu>
                                        <DropdownItem>
                                            <h2>DUNS Number</h2>
                                            <p>some tooltip sentences go here</p>
                                        </DropdownItem>
                                    </DropdownMenu>
                                    {this.state.dropDownOpen.DUNS_Number && <div className="tooltip-arrow-left"></div>}
                                </Dropdown>
                            </ButtonDropdown>
                            {/* <ReactTooltip place="top" type="dark" effect="solid" />
                                <i  id="DUNS_number_info" 
                                    className="material-icons"
                                    data-tip="The Dun and Bradstreet D‑U‑N‑S Number is a unique nine-digit identifier for businesses. This number is assigned once our patented identity resolution process, part of our DUNSRight methodology, identifies a company as being unique from any other in the Dun & Bradstreet Data Cloud. The D‑U‑N‑S Number is used as the starting point for any company's Live Business Identity, the most comprehensive and continually updated view of any company in the Data Cloud.">
                                    info
                                </i> */}
                            <input name="DUNS_Number" value={user.DUNS_Number} onChange={this.handleChange} />
                        </div>
                        <div className="question_section">
                            <label>Are You Registered in SAM? (optional)</label>
                            <Select 
                                name="SAM_Registration"
                                isClearable={false}
                                isSearchable={false}
                                defaultValue={{ label: "No", value: false }}
                                onChange={(value) => this.handleSelectionChange(value, "SAM_Registration")}
                                options={options}
                            />
                        </div>
                        <div className="question_section">
                            <label>Woman Owned Business? (optional)</label>
                            <Select 
                                name="Woman_Owned"
                                isClearable={false}
                                isSearchable={false}
                                defaultValue={{ label: "No", value: false }}
                                onChange={(value) => this.handleSelectionChange(value, "Woman_Owned")}
                                options={options}
                            />
                        </div>
                        <div className="question_section">
                            <label>Certified Veteran Owned Business (optional)</label>
                            <Select 
                                name="Veteran_Owned"
                                isClearable={false}
                                isSearchable={false}
                                defaultValue={{ label: "No", value: false }}
                                onChange={(value) => this.handleSelectionChange(value, "Veteran_Owned")}
                                options={options}
                            />
                        </div>
                        <div className="question_section">
                            <label>Are You Located in a Hubzone? (optional)</label>
                            <Select 
                                name="Hubzone_Owned"
                                isClearable={false}
                                isSearchable={false}
                                defaultValue={{ label: "No", value: false }}
                                onChange={(value) => this.handleSelectionChange(value, "Hubzone_Owned")}
                                options={options}
                            />
                        </div>
                    </div>
                    <div>
                        {/* <button className="backButton" onClick={() => this.handleNavigation('prev')}>Back</button>
                        <button className="nextButton" onClick={() => this.handleNavigation('next')}>Next</button> */}
                        <button 
                            className="backButton" 
                            onClick={() => this.handleNavigation('prev')}
                            style={{color: this.state.themeColor, borderColor: this.state.themeColor}}
                            >
                                Back
                            </button>
                        <button 
                            className="nextButton" 
                            onClick={() => this.handleNavigation('next')}
                            style={{background: this.state.themeColor, border: this.state.themeColor}}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}