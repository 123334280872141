import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../_helpers';
import { Loader } from '../Shared/Loader';
import { favoritesActions } from '../../_actions';
import { favoritesService } from '../../_services';
import { Favorite } from './Favorite';
import { NoFavorites } from './NoFavorites';
import { NoFavoritesResults } from './NoFavoritesResults';
import '../../Styles/SavedListings.css';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";

class Favorites extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            favorites: localStorage.getItem('favorite'),
            searchGroup: {
                search: "",
                user: this.props.user,
                categories: [],
                agencies: [],
                page: 1,
                totalResults: 0,
                loading: true,
            }
        };

        this.updateFavorites = this.updateFavorites.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
        this.saveListing = this.saveListing.bind(this);

    }
    
    async componentDidMount() {
        this.setState({
            loading: true,
        });

        const user_role = this.props.user.userRoles;
        if (user_role != 'Client_Basic' 
            && 
            user_role != 'Client_Premium'
          )
        {
          history.push('/settings?val=billing');
        }

        await localStorage.setItem('activeSearch', "");
        await this.props.dispatch(favoritesActions.getFavorites(this.props.user));
        await localStorage.setItem('activePage', 'saved');

        window.addEventListener('storage',e => 
            e.key.includes("favoritesCount") ?
            this.props.dispatch(favoritesActions.getFavorites(this.props.user)) : console.log(e));
    
        setTimeout(() => {
            this.setState({
                loading: false,
            })
        }, 750);
    
    }

    openSearch = () => {
        history.push('/search');
    }

    async updateSearch(searchParameters) {
        await this.setState(prevState => ({
            searchGroup: {
                ...searchParameters,
                search: localStorage.getItem('activeSearch'),
            }
        }), () => {
            this.props.dispatch(favoritesActions.getFavorites(this.props.user))
        });
    }

    async updateFavorites() {
        await this.setState(prevState => ({
            favorites: localStorage.getItem('favorite'),
        }), () => {
            let stateLength = JSON.parse(this.state.favorites).length;
            let propsLength = this.props.getFavoritesitems.length;

            if (stateLength !== propsLength)
            {
                this.props.dispatch(favoritesActions.getFavorites(this.props.user));
            }
        });
    }

    async saveListing(listing_Id, user) {
        this.setState({
            loading: true,
        })
        
        await this.props.dispatch(favoritesActions.saveListing(listing_Id, user));
        await this.props.dispatch(favoritesActions.getFavorites(this.props.user));

        setTimeout(() => {
            this.setState({
                loading: false,
            })
        }, 750);
    }

    exportFavorites = async () => {
        var user = JSON.parse(localStorage.getItem('user'));
        var blob = await favoritesService.exportFavorites(user.id);
        const file = window.URL.createObjectURL(blob);

        var aTag = document.createElement("a");
        aTag.download = "FavoriteListings.csv"
        aTag.href = file;
        aTag.target = "_blank";

        aTag.click();
    }

    render() {
        const { getFavorites, whitelabel } = this.props;
        const { loading } = this.state;
        const localSearchResults = JSON.parse(localStorage.getItem('favorite'));
        const activeSearch = localStorage.getItem('activeSearch') !== '';
        const searchPageActive = localStorage.getItem('activePage') === 'search';
        const savedPageActive = localStorage.getItem('activePage') === 'saved';

        return (
            <div 
                className="row" 
                id="savedListings"
                style={{marginTop: "5%"}}
            >
                <h1 id="searchHeader">Your Saved Listings</h1>
                  <div class="row">
                    <div style={{float: "left"}}>
                      <p id="numRecords">No. Favorites: 
                        <span style={{color: "#555", fontWeight: 600}}> {localSearchResults.length}</span>
                      </p>
                    </div>
                    <div style={{float: "right"}}>
                        <Button onClick={this.exportFavorites} style={{background: "#999"}}>Export CSV</Button>
                    </div>
                  </div>
                {(getFavorites.loading || loading) && <Loader />}
                {getFavorites.error && <span className="text-danger">ERROR: {getFavorites.error}</span>}
                {localSearchResults && 
                    <div>
                        {(localSearchResults.length > 0 || activeSearch) &&
                            <div id="hasFavorites">
                                <div class="ais-Hits">
                                    <ul class="ais-Hits-list">
                                        {localSearchResults.length > 0 &&
                                        localSearchResults.map((listing) =>
                                            <Favorite 
                                                listing={listing} 
                                                key={"Saved_" + listing.listing_Id}
                                                updateFavorites={this.updateFavorites}
                                                saveListing={this.saveListing}/>
                                        )}
                                    </ul>
                                </div>
                                {localSearchResults.length === 0 && activeSearch &&
                                    <NoFavoritesResults />
                                }
                            </div>
                        }
                        {localSearchResults.length === 0 && !activeSearch &&
                            <NoFavorites />
                        }
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { getFavorites, setUser, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        getFavorites,
        whitelabel,
    };
}

const connectedFavorites = connect(mapStateToProps)(Favorites);
export { connectedFavorites as Favorites };