export const searchConstants = {
    GETSEARCHRESULTS_REQUEST: 'SEARCH_GETSEARCHRESULTS_REQUEST',
    GETSEARCHRESULTS_SUCCESS: 'SEARCH_GETSEARCHRESULTS_SUCCESS',
    GETSEARCHRESULTS_FAILURE: 'SEARCH_GETSEARCHRESULTS_FAILURE',

    GETEMERGENCYSEARCHRESULTS_REQUEST: 'SEARCH_GETEMERGENCYSEARCHRESULTS_REQUEST',
    GETEMERGENCYSEARCHRESULTS_SUCCESS: 'SEARCH_GETEMERGENCYSEARCHRESULTS_SUCCESS',
    GETEMERGENCYSEARCHRESULTS_FAILURE: 'SEARCH_GETEMERGENCYSEARCHRESULTS_FAILURE',

    GETALLCATEGORIES_REQUEST: 'SEARCH_GETALLCATEGORIES_REQUEST',
    GETALLCATEGORIES_SUCCESS: 'SEARCH_GETALLCATEGORIES_SUCCESS',
    GETALLCATEGORIES_FAILURE: 'SEARCH_GETALLCATEGORIES_FAILURE',

    GETALLAGENCIES_REQUEST: 'SEARCH_GETALLAGENCIES_REQUEST',
    GETALLAGENCIES_SUCCESS: 'SEARCH_GETALLAGENCIES_SUCCESS',
    GETALLAGENCIES_FAILURE: 'SEARCH_GETALLAGENCIES_FAILURE',

    GETALLLOCATIONS_REQUEST: 'SEARCH_GETALLLOCATIONS_REQUEST',
    GETALLLOCATIONS_SUCCESS: 'SEARCH_GETALLLOCATIONS_SUCCESS',
    GETALLLOCATIONS_FAILURE: 'SEARCH_GETALLLOCATIONS_FAILURE',
};
