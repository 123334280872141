import React from 'react';
import { connect } from 'react-redux';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import { Loader } from '../../Shared/Loader';
import '../../../Styles/Organization.css';
import { Registration_AutoSuggestion } from '../../Account/RegisterPage';
import { Previews } from './OrganizationImageUpload';
import { OrganizationList, OrganizationView } from '.';
import ReactTooltip from "react-tooltip";
import { EditOrganizationIndustries } from './EditOrganizationIndustries';
import { EditOrganizationTechnologies } from './EditOrganizationTechnologies';
import { EditOrganizationImpact } from './EditOrganizationImpact';
import { EditOrganizationNAICS } from './EditOrganizationNAICS';
import { EditOrganizationSAM } from './EditOrganizationSAM';
import { EditOrganization8A } from './EditOrganization8A';
import { EditOrganizationWOB } from './EditOrganizationWOB';
import { EditOrganizationVOB } from './EditOrganizationVOB';
import { EditOrganizationHubzone } from './EditOrganizationHubzone';
import { EditOrganizationSB } from './EditOrganizationSB';
import { EditOrganizationDUNS } from './EditOrganizationDUNS';
import { organizationService, locationsService } from '../../../_services';


const options = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
];

class EditOrganizationFormMembers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {
        const { user, whitelabel,
            addOrganization, editOrganization,
            invite_emailInput,
            accepted_profiles, accepted_profile_list,
            pending_profiles, pending_profile_list,
            pending_invitation_profiles,
            pending_invitation_list,
            transfer,
            handleSubmit,
            hideAddEditOrganization
        }  = this.props;
        const { organization } = this.props.org;

        return (
            <div id="mainSettings">
                <hr style={{marginTop: 75}}/>
                {(transfer || organization.owner == user.id) && 
                <div>
                    <div id="mainSettings">
                        <div className="row" style={{marginTop: "5%"}}>
                            <h1 id="dashHeader" style={{marginBottom: 20}}>Members</h1>
                            <div style={{paddingLeft: 15, paddingRight: 15}}>
                                <p style={{marginBottom: 25}}><strong>{accepted_profiles ? accepted_profiles.length : 0}</strong> Member(s)</p>
                                <div style={{marginTop: 25}}>
                                    {accepted_profile_list}
                                </div>
                                <div style={{marginTop: 40, marginBottom: 40}}>
                                    <p style={{marginBottom: 25}}><strong>{pending_invitation_profiles ? pending_invitation_profiles.length : 0}</strong> Pending Invitation(s)</p>
                                    {!transfer && <div>
                                        <input name="invite_emailInput" value={invite_emailInput} placeholder="Email" onChange={this.props.handleNewOrganization} />
                                        <Button 
                                            color="info" 
                                            onClick={() => {this.props.inviteToJoinOrganization(user.id, organization.organization_Id, invite_emailInput)}} 
                                            style={{marginTop: 15, background: whitelabel ? whitelabel.button_Color : "#29a"}}
                                        >
                                            Invite
                                        </Button>
                                    </div>}
                                    <div style={{marginTop: 25}}>
                                        {pending_invitation_list}
                                    </div>
                                </div>
                                <div style={{marginTop: 40}}>
                                    <p style={{marginBottom: 25}}><strong>{pending_profiles ? pending_profiles.length : 0}</strong> Pending Request(s)</p>
                                    {pending_profile_list}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style={{marginTop: 35}}/>
                </div>}
                <h4 style={{fontWeight: 500}}>
                    {editOrganization && !this.props.organizationNameError &&
                        <Button 
                            color="info" 
                            onClick={handleSubmit}
                            style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                        >
                            Update Organization
                        </Button>
                    }
                    {editOrganization && this.props.organizationNameError &&
                        <Button 
                            color="grey"
                            style={{background: "#666!important", cursor: "help"}}
                            onClick={null}
                            // style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                        >
                            Update Organization
                        </Button>
                    }
                    {addOrganization &&
                        <Button 
                            color="info" 
                            onClick={handleSubmit}
                            style={{margin: 20, background: whitelabel ? whitelabel.button_Color : "#29a"}}
                        >
                            Create Organization
                        </Button>
                    }
                    <Button 
                        onClick={hideAddEditOrganization} 
                        style={{marginLeft: 20, background: whitelabel ? whitelabel.button_Color : "#29a"}}
                    >
                        Cancel
                    </Button>
                </h4>
                <br />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        organization,
        whitelabel,
    };
}

const connectedEditOrganizationFormMembers = connect(mapStateToProps)(EditOrganizationFormMembers);
export { connectedEditOrganizationFormMembers as EditOrganizationFormMembers };