import React, { Component } from 'react';
import { NavItem } from 'reactstrap';
import { connect } from 'react-redux';
import '../../../Styles/NavMenu.css';

class MobileNavMenu extends Component {
    constructor(props) {
        super(props);
    }

    goToLink = (url) => {
        window.location.href = url;
    }

    render() {
        const { user, whitelabel } = this.props;

        return (
            <div>
                {user &&
                <div hidden id="mobile-nav" className="nav navbar-nav">
                    <ul>
                        <NavItem onClick={() => this.goToLink("/dashboard")} className="mobileLI">
                            <i className="material-icons">dashboard</i>
                            <a>Dashboard</a>
                        </NavItem>
                        {whitelabel && whitelabel.features.feature_Basic_Search &&
                            <NavItem onClick={() => this.goToLink("/search")} className="mobileLI">
                                <i className="material-icons">search</i>
                                <a>Search Funding</a>
                            </NavItem>
                        }
                        {((user && user.app_Registration == false) || !user) &&
                           whitelabel && whitelabel.features.feature_Quickstart &&
                            <NavItem onClick={() => this.goToLink("/quickstart/get-started")} className="mobileLI">
                                <i className="material-icons">speed</i>
                                <a>Quickstart</a>
                            </NavItem>
                        }
                        {user && user.app_Registration == true &&
                         whitelabel && whitelabel.features.feature_Quickstart &&
                            <NavItem onClick={() => this.goToLink("/quickstart/dashboard")} className="mobileLI">
                                <i className="material-icons">speed</i>
                                <a>Quickstart</a>
                            </NavItem>
                        }
                        {(user.userRoles != 'Client_Free') &&
                          whitelabel && whitelabel.features.feature_Matches && whitelabel.organization_Name != "portal.chicostart.com" &&
                            <NavItem onClick={() => this.goToLink('/matches')}className="mobileLI">
                                <i className="material-icons">thumb_up</i>
                                <a>Matches</a>
                            </NavItem>
                        }
                        {(user.userRoles == 'Client_Free') &&
                            <NavItem onClick={() => this.goToLink('/settings?val=billing')}className="mobileLI">
                                <i className="material-icons" style={{color: "grey"}}>thumb_up</i>
                                <p style={{display: "inline", marginTop: "-5px", color: "grey"}}>Matches  *</p>
                            </NavItem>
                        }
                        {(user.userRoles != 'Client_Free') &&
                          whitelabel && whitelabel.features.feature_Favorites &&
                            <NavItem onClick={() => this.goToLink('/favorites')}className="mobileLI">
                                <i className="material-icons">library_add_check</i>
                                <a>Favorites</a>
                            </NavItem>
                        }
                        {(user.userRoles == 'Client_Free') &&
                            <NavItem onClick={() => this.goToLink('/settings?val=billing')}className="mobileLI">
                                <i className="material-icons" style={{color: "grey"}}>save</i>
                                <p style={{display: "inline", marginTop: "-5px", color: "grey"}}>Favorites  *</p>
                            </NavItem>
                        }
                        {whitelabel && whitelabel.features.feature_Apply &&
                            <NavItem onClick={() => this.goToLink('/apply')}className="mobileLI">
                                <i className="material-icons">fact_check</i>
                                <a>Apply</a>
                            </NavItem>
                        }
                        {/* {whitelabel && whitelabel.features.feature_Expert_Resources &&
                            <NavItem onClick={() => this.goToLink('/find-an-expert')}className="mobileLI">
                                <i className="material-icons">fact_check</i>
                                <a>Find an Expert</a>
                            </NavItem>
                        } */}
                        <NavItem onClick={() => this.goToLink('/settings')}className="mobileLI">
                            <i className="material-icons">settings</i>
                            <a>Settings</a>
                        </NavItem>
                        <NavItem onClick={() => this.goToLink('/login')} className="mobileLI">
                            <i className="material-icons">exit_to_app</i>
                            <a>Logout</a>
                        </NavItem>
                    </ul>
                </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        whitelabel
    };
}

const connectedHomePage = connect(mapStateToProps)(MobileNavMenu);
export { connectedHomePage as MobileNavMenu };