import React from 'react';
import { connect } from 'react-redux';
import { registrationActions } from '../../../_actions';
import { registrationService } from '../../../_services';
import { setPartnerInfo } from '../../Shared/Partners';
import '../../../Styles/ConfirmEmail.css';

class ConfirmEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partner: '',
        }
    }

    async componentDidMount() {
        const {userId, code} = this.processUrl();

        if (userId)
        {
            await registrationService.getUserPartner(userId);
            await setPartnerInfo(localStorage.getItem('partner'));
        }
    }

    processUrl = () => {
        const {search} = this.props.location;
        let userId = "";
        let code = "";
        if (search !== "" && search.includes("?") && search.includes("userId") && search.includes("code")) {
            const parameters = search.split("?")[1];
            const parametersSplit = parameters.split("&");
            for (var i = 0; i < parametersSplit.length; i++) {
                if (parametersSplit[i].includes("userId")) {
                    userId = parametersSplit[i].split("userId=")[1];
                } else if (parametersSplit[i].includes("code")) {
                    code = parametersSplit[i].split("code=")[1];
                }
            }
        }
        console.log(userId);
        console.log(code);

        return {userId, code};
    }

    confirmEmail = (emailConfirmation) => {
        const { dispatch } = this.props;
        const server = window.location.protocol + '//' + window.location.host;

        dispatch(registrationActions.confirmEmail(emailConfirmation, server));
        this.setState({
            pageLoading: false,
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {dispatch} = this.props;
        const server = window.location.protocol + '//' + window.location.host;

        const email = document.getElementById("Email").value;
        if (email) {
            dispatch(registrationActions.sendConfirmationEmail(email, server));
        }
    }

    handleEmailConfiramtion = () => {
        const {userId, code} = this.processUrl();
        if (userId !== "" && code !== "") {
            const emailConfirmation = {UserId: userId, Code: code};
            this.confirmEmail(emailConfirmation);
            return true;
        }
        return false;
    }

    clearInput = () => {
        let email_element = document.getElementById("Email");
        if (email_element) {
            email_element.value = "";
        }
        return true;
    }

    redirectPage = (timeout) => {
        setTimeout(() => {
            const partner = localStorage.getItem('partner');
            if (!partner || partner === '' || partner === null)
            {
                window.location.href='/login';
            }
            else
            {
                const loginURL = '/login/' + partner;
                window.location.href=loginURL;
            }
        }, timeout);
    }

    render() {
        console.log(this.props);
        const {status, email} = this.props.confirmEmail;
        let loading = true;
        if (status === "") {
            loading = this.handleEmailConfiramtion();
        }
        console.log(loading);
        const partner = localStorage.getItem('partnerLogo');

        return (
            <div id="confirmEmail">
                {!partner &&
                    <div>
                        <h1 className="opengrantsLogo">opengrants.io</h1>
                        <hr className="styledLine" />
                    </div>
                }
                {partner &&
                    <div id="partnerLoginLogo">
                        <img src={localStorage.getItem('partnerLogo')} width="250" />
                    </div>
                }
                {status === "complete_registration" &&
                    <div>
                        <h1 className="heading">THANK YOU FOR SIGNING UP!</h1>
                        <h1 className="subheading">You're ready to go! Please check your email (and your spam folder) to finish verifying your account.</h1>
                        <div id="letsChat">
                            <span className="paragraph">
                                Questions? Email us at <br /><a href="mailto:support@opengrants.io" className="themeColor">support@opengrants.io.</a>
                            </span>
                        </div>
                    </div>
                }
                {status === "success" && 
                    <div>
                        {this.redirectPage(5000)}
                        <h1 className="heading">Thank you for verifying your email.</h1>
                        <div id="letsChat">
                            <div>
                                <h2 className="subheading">
                                    You will automatically be redirected to the login page.
                                    If you are not redirected to the login page within 10 seconds, please
                                    <span className="themeColor" onClick={() => this.redirectPage(0)}> click here</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                }
                {status === "error" &&
                <div>
                    <h1 className="heading">This link has expired.</h1>
                </div>
                }
                {status === "sent_success" && 
                <div>
                    <h1 className="heading">A verification email has been sent to {email}</h1>
                </div>
                }
                {status === "sent_failure" && 
                <div>
                    <h1 className="heading">We're sorry. There was an error sending a verification link to your email.</h1>
                </div>
                }
                {((status === "error" || status === "sent_failure") || (status === "" && !loading)) && this.clearInput() &&
                <div>
                    <div className="filler"></div>
                    <h1 className="subheading">Enter your email below to resend a verfication link. Remember to click on the most recent link sent by us.</h1>
                    <form onSubmit={this.handleSubmit}>
                        <input id="Email" placeholder="Email"></input>
                        <button>Send</button>
                    </form>
                    <div id="letsChat"><span className="paragraph">If you continue to experience any issues, please contact us at <a href="mailto:support@opengrants.io" className="themeColor">support@opentgrants.io</a>.</span></div>
                </div>
                }
                {(status !== "" || !loading) &&
                <div className="bottom-links">
                    <div className="links">
                        <a href="https://www.opengrants.io/opengrants-legal/">Privacy Policy</a>
                        <a href="https://www.opengrants.io/opengrants-legal/">Terms and Conditions</a>
                        <a href="https://experience.opengrants.io/contact-opengrants">Help</a>
                        <a href="/login">Login</a>
                    </div>
                    <div className="copyFooter">
                        opengrants.io &#x24B8; {(new Date().getFullYear())} Copyright by Egeria Corporation
                    </div>
                </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { confirmEmail } = state;
    return {
        confirmEmail
    };
}

const connectedConfirmEmail = connect(mapStateToProps)(ConfirmEmail);
export { connectedConfirmEmail as ConfirmEmail };