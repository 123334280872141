export const layoutService = {
    subscribe,
    getStats
};

function subscribe(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch('https://refactor.opengrants.io/api/layout/email_subscribe?email=' + email, requestOptions).then(handleResponse);
}

function getStats() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch('https://refactor.opengrants.io/api/layout/footer_statistics', requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status == "401") {
                window.location.href="/login";
            }
            
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}