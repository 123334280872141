import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { settingsActions } from '../../_actions';
import { authenticateActions } from '../../_actions';
import { personalprofileService, authenticateService } from '../../_services';
import { Previews } from './ImageUpload';
import { Loader } from '../Shared/Loader';
import './ImageUpload.jsx';
import '../../Styles/SettingsStyles/Profile.css';

import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import Class from "@material-ui/icons/Class";

const styles = {
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    }
  };

class Profile extends React.Component {
    constructor(props) {
        super(props);

        const user = JSON.parse(localStorage.getItem('user'));
        let username = "";
        if (user.userName && user.userName != user.email) {
            username = user.userName;
        } else {
            if (user.name && user.name != user.email) {
                username += user.name;
            } 
            if (user.surname && user.surname != user.email) {
                username += username ? "-" + user.surname : user.surname;
            } 
            username += username != "" ? "-" + user.people_Id : user.people_Id;
        }
        user.userName = username;

        this.state = {
            submitted: false,
            submitting: false,
            updatedAlert: false,
            user: user,
            publicProfile: user.public_Profile,
            usernameError: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    async handleSubmit(event) {
        this.setState({
            submitting: true,
            usernameError: ""
        });

        event.preventDefault();

        const updateUser = this.state;
        const { dispatch } = this.props;

        updateUser.file_string = localStorage.getItem('fileBase64');
        updateUser.user = this.state.user;

        const {user} = this.props;
        var isValid = true;
        if (updateUser.user.userName !== user.userName) {
            isValid = await authenticateService.checkUsername(updateUser.user.userName);
        }
        
        if (isValid) {
            console.log(updateUser);
            await dispatch(settingsActions.updateProfile(updateUser));
            await this.props.dispatch(authenticateActions.setUser(updateUser.user));

            this.setState({
                submitted: true,
                submitting: false,
                updatedAlert: true,
                usernameError: ""
            });

            setTimeout(() => {
                this.setState({
                updatedAlert: false
                });
            }, 4000000);
        } else {
            document.body.scrollTo(0,0);
            this.setState({
                submitted: true,
                submitting: false,
                usernameError: "This username already exists"
            });
        }
    }

    togglePublic = async() => {
        const user = JSON.parse(localStorage.getItem('user'));
        const new_publicProfile = !this.state.publicProfile;
        await personalprofileService.setPublicProfile(user.id, new_publicProfile);

        user.public_Profile = new_publicProfile;
        localStorage.setItem('user', JSON.stringify(user));

        this.setState({
            publicProfile: new_publicProfile
        });
    }

    render() {
        const { user, submitting, publicProfile, usernameError }  = this.state;
        const { whitelabel } =  this.props;

        return (
            <div>
                <form id="profileForm" className="settingsForm" onSubmit={this.handleSubmit}>
                    <div id="mainSettings">
                        {submitting && <Loader />}
                        <div>
                            <div className="row">
                                {whitelabel && whitelabel.organization_Name == "portal.opengrants.io" &&
                                    <div style={{overflow: "auto"}}>
                                        <div style={{float: "right"}}>
                                            <label class="switch">
                                                {publicProfile && <input checked type="checkbox" onChange={this.togglePublic} />}
                                                {!publicProfile && <input type="checkbox" onChange={this.togglePublic} />}
                                                <span class="slider round"></span>
                                            </label>
                                            <span style={{fontSize:"14px", marginLeft:"10px"}}>Public</span>
                                        </div>
                                    </div>
                                }
                                <div style={{marginTop: "-15px"}}>
                                    <div className="col-lg-6">
                                        {whitelabel && whitelabel.features.feature_Settings_Organizations && 
                                            <div>
                                                <h2>Username</h2>
                                                <input id="userName" name="userName" value={user.userName} required onChange={this.handleChange}/>
                                                {usernameError && <p style={{color: "red", marginTop: "10px"}}>{usernameError}</p>}
                                            </div>
                                        }
                                        <div>
                                            <h2>Name</h2>
                                            <input id="name" name="name" value={user.name} required onChange={this.handleChange}/>
                                            {/* <span asp-validation-for="@Model.Name" className="text-danger"></span> */}
                                        </div>
                                        <div>
                                            <h2>Surname</h2>
                                            <input id="surname" name="surname" value={user.surname} required onChange={this.handleChange}/>
                                            {/* <span asp-validation-for="@Model.Surname" className="text-danger"></span> */}
                                        </div>
                                        <div>
                                            <h2>Title</h2>
                                            <input name="title" value={user.title} onChange={this.handleChange}/>
                                        </div>
                                        <div>
                                            <h2>Bio</h2>
                                            <textarea id="profileBio" name="bio" value={user.bio} onChange={this.handleChange}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div>
                                            <h2>Twitter</h2>
                                            <input name="twitter" value={user.twitter} onChange={this.handleChange}/>
                                        </div>
                                        <div>
                                            <h2>LinkedIn</h2>
                                            <input name="linkedin_Profile" value={user.linkedin_Profile} onChange={this.handleChange}/>
                                        </div>
                                        <div style={{maxWidth: 500}}>
                                            <h2>Upload Headshot (Max 5Mb)</h2>
                                            <input type="hidden" name="headshot" value={user.headshot} />
                                            <span id="image_error" className="error"></span>
                                            <div id="profileImage">
                                                <Previews />
                                            </div>
                                            {user.headshot && <img src={user.headshot} width="250" style={{marginTop: "2.5%"}}/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div id="saveProfileDiv">
                        <button className="saveForm" id="saveProfile">Save Profile</button>
                    </div> */}
                    <hr style={{marginTop: 35}}/>
                    <h4 style={{fontWeight: 500}}>
                        <Button 
                            color="info"
                            onClick={this.handleSubmit}
                            style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                        >
                            Update Profile
                            <ArrowForwardIos style={{float: "right", fontSize:32, marginTop: 0, marginLeft:10, marginRight:-10}}/>
                        </Button>
                    </h4>
                    <br />
                    {this.state.updatedAlert &&
                        <div>
                            <br /><br />
                            <div id="settingsUpdatedSuccess" className={`alert-success`}>Profile settings have been updated.</div>
                        </div>
                    }
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, profile, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        profile,
        whitelabel,
    };
}

const connectedRegisterPage = connect(mapStateToProps)(Profile);
export { connectedRegisterPage as Profile };