import React from 'react';
import { history } from '../../_helpers';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import '../../Styles/Matches.css';

export class NoMatches extends React.Component {
    constructor(props) {
        super(props);
    }

    goToSearch() {
        history.push("/search");
    }

    goToSettings() {
        history.push("/settings?val=2");
    }

    goToSubmitProgram = () => {
        window.open('https://airtable.com/shr1UV3Bit2ZQURnZ', '_blank');
    }

    openChat(type) {
        window.Intercom('trackEvent', type);
        window.Intercom('showNewMessage');
    }

    render() {
        
        return (
            <div id="noNewRecommendations">
                <h4>No new recommendations.</h4>
                <hr />
                <img src="https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_404_page.jpg" width="250" align="center"/>
                <br /><br /><br />
                <h3 className="noNewRecommendationsText">You have no new recommendations at this time.</h3>
                <p className="noNewRecommendationsText" style={{fontSize: 16, maxWidth: 650, textAlign: "center"}}>Visit your profile to update your categories of interest, or explore grants and other funding programs with the search tool.</p>
                <br /><br />
                <Button 
                    color="info"
                    onClick={() => {this.goToSearch()}}
                    style={{margin: 10, minWidth: 250}}

                >
                    Search Programs
                </Button>
                <Button 
                    color="info"
                    onClick={() => {this.goToSettings()}}
                    style={{margin: 10, minWidth: 250}}

                >
                    Update Preferences
                </Button>
                <Button 
                    color="info"
                    style={{margin: 10, minWidth: 250}}
                    onClick={() => {this.goToSubmitProgram()}}
                >
                    Submit Program for Tracking
                </Button>
            </div>
        );
    }
}

