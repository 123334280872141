import { connect } from 'react-redux';
import React from 'react';
import { stripeService } from '../../../_services';
import { Loader } from '../../Shared/Loader';
import '../../../Styles/StripeProduct.css';
import { stripeActions } from '../../../_actions';
import {StripeBilling, StripePricingPage} from '.';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";

class StripeBillingPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            card: {
                cardNumber: '', //sources -> data -> last4
                expDate: '', //sources -> data -> exp_month, exp_year
            },
            address: {
                cardHolderName: '', //sources -> data -> name
                address: '', //sources -> data -> address_line1
                city: '', //source -> data -> address_city
                state: '', //sources -> data -> address_state
                zipCode: '' //sources -> data -> address_zip
            },
            
            subscriptions: {
            },

            // updateBillingInfo: false,
            updateCard: false,
            updateAddress: false,

            updatePlan: false,

            purchaseHistory: false
        }
    }

    async componentDidMount() {
        var user = JSON.parse(localStorage.getItem('user'));
        if (user != null) {
            // if (user.partner) {
            //     var redirectUrl = await stripeService.redirectToConnect(user.id, "/settings");
            //     window.location.href = redirectUrl.url;
            // } 
            this.getSubscriptions(user);
            // else {
            //     this.getBillingInfo(user);
            // }
        }

        const initVal = window.location.search.includes('val') ? parseInt(window.location.search.split("=")[1] , 10 ) : 0;
        if (initVal == 3)
        {
            this.setState({
                updatePlan: true
            })
        }

    }

    getSubscriptions = async(user) => {
        var data = await stripeService.getSubscriptions(user.id);
        // if (data && data.billingInfo && data.billingInfo.userRoles) {
        //     let user = JSON.parse(localStorage.getItem('user'));
        //     user.userRoles = data.billingInfo.userRoles;

        //     localStorage.setItem('user', JSON.stringify(user));
        // }
        
        this.setState({
            subscriptions: data.billingInfo.subscriptions,
        });
    }

    getBillingInfo = async (user) => {
        var data = await stripeService.getBillingInfo(user.id);
        
        this.setState({
            address: data.billingInfo.billingAddress,
            card: data.billingInfo.card,
        });
    }

    handlePaymentDetails = async (paymentDetails, userDetails) => {
        const {paymentMethodId} = paymentDetails;

        var productDetails = {
            userDetails: {
                ...userDetails,
            },
            productDetails: {
                paymentMethodId,
            }
        };

        this.props.dispatch(stripeActions.updateUserDetails(productDetails));
    }

    updateBillingInfo = (type) => {
        if (type === 'card') {
            this.setState({
                updateCard: true,
                updateAddress: false,
                updatePlan: false,
                purchaseHistory: false
            });
        }
        else if (type === 'address') {
            this.setState({
                updateAddress: true,
                updateCard: false,
                updatePlan: false,
                purchaseHistory: false
            });
        } 
        else if (type == 'plan') {
            this.setState({
                updatePlan: true,
                updateCard: false,
                updateAddress: false,
                purchaseHistory: false
            });
        }
        else {
            this.setState({
                updateCard: false,
                updateAddress: false,
                updatePlan: false,
                purchaseHistory: false
            });
        }
    }

    cancelSubscription = (subscriptionId) => {
        this.props.dispatch(stripeActions.cancelSubscription(subscriptionId));
    }

    changePlan = (subscriptionId) => {
        this.setState({
            updatePlan: true
        });

        localStorage.setItem('planChange', true);
    }

    cancelSubscriptionDowngrade = (subscriptionId) => {
        var user = JSON.parse(localStorage.getItem('user'));
        this.props.dispatch(stripeActions.cancelSubscriptionDowngrade(user.id, subscriptionId));
    }

    getPurchaseHistory = async () => {
        var user = JSON.parse(localStorage.getItem('user'));
        var purchasedPrices = await stripeService.getPurchasedPrices(user.id);
        console.log(purchasedPrices);

        this.setState({
            purchasedPrices,
            purchaseHistory: true
        });
    }

    render () {
        const {card, address, subscriptions,
            updateCard,
            updateAddress,
            updatePlan,
            purchaseHistory,
            purchasedPrices
        } = this.state;
        const {updateBilling, 
            cancelSubscription, updateSubscription,
            purchaseProduct} = this.props;
        var user = JSON.parse(localStorage.getItem('user'));
        var addOns = null;
        var canceled = null;
        var mains = null;
        var mains_canceled = null;
        var mainGroup = "";
        var mainSubscriptionId = "";
        var mainEndDate = "";
        var otherMainGroup = ""; //canceled main subscription
        var otherMainSubscriptionId = "";
        var otherMainEndDate = "";
        var cancelPlanChange = false;

        if (updateBilling.status === true || cancelSubscription.status === true || updateSubscription === true || purchaseProduct.purchaseSuccess == true) {
            document.body.scrollTo(0,0);
            if (updateCard) {
                this.setState({updateCard: false});
            }
            if (cancelSubscription.status === true || updateSubscription === true || purchaseProduct.purchaseSuccess == true) {
                this.getSubscriptions(user);
            }
        }

        if (subscriptions && subscriptions.mains && subscriptions.mains.length > 0) {
            if (subscriptions.mains.length > 1) {
                cancelPlanChange = true;
            }
            mains_canceled = subscriptions.mains.map((main, idx) => {
                if (main.status !== "Cancel") {
                    otherMainGroup = main.group;
                    otherMainSubscriptionId = main.id;
                    otherMainEndDate = main.endDate;

                    return (
                        <div key={idx}>
                            {main.status === "Cancel" &&
                                <Button 
                                    onClick={() => this.changePlan(main.id)} 
                                    style={{marginLeft:"20px"}}
                                    color="#999"
                                >
                                    Change Plan
                                </Button>
                            }
                            <h3 style={{display: "inline-block"}}>{main.name}</h3>
                            
                            {main.status === "Cancel" &&
                                <p>{main.price} {mainEndDate ? "starting on " + mainEndDate : ""}</p>
                            }
                            {main.status !== "Cancel" && 
                                <label>{main.status}</label>
                            }
                        </div>
                    )
                }
            });
            mains = subscriptions.mains.map((main, idx) => {
                if (main.status === "Cancel") {
                    mainGroup = main.group;
                    mainSubscriptionId = main.id;
                    mainEndDate = main.endDate;

                    return (
                        <div key={idx}>
                                <Button 
                                    onClick={() => this.changePlan(main.id)} 
                                    style={{marginLeft:20, marginTop: -35, position: "absolute", right: 35, width: 200}}
                                    color="#999"
                                >
                                    Change Plan
                                </Button>

                            <h3 style={{display: "inline-block"}}>{main.name}</h3>
                            {main.status === "Cancel" &&
                                <p>{main.price} {otherMainEndDate ? "starting on " + otherMainEndDate : ""}</p>
                            }
                            {main.status !== "Cancel" && 
                                <label>{main.status}</label>
                            }
                        </div>
                    )
                }
            });
        }

        if (subscriptions && subscriptions.addOns && subscriptions.addOns.length > 0) {
            addOns = subscriptions.addOns.map((addOn, idx) => {
                if (addOn.status === "Cancel") {
                    return (
                        <div key={idx}>
                            <h3 style={{display: "inline-block"}}>{addOn.name}</h3>
                            <Button 
                                onClick={() => this.cancelSubscription(addOn.id)} 
                                style={{marginLeft:"20px"}}
                                color="#999"
                            >
                                {addOn.status}
                            </Button>
                            <p>{addOn.price}</p>
                        </div>
                    )
                }
                else return null;
            });

            canceled = subscriptions.addOns.map((addOn, idx) => {
                if (addOn.status !== "Cancel") {
                    return (
                        <div key={idx}>
                            <h3 style={{display: "inline-block"}}>{addOn.name}</h3>
                            <label>{addOn.status}</label>
                        </div>
                    )
                }
                else return null;
            });
        }

        var purchaseList = null;
        if (purchaseHistory) {
            var i = 0;
            purchaseList = purchasedPrices.map((price) => {
                i++;
                return (
                    <tr key={i}>
                        <td><p style={{display: "inline-block", marginRight: "20px"}}>{price.date.split("T")[0]}</p></td>
                        <td><a href={price.url} target="_blank" style={{display: "inline-block", marginRight: "20px"}}><p>{price.name}</p></a></td>
                        <td><p style={{display: "inline-block", marginRight: "20px", color: "grey"}}>- ${(price.total/100.00).toFixed(2)}</p></td>
                        {/* {price.refunded && <td><p style={{display: "inline-block", marginRight: "20px", color: "green"}}>+ ${(price.total/100.00).toFixed(2)}</p></td>} */}
                        {price.refunded && <td>
                            <i className="material-icons" style={{color: "grey", float: "left"}}>keyboard_return</i>
                            <p style={{display: "inline-block", color: "grey"}}>Refunded on {price.refundDate.split("T")[0]}</p>
                            </td>}
                    </tr>
                )
            });
        }

        return (
            <div>
                {(updateBilling.loading || cancelSubscription.loading || updateSubscription.loading || purchaseProduct.loading) && <Loader />}

                {!updatePlan &&
                <div>
                    {!updateCard && !purchaseHistory && 
                    <div>
                        {!purchaseHistory && <div>
                            <a 
                                style={{cursor: "pointer", marginTop: 15, position: "absolute", top: "20px", right: "35px", fontSize: "16px"}}
                                onClick={this.getPurchaseHistory}
                            >
                                Purchase History >>>
                            </a>
                        </div>}
                       <div className="background-white pop gap-bottom">
                            <h2 style={{marginTop: 20}}>Current Plan</h2>
                            {cancelPlanChange &&
                                <Button 
                                    onClick={() => this.cancelSubscriptionDowngrade(mainSubscriptionId)}
                                    style={{marginTop: 15, width: 200}}
                                >
                                    Cancel Plan Change
                                </Button>
                            }
                            <div style={{marginLeft: "30px"}}>
                                {mains}
                                {mains_canceled}
                            </div>
                        </div>
                        {(addOns || canceled) && <div className="background-white pop gap-bottom">
                            <h2 style={{marginTop: 0}}>Current Add On Services</h2>
                            <div style={{marginLeft: "30px"}}>
                                {addOns}
                                {canceled}
                            </div>
                        </div>
                        }
                    </div>
                    }
                    
                    {!purchaseHistory && <div>
                        <hr style={{marginBottom: 40}}/>
                        <div>
                            <StripeBilling 
                                purchaseProductWithMethod={this.handlePaymentDetails} 
                                updateButton={updateCard}
                                updateAddress={false}
                                billingAddress={address}
                                updateCard={updateCard}
                                updateBillingInfo={this.updateBillingInfo}
                            />
                        </div>
                    </div>} 

                    {!updateCard && purchaseHistory && <div>
                        <Button 
                            style={{float: "none"}} 
                            onClick={() => {this.updateBillingInfo()}}
                        >
                            {"<<< Return To Billing"}
                        </Button>
                        <h1 style={{marginTop: 20, marginBottom: 15, marginLeft: 15}}>Purchase History</h1>
                        <table className="purchaseHistory" style={{marginLeft: 30, tableLayout: "fixed"}}>
                            {purchaseList}
                        </table>
                    </div>}
                </div>
                }

                {updatePlan &&
                <div>
                    <StripePricingPage
                        updatePlan={updatePlan}
                        updateBillingInfo={this.updateBillingInfo}
                        selectedProduct={mainGroup}
                        mainSubscriptionId={mainSubscriptionId}
                        mainEndDate={mainEndDate}
                        otherMainGroup={otherMainGroup}
                        otherMainSubscriptionId={otherMainSubscriptionId}
                        otherMainEndDate={otherMainEndDate}
                    />
                </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    let updateBilling = state.updateBilling;
    let cancelSubscription = state.cancelSubscription;
    let updateSubscription = state.updateSubscription;
    const { purchaseProduct } = state;
    return {
        updateBilling,
        cancelSubscription,
        updateSubscription,
        purchaseProduct
    };
}

const connectedBilling = connect(mapStateToProps)(StripeBillingPage);
export { connectedBilling as StripeBillingPage };