import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { settingsActions, authenticateActions } from '../../_actions';
import { searchService } from '../../_services';
import { Previews } from './ImageUpload';
import { Loader } from '../Shared/Loader';
import './ImageUpload.jsx';
import '../../Styles/SettingsStyles/Profile.css';
import {Registration_AutoSuggestion} from '../Account/RegisterPage';

class ProfilePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            updatedAlert: false,
            user: JSON.parse(localStorage.getItem('user')),
            categoryError: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        let category = await searchService.getAllCategories();
        let categoryTags = await searchService.getAllCategoryTags();

        this.setState ({
            category: category,
            categoryTags: categoryTags,
        });
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        const updateUser = this.state;
        const { dispatch } = this.props;

        updateUser.file_string = localStorage.getItem('fileBase64');
        updateUser.user = this.state.user;
        await dispatch(settingsActions.updateProfile(updateUser));
        await this.props.dispatch(authenticateActions.setUser(localStorage.getItem('user')));

        this.setState({
            submitted: true,
            updatedAlert: true,
        });

        setTimeout(() => {
            this.setState({
              updatedAlert: false
            });
          }, 4000);
    }

    handleCategoryInput = (categoryInputs) => {
        this.setState(
            categoryInputs
        )
    }

    handleCategorySelection = (categories) => {
        const {user} = this.state;
        this.setState({
            user: {
                ...user,
                ...categories
            }
        })
    }

    render() {
        const user  = this.state.user;
        const { profile } = this.props;
        const { settingsAlert } = this.props;
        const { category, categoryTags, submitted, categoryError } = this.state;
        let categories = {"category": category, "category_Tags": categoryTags};
        var userCategory = user.category != "" ? [user.category] : [];

        return (
            <div>
                <form id="profileForm" className="settingsForm" onSubmit={this.handleSubmit}>
                    <div id="mainSettings">
                        {profile.loading && <Loader />}
                        <h1>Personal Profile</h1>
                        <div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div>
                                        <h2>Name</h2>
                                        <input id="name" name="name" value={user.name} required onChange={this.handleChange}/>
                                        {/* <span asp-validation-for="@Model.Name" className="text-danger"></span> */}
                                    </div>
                                    <div>
                                        <h2>Surname</h2>
                                        <input id="surname" name="surname" value={user.surname} required onChange={this.handleChange}/>
                                        {/* <span asp-validation-for="@Model.Surname" className="text-danger"></span> */}
                                    </div>
                                    <div>
                                        <h2>Email</h2>
                                        <input id="email" name="email" value={user.email} required onChange={this.handleChange}/>
                                        {/* <span asp-validation-for="@Model.Surname" className="text-danger"></span> */}
                                    </div>
                                    <div>
                                        <h2>Organization Name</h2>
                                        <input id="organizationName" name="organization_Name" value={user.organization_Name} required onChange={this.handleChange}/>
                                        {/* <span asp-validation-for="@Model.Surname" className="text-danger"></span> */}
                                    </div>
                                    <div id="organizationType">
                                        <h2>Organization Type</h2>
                                        <Registration_AutoSuggestion 
                                            categories={categories} 
                                            categoryName="category"
                                            currentSelectedCategories={userCategory}
                                            submitted={submitted}
                                            requiredInput={true}
                                            errorInput={categoryError}
                                            handleCategorySelection={this.handleCategorySelection}
                                            handleCategoryInput={this.handleCategoryInput}/>
                                    </div>
                                {/* </div> */}
                                {/* <div className="col-lg-6"> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="saveProfileDiv">
                        <button className="saveForm" id="saveProfile">Save Profile</button>
                    </div>
                    {this.state.updatedAlert &&
                        <div>
                            <br /><br />
                            <div id="settingsUpdatedSuccess" className={`alert-success`}>Profile settings have been updated.</div>
                        </div>
                    }
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, profile } = state;
    const { user } = setUser;
    return {
        user,
        profile
    };
}

const connectedProfilePage = connect(mapStateToProps)(ProfilePage);
export { connectedProfilePage as ProfilePage };