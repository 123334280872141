import React from 'react';
import { history } from '../../_helpers';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";

export class NoFavorites extends React.Component {
    constructor(props) {
        super(props);
    }

    goToSearch() {
        history.push('/search');
    }

    render() {
        
        return (
            <div id="noSavedListings">
                <img src="https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_404_page.jpg" width="250" align="center"/>
                <br /><br /><br />
                <h3 className="noSavedFavoritesText">You have no saved listings at this time.</h3>
                <p className="noNewRecommendationsText">Get started by using the search tool to discover new funding opportunities.</p>
                <br /><br />
                <Button 
                    color="info"
                    onClick={() => {this.goToSearch()}}
                    style={{margin: 10, minWidth: 250}}

                >
                    Search Funding Programs
                    <ArrowForwardIos style={{float: "right", fontSize:32, marginTop: 0, marginLeft:10, marginRight:-10}} />
                </Button>
            </div>
        );
    }
}

