import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ProgressBar, Step } from "react-step-progress-bar";
import { dashboardActions } from '../../_actions';
import '../../Styles/Dashboard.css';
import "react-step-progress-bar/styles.css";
import '../../Styles/Checkout.css';
import '../../Styles/Products.css';
import { stripeService } from '../../_services';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";

class Products extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // this.props.dispatch(dashboardActions.getPrograms(this.props.user));
    }

    abbreviateNumber(value) {
        var newValue = value;
        if (value >= 1000) {
            var suffixes = ["", "k", "m", "b","t"];
            var suffixNum = Math.floor( (""+value).length/3 );
            var shortValue = '';
            for (var precision = 2; precision >= 1; precision--) {
                shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
                var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
                if (dotLessShortValue.length <= 2) { break; }
            }
            if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
            newValue = shortValue+suffixes[suffixNum];
        }
        return newValue;
    }

    goToGW() {
        const grantId = window.location.href.split("?")[1] ? "?" + window.location.href.split("?")[1] : "";
        window.location.href="/checkout/grantwriter"+grantId;
    }

    goToQuickStart() {
        const user = localStorage.getItem('user');

        if(!user.app_Registration) {
            window.location.href="/quickstart/get-started";
        }
        else {
            window.location.href="/quickstart/dashboard";
        }
    }

    async goToDIY() {
        let user = JSON.parse(window.localStorage.getItem('user'));
        let productGroup = 'diy';
        await stripeService.purchaseProductType(user.id, productGroup);
        window.location.href="/quickstart/diy-resources"
    }

    goToSAM () {
        window.location.href="/checkout/registration"
    }

    openChat() {
        window.Intercom('showNewMessage');
    }

    render() {
        const { user, programs, whitelabel } = this.props;
        return (
            <div style={{marginTop: whitelabel && whitelabel.organization_Name == "portal.opengrants.io" ? "5%" : "10%"}}            >
                <link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet' />
                <div className="row" id="productSelectRow">
                    <div>
                        <h1 id="getStartedProducts"><strong>{ user.name }, let's get started on winning a grant!</strong></h1>
                        <hr />
                    </div>
                    <div className="col-md-5">
                        <img id="grantHeroIMG" src="https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/grant_writing_superheroes.png" />
                    </div>
                    <div className="col-md-7">
                        <div id="productGW">
                            <h2 id="productTitleMain">Grant Writing &#38; Application</h2>
                            <br />
                            <p id="productDesc">
                                Kick off your next grant. After submitting a deposit, a grant developer with experience in your field will reach out to schedule a call and prepare a proposal.
                                <br></br>
                                <br></br>
                                If you decide to go ahead with the proposal, the full $99 will go toward the project. If you don't move forward, we'll refund you immediately.
                            </p>
                            <hr id="productHR" />
                            <h2 id="productPrice">$99 deposit</h2>
                            <p id="productDesc" style={{height: "auto", fontSize: 14}}>*Refundable deposit to get started</p>
                            <hr id="productHR" />
                            <p id="productDescIncluded" style={{marginBottom: 25}}>What's included:</p>
                            <ul style={{height: "auto"}}>
                                <li id="productListItem">
                                    <i id="productCheckmark" className="material-icons">
                                        done
                                    </i>
                                    <p id="productCheckP">Custom service that's scoped to fit your exact needs. May include grant writing, application submission, award management, funding strategy, and ongoing guidance.</p>
                                </li>
                                <li id="productListItem">
                                <i id="productCheckmark" className="material-icons">
                                        done
                                    </i>
                                    <p id="productCheckP">Convenient financing options.</p>
                                </li>
                                <li id="productListItem">
                                <i id="productCheckmark" className="material-icons">
                                        done
                                    </i>
                                    <p id="productCheckP">Work with curated industry experts to get immediate guidance and develop the most competitive proposal possible.</p>
                                </li>
                                <li id="productListItem">
                                <i id="productCheckmark" className="material-icons">
                                        done
                                    </i>
                                    <p id="productCheckP">Option to add on SAM / DUNS registration services if needed (required for Federal grants)</p>
                                </li>
                            </ul>
                            {/* <input type="button" id="productButtonGW" value="Let's do this." onClick={this.goToGW} /> */}
                            <Button 
                              id="productButtonGW"
                              color="secondary"
                              onClick={this.goToGW}
                              style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                            >
                                <strong>Let's do this.</strong>
                            </Button>
                        </div>
                    </div>
                    <hr id="quickstartHR"/>
                    <div style={{textAlign: "center"}}>
                        <h2>Not sure where to begin?</h2>
                        <hr align="center" style={{width: "25%"}} />
                        <p style={{fontSize:18}}>
                            Use our quickstart tool to instantly discover funding you might be eligibile for.
                            After answering a few questions, we'll match you with expert grantwriters in your field to help you submit a highly competitive application.
                        </p>
                        <Button 
                            id="productButtonGW"
                            color="secondary"
                            style={{width:"35%"}}
                            onClick={this.goToQuickStart}
                            style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                        >
                            <strong>Use QuickStart</strong>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, programs, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        programs,
        whitelabel,
    };
}

const connectedProducts = connect(mapStateToProps)(Products);
export { connectedProducts as Products };