import React from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { layoutActions } from '../../../_actions';
import { history } from '../../../_helpers';
import { alertActions } from '../../../_actions';

class EmailSignup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            submitted: false
        };

        const { dispatch } = this.props;
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.emailClear());
        });

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { email } = this.state;
        const { dispatch } = this.props;
        if (email) {
            dispatch(layoutActions.subscribe(email));
        }
    }

    render() {
        const { subscribe, emailAlert } = this.props;
        const { email, submitted } = this.state;
        return (
            <Router history={history}>
            <div id="footer-4" className="col-md-3">
                <h3>Email Newsletter</h3>
                <hr align="left" />
                <form name="form" onSubmit={this.handleSubmit}>
                    <h4 id="footerEmail">Keep me up to date with the latest funding opportunities.</h4>
                    <div>
                        <input id="newsletter_email" name="email" type="text" placeholder="Email address" value={email} onChange={this.handleChange} />
                        <input id="newsletter_subscribe_button" type="submit" value="SUBSCRIBE" />
                    </div>
                </form>
                {emailAlert.message &&
                    <div className={`alert ${emailAlert.type}`}>{emailAlert.message}</div>
                }
            </div>
            </Router>
        );
    }
}

function mapStateToProps(state) {
    const { subscribe } = state;
    const { emailAlert } = state;

    return {
        subscribe, emailAlert
    };
}

const connectedEmailSignup = connect(mapStateToProps)(EmailSignup);
export { connectedEmailSignup as EmailSignup }; 