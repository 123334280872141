import React from 'react';
import { connect } from 'react-redux';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import '../../../Styles/Organization.css';

class PendingInvitationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { user, whitelabel,
            profile, transfer, idx,
        }  = this.props;
        const { organization } = this.props.org;

        return (
            <div key={idx} style={{marginBottom: 20, marginLeft: 30, marginRight: 30, overflow: "auto"}}>
                {!profile.headshot && profile.surname && <div className="profile-circle" style={{position:"relative", height: "60px", width: "60px", lineHeight: "55px", fontSize: "30px", marginRight: 30, verticalAlign: "top"}}>
                    {profile.surname[0]}
                    </div>
                }
                {!profile.headshot && !profile.surname && <div className="profile-circle" style={{position:"relative", height: "60px", width: "60px", lineHeight: "55px", fontSize: "30px", marginRight: 30, verticalAlign: "top"}}>
                    {profile.email[0]}
                    </div>
                }
                {profile.headshot && 
                    <img src={profile.headshot} style={{height: 60, width: 60, marginRight: 30, borderRadius: "50%", verticalAlign: "top"}} /> 
                }
                {profile.username && <a href={"/profiles/" + profile.username} target="_blank" className="linkListing" style={{display: "inline-block", color: "black"}}>
                    <h3 style={{margin: 0}}>{profile.name} {profile.surname}</h3>
                    <p>{profile.title}</p>
                </a>}
                {!profile.username && <a style={{display: "inline-block", color: "black"}}>
                    <h3 style={{margin: 0}}>{profile.email}</h3>
                </a>}
                {!transfer && <div style={{float: "right"}}>
                    <Button 
                        onClick={() => {this.props.cancelOrganizationInvitation(organization.organization_Id, user.id, profile.email)}}
                        style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                    >
                        Cancel
                    </Button>
                </div>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { setUser, organization, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        organization,
        whitelabel,
    };
}

const connectedPendingInvitationList = connect(mapStateToProps)(PendingInvitationList);
export { connectedPendingInvitationList as PendingInvitationList };