import { dashboardConstants } from '../_constants';

export function dashboard(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.LOADDASHBOARD_REQUEST:
      return {
        loading: true
      };
    case dashboardConstants.LOADDASHBOARD_SUCCESS:
      return {
        items: action.dashboard
      };
    case dashboardConstants.LOADDASHBOARD_FAILURE:
      return { 
        error: "There was an error processing this request."
      };
      default:
      return state
  }
};