import React from 'react';
import { connect } from 'react-redux';
import { Range, getTrackBackground } from "react-range";
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '../../Shared/Loader';
import { history } from '../../../_helpers';
import ProgressBar from 'react-bootstrap/ProgressBar';
import '../../../Styles/QuickstartSurvey.css';

const STEP = 1;
const MIN = 3;
const MAX = 12;

class FundingTimeline extends React.Component {
    constructor(props) {
        super(props);
        const user = JSON.parse(localStorage.getItem('user'));
        const months = user.funding_Months == 0 ? 3 : user.funding_Months;

        this.state = {
            user: user,
            submitted: false,
            values: [months],
            loading: true,
        }
        // this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        document.body.scrollTop = 0;

        setTimeout(() => {
            this.setState({
            loading: false
            });
        }, 500);
    }

    handleNavigation = async () => {
        var {user} = this.state;

        this.setState({
            user: {
                ...user,
                funding_Months: this.state.values
            }
        });

        user.funding_Months = this.state.values[0];

        await localStorage.setItem('user', JSON.stringify(user));
        history.push('/quickstart/small-business');
    }

    useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '100ch',
            },
        },
    }));   

    render() {
        const { loading } = this.state;
        const { whitelabel } = this.props;
        const progressBarColor = whitelabel ? whitelabel.accent_Color : "#29a";
        const now = 25;
        const progressInstance = <ProgressBar now={now} label={`${now}%`} />;

        return (
            <div>
                <style>{"\
                    .progress-bar{\
                    background:" + progressBarColor + ";\
                    }\
                "}</style>
                {loading && <Loader />}
                <div>{progressInstance}</div>
                <div id="fundingSlider4">
                    <h1 id="fundingSliderHeader">
                        How soon does your organization need grant funding?
                    </h1>
                    <Range
                        values={this.state.values}
                        step={STEP}
                        min={MIN}
                        max={MAX}
                        onChange={values => this.setState({ values })}
                        renderTrack={({ props, children }) => (
                            <div id="fundSliderDiv"
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                height: "36px",
                                display: "flex",
                                width: "100%"
                            }}
                            >
                            <div
                                ref={props.ref}
                                style={{
                                height: "5px",
                                width: "100%",
                                borderRadius: "4px",
                                background: getTrackBackground({
                                    values: this.state.values,
                                    colors: [whitelabel ? whitelabel.accent_Color : "#2aa79f", "#ccc"],
                                    min: MIN,
                                    max: MAX
                                }),
                                alignSelf: "center"
                                }}
                            >
                                {children}
                            </div>
                            </div>
                        )}
                        renderThumb={({ props, isDragged }) => (
                            <div
                            {...props}
                            style={{
                                ...props.style,
                                height: "42px",
                                width: "42px",
                                borderRadius: "4px",
                                backgroundColor: "#FFF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0px 2px 6px #AAA"
                            }}
                            >
                            <div
                                style={{
                                height: "16px",
                                width: "5px",
                                backgroundColor: isDragged ? "#2aa79f" : "#CCC"
                                }}
                            />
                            </div>
                        )}
                    />
                    <output style={{ marginTop: "30px" }} id="output">
                        {this.state.values[0].toFixed(0)} Months
                    </output>
                    
                </div>
                <div className="continueButton">
                    <button 
                        className="signUpContinue"
                        onClick={() => this.handleNavigation('next')}
                        style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                    >
                        Continue  →
                    </button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering, nav } = state.registration;
    const {registrationAlert} = state;
    const { whitelabel } = state.setWhitelabel;

    return {
        registering, 
        nav, 
        registrationAlert,
        whitelabel,
    };
}

const connectedFundingTimeline = connect(mapStateToProps)(FundingTimeline);
export { connectedFundingTimeline as FundingTimeline };