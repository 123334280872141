import React from "react";
import UserProfile from "./SettingsView";
import { connect } from 'react-redux';
import '../../Styles/Dashboard.css';

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGroup: {
                search: "",
                user: this.props.user,
                page: 1,
                totalResults: 0,
            }
        };
    }

    componentDidMount() {
        document.body.scrollTo(0,0);
    }

    render() {
        return (
            <div id="portalProfile" style={{marginTop: "5%"}}>
                <h1 id="dashboardHeader" style={{marginBottom: -15}}>USER PROFILE</h1>
                <UserProfile 
                    user={this.props.user} 
                    whitelabel={this.props.whitelabel}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        whitelabel,
    };
}

const connectedStore = connect(mapStateToProps)(Settings);
export { connectedStore as Settings };
