import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import '../../../Styles/Organization.css';

class EditOrganizationDUNS extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { org } = this.props;

        return (
            <div style={{paddingBottom: 20}}>
                <h2>DUNS Number</h2>
                <p style={{marginTop: 10}}>A DUNS number is a unique nine-character number used to identify your organization. The federal government uses the DUNS number to track how federal money is allocated. <a href="https://fedgov.dnb.com/webform/displayHomePage.do" target="_blank">Register for DUNS</a> or <a href="https:/portal.opengrants.io/checkout/registration" target="_blank">let us do it for you</a>.</p>
                <input name="dunS_Number" value={ org.organization.dunS_Number } onChange={this.props.handleChange} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        organization,
        whitelabel,
    };
}

const connectedEditOrganizationDUNS = connect(mapStateToProps)(EditOrganizationDUNS);
export { connectedEditOrganizationDUNS as EditOrganizationDUNS };