import '../../../Styles/StripeProduct.css';
import React from 'react';
import '../../../Styles/StripeProduct.css';

export class StripeBillingUpdated extends React.Component {
    render() {
        const {invoiceId} = this.props;

        let productClass = "stripe-product";
        if ((!invoiceId && !localStorage.getItem('user')) || !localStorage.getItem('user')) {
            productClass += " overflow grey-background height-full";
        }

        return (
            <div className={productClass}>
            {((!invoiceId && !localStorage.getItem('user')) || !localStorage.getItem('user')) &&
            <div>
                <h1 className="opengrants-logo">opengrants.io</h1>
            </div>}
            <div className="background-white pop payment-form gap-top gap-bottom center">
                <h2 className="gap-bottom">Billing Successfully Updated</h2>
                <div>
                    <p>Thank you for updating your billing.</p>            
                    <p>If you have any questions or concerns, email 
                    <a className="og-color" href="mailto:support@opengrants.io"> support@opengrants.io</a>.</p>
                </div>
            </div>
            </div>
        )
    }
}