import { alertConstants } from '../_constants';

export const alertActions = {
    loginSuccess,
    loginError,
    loginClear,
    updatePasswordSuccess,
    updatePasswordError,
    updatePasswordClear,
    emailSuccess,
    emailError,
    emailClear,
    registrationSuccess,
    registrationError,
    settingsSuccess,
    settingsError,
    settingsClear
};

function loginSuccess(message) {
    return { type: alertConstants.LOGIN_SUCCESS, message };
}

function loginError(message) {
    return { type: alertConstants.LOGIN_ERROR, message };
}

function loginClear() {
    return { type: alertConstants.LOGIN_CLEAR };
}

function updatePasswordSuccess(message) {
    return { type: alertConstants.UPDATEPASSWORD_SUCCESS, message };
}

function updatePasswordError(message) {
    return { type: alertConstants.UPDATEPASSWORD_ERROR, message };
}

function updatePasswordClear() {
    return { type: alertConstants.UPDATEPASSWORD_CLEAR };
}

function emailSuccess(message) {
    return { type: alertConstants.SUBSCRIBE_SUCCESS, message };
}

function emailError(message) {
    return { type: alertConstants.SUBSCRIBE_ERROR, message };
}

function emailClear() {
    return { type: alertConstants.SUBSCRIBE_CLEAR };
}

function registrationSuccess(message) {
    return { type: alertConstants.REGISTRATION_SUCCESS, message }
}

function registrationError(message) {
    return { type: alertConstants.REGISTRATION_ERROR, message };
}

function settingsSuccess(message) {
    return { type: alertConstants.SETTINGS_SUCCESS, message };
}

function settingsError(message) {
    return { type: alertConstants.SETTINGS_ERROR, message };
}

function settingsClear() {
    return { type: alertConstants.SETTINGS_CLEAR };
}