import React from 'react';
import { connect } from 'react-redux';
import { organizationService, locationsService } from '../../../_services';
import { OrganizationSettings } from './OrganizationSettings';
import '../../../Styles/Organization.css';

const options = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
];

class Organization extends React.Component {
    constructor(props) {
        super(props);

        const { org_id } = this.props;

        this.state = {
            submitted: false,
            submitting: false,
            updatedAlert: false,
            user: JSON.parse(localStorage.getItem('user')),

            org_id: org_id,
            org: {
                organization: {
                    industries: [],
                    social_Impact_Categories: [],
                    technologies_Used: [],
                    naicS_Codes: []
                },
            },
            organizations: {},
            owned_organizations: 0,
            addOrganization: false,
            editOrganization: false,
            viewOrganization: 0,
            organizationNameError: false,

            industries: [],
            social_Impact_Categories: [],
            technologies_Used: [],
            naicS_Codes: [],

            organization_namesInput: "",
            newOrganization: false,
            invite_emailInput: "",
            transfer: false,
            loading: true
        }

        this.handleChange = this.handleChange.bind(this);
        this.updateOrganizationName = this.updateOrganizationName.bind(this);
        this.handleSelectionChange = this.handleSelectionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getAllOrganizationsByUser();
        this.getAllOrganizationInvitations();
    }

    getAllOrganizationsByUser = async() => {
        const {user} = this.state;
        var organizations = await organizationService.getAllOrganizationsByUser(user.id);

        this.setState({
            ...organizations,
            loading: false
        }, () => {
            if (this.props.org_id > 0) {
                this.editOrganization(this.state.org_id);
            }
        });
    }

    getAllOrganizationInvitations = async() => {
        const user = JSON.parse(localStorage.getItem('user'));
        var pending_invitations = await organizationService.getUserOrganizationPendingInvitations(user.id);

        this.setState({
            pending_invitations
        });
    }

    getAddresses = async() => {
        var city = await locationsService.getLocations('City');
        var state = await locationsService.getLocations('US_State');
        var country = ["United States"];

        this.setState({
            city,
            state, 
            country
        })
    }

    getIndustries = async() => {
        const {user} = this.state;
        var industries = await organizationService.getIndustries(user.id);

        this.setState({
            industries
        });
    }

    getSocialImpactCategories = async() => {
        const {user} = this.state;
        var social_Impact_Categories = await organizationService.getSocialImpactCategories(user.id);

        this.setState({
            social_Impact_Categories
        });
    }

    getTechnologies = async() => {
        const {user} = this.state;
        var technologies_Used = await organizationService.getTechnologies(user.id);

        this.setState({
            technologies_Used
        });
    }

    getNAICSCodes = async() => {
        const {user} = this.state;
        var naicS_Codes = await organizationService.getNAICSCodes(user.id);

        this.setState({
            naicS_Codes
        });
    }

    getOrganizationMembers = async(organization_id) => {
        const {user} = this.state;
        var members = await organizationService.getOrganizationMembers(user.id, organization_id);
        this.setState({
            ...members
        });
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { org } = this.state;
        const {organization} = org;
        this.setState({
            org: {
                ...org,
                organization: {
                    ...organization,
                    [name]: value
                }
            }
        });
    }

    handleNewOrganization = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    async updateOrganizationName(event) {
        const { value } = event.target;
        this.setState({
            organization_namesInput: value,
        });

        const organizationNameError = await organizationService.checkOrganizationName(value);
        
        if (organizationNameError) {
            this.setState({
                organizationNameError: true
            });
        }
        else {
            this.setState({
                organizationNameError: false
            })
        }
    }

    handleSelectionChange(value, name) {
        const { org } = this.state;
        this.setState({
            org: {
                ...org,
                organization: {
                    ...org.organization,
                    [name]: value.value
                }
            }
        });
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({
            submitting: true,
            // organizationNameError: false,
            organizationCityError: false,
            organizationStateError: false,
            organizationCountryError: false,
            updatedErrorAlert: false
        });

        let organizationStateError = false;
        let organizationCountryError = false;

        const { user, organizations, organization_namesInput, organizationNameError, editOrganization, newOrganization,
            city, state, country
        } = this.state;
        let {org, owned_organizations} = this.state;
        
        //Update org name if different and valid
        if (!organizationNameError) {
            org.organization.name = organization_namesInput;
        }
        
        let state_input = org.organization.state;
        if (state_input) {
            let result = state.filter((s) => s.toLowerCase() === state_input.toLowerCase());
            if (result.length > 0) {
                org.organization.state = result[0];
            } else {
                organizationStateError = true;
            }
        }

        let country_input = org.organization.country;
        if (country_input) {
            let result = country.filter((c) => c.toLowerCase() === country_input.toLowerCase());
            if (result.length > 0) {
                org.organization.country = result[0];
            } else {
                organizationCountryError = true;
            }
        }

        if ((organization_namesInput === org.organization.name || !organizationNameError) && !organizationStateError && !organizationCountryError) {
            //set org logo
            org.organization.logo_URL = localStorage.getItem('organizationLogo');
            //set email invites
            org["emailInvites"] = this.state.emailInvites;
            
            //reset org values for backend
            org.organization.industries = [];
            org.organization.social_Impact_Categories = [];
            org.organization.technologies_Used = [];
            org.organization.naicS_Codes = [];

            //add/edit organization
            org = await organizationService.createOrganization(user.id, org);

            //edit organization
            if (editOrganization) {
                this.setState({
                    organizations: {
                        ...organizations,
                        [org.organization.organization_Id]: {
                            ...org
                        }
                    },
                    submitted: true,
                    submitting: false,
                    updatedAlert: true,
                    updatedErrorAlert: false
                });
            } 
            //new organization
            else if (newOrganization) {
                owned_organizations = owned_organizations + 1;
                this.setState({
                    organizations: {
                        ...organizations,
                        [org.organization.organization_Id]: {
                            ...org
                        }
                    },
                    owned_organizations,
                    submitted: true,
                    submitting: false,
                    updatedAlert: true,
                    addOrganization: false,
                    editOrganization: false,
                    newOrganization: false,
                    organization_namesInput: '',
                    invite_emailInput: '',
                    updatedErrorAlert: false
                });
            }
        } else {
            this.setState({
                submitting: false,
                organizationNameError,
                organizationStateError,
                organizationCountryError,
                updatedErrorAlert: true
            });
        }

        setTimeout(() => {
            this.setState({
            updatedAlert: false,
            updatedErrorAlert: false
            });
        }, 4000);

        document.body.scrollTo(0,0);
    }

    addOrganization = async () => {
        const { user } = this.state;
        // var organization_names = await organizationService.getAllOrganizationNames();
        var all_organizations = await organizationService.getAllOrganizations(user.id);
        var organization_names = Object.values(all_organizations).map((org) => {
            return (org.organization.name)
        });

        localStorage.setItem('organizationLogo', '');

        this.setState({
            addOrganization: true,
            editOrganization: false,
            viewOrganization: 0,
            all_organizations,
            organization_names,
            accepted_profiles: '', 
            pending_profiles: '', 
            pending_invitation_profiles: ''
        });
        document.body.scrollTo(0,0);
    }

    editOrganization = (organization_id) => {
        this.getAddresses();
        this.getIndustries();
        this.getSocialImpactCategories();
        this.getTechnologies();
        this.getNAICSCodes();
        this.getOrganizationMembers(organization_id);
        
        
        const {organizations} = this.state;
        let org = organizations[organization_id];
        org.organization["industries"] = org.industries;
        org.organization["social_Impact_Categories"] = org.social_Impact_Categories;
        org.organization["technologies_Used"] = org.technologies_Used;
        org.organization["naicS_Codes"] = org.naicS_Codes;
        localStorage.setItem('organizationLogo', org.organization.logo_URL);

        this.setState({
            org,
            organization_namesInput: org.organization.name,
            editOrganization: true,
            addOrganization: false,
            viewOrganization: 0
        });
        document.body.scrollTo(0,0);
    }

    viewOrganization = (organization_id) => {
        this.setState({
            viewOrganization: organization_id
        });
        document.body.scrollTo(0,0);
    }

    hideAddEditOrganization = () => {
        this.setState({
            addOrganization: false,
            editOrganization: false,
            viewOrganization: 0,
            newOrganization: false,
            organization_namesInput: "",
            invite_emailInput: ''
        });
        document.body.scrollTo(0,0);
    }

    handleLocationInput = (categoryInputs) => {
        var key = Object.keys(categoryInputs)[0].split("Input")[0];
        var value = Object.values(categoryInputs)[0];

        this.setState({
            org: {
                ...this.state.org,
                organization: {
                    ...this.state.org.organization,
                    [key]: value
                }
            }
        });
    }

    handleLocationSelection = (categories) => {
        const {org} = this.state;
        const {organization} = org;
        this.setState({
            org: {
                ...org,
                organization: {
                    ...organization,
                    ...categories
                }
            }
        })
    }

    newOrg = (org_id) => {
        const {user, all_organizations, organizations, organization_names, organization_namesInput} = this.state;
        var orgs = Object.values(all_organizations).filter(org => org.organization.name == organization_namesInput);
        if (!org_id && orgs.length == 0) {
            this.getAddresses();
            this.getIndustries();
            this.getSocialImpactCategories();
            this.getTechnologies();
            this.getNAICSCodes();
            
            this.setState({
                org: {
                    organization: {
                        name: organization_namesInput,
                        owner: user.id,
                        industries: [],
                        social_Impact_Categories: [],
                        technologies_Used: [],
                        naicS_Codes: []
                    },
                    industries: [],
                    social_Impact_Categories: [],
                    technologies_Used: [],
                    naicS_Codes: []
                },
                emailInvites: [],
                newOrganization: true
            });
        } 
        else {
            const { user } = this.state;

            let organization_Id = org_id;
            if (!org_id) {
                organization_Id = orgs[0].organization.organization_Id;
            }

            let new_org_requests = Object.assign([], this.state.organization_requests);
            new_org_requests.push(all_organizations[org_id].organization);

            let all_orgs = Object.assign({}, this.state.all_organizations);
            all_orgs[organization_Id].pending.push(user.id);
    
            this.setState({
                all_organizations: {
                    ...all_orgs
                },
                organization_requests: [
                    ...new_org_requests
                ]
            });

            organizationService.requestToJoinOrganization(user.id, all_orgs[organization_Id].organization.name);

            document.body.scrollTo(0,0);
        }
    }

    removeSelfFromOrg = (organizations) => {
        this.setState({organizations});
    }

    confirmOrganizationRequest = async(organization, user_id, username, confirmation) => {      
        await organizationService.confirmOrganizationRequest(organization, user_id, username, confirmation);

        const { pending_profiles } = this.state;
        var new_pending_profiles = pending_profiles.filter(profile => profile.username != username);
        //add profile to members if accepted
        if (confirmation == "Accepted") {
            let new_accepted_profiles = Object.assign([], this.state.accepted_profiles);
            var new_accepted_profile = pending_profiles.filter(profile => profile.username == username);
            new_accepted_profiles.push(new_accepted_profile[0]);

            this.setState({
                accepted_profiles:
                    [...new_accepted_profiles],
                pending_profiles: 
                    [...new_pending_profiles]
            });
        }
        //remove profile if rejected
        else if (confirmation == "Pending") {
            this.setState({
                pending_profiles: 
                    [...new_pending_profiles]
            });
        } else if (confirmation == "Rejected") {
            var new_accepted_profiles = this.state.accepted_profiles.filter(profile => profile.username != username);

            this.setState({
                accepted_profiles:
                    [...new_accepted_profiles],
                pending_profiles: 
                    [...new_pending_profiles]
            });
        }
    }

    cancelOrganizationInvitation = async(organization_id, user_id, email) => {
        const{pending_invitation_profiles, newOrganization} = this.state;

        if (!newOrganization)
            await organizationService.cancelOrganizationInvitation(organization_id, user_id, email);

        var new_pending_invitations = pending_invitation_profiles.filter(invitation => (invitation.email !== email));

        this.setState({
            pending_invitation_profiles: [...new_pending_invitations]
        });
    }

    updateOrganizationOwner = async(user_id, username, organization) => {
        await organizationService.updateOrganizationOwner(user_id, username, organization);

        const {user, accepted_profiles} = this.state;
        var new_accepted_profiles = accepted_profiles.filter(profile => profile.owner != true && profile.username != username);
        var old_owner = accepted_profiles.filter(profile => profile.username == user.userName);
        var new_owner = accepted_profiles.filter(profile => profile.username == username);

        old_owner[0].owner = false;
        new_owner[0].owner = true;

        new_accepted_profiles.push(old_owner[0]);
        new_accepted_profiles.push(new_owner[0]);

        this.setState({
            accepted_profiles: [...new_accepted_profiles],
            transfer: true
        });
    }

    inviteToJoinOrganization = async (user_id, organization_id, email) => {        
        
        if (!email) {
            return;
        }
        const {newOrganization} = this.state;
        if (!newOrganization) {
            var invited_user = await organizationService.inviteToJoinOrganization(organization_id, user_id, email);

            var new_pending_invitation_profiles = Object.assign([], this.state.pending_invitation_profiles);
            if (invited_user) {
                new_pending_invitation_profiles.push(invited_user);
            }
            this.setState({
                pending_invitation_profiles: [...new_pending_invitation_profiles],
                invite_emailInput: ''
            });
        } else {
            var new_pending_invitation_profiles = Object.assign([], this.state.pending_invitation_profiles);
            new_pending_invitation_profiles.push({email: email});
            var emailInvites = Object.assign([], this.state.emailInvites);
            emailInvites.push(email);
            this.setState({
                emailInvites: [...emailInvites],
                pending_invitation_profiles: [...new_pending_invitation_profiles],
                invite_emailInput: ''
            });
        }
    }

    updateOrganizations = (organizations) => {
        
        const {user} = this.state;
        var owned_organizations = Object.values(organizations).filter((org) => org.organization.owner === user.id).length;

        this.setState({
            organizations,
            owned_organizations
        });
    }

    updateInvitations = (pending_invitations) => {
        
        this.setState({
            pending_invitations
        });
    }

    updateState = (newState) => {
        this.setState({
            ...newState
        });
    }

    handleCategoryInput = (categoryInputs) => {
        this.setState(
            categoryInputs
        )
    }

    handleCategorySelection = (categories) => {
        const {org} = this.state;
        const {organization} = org;
        this.setState({
            org: {
                ...org,
                organization: {
                    ...organization,
                    ...categories
                },
                ...categories
            }
        })
    }

    render() {
        return (
            <div>
                <OrganizationSettings
                    user={this.state.user}
                    submitting={this.state.submitting}
                    submitted={this.state.submitted}
                    accepted_profiles={this.state.accepted_profiles}
                    whitelabel={this.props.whitelabel}
                    updatedAlert={this.state.updatedAlert}
                    org={this.state.org}
                    organizations={this.state.organizations}
                    organization_requests={this.state.organization_requests}
                    owned_organizations={this.state.owned_organizations}
                    pending_invitations={this.state.pending_invitations}
                    addOrganization={this.state.addOrganization}
                    editOrganization={this.state.editOrganization}
                    viewOrganization={this.state.viewOrganization}
                    organization_names={this.state.organization_names}
                    newOrganization={this.state.newOrganization}
                    organization_namesInput={this.state.organization_namesInput}
                    all_organizations={this.state.all_organizations}
                    organizationNameError={this.state.organizationNameError}
                    organizationCityError={this.state.organizationCityError}
                    organizationStateError={this.state.organizationStateError}
                    organizationCountryError={this.state.organizationCountryError}
                    invite_emailInput={this.state.invite_emailInput}
                    accepted_profiles={this.state.accepted_profiles}
                    pending_profiles={this.state.pending_profiles}
                    pending_invitation_profiles={this.state.pending_invitation_profiles}
                    transfer={this.state.transfer}
                    loading={this.state.loading}
                    city={this.state.city}
                    state={this.state.state}
                    country={this.state.country}
                    newOrganizationFunc={this.newOrg}
                    addOrganizationFunc={this.addOrganization}
                    editOrganizationFunc={this.editOrganization}
                    updateInvitations={this.updateInvitations}
                    updateOrganizationsFunc={this.updateOrganizations}
                    updateOrganizationName={this.updateOrganizationName}
                    updateOrganizationOwner={this.updateOrganizationOwner}
                    confirmOrganizationRequest={this.confirmOrganizationRequest}
                    cancelOrganizationInvitation={this.cancelOrganizationInvitation}
                    inviteToJoinOrganization={this.inviteToJoinOrganization}
                    removeSelfFromOrg={this.removeSelfFromOrg}
                    handleSubmit={this.handleSubmit}
                    handleNewOrganization={this.handleNewOrganization}
                    hideAddEditOrganization={this.hideAddEditOrganization}
                    handleChange={this.handleChange}
                    handleCategoryInput={this.handleCategoryInput}
                    handleCategorySelection={this.handleCategorySelection}
                    handleSelectionChange={this.handleSelectionChange}
                    handleLocationSelection={this.handleLocationSelection}
                    handleLocationInput={this.handleLocationInput}
                    updateState={this.updateState}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        organization,
        whitelabel,
    };
}

const connectedOrganizationProfilePage = connect(mapStateToProps)(Organization);
export { connectedOrganizationProfilePage as Organization };