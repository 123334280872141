import React from 'react';
import { connect } from 'react-redux';
import { searchActions, favoritesActions } from '../../_actions';
import { BasicSearch } from './BasicSearch';
import { PowerSearch } from './PowerSearch';
import '../../Styles/SearchResults.css';

class SearchListings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGroup: {
                search: "",
                user: this.props.user,
                categories: [],
                category_Tags: [],
                locations: [],
                agencies: [],
                page: 1,
                totalResults: 0,
            },
        };

        this.childDiv = React.createRef();
        this.updateSearch = this.updateSearch.bind(this);
    }

    async componentDidMount() {

    }

    async updateSearch(searchParameters) {
        console.log("main update search");
        await this.setState(prevState => ({
            searchGroup: {
                ...searchParameters,
                search: localStorage.getItem('activeSearch'),
            }
                }), () => {
                    this.props.dispatch(searchActions.getSearchResults(this.state.searchGroup))
                });
    }

    render() {
        const { user } = this.props;

        return (
            <div>
                {/* <h1 id="mobileSearchHeader">Search Listings</h1> */}
                <div id="searchListingsDiv" className="row">
                    
                    <div id="searchResultsReturned" className="col-sm-12">
                        {/* {user.userRoles == 'Client_Premium' && */}
                            <PowerSearch />
                        {/* }
                        {user.userRoles != 'Client_Premium' &&
                            <BasicSearch />
                        } */}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { searchResults, setUser, favorites } = state;
    const { user } = setUser;
    return {
        user,
        searchResults,
        favorites
    };
}

const connectedHomePage = connect(mapStateToProps)(SearchListings);
export { connectedHomePage as SearchListings };