import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '../../../Shared/Loader';
import { history } from '../../../../_helpers';
import ProgressBar from 'react-bootstrap/ProgressBar';
import '../../../../Styles/QuickstartSurvey.css';

class BusinessFocusInfo extends React.Component {
    constructor(props) {
        super(props);
        // const {user, nav} = this.props;
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            // nav: localStorage.getItem('nav'),
            submitted: false,
            loading: true,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        document.body.scrollTop = 0;

        setTimeout(() => {
            this.setState({
            loading: false
            });
        }, 500);

        if (!localStorage.getItem('focus_areas'))
        {
            this.setState({
                focus_areas: [],
                user: {
                    ...this.state.user,
                    focus_areas: []
                }
            });

            localStorage.setItem('focus_areas', JSON.stringify([]));

        }
    }

    handleChange(e) {
        const {name, value} = e.target;
        let {user} = this.state;
        user[name] = value;
        this.setState({
            user: user
        })
    }

    handleNavigation = async (type) => {
        var {user} = this.state;
        await localStorage.setItem('user', JSON.stringify(user));
        history.push('/quickstart/nsf-focus');
    }

    useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '100ch',
            },
        },
    }));   

    render() {
        const { loading } = this.state;
        const { whitelabel } = this.props;
        const progressBarColor = whitelabel ? whitelabel.accent_Color : "#29a";
        const now = 70;
        const progressInstance = <ProgressBar now={now} label={`${now}%`} />;

        return (
            <div>
                <style>{"\
                    .progress-bar{\
                    background:" + progressBarColor + ";\
                    }\
                "}</style>
                {loading && <Loader />}
                <div>{progressInstance}</div>
                <div id="fundingSlider1">
                    <h1 
                        id="signUpWelcomeH1"
                        style={{color: whitelabel && whitelabel.organization_Name == "portal.opengrants.io" ? "#29a" : "#666"}}
                    >
                        We're almost there!
                    </h1>
                    <hr id="signUpSliderHR" />
                    <h1 id="askQuestions">
                        Now that we've answered some of the basics, let's explore the focus of your business.
                    </h1>
                </div>
                <div className="continueButton">
                <button 
                        className="signUpContinue"
                        onClick={() => this.handleNavigation('next')}
                        style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                    >
                        Continue  →
                    </button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering, nav } = state.registration;
    const { whitelabel } = state.setWhitelabel;
    const { registrationAlert } = state;
    
    return {
        registering, 
        nav, 
        registrationAlert,
        whitelabel,
    };
}

const connectedBusinessFocusInfo = connect(mapStateToProps)(BusinessFocusInfo);
export { connectedBusinessFocusInfo as BusinessFocusInfo };