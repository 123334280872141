import React from 'react';
import { connect } from 'react-redux';
import { favoritesActions } from '../../_actions';
import '../../Styles/Listing.css'

class SaveListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            favorites: localStorage.getItem('favorite') ? localStorage.getItem('favorite').includes(this.props.listing.listing_Id) : false,
            themeColor: localStorage.getItem('themeColor')
        }
    }

    
    handleSaveListing = (listing_Id, user) => {
        var user = JSON.parse(localStorage.getItem('user'));
        this.props.dispatch(favoritesActions.saveListing(listing_Id, user));
            this.setState ({
                favorites: !this.state.favorites
            });
    }

    render() {
        const { favorites } = this.state;
        const { listing, user }  = this.props;
        return (
            <div>
                {favorites && 
                    <button 
                        id="listingSavedButton" 
                        className="requestInfoButton" 
                        onClick={() => { this.handleSaveListing(listing.listing_Id, user) }}
                        style={{background: this.state.themeColor}}
                    >
                        <i id="listingSavedButtonIcon" className="material-icons">
                            library_add_check
                        </i>
                        SAVED
                    </button>
                }
                {!favorites && 
                    <button id="saveListingButton" className="requestInfoButton" onClick={() => { this.handleSaveListing(listing.listing_Id, user) }}>
                        <i 
                            id="saveListingButtonIcon" 
                            className="material-icons"
                            style={{color: this.state.themeColor}}
                        >
                            library_add
                        </i>
                        SAVE
                    </button>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser } = state;
    const { user } = setUser;
    return {
        user
    };
}

const connectedSaveListing = connect(mapStateToProps)(SaveListing);
export { connectedSaveListing as SaveListing };