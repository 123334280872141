import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../../_helpers';
import '../../../Styles/ConfirmEmail.css';
import '../../../Styles/ForgotPassword.css';

class ResetPasswordConfirmation extends React.Component {
    redirectToLogin() {
        history.push("/login")
    }

    render() {

        return (
            <div id="forgotPasswordConfirmation">
                <div id="forgotPassowrdRightLogin">
                    <h1 id="forgotPasswordLogo">opengrants.io</h1>
                    <hr />
                    <br />
                    <h1 id="passwordResetLinkHeader">Your password reset request has been submitted.</h1>
                    <div id="forgotPasswordRedirect">
                        <h2 id="forgotPasswordRedirectHeader">
                            You will automatically be redirected to the login page. If your account information is valid, you can now use your new password to access your account.
                            <br /><br />
                            If you are not redirected to the login page within 10 seconds, please
                            <span id="redirectForgotPasswordConfirmation" onClick={this.redirectToLogin}> click here</span>
                        </h2>
                    </div>
                </div>
                <div className="forgotPasswordBottomLinks">
                    <div className="forgotPasswordLinks">
                        <a href="https://www.opengrants.io/opengrants-legal/">Privacy Policy</a>
                        <a href="https://www.opengrants.io/opengrants-legal/">Terms and Conditions</a>
                        <a href="https://experience.opengrants.io/contact-opengrants">Help</a>
                        <a href="/login">Login</a>
                    </div>
                    <div className="forgotPasswordCopyFooter">
                        opengrants.io &#x24B8; {(new Date().getFullYear())} Copyright by Egeria Corporation
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    //const { forgotPassword } = state;
    return {
        // forgotPassword
    };
}

const connectedResetPasswordConfirmation = connect(mapStateToProps)(ResetPasswordConfirmation);
export { connectedResetPasswordConfirmation as ResetPasswordConfirmation };