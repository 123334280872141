import { matchesConstants } from '../_constants';
import { matchesService, favoritesService } from '../_services';
import { alertActions, notificationsActions } from '../_actions';

export const matchesActions = {
    getMatches,
    saveMatch,
    discardMatch
};

function getMatches(user) {
    return dispatch => {
        dispatch(request(user));
        matchesService.getMatches(user)
            .then(
                getMatches => dispatch(success(getMatches)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: matchesConstants.GETMATCHES_REQUEST } }
    function success(getMatches) { return { type: matchesConstants.GETMATCHES_SUCCESS, getMatches } }
    function failure(error) { return { type: matchesConstants.GETMATCHES_FAILURE, error } }
}

function saveMatch(listing_id, user) {
    return async dispatch => {
        dispatch(request())
        await matchesService.saveMatch(listing_id, user)
        .then(
            () => {
                dispatch(success());
                dispatch(notificationsActions.getAllNotifications(JSON.parse(localStorage.getItem('user')).id));
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
        
        favoritesService.getFavorites(user);
    };



    function request() { return { type: matchesConstants.SAVEMATCH_REQUEST } }
    function success() { return { type: matchesConstants.SAVEMATCH_SUCCESS } }
    function failure(error) { return { type: matchesConstants.SAVEMATCH_FAILURE, error } }
}

function discardMatch(listing_id, user) {
    return dispatch => {
    dispatch(request());
    matchesService.discardMatch(listing_id, user)
        .then(
            dispatch(success()),
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request() { return { type: matchesConstants.DISCARDMATCH_REQUEST } }
    function success() { return { type: matchesConstants.DISCARDMATCH_SUCCESS } }
    function failure(error) { return { type: matchesConstants.DISCARDMATCH_FAILURE, error } }
}
