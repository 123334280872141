import { authHeader } from '../_helpers';

export const favoritesService = {
    getFavorites,
    saveListing,
    exportFavorites
};

function getFavorites(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/favorites/get_user_favorites', requestOptions)
    .then(handleResponse)
    .then(favorites => {
        localStorage.setItem('favorite', JSON.stringify(favorites));
        
        return favorites;
    });
}

function saveListing(listing_id, user, match) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/favorites/save_listing?listing_id=' + listing_id + "&user_id="+ user.id + "&match=" + match, requestOptions).then(handleResponse);
}

function exportFavorites(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/favorites/export_favorites?user_id='+ user_id, requestOptions)
        .then(async (data) => {
            var content = await data.blob();
            console.log(content)
            return content;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok ) {
            if (response.status == "401") {
                window.location.href="/login";
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}