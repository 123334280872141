import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/SettingsStyles/Settings.css';
import '../../../Styles/SettingsStyles/Focus.css';

class FocusSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            submitted: false,
            updatedAlert: false,
            user: JSON.parse(localStorage.getItem('user'))
        }
    }

    addCategory(e) {
        this.props.addCategory(e);
    }

    removeCategory(e) {
        this.props.removeCategory(e);
    }

    render() {
        const { user, categories, selected } = this.props;
        console.log(categories);
        //const categories = this.props.categories;
        return (
            <div id="preferenceBody_left">
                <span id="preference_error" hidden>Please select at least one category</span>
                <div id="preferences_list">
                    <div id="categorySelectList">
                        {categories && categories.map((category) =>
                            selected && selected.includes(category) ?
                            <div className="categoryOption">
                                <input className="selectedCategory" 
                                    value={category} selected="true" type="button" 
                                    key={"selectedCategory_" + category}
                                    onClick={() => { this.removeCategory(category) }}
                                />
                            </div>
                            :
                            <div className="categoryOption">
                                <input className="unselectedCategory" 
                                    value={category} selected="false" type="button" 
                                    key={"unSelectedCategory_" + category}
                                    onClick={() => { this.addCategory(category) }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, categoryList } = state;
    const { user } = setUser;
    return {
        categoryList,
        user
    };
}

const connectedHomePage = connect(mapStateToProps)(FocusSelect);
export { connectedHomePage as FocusSelect };