import React from 'react';
import { connect } from 'react-redux';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import { Loader } from '../../Shared/Loader';
import { organizationService } from '../../../_services';
import '../../../Styles/Organization.css';

class OrganizationList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmDelete: '',
            loading: false
        }
    }

    editOrganization = (organization_id) => {
        this.props.editOrganization(organization_id);
    }

    confirmDeleteOrganization = (organization_id) => {
        this.setState({
            confirmDelete: organization_id
        });

        var y = document.body.scrollHeight - document.getElementById("mainLayoutDiv").scrollHeight;
        document.body.scrollTo(0,y);
    }

    cancelDeleteOrganization = () => {
        this.setState({
            confirmDelete: "",
        });
    }

    deleteOrganization = () => {
        const organization_id = this.state.confirmDelete;
        const user = JSON.parse(localStorage.getItem('user'));
        organizationService.deleteOrganization(user.id, organization_id);

        var newOrganizations = Object.assign({}, this.props.organizations);
        delete newOrganizations[organization_id];
        this.props.updateOrganizations(newOrganizations);

        this.setState({
            confirmDelete: ''
        });
    }

    confirmOrganizationRequest = async(organization_id, user_id, username, confirmation) => {
        await organizationService.confirmOrganizationRequest(organization_id, user_id, username, confirmation);

        const {organizations} = this.props;
        var new_organizations = Object.values(organizations).filter(org => org.organization.organization_Id != organization_id);
        this.props.removeSelfFromOrg(new_organizations);
    }

    confirmOrganizationInvitation = async(organization_id, user_id, confirmation) => {
        this.setState({
            loading: true
        });

        const{organizations, pending_invitations} = this.props;

        var pending_invitation = await organizationService.confirmOrganizationInvitation(organization_id, user_id, confirmation);
        var new_pending_invitations = pending_invitations.filter(invitation => invitation.organization_Id != organization_id);
        this.props.updateInvitations(new_pending_invitations);
        if (confirmation === "Accepted") {
            var new_organizations = Object.values(organizations).filter(org => org.organization.organization_Id != organization_id);
            new_organizations[organization_id] = pending_invitation.organization;

            this.props.updateOrganizations(new_organizations);
        }

        this.setState({
            loading: false
        });
    }
    
    cancelOrganizationRequest = async(organization_id, user_id) => {
        this.setState({
            loading: true
        });

        const{ organization_requests } = this.props;

        await organizationService.cancelOrganizationRequest(organization_id, user_id);
        var new_organization_requests = { 
            organization_requests: 
                organization_requests.filter(org => org.organization_Id !== organization_id)
        };
        this.props.updateState(new_organization_requests)

        this.setState({
            loading: false
        });
    }

    render() {
        const { organizations, organization_requests, pending_invitations }  = this.props;
        const {confirmDelete} = this.state;
        const user = JSON.parse(localStorage.getItem('user'));

        var organizations_ownedList = null;
        var organizations_memberList = null;
        var organizations_owned = [];
        var organizations_member = [];
        if (Object.keys(organizations).length > 0)
        {
            var organizations_owned = Object.values(organizations).filter(org => org.organization.owner === user.id);
            var organizations_member = Object.values(organizations).filter(org => org.organization.owner !== user.id && org.users.includes(user.id));
            
            organizations_memberList = organizations_member.map((org) => {
                return (
                    <dt key={org.organization.organization_Id} className="organizationList" style={{marginLeft: 10, marginRight: 10, marginTop: 20, overflow: "auto"}}>
                        {org.organization.logo_URL && <img src={org.organization.logo_URL} style={{height: "60px", width: "60px", borderRadius: "50%", margin: "0 10px 0 0", verticalAlign: "initial"}} />}
                        {!org.organization.logo_URL && <i className="material-icons" style={{float: "left", color: "black", fontSize: "60px", width: "60px", borderRadius: "50%", marginLeft: 0}}>business</i>}
                        <a href={"/organizations/" + org.organization.organization_Id} target="_blank" className="linkListing" style={{display: "inline-block", color: "black", verticalAlign: "top"}}>
                            <h3 style={{margin: 0}}><strong>{org.organization.name}</strong></h3>
                            <p>{org.organization.description}</p>
                        </a>
                        <div style={{display: "inline-block", float: "right"}}>
                            {this.props.editOrganization &&
                                <Button onClick={() => {this.editOrganization(org.organization.organization_Id)}}
                                    style={{color: "black", backgroundColor: "white", border: "1px solid black", marginRight: 15}}>
                                    Edit
                                </Button>
                            }
                            {this.props.editOrganization &&
                                <Button onClick={() => {this.confirmOrganizationRequest(org.organization.organization_Id, user.id, user.userName, "Rejected")}}>
                                    Disjoin
                                </Button>
                            }
                        </div>
                    </dt>
                )
            });
            organizations_ownedList = organizations_owned.map((org) => {
                return (
                    <dt key={org.organization.organization_Id} className="organizationList" style={{marginLeft: 10, marginRight: 10, marginTop: 20, overflow: "auto"}}>
                        {org.organization.logo_URL && <img src={org.organization.logo_URL} style={{height: "60px", width: "60px", borderRadius: "50%", margin: "0 10px 0 0", verticalAlign: "initial"}} />}
                        {!org.organization.logo_URL && <i className="material-icons" style={{float: "left", color: "black", fontSize: "60px", width: "60px", borderRadius: "50%", marginLeft: 0}}>business</i>}
                        <a href={"/organizations/" + org.organization.organization_Id} target="_blank" className="linkListing" style={{display: "inline-block", color: "black", verticalAlign: "top"}}>
                            <h3 style={{margin: 0}}><strong>{org.organization.name}</strong></h3>
                            <p>{org.organization.description}</p>
                        </a>
                        <div style={{display: "inline-block", float: "right"}}>
                            {this.props.editOrganization &&
                                <Button onClick={() => {this.editOrganization(org.organization.organization_Id)}}
                                    style={{color: "black", backgroundColor: "white", border: "1px solid black", marginRight: 15}}>
                                    Edit
                                </Button>
                            }
                            {this.props.editOrganization &&
                                <Button onClick={() => {this.confirmDeleteOrganization(org.organization.organization_Id)}} 
                                    style={{color: "white", backgroundColor: "red"}}>
                                    Delete
                                </Button>
                            }
                        </div>
                    </dt>
                )
            });
        }
        
        var organizationRequestsList = null;
        if (organization_requests)
            {organizationRequestsList = organization_requests.map((org) => {
                return (
                    <dt key={org.organization_Id} style={{marginLeft: 10, marginRight: 10, marginTop: 20, overflow: "auto"}}>
                    {org.logo_URL && <img src={org.logo_URL} style={{height: "60px", width: "60px", borderRadius: "50%", margin: "0 10px 0 0", verticalAlign: "bottom"}} />}
                    {!org.logo_URL && <i className="material-icons" style={{float: "left", color: "black", fontSize: "60px", width: "60px", borderRadius: "50%", marginLeft: 0}}>business</i>}
                        <a className="linkListing" href={"/organizations/"+org.organization_Id} target="_blank" style={{display: "inline-block", color: "black"}}>
                            <h3 style={{margin: 0}}><strong>{org.name}</strong></h3>
                            <p>{org.description}</p>
                        </a>
                        <div style={{display: "inline-block", float: "right"}}>
                            <Button onClick={() => {this.cancelOrganizationRequest(org.organization_Id, user.id)}}>Cancel</Button>
                        </div>
                    </dt>
                )
            });
        }
        
        var organizationInvitationList = null;
        if (pending_invitations)
            {organizationInvitationList = pending_invitations.map((org) => {
                return (
                    <dt key={org.organization.organization_Id} style={{marginLeft: 10, marginRight: 10, marginTop: 20, overflow: "auto"}}>
                    {org.organization.logo_URL && <img src={org.organization.logo_URL} style={{height: "60px", width: "60px", borderRadius: "50%", margin: "0 10px 0 0", verticalAlign: "bottom"}} />}
                    {!org.organization.logo_URL && <i className="material-icons" style={{float: "left", color: "black", fontSize: "60px", width: "60px", borderRadius: "50%", marginLeft: 0}}>business</i>}
                        <a className="linkListing" href={"/organizations/"+org.organization.organization_Id} target="_blank" style={{display: "inline-block", color: "black"}}>
                            <h3 style={{margin: 0}}><strong>{org.organization.name}</strong></h3>
                            <p>{org.organization.description}</p>
                        </a>
                        <div style={{display: "inline-block", float: "right"}}>
                            <Button color="info" style={{marginRight: 20}} onClick={() => {this.confirmOrganizationInvitation(org.organization.organization_Id, user.id, "Accepted")}}>Accept</Button>
                            <Button onClick={() => {this.confirmOrganizationInvitation(org.organization.organization_Id, user.id, "Rejected")}}>Reject</Button>
                        </div>
                    </dt>
                )
            });
        }

        console.log(this.props);
        console.log(this.state);

        return (
            <div>
                {this.state.loading && <Loader />}
                {confirmDelete && 
                    <div style={{position: "absolute", height: "100%", width: "100%", top: 0, left: 0, background: "#dde5e8c9", zIndex: 1000}}>
                        <div style={{margin: "15% 20%",  background: "white", padding: "15px"}}>
                            <p>Are you sure?</p>
                            <div style={{textAlign: "center"}}>
                                <Button color="info" onClick={this.deleteOrganization} style={{marginRight: 15}}>Yes</Button>
                                <Button onClick={this.cancelDeleteOrganization}>No</Button>
                            </div>
                        </div>
                    </div>
                }
                <div>
                    <div style={{marginTop: 40}}>
                        <p>You own {organizations_owned.length} organization(s)</p>
                        {organizations_ownedList}
                    </div>
                    <div style={{marginTop: 40}}>
                        <p>You are a member of {organizations_member.length} organization(s)</p>
                        {organizations_memberList}
                    </div>
                </div>
                {pending_invitations && <div style={{marginTop: 40, marginBottom: 40}}>
                    <hr/>
                    <p><strong>{!pending_invitations || pending_invitations.length == 0 ? 0 : pending_invitations.length}</strong> Pending Invitation(s)</p>
                    {organizationInvitationList}
                </div>}
                {organization_requests && <div style={{marginTop: 40, marginBottom: 40}}>
                    <hr/>
                    <p><strong>{!organization_requests || organization_requests.length == 0 ? 0 : organization_requests.length}</strong> Pending Request(s)</p>
                    {organizationRequestsList}
                </div>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;
    return {
        user,
        organization
    };
}

const connectedOrganizationList = connect(mapStateToProps)(OrganizationList);
export { connectedOrganizationList as OrganizationList };