import React from 'react';
export class StripeProductInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {page, products} = this.props;

        let productInfo_0 = 
            <div>
                <h2>OpenGrants Academy</h2>
                <p>Get access to a community of engaged professionals and subject matter experts. Earn industry recognized certifications and enjoy monthly connections with a dedicated team of mentors. If you decide its not for you, refunds are available during the first 15 days.</p>
                <ul>
                    <li>Science Based Writing & Communication</li>
                    <li>Medical Writing</li>
                    <li>Grant Writing Masterclass</li>
                    <li>Technical Writing Masterclass</li>
                    <li>Branding, Marketing & Business Development</li>
                    <li>Legal Structure, Managing Liability and Insurance Requirements </li>
                </ul>
            </div>

        let productInfo_1 = 
            <div>
                <h2>OpenGrants Portal</h2>
                <p>PORTAL is the OpenGrants white-label grant funds monitoring product, ideal for growing, engaging and adding value for your clients.</p>
                <p>Pull valuable metrics from dashboards to understand how your community is engaging. PORTAL includes:</p>
                <ul>
                    <li>Branded Grant Intelligence That’s Scaleable</li>
                    <li>Ideal Top-of-Funnel Value for Your Broader Community</li>
                    <li>Simple, Easy UX That Eases the Grant Search Process</li>
                    <li>Added Value to Drive Revenue</li>
                    <li>Access to Whitepapers, Webinars and Additional Resources to Demystify the Grants Process</li>
                </ul>
            </div>
        
        let productInfo_2 = 
            <div>
                <h2>OpenGrants DNA</h2>
                <p>Grant DNA gives you access to a growing library of successfully funded grant applications in their entirety. Dissect budgets, narratives, letters of support and everything in between.</p>
                <ul>
                    <li>Unlimited access to a database of successful grant applications.</li>
                    <li>Examine how winning grants are crafted. </li>
                    <li>Search by agency, grant title, funding amount. </li>
                    <li>Grants are deconstructed into narrative, budgets and supplemental materials.</li>
                </ul>
            </div>

        return (
            <div>
                {page == products[0] && productInfo_0}
                {page == products[1] && productInfo_1}
                {page == products[2] && productInfo_2}
            </div>
        );
    }
}