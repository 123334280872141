import { connect } from 'react-redux';
import React from 'react';
import '../../Styles/GrantWriters.css';
import {projectproposalActions} from '../../_actions';
import {projectProposalService} from '../../_services';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import { Loader } from '../Shared/Loader';

class ProjectProposalList extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // this.getProposals();
    }

    getProposals = async () => {
        const user_unparsed = localStorage.getItem('user');
        if (user_unparsed) {
            const user = JSON.parse(user_unparsed);
            var result = await projectProposalService.getProjectProposals(user.id);
            console.log(result);

            if (result) {
                this.setState({
                    ...result,
                    loading: false
                });
            } 
        }
    }

    render() {
        console.log("records");
        console.log(this.props);
        const {records} = this.props;
        var recordList = null;
        if (records) {
            recordList = records.map((record) => {
                return (
                    <div key={record.recordId}  class="col-md-12" style={{marginBottom: "20px"}}>
                        <a href={"/projectproposal/"+record.recordId} class="col-md-6">
                            {record.seen != "true" && <span style={{color: "red"}}>New!&nbsp;&nbsp;</span>}
                            Proposal From:&nbsp;{record.grantwriterName}
                        </a>
                        <p class="col-md-6">{record.dateSubmitted}</p>
                    </div>
                )
            });
        }

        return (
            <div id="projectProposal" className="font-ubuntu">
                {recordList}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const connectedProjectProposalList = connect(mapStateToProps)(ProjectProposalList);
export { connectedProjectProposalList as ProjectProposalList }; 