import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '../../../Shared/Loader';
import { history } from '../../../../_helpers';
import ProgressBar from 'react-bootstrap/ProgressBar';
import '../../../../Styles/QuickstartSurvey.css';

class ExtraCategoriesInfoSecond extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            submitted: false,
            loading: true,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        document.body.scrollTop = 0;

        setTimeout(() => {
            this.setState({
            loading: false
            });
        }, 500);
    }

    handleChange(e) {
        const {name, value} = e.target;
        let {user} = this.state;
        user[name] = value;
        this.setState({
            user: user
        })
    }

    handleNavigation = async (type) => {  
        history.push('/quickstart/cec-focus');
    }

    useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '100ch',
            },
        },
    }));   

    render() {
        const { loading } = this.state;
        const { whitelabel } = this.props;
        const progressBarColor = whitelabel ? whitelabel.accent_Color : "#29a";
        const now = 90;
        const progressInstance = <ProgressBar now={now} label={`${now}%`} />;

        return (
            <div>
                <style>{"\
                    .progress-bar{\
                    background:" + progressBarColor + ";\
                    }\
                "}</style>
                {loading && <Loader />}
                <div>{progressInstance}</div>
                <div id="fundingSlider1">
                    <h1 
                        id="signUpWelcomeH1"
                        style={{color: whitelabel && whitelabel.organization_Name == "portal.opengrants.io" ? "#29a" : "#666"}}
                    >
                        Let's look nearby!
                    </h1>
                    <hr id="signUpSliderHR" />
                    <h1 id="askQuestions">
                        Based on the zip code you gave us, you may qualify you for additional programs. Let's look at those now.
                    </h1>
                </div>
                <div className="continueButton">
                <button 
                        className="signUpContinue"
                        onClick={() => this.handleNavigation('next')}
                        style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                    >
                        Continue  →
                    </button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering, nav } = state.registration;
    const { whitelabel } = state.setWhitelabel;
    const { registrationAlert } = state;
    
    return {
        registering, 
        nav, 
        registrationAlert,
        whitelabel,
    };
}

const connectedExtraCategoriesInfoSecond = connect(mapStateToProps)(ExtraCategoriesInfoSecond);
export { connectedExtraCategoriesInfoSecond as ExtraCategoriesInfoSecond };