import { authHeader } from '../_helpers';

export const organizationService = {
    getAllOrganizations,
    getAllOrganizationsByUser,
    getOrganization,
    createOrganization,
    getIndustries,
    getSocialImpactCategories,
    getTechnologies,
    getNAICSCodes,
    getOrganizationMembers,
    getAllOrganizationNames,
    requestToJoinOrganization,
    confirmOrganizationRequest,
    acceptOrganizationRequest,
    rejectOrganizationRequest,
    getAllOrganizationRequests,
    cancelOrganizationRequest,
    deleteOrganization,
    updateOrganizationOwner,
    getUserOrganizationPendingInvitations,
    confirmOrganizationInvitation,
    cancelOrganizationInvitation,
    inviteToJoinOrganization,
    checkOrganizationName
};

function createOrganization(user_id, organization_name) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(organization_name)
    };

    return fetch('https://refactor.opengrants.io/api/organization/create_organization?user_id='+user_id, requestOptions).then(handleResponse);
}

function getAllOrganizations(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/organization/get_all_organizations?user_id='+user_id, requestOptions).then(handleResponse);
}

function getAllOrganizationsByUser(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/organization/get_all_organizations_by_user?user_id='+user_id, requestOptions).then(handleResponse);
}

function getOrganization(organization_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/organization/get_organization?organization_id=' + organization_id, requestOptions).then(handleResponse);
}

function getIndustries() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/organization/get_industries', requestOptions).then(handleResponse);
}

function getSocialImpactCategories() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/organization/get_social_impact_categories', requestOptions).then(handleResponse);
}

function getTechnologies(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/organization/get_technologies?user_id='+user_id, requestOptions).then(handleResponse);
}

function getNAICSCodes(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/organization/getNAICSCodes?user_id='+user_id, requestOptions).then(handleResponse);
}

function getOrganizationMembers(user_id, organization_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/organization/getOrganizationMembers?user_id='+user_id+'&organization_id='+organization_id, requestOptions).then(handleResponse);
}

function getAllOrganizationNames() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/organization/getAllOrganizationNames', requestOptions).then(handleResponse);
}

function requestToJoinOrganization(user_id, organization_name) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(organization_name)
    };

    return fetch('https://refactor.opengrants.io/api/organization/requestToJoinOrganization?user_id='+user_id, requestOptions).then(handleResponse);
}

function confirmOrganizationRequest(organization_id, user_id, username, confirmation) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/organization/confirmOrganizationRequest?organization_id='+organization_id+'&user_id='+user_id+'&username='+username+'&confirmation='+confirmation, requestOptions).then(handleResponse);
}

function acceptOrganizationRequest(organization_id, user_id, username) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/organization/acceptOrganizationRequest?organization_id='+organization_id+'&user_id='+user_id+'&username='+username, requestOptions).then(handleResponse);
}

function rejectOrganizationRequest(organization_id, user_id, username) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/organization/rejectOrganizationRequest?organization_id='+organization_id+'&user_id='+user_id+'&username='+username, requestOptions).then(handleResponse);
}

function getAllOrganizationRequests(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/organization/getAllOrganizationRequests?user_id='+user_id, requestOptions).then(handleResponse);
}

function deleteOrganization(user_id, organization_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/organization/deleteOrganization?user_id='+user_id+'&organization_id='+organization_id, requestOptions).then(handleResponse);
}

function updateOrganizationOwner(user_id, username, organization_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/organization/updateOrganizationOwner?user_id='+user_id+ '&username=' + username +'&organization_id='+organization_id, requestOptions).then(handleResponse);
}

function getUserOrganizationPendingInvitations(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/organization/getUserOrganizationPendingInvitations?user_id='+user_id, requestOptions).then(handleResponse);
}

function confirmOrganizationInvitation(organization_id, user_id, confirmation) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/organization/confirmOrganizationInvitation?organization_id='+organization_id+'&user_id='+user_id+'&confirmation='+confirmation, requestOptions).then(handleResponse);
}

function cancelOrganizationRequest(organization_id, user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/organization/cancelOrganizationRequest?organization_id='+organization_id+'&user_id='+user_id, requestOptions).then(handleResponse);
}

function cancelOrganizationInvitation(organization_id, user_id, email) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/organization/cancelOrganizationInvitation?organization_id='+organization_id+'&user_id='+user_id+'&email='+email, requestOptions).then(handleResponse);
}

function inviteToJoinOrganization(organization_id, user_id, email) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/organization/inviteToJoinOrganization?organization_id='+organization_id+'&user_id='+user_id+'&email='+email, requestOptions).then(handleResponse);
}

function checkOrganizationName(organization_name) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/organization/checkOrganizationName?organization_name='+organization_name, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok ) {
            if (response.status == "401") {
                window.location.href="/login";
            }
            
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}