import React from 'react';
import { connect } from 'react-redux';
import { CreateInputs } from './CreateInputs';
import { searchActions } from '../../../_actions';

class Tagging extends React.Component {
    constructor(props) {
        super(props);
        const {selectedCategories} = this.props;

        this.state = {
            categories: selectedCategories,
        }
    }

    //add option tag
    addOptionTag = (option_id, idx) => {
        const {categoryList} = this.props;
        var options = categoryList[option_id];
        var optionValue = options[idx];

        //update category
        const categories = Object.assign({}, this.state.categories);
        categories[option_id].push(optionValue);
        this.setState({
            categories: categories
        });
        
        //update parent categories
        const {handleCategorySelection} = this.props;
        handleCategorySelection(categories);
        
        // this.showClearAll();
    }

    //remove option tag
    removeOptionTag = (option_id, optionValue) => {
        const categories = Object.assign({}, this.state.categories);
        var optionValueIdx = categories[option_id].indexOf(optionValue);

        //remove tag from list
        categories[option_id].splice(optionValueIdx, 1);

        //remove tag from parent list
        const {handleCategorySelection} = this.props;
        handleCategorySelection(categories);
    }

    //clear tags
    clearTags = () => {
        const categories = Object.assign({}, this.state.categories);
        const categoryKeys = Object.keys(categories);

        //update cateogries
        categoryKeys.forEach((key) => {
            categories[key] = []
        });
        this.setState({
            categories : categories
        });

        //update parent categories
        const {handleCategorySelection} = this.props;
        handleCategorySelection(categories);

        // this.hideClearAll();
    }

    //show clear all
    showClearAll = () => {
        var optionClearAll = document.getElementById("clearAll");
        optionClearAll.style.display = "inline-block";
    }

    //hide clear all
    hideClearAll = () => {
        var optionClearAll = document.getElementById("clearAll");
        optionClearAll.style.display = "none";
    }

    updateSearchCategories(e) {
        this.props.updateSearchCategories(e);
    }

    //add clicked option
    updateSelectedCategories = (option_id, idx) => {
        const {categories} = this.state;
        const {categoryList} = this.props;

        var options = categoryList[option_id];
        var optionValue = options[idx];
        
        var selectedCategories = categories[option_id];
        if (!selectedCategories.includes(optionValue)) {
            this.addOptionTag(option_id, idx);
            const {handleCategorySelection} = this.props;
            handleCategorySelection(categories);
        }
    }

    render() {
        // const {categories, currentActiveSelection} = this.state;
        const {categories} = this.state;
        const {submitted, categoryList, requiredInput} = this.props;
        var option_ids = Object.keys(categories);
        var showClearAll = false;
        for (var i = 0; i < option_ids.length; i++) {
            if (categories[option_ids[i]].length > 0) {
                showClearAll = true;
                break;
            }
        }


        //create tag for each inputs
        const tags = option_ids.map((option_id) => {
            var option_tags = categories[option_id].map((optionValue) => {
                return (
                    <div className="categoryTag" key={"tags_" + option_id + "_" + optionValue}>
                        {optionValue}
                        <div onClick={() => this.removeOptionTag(option_id, optionValue)}>X</div>
                    </div>
                )
            });

            return (
                <div key={"tags_" + option_id}>
                    {option_tags}
                </div>
            )
        });

        return (
            <div>
                { categories && categoryList &&
                <CreateInputs 
                    submitted={submitted} 
                    selectedCategories={categories} 
                    categoryList={categoryList} 
                    requiredInput={requiredInput}
                    reset={true}
                    updateSelectedCategories={this.updateSelectedCategories} 
                />
                }
                {showClearAll && 
                <div>
                    <a id="clearAll" className="showClearAll" onClick={this.clearTags}>Clear</a>
                    <div id="tags">{tags}</div>
                </div>
                }
                {!showClearAll &&
                <div>
                    <a id="clearAll" className="hideClearAll" onClick={this.clearTags}>Clear</a>
                    <div id="tags"></div>
                </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser } = state;
    const { user } = setUser;
    return {
        user
    };
}

const connectedHomePage = connect(mapStateToProps)(Tagging);
export { connectedHomePage as Tagging };