import { authHeader } from '../_helpers';

export const registrationService = {
    register,
    registerWhitelabel,
    checkEmail,
    getAllLocations,
    confirmEmail,
    sendConfirmationEmail,
    getUserPartner,
};

function register(user, server) { 
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/register/register_portal_user?server=' + server, requestOptions).then((handleError) => handleResponse(handleError));
}

function registerWhitelabel(user, server) { 
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/register/register_whitelabel_user?server=' + server, requestOptions).then((handleError) => handleResponse(handleError));
}

function checkEmail(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/register/check_email/', requestOptions).then((handleError) => handleResponse(handleError));
}

function getAllLocations() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch('https://refactor.opengrants.io/api/register/get_locations', requestOptions)
    .then(handleResponse)
}

function confirmEmail(emailConfirmation, server) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emailConfirmation)
    };

    return fetch('https://refactor.opengrants.io/api/register/confirm_email?server=' + server, requestOptions).then((handleError) => handleResponse(handleError));
}

function sendConfirmationEmail(email, server) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(email)
    };

    return fetch('https://refactor.opengrants.io/api/authorize/send_confirmation_email?server=' + server, requestOptions).then((handleError) => handleResponse(handleError));
}

function getUserPartner(userId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch('https://refactor.opengrants.io/api/authorize/get_user_Partner?userId=' + userId, requestOptions)
    .then(handleResponse)
    .then(user => {
        localStorage.setItem('partner', user.partner);
        
        return user.partner;
    });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok ) {
            if (response.status == "401") {
                window.location.href="/login";
            }
            
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}