import React from 'react';
import { connect } from 'react-redux';
import '../../Styles/SavedListings.css';

class FavoritesBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGroup: {
                search: localStorage.getItem('activeSearch'),
                user: this.props.user,
                agencies: JSON.parse(localStorage.getItem('activeAgencies')),
                categories: JSON.parse(localStorage.getItem('activeCategories')),
                category_Tags: [],
                page: 1,
                totalResults: 0,
            },
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleChange(e) {
        const { value } = e.target;
        await this.setState(prevState => ({
            searchGroup: {
                ...prevState.searchGroup,
                search: value,
            }
        }), async () => {});
    }

    async handleSubmit(e) {
        e.preventDefault();
        await localStorage.setItem('activeSearch', this.state.searchGroup.search);
        this.setState({ submitted: true });
        this.props.updateSearch(this.state.searchGroup);
    }

    clearSearch = async (e) => {
        await this.setState(prevState => ({
            searchGroup: {
                ...prevState.searchGroup,
                search: "",
            }
        }), async () => {
            if (localStorage.getItem('activeSearch'))
            {
                await localStorage.setItem('activeSearch', '');
                this.props.updateSearch(this.state.searchGroup);
            }
        });
    }

    render() {
        return (
            <div id="savedListingsSearchBar">
                <form id="listingsSearchForm" className="form-control" onSubmit={this.handleSubmit}>
                    <input
                        type="text"
                        name="search"
                        value={this.state.searchGroup.search}
                        id="listingsSearchBar"
                        className="form-control"
                        placeholder="Search your saved opportunities..."
                        onChange={this.handleChange}
                    />
                    <i hidden className="material-icons" id="listingsSearchBarIcon" onClick={this.handleSubmit}>search</i>
                </form>
                {this.state.searchGroup.search &&
                    <button id="clearSavedBar" onClick={() => this.clearSearch()}>
                        <i hidden id="clearSavedBarIcon" className="material-icons" >backspace</i>
                    </button>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser } = state;
    const { user } = setUser;
    return {
        user
    };
}

const connectedFavoritesBar = connect(mapStateToProps)(FavoritesBar);
export { connectedFavoritesBar as FavoritesBar };