import { quickstartConstants } from '../_constants';
import { quickstartService } from '../_services';

export const quickstartActions = {
    getPrograms,
};

function getPrograms(user) {
    return dispatch => {
        dispatch(request(user));
        quickstartService.getPrograms(user)
            .then(
                programs => dispatch(success(programs)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: quickstartConstants.GETPROGRAMS_REQUEST } }
    function success(programs) { return { type: quickstartConstants.GETPROGRAMS_SUCCESS, programs } }
    function failure(error) { return { type: quickstartConstants.GETPROGRAMS_FAILURE, error } }
}