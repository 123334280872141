import React from 'react';
import { connect } from 'react-redux';
import { matchesActions } from '../../_actions';
import { favoritesService } from '../../_services';
import { Match } from './Match';
import { NoMatches } from './NoMatches';
import { Loader } from '../Shared/Loader';
import { TruncateFunding } from '../Shared/Listing';
import { history } from '../../_helpers';
import '../../Styles/Matches.css';

class Matches extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGroup: {
                search: "",
                user: this.props.user,
                page: 1,
                totalResults: 0,
            }
        };
    }

    async componentDidMount() {
        await this.props.dispatch(matchesActions.getMatches(this.props.user));
        await favoritesService.getFavorites(this.props.user);

        const user_role = this.props.user.userRoles;
        if (user_role != 'Client_Premium') {
          history.push('/settings?val=billing');
        }

        window.addEventListener('storage', e => 
            e.key.includes("favoritesCount") ?
            this.props.dispatch(matchesActions.getMatches(this.props.user)) : console.log(e));
    }

    render() {
        const { user, getMatches } = this.props;
        const totalAwards = getMatches.items && getMatches.items.reduce((totalAwards, listing) => 
          totalAwards + listing.funding_Amount, 0)

        return (
            <div style={{marginTop: "5%"}}>
                <div id="matchResults">
                  <h1 id="searchHeader">Matched Funding Opportunities</h1>
                  <div class="row">
                    <p id="numRecords">Displayed No. Matches: 
                      <span style={{color: "#555", fontWeight: 600}}> 
                        {getMatches.items && getMatches.items.length}
                        {!getMatches.items && ' 0'}
                      </span>
                    </p>
                    <p id="numRecords">Awards Available: 
                      <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="verifiedAward">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                      <span style={{color: "#555", fontWeight: 600, display: "inline"}}> {TruncateFunding(totalAwards)}</span>
                    </p>
                  </div>
                    {getMatches.loading && <Loader />}
                    {getMatches.error && <span className="text-danger">ERROR: {getMatches.error}</span>}
                    {getMatches.items && 
                        <div class="ais-Hits">
                          <ul class="ais-Hits-list">
                            {getMatches.items.map((listing) =>
                                <Match 
                                  listing={listing} 
                                  key={"Match_" + listing.listing_Id}
                                  fadeOut={this.fadeOut} 
                                />
                            )}
                            </ul>
                        </div>
                    }
                    {(!getMatches.items || getMatches.items.length === 0) && !getMatches.loading &&
                        <NoMatches />
                    }
                </div>
                
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { getMatches, setUser } = state;
    const { user } = setUser;
    return {
        user,
        getMatches
    };
}

const connectedMatchesPage = connect(mapStateToProps)(Matches);
export { connectedMatchesPage as Matches };