import { authHeader } from '../_helpers';

export const projectProposalService = {
    getGrantWritingPurchase,
    getProjectProposal,
    getProjectProposals,
    acceptProjectProposal,
    rejectProjectProposal
};

function getGrantWritingPurchase(user_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/grantwritingproject/getGrantWritingPurchase?user_Id=' + user_Id, requestOptions)
        .then(handleResponse);
}

function getProjectProposal(user_Id, recordId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/grantwritingproject/getprojectproposal?user_Id=' + user_Id + '&recordId='+ recordId, requestOptions)
        .then(handleResponse);
}

function getProjectProposals(user_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/grantwritingproject/getprojectproposals?user_Id=' + user_Id, requestOptions)
        .then(handleResponse);
}

function acceptProjectProposal(user_Id, recordId, plan) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/grantwritingproject/acceptprojectproposal?user_Id=' + user_Id + '&recordId='+ recordId + '&plan='+plan, requestOptions)
        .then(handleResponse);
}

function rejectProjectProposal(user_Id, recordId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/grantwritingproject/rejectprojectproposal?user_Id=' + user_Id + '&recordId='+ recordId, requestOptions)
        .then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok ) {
            if (response.status == "401") {
                window.location.href="/login";
            }
            
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}