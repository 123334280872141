import React from 'react';
import { connect } from 'react-redux';
import { algoliaService } from '../../_services';
import DateRangePicker from "./DateRangePicker";
import CustomCurrentRefinements from "./CurrentRefinements";
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import { unixDate } from '../SearchListings/Result';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import { RefinementList,
          Panel,
          RangeInput,
        } from 'react-instantsearch-dom';

/////////////////////////////////////////
/// SEARCH BAR CLASS
/// Handle all Algolia Functionality
/////////////////////////////////////////
class PowerSearchFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total_Grants: null,
      favorites: localStorage.getItem('favorite'),
      searchGroup: {
        search: "",
        user: this.props.user,
        categories: [],
        agencies: [],
        page: 1,
        totalResults: 0,
      },
      loading: true,
      currentSearchState: {},
      savedQueryTitle: '',
      showQueryDiv: false,
    };

    this.handleChange = this.handleChange.bind(this);
    // this.Result = this.Result.bind(this);
    this.closeQueryWindow = this.closeQueryWindow.bind(this);
  }

  async componentDidMount() {
    this.setState({
      total_Grants: this.props.total_Grants,
      saved_Searches: this.props.saved_Searches,
      loading: false,
    });
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  async goToUpgrade() {
    window.location.href="/settings?val=billing";
  }

  async closeQueryWindow() {
    this.setState({
      showQueryDiv: false
    });
  }

  async updateCurrentSearch(searchState) {
    this.setState({
      currentSearchState: searchState
    });
  };

  async loadSavedSearch(savedSearch) {
    sessionStorage.removeItem('from');
    sessionStorage.removeItem('to');

    this.setState({
      currentSearchState: JSON.parse(savedSearch.value)
    });

    const json_Dates = JSON.parse(savedSearch.value).dateRange;
    if (json_Dates) {
      var json_from_date = json_Dates.from;
      var json_to_date = json_Dates.to;
  
      sessionStorage.setItem('from', json_from_date);
      sessionStorage.setItem('from_time', json_from_date / 1000);
      sessionStorage.setItem('to', json_to_date);
      sessionStorage.setItem('to_time', json_to_date / 1000);
    }
  };

  async loadQuerySaver(query, user_Id) {
    alert("LOAD QUERY");
    this.setState({
      showQueryDiv: true,
      newQuery: {
        query: query,
        user_Id: user_Id
      }
    });
  }

  async saveQuery() {
    this.setState({
      showQueryDiv: false
    });

    const title = this.state.savedQueryTitle;
    const escapeQuery = JSON.stringify(this.state.newQuery.query);
    const user_Id = this.state.newQuery.user_Id;

    await algoliaService.saveQuery(escapeQuery, title, user_Id);
    window.location.reload();
  };

  exportSearchResults = async (query) => {
    var blob = await algoliaService.exportSearchResults(query);
    const file = window.URL.createObjectURL(blob);

    var aTag = document.createElement("a");
    aTag.download = "SearchResults.csv"
    aTag.href = file;
    aTag.target = "_blank";

    aTag.click();
  }

  render() {
    const { total_Grants, currentSearchState, savedQueryTitle, showQueryDiv } = this.state;
    const saved_Search = JSON.parse(localStorage.getItem('savedQueries'));
    const { user } = this.props;
    const userRole = user.userRoles;
    const saved_Search_Select = [];
    
    saved_Search && saved_Search.forEach(function(search) {
      saved_Search_Select.push({ label: search.query_Title, value: search.query_Body })
    });

    return (
      <div 
        id={userRole == "Client_Premium" ? "filterBox" : "filterBox_Disabled"}
      >
        <h1 id="searchHeader">Power Search</h1>
        <hr />
        <Button onClick={() => {this.loadQuerySaver(currentSearchState, user.id)}} style={{width: "100%", marginBottom: 5}}>
            <span style={{marginRight: 5}}><strong>+</strong></span>Save Current Query
        </Button>
        <Button onClick={() => this.exportSearchResults(currentSearchState)} style={{width: "100%"}}>
            <span style={{marginRight: 5}}></span>Export Results
        </Button>
        <br /><br />
        <Panel header="Saved Searches">
          <Select
            id="saved_Searches_Select"
            name="saved_Searches"
            isClearable={false}
            isSearchable={true}
            defaultValue={{label: "Your Saved Search Queries", value: ""}}
            onChange={(search) =>this.loadSavedSearch(search)}
            options={saved_Search_Select}
          />
        </Panel>
        <br /><br />
        <Panel header="Applied Filters">
          <CustomCurrentRefinements />
        </Panel>
        <br /><br />
        <Panel header="Funding Amount ($)">
          <RangeInput min={0} max={100000000000} attribute="funding_Amount" />
        </Panel>
        <br /><br />
        <Panel header="Application Due Dates">
          <DateRangePicker
            attributes={
              {
                from: "application_Due_Date",
                to: "application_Due_Date"
              }
            }
            restrictResultsToStrictRange
          />
        </Panel>
        <br /><br />
        <Panel header="Agencies">
          <RefinementList 
            attribute="agency" 
            searchable={true}
            limit={5}
            showMore={true}
            showMoreLimit={30}
            translations={{
              showMore(expanded) {
                return expanded ? 'Show less' : 'Show more';
              },
              noResults: 'No results',
              submitTitle: 'Submit your search query.',
              resetTitle: 'Clear your search query.',
              placeholder: 'Search Agencies...',
            }}
          />
        </Panel>
        <br /><br />
        <Panel header="Tags / Keywords">
          <RefinementList 
            attribute="tags" 
            searchable={true}
            limit={5}
            showMore={true}
            showMoreLimit={30}
            translations={{
              showMore(expanded) {
                return expanded ? 'Show less' : 'Show more';
              },
              noResults: 'No results',
              submitTitle: 'Submit your search query.',
              resetTitle: 'Clear your search query.',
              placeholder: 'Search Tags...',
            }}
          />
        </Panel>
        <br /><br />
        <Panel header="CMC">
          <RefinementList 
            attribute="whitelabel_tags" 
            searchable={true}
            limit={5}
            showMore={true}
            showMoreLimit={30}
            translations={{
              showMore(expanded) {
                return expanded ? 'Show less' : 'Show more';
              },
              noResults: 'No results',
              submitTitle: 'Submit your search query.',
              resetTitle: 'Clear your search query.',
              placeholder: 'Search Tags...',
            }}
          />
        </Panel>
        <br /><br />
        <Panel header="Countries">
          <RefinementList 
            attribute="locations.country" 
            searchable={true}
            limit={3}
            showMore={true}
            showMoreLimit={30}
            style={{color: "#000!important"}}
            translations={{
              showMore(expanded) {
                return expanded ? 'Show less' : 'Show more';
              },
              noResults: 'No results',
              submitTitle: 'Submit your search query.',
              resetTitle: 'Clear your search query.',
              placeholder: 'Search Countries...',
            }}
          />
        </Panel>
        <br /><br />
        <Panel header="US States">
          <RefinementList 
            attribute="locations.uS_State" 
            searchable={true}
            limit={3}
            showMore={true}
            showMoreLimit={30}
            translations={{
              showMore(expanded) {
                return expanded ? 'Show less' : 'Show more';
              },
              noResults: 'No results',
              submitTitle: 'Submit your search query.',
              resetTitle: 'Clear your search query.',
              placeholder: 'Search States...',
            }}
          />
        </Panel>
        <br /><br />
        <Panel header="US Cities">
          <RefinementList 
            attribute="locations.uS_City" 
            searchable={true}
            limit={3}
            showMore={true}
            showMoreLimit={30}
            translations={{
              showMore(expanded) {
                return expanded ? 'Show less' : 'Show more';
              },
              noResults: 'No results',
              submitTitle: 'Submit your search query.',
              resetTitle: 'Clear your search query.',
              placeholder: 'Search Cities...',
            }}
          />
        </Panel>
        <br /><br />
        <Panel header="US Regions">
          <RefinementList 
            attribute="locations.region" 
            searchable={true}
            limit={3}
            showMore={true}
            showMoreLimit={30}
            translations={{
              showMore(expanded) {
                return expanded ? 'Show less' : 'Show more';
              },
              noResults: 'No results',
              submitTitle: 'Submit your search query.',
              resetTitle: 'Clear your search query.',
              placeholder: 'Search US Regions...',
            }}
          />
        </Panel>
        <br />
        {showQueryDiv &&
          <div id="showQueryBackground">
            <div id="showQueryDiv">
              <p onClick={this.closeQueryWindow} style={{float: "right", paddingRight: 10, paddingTop: 10, hover: "pointer"}}><strong>X</strong></p>
              <br />
              <h1>Custom Query Builder</h1>
              <hr />
              <TextField
                  required
                  id="saved-query-title"
                  label="SAVED QUERY TITLE"
                  name="savedQueryTitle"
                  value={savedQueryTitle}
                  onChange={this.handleChange}
                  style={{width:"100%", marginBottom:15}}
              />
              <Button onClick={() => {this.saveQuery()}}>
                Save Query
              </Button>
              <br /><br /><br />
              <hr />
              {/* {this.state.newQuery.query.query} */}
              <div id="currentQuery">
                {this.state.newQuery.query.query && 
                  <div>
                    <strong>Search Term: </strong>
                    {this.state.newQuery.query.query}
                  </div>
                }
                {this.state.newQuery.query.dateRange && 
                  <div>
                    <strong>Due After: </strong>
                    {unixDate((this.state.newQuery.query.dateRange.from / 1000))}
                    <br />
                    <strong>Due Before: </strong>
                    {unixDate((this.state.newQuery.query.dateRange.to / 1000))}
                    <br />
                  </div>
                }
                {this.state.newQuery.query.range && 
                 this.state.newQuery.query.range.funding_Amount && 
                  <div>
                    {this.state.newQuery.query.range.funding_Amount.min &&
                      <div>
                        <strong>Funding Amount (min): </strong>
                        ${JSON.stringify(this.state.newQuery.query.range.funding_Amount.min)}
                      </div>
                    }
                    {this.state.newQuery.query.range.funding_Amount.max &&
                      <div>
                        <strong>Funding Amount (max): </strong>
                        ${JSON.stringify(this.state.newQuery.query.range.funding_Amount.max)}
                      </div>
                    }
                  </div>
                }
                {this.state.newQuery.query.refinementList && 
                  <div>
                    {this.state.newQuery.query.refinementList.agency &&
                      <div>
                      <strong>Agencies: </strong>
                      {JSON.stringify(this.state.newQuery.query.refinementList.agency)}
                      </div>
                    }
                    {this.state.newQuery.query.refinementList.tags &&
                      <div>
                      <strong>Keywords / Tags: </strong>
                      {JSON.stringify(this.state.newQuery.query.refinementList.tags)}
                      </div>
                    }
                  </div>
                }
                <br />
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
    const { setUser, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        whitelabel,
    };
}

const connectedPowerSearchFilters = connect(mapStateToProps)(PowerSearchFilters);
export { connectedPowerSearchFilters as PowerSearchFilters };