import { quickstartConstants } from '../_constants';

export function getPrograms(state = {}, action) {
  switch (action.type) {
    case quickstartConstants.GETPROGRAMS_REQUEST:
      return {
        loading: true
      };
    case quickstartConstants.GETPROGRAMS_SUCCESS:
      return {
        items: action.programs
      };
    case quickstartConstants.GETPROGRAMS_FAILURE:
      return { 
        error: "There was an error processing this request."
      };
      default:
      return state
  }
};