import React from 'react';
import { registrationService } from '../../../_services';
import ReactPasswordStrength from 'react-password-strength';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

export class Registration_Form_Partner extends React.Component {
    constructor(props) {
        super(props);
        const {user, nav, categories} = this.props;

        this.state = {
            user: {
                Discriminator: 'Client',

                Email: '',
                Password: '',

                partnerClient: '',
            },
            submitted: false,
            emailExists: false,
            emailFormat: false,
            password: '',
            isValid: false,

            themeColor: ''
        }
    }

    componentDidMount() {
        const path = window.location.pathname;
        if (path.toLowerCase() != "/register")
        {
            const pathSplit = path.split('/').pop();
            if (isNaN(pathSplit))
            {
                const partnerClient = pathSplit;
                this.setState(prevState => ({
                    user: {
                        ...prevState.user,
                        partnerClient: partnerClient,
                    }
                }));
            }
        }
    }

    goToLogin = () => {
        // history.push('/login');
        window.location.href="/login";
    }

    handleChange = (e) => {
        const {name, value} = e.target;
        let {user} = Object.assign({}, this.state);
        user[name] = value;
        this.setState({
            user: user
        })
    }

    handleCategoryInput = (categoryInputs) => {
        this.setState(
            categoryInputs
        )
    }

    handleCategorySelection = (categories) => {
        const {user} = this.state;
        this.setState({
            user: {
                ...user,
                ...categories
            }
        })
    }

    handleNavigation = async () => {
        var {user, nav} = this.state;
        let emailExists = false;
        let emailFormat = false;
        let valid = true;

        if (user.Email === "") {
            valid = false;
        } else {
            let emailState = await registrationService.checkEmail(user);

            if (!emailState.valid) {
                if (emailState.type === "format") {
                    valid = false;
                    emailFormat = true;
                } 
                // else if (emailState.type === "present") {
                //     valid = false;
                //     emailExists = true;
                // }
            }
        }

        if (valid && this.state.isValid)
            this.props.handleFormInputs({user, nav});
        
        this.setState({ 
            ...this.state,
            emailExists: emailExists,
            emailFormat: emailFormat,
            submitted: true });
    }

    changeCallback = state => {
        this.setState(prevState => ({
            passLength: state.password.length,
            isValid: state.isValid,
            user: {
                ...prevState.user,
                Password: state.password,
            }
        }), () => {});
    }

    useStyles = makeStyles((theme) => ({
        root: {
          '& > *': {
            margin: theme.spacing(1),
            width: '100ch',
          },
        },
    }));
    
    render() {
        const {
            user, 
            submitted, 
            emailExists, 
            emailFormat} = this.state;
        console.log(this.state);
        console.log(this.state.user);
        const classes = this.useStyles;
        
        return (
            <div id="form_0" className="forms">
                <h1 id="regPortalLogo">opengrants.io</h1>
                {user.partnerClient !== 'grantwriter' && 
                <h1 id="signUpTagLine">
                    <p>Partner Payment Registration</p>
                </h1>}
                {user.partnerClient === 'grantwriter' && 
                <h1 id="signUpTagLine">
                    <p>Grant Writer Stripe & PORTAL Registration</p>
                </h1>}
                <div id="mobileRegPoweredBy">
                    <hr style={{width: "25%"}} />
                    <p id="mobileRegPoweredByP">Powered by Opengrants.io</p>
                </div>
                <hr />
                {user.partnerClient !== 'grantwriter' && <p style={{textAlign: "center", fontSize: 18, lineHeight: 1.5}}>Please complete this partner registration. After registering, you'll be redirected to complete the Stripe Connect signup. This process allows us to authenticate your profile, and securely send you payments in the future.</p>}
                {user.partnerClient === 'grantwriter' && 
                <p style={{textAlign: "center", fontSize: 18, lineHeight: 1.5}}>
                Please complete this registration. This required step in the grant writer onboarding process will do two things:
                <span style={{display: "block", marginTop: "20px"}}>
                1. Your OpenGrants account will be created. This will let you use PORTAL, our customer-facing software for finding and sharing grants. We encourage you to use this tool for your work and send us feedback on your experience so we can keep improving this tool for our customers.
                </span>
                <span style={{display: "block", marginTop: "20px"}}>
                2. You will be redirected to Stripe so you can enter your payment information. This will let us pay you securely and on time for all the work you invoice through OpenGrants.
                </span>
                <span style={{display: "block", marginTop: "20px"}}>
                If you have questions, please email <span className="og-color">info@opengrants.io</span>.
                </span>
                </p>}
                <div id="signUpForm">
                    <form className={classes.root} noValidate autoComplete="off">
                        <div className="row" id="portalRegRow">
                            <div className="col-md-12">
                                <TextField 
                                    required 
                                    id="standard-basic" 
                                    label="EMAIL ADDRESS" 
                                    name="Email" 
                                    value={user.Email} 
                                    onChange={this.handleChange} 
                                />
                                {submitted && !user.Email &&
                                    <p className="error">* required</p>
                                }
                                {submitted && emailFormat &&
                                    <p className="error">* this email has an invalid format.</p>
                                }
                                {submitted && emailExists &&
                                    <p className="error">* this email is already in use.</p>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12" id="opengrantsPassword">
                                <ReactPasswordStrength
                                    className="passwordStrength"
                                    minLength={8}
                                    minScore={2}
                                    scoreWords={['poor', 'weak', 'good!', 'strong!', 'stronger!!']}
                                    changeCallback={this.changeCallback}
                                    inputState={{ name: "Password", autoComplete: "off", className: "form-control", onChange: this.handleChange }}
                                    inputProps={{placeholder: "CREATE PASSWORD *"}}
                                />
                                {submitted && !user.Password &&
                                    <p className="error">*required</p>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <button 
                                id="signUpLetsGo"
                                type="button"
                                onClick={() => this.handleNavigation()}
                            >
                                Register
                            </button>
                        </div>
                        {user.partnerClient !== 'grantwriter' && 
                        <div className="row" id="alreadySignedUp">
                            <p>Already signed up? 
                                <span style={{color: "teal", cursor: "pointer"}} onClick={() => this.goToLogin()}> Log in.</span>
                            </p>
                        </div>}
                        <div className="row" id="signUpTerms">
                            <p style={{fontSize:14}}>*By clicking "Register" you agree to the
                                <a href="https://www.opengrants.io/opengrants-legal/"><span style={{color: "teal"}}> privacy policy</span></a> and 
                                <a href="https://www.opengrants.io/opengrants-legal/"><span style={{color: "teal"}}> terms of service.</span></a>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}