import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import { history } from '../../_helpers';
import '../../Styles/Error.css';

export class Outside404Page extends React.Component {

    handleRegister() {
        history.push('/register');
    }

    handleLogin() {
        history.push('/login');
    }

    render() {
        const {match: {url}} = this.props;

        if(url.startsWith('/grants')) {
            return null;
        } else {
            return (
                <div id="pageNotFoundDiv">
                    <img 
                        id="outsidePageNotFoundBackground" 
                        src="https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_404_page.jpg"
                    />
                    <div id="listingPreview">
                            <Modal id="listingPreviewModal"
                                isOpen={true} 
                                fade={false}
                            >
                                <ModalHeader>
                                    <h1 id="modalTitle">opengrants.io</h1>
                                </ModalHeader>
                                <ModalBody>
                                    <h2>Page Not Found</h2>
                                    <hr className="pageNotFoundHR" />
                                    <p>
                                        Looks like that content does not exist!
                                        <br />
                                        Here are a few links to get you back on track.
                                    </p>
                                    <br />
                                </ModalBody>
                                <ModalFooter>
                                    <Button 
                                        id="listingModalPreviewRegister" 
                                        color="info"
                                        onClick={this.handleLogin}
                                        style={{minWidth:200}}
                                    >
                                        Login
                                    </Button>
                                    <Button 
                                        id="listingModalPreviewLogin" 
                                        color="secondary"
                                        onClick={this.handleRegister}
                                        style={{minWidth:200}}
                                    >
                                        Create an Account
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </div>

                </div>
            );
        }
    }
}

