import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { Listing, ListingTopBar } from '../Listing';
import ReactTooltip from "react-tooltip";
import { format, parseISO } from "date-fns";
import '../../Styles/SavedListings.css';
import '../../Styles/ListingModal.css';
import { TruncateFunding } from '../Shared/Listing';
import NumberFormat from 'react-number-format';
import TextTruncate from 'react-text-truncate';
import he from 'he';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import stripHtml from "string-strip-html";
import {Loader} from '../Shared/Loader';

const viewListing = (id) => {
    const server = window.location.protocol + '//' + window.location.host;
    const source_link = server + "/grants/" + id;
  
    window.open(source_link, "_blank")
  }

class Favorite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: props.initialModalState,
            fade: true,
            favorites: localStorage.getItem('favorite'),
            themeColor: localStorage.getItem('themeColor'),
            loading: false,
        };
        // this.toggle = this.toggle.bind(this);
        this.discardListing = this.discardListing.bind(this);
    }

    discardListing(e) {
        e.stopPropagation();
        
        this.props.saveListing(this.props.listing.listing_Id, this.props.user);
    }

    render() {
        const { listing, whitelabel }  = this.props;
        const { loading } = this.state;
        const app_due_date = listing.application_Due_Date ? format(parseISO(listing.application_Due_Date), "MMMM do, yyyy") : null;
        const description = stripHtml(listing.description, {
            stripTogetherWithTheirContents: [
              'script', // default
              'style',  // default
              'xml',    // default
              'pre', // <-- custom-added
            ]}
          ).result;

        return (
            <li class="ais-Hits-item">
                {loading &&
                    <Loader />
                }
                <div class="hit-title">
                <TextTruncate
                        line={2}
                        element="span"
                        truncateText="…"
                        text={he.decode(listing.title.trim())}
                        style={{fontWeight: 900}}
                    />
                </div>
                
                <div class="hit-agency" style={{marginTop:5}}>
                { listing.agency }
                </div>

                <hr />
                <class style={{overflow: "hidden", fontSize:14}}>
                    <TextTruncate
                        line={2}
                        element="class"
                        truncateText="…"
                        text={he.decode(description.trim())}
                        style={{color: "#444", fontSize:14}}
                    />
                </class>
                
                {/* FUNDING AMOUNT OUTPUT */}
                <br /><br /><br />
                <div className="hit-funding">
                    {(listing.funding_Amount != null && listing.funding_Amount > 0) &&
                    <NumberFormat value={listing.funding_Amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    }
                    {(listing.funding_Amount == 0 || !listing.funding_Amount) && 
                    <p>Funding varies.</p>
                    }
                </div>

                {/* DUE DATE OUTPUT */}
                <div className="hit-description">
                    {app_due_date && <p><strong>Due: </strong>{app_due_date}</p>}
                    {!app_due_date && <p>Applications ongoing.</p>}
                </div>
                <hr />

                <div class="hitActions">
                    <ReactTooltip effect="solid" />
                    <div class="clearMatchDiv"
                        onClick={this.discardListing}
                        data-tip="Discard favorite."
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="">
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        </svg>
                    </div>
                    <Button 
                        color="info" 
                        onClick={() => {viewListing(listing.listing_Id)}}
                        style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                    >
                        View Grant
                        <ArrowForwardIos style={{float: "right", fontSize:32, marginTop: 0, marginLeft:10, marginRight:-10}}/>
                    </Button>
                </div>
            </li>
        );
    }
}

function mapStateToProps(state) {
    const { searchResults, favorites, setUser, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        searchResults,
        favorites,
        whitelabel,
    };
}

const connectedFavorite = connect(mapStateToProps)(Favorite);
export { connectedFavorite as Favorite };