import React from 'react';
import { connect } from 'react-redux';
import { authenticateActions } from '../../../_actions';
import { ForgotPasswordConfirmation } from './ForgotPasswordConfirmation';
import '../../../Styles/ForgotPassword.css';
import '../../../Styles/ConfirmEmail.css';


class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            submitted: false,
            confirm: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email } = this.state;
        const { dispatch } = this.props;

        if (email) {
            const server = window.location.protocol + '//' + window.location.host;
            dispatch(authenticateActions.forgotPassword(email, server));
            this.setState({ confirm: true });
        }
    }

    render() {
        const { submitted, confirm, email } = this.state;

        return (
            <div>
                {!confirm &&
                    <div id="forgotPassword">
                        <div id="forgotPassowrdRightLogin">
                            <h1 id="forgotPasswordLogo">opengrants.io</h1>
                            <hr />
                            <br />
                            <h3>Enter your email, and we will send you a password reset link.</h3>
                            <form onSubmit={this.handleSubmit}>
                                <div>
                                    <input id="forgotPasswordEmailInput" name="email" placeholder="Email" onChange={this.handleChange} />
                                    {submitted && !email &&
                                        <div className="help-block">Email address is required yo.</div>
                                    }
                                    <button id="forgotPasswordSubmit" type="submit">Send</button>
                                </div>
                            </form>
                            <div id="forgotPasswordIssues">
                                <h3>
                                    If you continue to experience any issues, please contact us for support at <a href="mailto:support@opengrants.io">support@opengrants.io.</a>
                                </h3>
                            </div>
                        </div>
                        <div className="forgotPasswordBottomLinks">
                            <div className="forgotPasswordLinks">
                                <a href="https://www.opengrants.io/opengrants-legal/">Privacy Policy</a>
                                <a href="https://www.opengrants.io/opengrants-legal/">Terms and Conditions</a>
                                <a href="https://experience.opengrants.io/contact-opengrants">Help</a>
                                <a href="/login">Login</a>
                            </div>
                            <div className="forgotPasswordCopyFooter">
                                opengrants.io &#x24B8; {(new Date().getFullYear())} Copyright by Egeria Corporation
                            </div>
                        </div>
                    </div>
                }
                {confirm && 
                    <ForgotPasswordConfirmation />
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    //const { forgotPassword } = state;
    return {
        // forgotPassword
    };
}

const connectedForgotPassword = connect(mapStateToProps)(ForgotPassword);
export { connectedForgotPassword as ForgotPassword };