
import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import '../../../Styles/SettingsStyles/Billings.css';

class StripeCoupon extends Component {
    constructor() {
        super();

        this.state = {
            coupon: '',
            couponId: ''
        };
    }
    
    handleInputChange = (e) => {
        const {id, value} = e.target;
        this.setState({
            [id]: value,
            [id+"Error"]: false
        });
    }

    handleCoupon = async (e) => {
      e.preventDefault();
      if (this.state.coupon !== "") {
        var processed = await this.props.handleCoupon(this.state.coupon, null);
        if (processed.discountStatus.status == "success") {
          const couponId = this.state.coupon;
          this.setState({
            coupon: '',
            couponId
          });
        } else {
          const couponId = this.state.coupon;
          this.setState({
            coupon: '',
            couponId
          });
        }
      }
    }

    render() {
      const {discount, whitelabel} = this.props;
        const {couponId} = this.state;
        let valid = "invalid";
        if (discount) {
          let priceString = undefined;
          if (discount.product && discount.product.includes("prod")) {
              if (discount.product && discount.product.includes(",")) {
                  var productsSplit = discount.product.split(", ");
                  productsSplit.map((prod) => {
                      priceString += this.props.consolidatedProducts[prod];
                  });
              } else {
                  priceString = this.props.consolidatedProducts[discount.product];
              }

              if (priceString) {
                priceString.map((price_id) => {
                    discount.product = price_id.id;
                });
              }
          }

          // if (discount.product && 
          //   (discount.product === "" || 
          //   discount.product.includes('opengrants_test_coupon'))) {
          //   valid = "valid";
          // } 
          // else {
          //   Object.values(this.props.selectedPrices).map((price) => {
          //     if (discount.product && discount.product.includes(price.id)) {
          //       valid = "valid";
          //     }
          //   });

          //   if ((!discount.product && valid !== "valid") || (discount.product && !discount.product.includes("prod"))) {
          //     valid = "cart";
          //   }
          // }

          // if (!discount.product ||
          //   discount.product === "" || 
          //   discount.product.includes('opengrants_test_coupon')) {
          //   valid = "valid";
          // } 
          // else {
          //   Object.values(this.props.selectedPrices).map((price) => {
          //     const interval = price.recurring ? price.recurring.interval : "";
          //     if (!(interval === "year" && parseInt(discount.repeat) < 12) && 
          //       !(interval !== "month" && interval !== "year" && discount.repeat && discount.repeat != "once")) {
          //       if (discount.product && discount.product.includes(price.id)) {
          //         valid = "valid";
          //       }
          //     }
          //   });
          // }
          Object.keys(this.props.consolidatedProducts).map((product) => {
            this.props.consolidatedProducts[product].map((price) => {
              const interval = price.recurring ? price.recurring.interval : "";
              if (!(interval === "year" && parseInt(discount.repeat) < 12) && 
                !(interval !== "month" && interval !== "year" && discount.repeat && discount.repeat != "once")) {
                  if (!discount.product ||
                    discount.product === "" || 
                    discount.product.includes('opengrants_test_coupon')) {
                    valid = "valid";
                  } 
                  else if (discount.product && discount.product.includes(price.id)) {
                    valid = "valid";
                  }
              }
            });
          });
        }

        return (
            <form className="overflow">
                <div className="overflow flex-2-1">
                    <input className="col-md-8 zero-margin" style={{margin: 0, marginRight: 10}} id="coupon" name="coupon" placeholder="Promo Code" type="text" value={this.state.coupon} onChange={this.handleInputChange} />
                    <Button 
                      // className="background-og-color border-theme button-padded-sm" 
                      style={{margin: 0, background: whitelabel ? whitelabel.button_Color : "#29a"}}
                      onClick={this.handleCoupon}
                      >
                        Apply
                    </Button>
                </div>

                {this.props.discountStatus.status === "success" && this.props.discount && valid === "valid" &&
                <p className="success-color" style={{fontWeight: "bolder", marginTop: 10}}>Applied promo code: {couponId}</p>}

                {(this.props.discountStatus.status === "failed" || (this.props.discount && valid === "invalid")) && 
                    <p style={{color: "red", fontWeight: "bolder", marginTop: 10}}>Could not apply promo code to: {couponId}</p>}

                {((this.props.discount && valid === "cart")) && 
                    <p style={{color: "red", fontWeight: "bolder", marginTop: 10}}>Could only apply promo code to add on services: {couponId}</p>}
            </form>
        )
    }
}

function mapStateToProps(state) {
  const { whitelabel } = state.setWhitelabel;
  
  return {
      whitelabel,
  };
}

const connectedStripeCoupon = withRouter(connect(mapStateToProps)(StripeCoupon));
export { connectedStripeCoupon as StripeCoupon };