export const layoutConstants = {
    SUBSCRIBE_REQUEST: 'USERS_SIGNUP_REQUEST',
    SUBSCRIBE_SUCCESS: 'USERS_SIGNUP_SUCCESS',
    SUBSCRIBE_FAILURE: 'USERS_SIGNUP_FAILURE',

    GETSTATS_REQUEST: 'GETSTATS_REQUEST',
    GETSTATS_SUCCESS: 'GETSTATS_SUCCESS',
    GETSTATS_FAILURE: 'GETSTATS_FAILURE',


};
