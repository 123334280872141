import React from 'react'

export class StripePaymentFailure extends React.Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div>
                
            </div>
        )
    }
}