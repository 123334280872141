import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '../../../Shared/Loader';
import { history } from '../../../../_helpers';
import { withRouter } from "react-router-dom";
import ProgressBar from 'react-bootstrap/ProgressBar';
import '../../../../Styles/QuickstartSurvey.css';

class GetStarted extends React.Component {
    constructor(props) {
        super(props);
        // const {user, nav} = this.props;
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            submitted: false,
            loading: true,
        }
        
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        document.body.scrollTop = 0;

        this.setState({
            user: {
                ...this.state.user,
                funding_Months: 3,
                zipcode: null,
                designated_Small_Business: false,
                under_500_Employees: false,
                uS_Owned: false,
                slack_Subscription: false,
                registrationComplete: false,
                app_Registration: false,
                focus_areas: [],
                impact_areas: [],
                impact_groups: [],
                uS_State: null,
            },
            focus_areas: [],
            impact_groups: [],
            submitted: false,
            emailExists: false,
            emailFormat: false,
            password: '',
            isValid: false,
            loading: true,
            loadingExternal: true,
        })

        setTimeout(() => {
            this.setState({
            loading: false
            });
        }, 500); 

        const { history, location } = window; 
        history.pushState(null, null, location.href); 
        window.onpopstate = event => { 
            event.preventDefault(); 
            history.go(1); 
        }; 
    }
          
    handleChange(e) {
        const {name, value} = e.target;
        let {user} = this.state;
        user[name] = value;
        this.setState({
            user: user
        })
    }

    handleNavigation = async(e) => {      
        history.push('/quickstart/learn-more');
    }

    useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '100ch',
            },
        },
    }));   

    render() {
        const { loading } = this.state;
        const { whitelabel } = this.props;
        const progressBarColor = whitelabel ? whitelabel.accent_Color : "#29a";
        const now = 10;
        const progressInstance = <ProgressBar now={now} label={`${now}%`} />;

        return (
            <div>
                <style>{"\
                    .progress-bar{\
                    background:" + progressBarColor + ";\
                    }\
                "}</style>
                {loading && <Loader creatingAccount={true} />}
                <div>{progressInstance}</div>
                { this.state.user.name &&
                    <div id="fundingSlider1">
                        <h1 
                            id="signUpWelcomeH1"
                            style={{color: whitelabel && whitelabel.organization_Name == "portal.opengrants.io" ? "#29a" : "#666"}}
                        >
                            Hi {this.state.user.name}!
                        </h1>
                        <hr id="signUpSliderHR" />
                        <h1 id="askQuestions">
                            We're here to help you find and win grant funding, without any of the extra stress and hassle!
                        </h1>
                    </div>
                }
                <div className="continueButton">
                    <button 
                        className="signUpContinue"
                        onClick={() => this.handleNavigation('next')}
                        style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                    >
                        Get Started  →
                    </button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering, nav } = state.registration;
    const { whitelabel } = state.setWhitelabel;
    const { registrationAlert } = state;
    
    return {
        registering, 
        nav, 
        registrationAlert,
        whitelabel,
    };
}

const connectedGetStarted = withRouter(connect(mapStateToProps)(GetStarted));
export { connectedGetStarted as GetStarted };