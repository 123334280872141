import React from 'react';
import Interweave from 'interweave';
import ExpandCollapse from 'react-expand-collapse';
import '../../Styles/Listing.css'

export class ListingDescription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            themeColor: localStorage.getItem('themeColor')
        }
    }

    render() {
        const description  = this.props.value.description;
        const nullDescription = this.props.value.description;
        return (
            <div id="description">
                    <h4>
                        PROJECT DESCRIPTION
                    </h4>
                    <div id="descriptionRow" className="row">
                        <div className="col-md-12">
                            { description !== "" &&
                                <ExpandCollapse previewHeight="88px">
                                    <Interweave content={description}/>
                                </ExpandCollapse>
                            }
                            { nullDescription === null &&
                                <p>Description not provided by listing agency.</p>
                            }
                        </div>
                    </div>
            </div>
        );
    }
}