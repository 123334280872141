export function setPartnerInfo(partner) {
    if (partner.toString().toLowerCase().includes('captjur')) {
        localStorage.setItem('partner', 'captjur');
        localStorage.setItem('themeColor', '#1574b2');
        localStorage.setItem('partnerLogo', 'https://experience.opengrants.io/hs-fs/hubfs/Captjur%20Logo-3.png?width=890&name=Captjur%20Logo-3.png')
    }
    if (partner.toString().toLowerCase().includes('startup-garage')) {
        localStorage.setItem('themeColor', 'rgb(156, 101, 101)');
        localStorage.setItem('partnerLogo', 'https://experience.opengrants.io/hs-fs/hubfs/Startup%20Garage%20Badge.png?width=512&name=Startup%20Garage%20Badge.png')
    }

    if (partner.toString().toLowerCase().includes('sv-venture')) {
        localStorage.setItem('themeColor', 'rgb(21, 155, 178)');
        localStorage.setItem('partnerLogo', 'https://experience.opengrants.io/hs-fs/hubfs/Asset%201@2x.png?width=1200&name=Asset%201@2x.png')
    }

    if (partner.toString().toLowerCase().includes('nexus')) {
        localStorage.setItem('themeColor', '#145277');
        localStorage.setItem('partnerLogo', 'https://experience.opengrants.io/hs-fs/hubfs/NEN-logo-05.png?width=1200&name=NEN-logo-05.png')
    }

    if (partner.toString().toLowerCase().includes('chicostart')) {
        localStorage.setItem('themeColor', '#186592');
        localStorage.setItem('partnerLogo', 'https://experience.opengrants.io/hs-fs/hubfs/ChicoStart.png?width=800&name=ChicoStart.png')
    }

    if (partner.toString().toLowerCase().includes('startupsac')) {
        localStorage.setItem('themeColor', '#416794');
        localStorage.setItem('partnerLogo', 'https://experience.opengrants.io/hs-fs/hubfs/StartupSacTextLogo%20Alt-01%20copy.png?width=1100&name=StartupSacTextLogo%20Alt-01%20copy.png')
    }

    if (partner.toString().toLowerCase().includes('wiase')) {
        localStorage.setItem('themeColor', '#416794');
        localStorage.setItem('partnerLogo', 'https://experience.opengrants.io/hs-fs/hubfs/16BBAF3E-3AA4-4988-A182-21A864B46743.png?width=570&name=16BBAF3E-3AA4-4988-A182-21A864B46743.png')
    }
}