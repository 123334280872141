import React from 'react';
import '../../Styles/GrantWriters.css'

export class ServiceProviders extends React.Component {
    render() {
        return (
            <div id="grantWriter">
                <h1>Service Providers</h1>
                <hr className="airTableHR" align="left" />
                <h2>Carefully Curated & Vetted Service Providers</h2>
                <iframe className="airtable-embed" src="https://airtable.com/embed/shrhAMIAXt5cYhgOK?backgroundColor=green&viewControls=on" frameBorder="0" onmousewheel="" width="100%" height="533"></iframe>
            </div>
        );
    }
}