import { authHeader } from '../_helpers';

export const listingNotesService = {
    getAllListingNotes,
    getListingNotes,
    saveListingNotes,
    deleteListingNotes
};

function getAllListingNotes(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/listingsnotes/get_all_listing_notes?user_id=' + user_id, requestOptions)
    .then(handleResponse);
}

function getListingNotes(user_id, listing_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/listingsnotes/get_listing_notes?user_id=' + user_id + '&listing_Id=' + listing_Id, requestOptions)
    .then(handleResponse);
}

function saveListingNotes(user_id, listing_Id, user_notes) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user_notes)
    };

    return fetch('https://refactor.opengrants.io/api/listingsnotes/save_listing_notes?user_id=' + user_id + '&listing_Id=' + listing_Id, requestOptions)
    .then(handleResponse);
}

function deleteListingNotes(user_id, listing_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/listingsnotes/delete_listing_notes?user_id=' + user_id + '&listing_Id=' + listing_Id, requestOptions)
    .then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status == "401") {
                window.location.href="/login";
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}