import React from 'react';
import { connect } from 'react-redux';
import { registrationActions } from '../../../_actions';
import { Registration_Form } from '../RegisterPage';
import '../../../Styles/Registration.css';

class Registration extends React.Component {
    constructor(props) {
        super(props);
        const {nav} = this.props;

        const partner = localStorage.getItem('partner');
        this.state = {
            user: {
                Discriminator: 'Client',
                Linked_Listing_Id: '',

                Email: '',
                Password: '',

                Name: '',
                Surname: '',
                Phone_Number: '',

                Organization_Name: '',
                Website: '',
                Title: '',
                Organization_Description: '',

                category: '',
                category_Tags: [],

                EIN_Number: '',
                DUNS_Number: '',
                SAM_Registration: '',
                Woman_Owned: '',
                Veteran_Owned: '',
                Hubzone_Owned: '',

                Organization_Address: '',
                City: '',
                Country: '',

                Referral: '',

                Twitter: '',
                LinkedIn: '',
                file: '',
                partner: '',

                whitelabel_Platform: window.location.hostname,
            },
            categories:[],
            nav: nav,
            totalNav: 0,
            listing_Id: 0
        };
    }

    async componentDidMount() {
        document.body.scrollTo(0,0);

        localStorage.removeItem('user');
    }

    handleSubmit = async (user) => {
        const { dispatch, whitelabel } = this.props;
        if (this.state.listing_Id !== 0)
        {
            user.Linked_Listing_Id = this.state.listing_Id;
        }
        const server = window.location.protocol + '//' + window.location.host;

        var userInfo = {
            email: user.Email,
            password: user.Password
        };
        this.props.updateUserInfo(userInfo);

        await dispatch(registrationActions.register(user, server, whitelabel.organization_Name));
    }

    handleFormInputs = (inputs) => {
        let {user} = inputs;

        user.file_string = localStorage.getItem('fileBase64');
        this.handleSubmit(user);
    }

    render() {
        const { user, nav, category, categoryTags, totalNav } = this.state;
        const { whitelabel } = this.props;
        let categoryOptions = {"category": category, "category_Tags": categoryTags};

        return (
            <div id="rightRegistration">
                <Registration_Form 
                    user={user} 
                    nav={nav} 
                    categories={categoryOptions} 
                    handleFormInputs={this.handleFormInputs}
                    whitelabel={whitelabel}
                >
                </Registration_Form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setWhitelabel } = state;
    const { whitelabel } = setWhitelabel;
    return {
        whitelabel
    };
}

const connectedRegistration = connect(mapStateToProps)(Registration);
export { connectedRegistration as Registration };
