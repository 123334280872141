import React from 'react';
import { connect } from 'react-redux';
// import { Landing } from '../_pages/Landing';
import { stripeActions } from '../../../_actions';
import { Loader } from '../../Shared/Loader';
import '../../../Styles/StripeProduct.css';

class StripeConnect extends React.Component {
    createStripeAccount = () => {
        var params = this.props.location.search.split("?")[1];
        if (params !== "" && params.includes("code")) {
            var params_split = params.split("&");
            var code = "";
            var state = "";
            params_split.forEach((param) => {
                var param_split = param.split("=");
                if (param_split[0] === "code") {
                    code = param_split[1];
                } else if (param_split[0] === "state") {
                    state = param_split[1];
                }
            });
            if (code !== "") {
                this.props.dispatch(stripeActions.createStripeAccount(code, state));
            }
        }
    }

    render() {
        const {status, loading} = this.props.createStripeAccount;
        if (status === "" && !loading) {
            this.createStripeAccount();
        } else if (status === "success") {
            this.props.history.push("/landing");
        }
        return (
            <div className="stripe-product">
                <div className="indent-left">
                    <h1 className="opengrants-logo">opengrants.io</h1>
                </div>
                {loading && <Loader />}
                {!loading && status === "failure" &&
                    <div className="center gap-top-lg background-white pop adjustable-width">
                        <h1 className="og-color">We're Sorry!</h1>
                        <p className="font-medium adjustable-width gap-bottom">We were unable to create or to connect to your account. Please contact us for support at 
                            <a href="mailto:support@opengrants.io" className="og-color"> support@opengrants.io.</a>
                        </p>
                    </div> 
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { createStripeAccount } = state;
    return {
        createStripeAccount
    };
}

const connectedRegisterPage = connect(mapStateToProps)(StripeConnect);
export { connectedRegisterPage as StripeConnect };