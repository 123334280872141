import React from 'react';
import { connect } from 'react-redux';
import { Registration_Partner} from '../RegisterPage_Partner';
import { Loader } from '../../Shared/Loader';
import '../../../Styles/Registration.css';
import { Segment } from '../../../_pages/Shared/Segment';

class RegisterPage_Partner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                email: '',
                password: '',
                partnerClient: ''
            }
        }
    }

    componentDidMount() {
        const path = window.location.pathname;

        if (path.toLowerCase() != "/register" && path != "/register/null")
        {
            const pathSplit = path.split('/').pop();
            this.setState({
                partnerClient: pathSplit
            });
        }
    }

    updateUserInfo = (user) => {
        this.setState({
            user
        })
    }

    render() {
        const {registering, purchaseProduct, user} = this.props;
        let windowState = (window.innerWidth >= 1000) ? true : false;
        
        if (registering == "success") {
            var redirect = user.partnerClient;

            user.partnerClient = user.partner;
            user.partner = "";
            user.userRoles = ["Client_Premium"];
            Segment.register(user);
            Segment.track(user, 'Register',
                {
                    user_type: user.partnerClient,
                }
            );
            window.location.href = redirect;
        }

        return (
            <div>
                {windowState === false && 
                    <h1 className="logo">opengrants.io</h1>
                }
                {(registering === "request" || purchaseProduct.loading) && <Loader />}
                {(registering !== "success") &&
                <div id="clientRegistration">
                    <Registration_Partner updateUserInfo={this.updateUserInfo}></Registration_Partner>
                </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering, nav, user } = state.registration;
    const {registrationAlert, purchaseProduct} = state;
    return {
        registering, nav, registrationAlert, purchaseProduct, user
    };
}

const connectedRegisterPage = connect(mapStateToProps)(RegisterPage_Partner);
export { connectedRegisterPage as RegisterPage_Partner };