import React from 'react';
import '../../Styles/GrantWriters.css'

export class GrantWriters extends React.Component {

    render() {
        return (
            <div id="grantWriter">
                <h1>OPENGRANTS HIRE</h1>
                <hr className="airTableHR" align="left" />
                <h2>Carefully Curated & Vetted Grant Writing Professionals</h2>
                <iframe className="airtable-embed" src="https://airtable.com/embed/shr8F2iLCGMJnMUtU?backgroundColor=green&viewControls=on" frameBorder="0" onmousewheel="" width="100%" height="533"></iframe>
            </div>
        );
    }
}
