import React from 'react';
import '../../Styles/ServiceProvider.css'

export class ServiceProvidersMessages extends React.Component {
  state = {
    email: JSON.parse(localStorage.getItem('user')).email
  }
  render() {
    const { messages, loadMore, channel } = this.props;
    let messages_view = null;
    if (messages) {
      messages_view = messages.map((message, idx) => {
        if (message.author !== (channel.user_Info ? channel.user_Info.message_Identity : channel.message_Identity))
          return <div key={idx}>
            <h6 style={{ float: "right", display: "block", margin: 0, fontSize: 12, fontWeight: 500 }}>
              {new Date(message.dateCreated.toISOString()).toLocaleString()}
            </h6>
            <br />
            <p class="outgoing">
              {message.state.body}
            </p>
          </div>;
        else
          return <div key={idx}>
            <h6 style={{ display: "block", margin: 0, fontSize: 12, fontWeight: 500 }}>
              {new Date(message.dateCreated.toISOString()).toLocaleString()}
            </h6>
            <p class="incoming">
              {message.state.body}
            </p>
          </div>;
      });
    }
    else {
      return <div>
        <br />
        <p>Send a message to this service provider to get a conversation going!</p>
      </div>;
    }

    return (
      <div class="messages_box">
        {loadMore && <a onClick={loadMore}>Load More...</a>}
        <h6 style={{ textAlign: "center", marginTop: -15, marginBottom: 25, fontWeight: 500 }}>
          Beginning of messages.
          <hr />
        </h6>
        {/* You haven't sent this person a message yet, so get a conversation started! */}
        {messages_view}
      </div>
    );
  }
}