import React from 'react';


import { connect } from 'react-redux';
import TextTruncate from '../../../node_modules/react-text-truncate';
import he from '../../../node_modules/he';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import { connectHighlight, } from 'react-instantsearch-dom';

const viewservice_Provider = (id) => {
  const server = window.location.protocol + '//' + window.location.host;
  const source_link = server + "/service-provider/" + id;

  window.location.href = source_link;
};

const CustomHighlight = connectHighlight(({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit
  });

  return (
    <div>
      {parsedHit.map(
        part => (part.isHighlighted ? <mark>{part.value}</mark> : part.value)
      )}
    </div>
  );
});

class MarketplaceResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFavorite: false,
      searchGroup: {
        search: "",
        user: this.props.user,
        categories: [],
        agencies: [],
        page: 1,
        totalResults: 0,
      },
    };
  }

  render() {
    const service_Provider = this.props.service_Provider.hit;
    const whitelabel = this.props.whitelabel;
    const navLinkBackground = whitelabel ? whitelabel.dashboard_Color : "#1215218c";

    return (
      <div id="searchHitItem">
        <div>
          <div className="row spContainer">
            {/* SP PROFILE IMAGE */}
            <div className="col-md-2" className="spImageDiv">
              <img
                src={service_Provider.logo_URL ? service_Provider.logo_URL : "https://images.squarespace-cdn.com/content/v1/59b82d7712abd96f8ef174c5/1517767018663-E1U7A0QITIZ23XF2TKM1/ke17ZwdGBToddI8pDm48kHqYAt3UgyjNg-0dlUc4K5hZw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7UnCxNA8dHvmd7460Z7fbKEmJ2gL2qv94i4UWS2y7YfwkXCxk_sn2atIO3dASbw33Q/placeholder.png"}
                className="spLogo"
              />
            </div>
            {/* SP INFORMATION */}
            <div className="col-md-10" className="spInfoDiv">
              {/* TITLE */}
              <div className="hit-title">
                <CustomHighlight attribute="title" hit={service_Provider} />
                <div className="hit-header" style={{ display: "flex", justifyContent: "space-between" }}>
                  <TextTruncate
                    line={2}
                    element="class"
                    truncateText="…"
                    text={he.decode(service_Provider.name)}
                    style={{ fontWeight: 900, display: "block" }}
                  />
                  <Button
                    onClick={() => { viewservice_Provider(service_Provider.objectID) }}
                    style={{ float: 'right', padding: 0, marginTop: 0, color: navLinkBackground, background: 'transparent', boxShadow: 'none', fontWeight: 'bold' }}
                    className="hitViewProfileBtn"
                  >
                    View Profile
                  </Button>
                </div>
                <hr style={{ marginBottom: '10px', marginTop: '10px' }} />
              </div>
              {/* ABOUT */}
              <div className="spAboutDiv" >
                <p className="TaletFinderTitle">About Me</p>
                <TextTruncate
                  line={3}
                  element="class"
                  truncateText="…"
                  text={service_Provider.bio && he.decode(service_Provider.bio.trim())}
                  style={{ color: "#333", fontSize: 16, clear: "both", display: "block" }}
                />
                <br />
                {/* TAGS */}
                <div className="row spTags">
                  {/* FOCUS AREAS */}
                  <div className="col-md-6" className="focusAreasTags">
                    {service_Provider.sP_Industry_Focus_Tags &&
                      service_Provider.sP_Industry_Focus_Tags.length > 0 &&
                      <div>
                        <p className="TaletFinderTitle">
                          Focus Areas
                        </p>
                      </div>
                    }
                    {service_Provider.sP_Industry_Focus_Tags && service_Provider.sP_Industry_Focus_Tags.map(service => (
                      <div id="wtfTag">
                        <p style={{ display: "inline" }}>{service.trim()}</p>
                      </div>
                    ))}
                  </div>
                  {/* SERVICES */}
                  <div className="col-md-6" className="servicesTags">
                    {service_Provider.sP_Industry_Services_Tags &&
                      service_Provider.sP_Industry_Services_Tags.length > 0 &&
                      <div>
                        <p className="TaletFinderTitle">
                          Services
                        </p>
                      </div>
                    }
                    {service_Provider.sP_Industry_Services_Tags && service_Provider.sP_Industry_Services_Tags.map(service => (
                      <div id="wtfTag">
                        <p style={{ display: "inline" }}>{service.trim()}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
        <br />
        <hr />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { setUser } = state;
  const { user } = setUser;
  const { whitelabel } = state.setWhitelabel;

  return {
    user,
    whitelabel
  };
}

const connectedResult = connect(mapStateToProps)(MarketplaceResult);
export { connectedResult as MarketplaceResult };