import React from 'react';
import { connect } from 'react-redux';
import { organizationService } from '../../../_services';
import { Registration_AutoSuggestion } from '../../Account/RegisterPage';
import '../../../Styles/Organization.css';

class EditOrganizationImpact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            org: this.props.org,
            industries: [],
        }
    }

    componentDidMount() {
        this.getSocialImpactCategories();
    }

    getSocialImpactCategories = async() => {
        const { user } = this.props;
        var social_Impact_Categories = await organizationService.getSocialImpactCategories(user.id);

        this.setState({
            social_Impact_Categories
        });
    }

    render() {
        const { social_Impact_Categories }  = this.state;
        const { org } = this.props;

        let social_Impact_Categories_Cat = {"social_Impact_Categories": social_Impact_Categories};

        return (
            <div style={{paddingBottom: 20}}>
                <h2>Social Impact Areas</h2>
                <Registration_AutoSuggestion 
                    categories={social_Impact_Categories_Cat} 
                    categoryName="social_Impact_Categories"
                    currentSelectedCategories={org.social_Impact_Categories}
                    // submitted={submitted}
                    handleCategorySelection={this.props.handleCategorySelection}
                    addTags={true}
                    multiple={true}
                    handleCategoryInput={this.props.handleCategoryInput}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        organization,
        whitelabel,
    };
}

const connectedEditOrganizationImpact = connect(mapStateToProps)(EditOrganizationImpact);
export { connectedEditOrganizationImpact as EditOrganizationImpact };