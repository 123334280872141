import { organizationConstants } from '../_constants';

export function organization_createorganizationalert(state = {}, action) {
  switch (action.type) {
    case organizationConstants.CREATE_ORGANIZATION_SUCCESS:
      return {
        created_organization: true
      };
    default:
      return state
  }
}

export function organization_noorganizationalert(state = {}, action) {
  switch (action.type) {
    case organizationConstants.NO_ORGANIZATION_ALERT:
      return {
        no_organization: true
      };
    default:
      return state
  }
}

export function organization_removemessageblock(state = {}, action) {
  switch (action.type) {
    case organizationConstants.REMOVE_MESSAGE_BLOCK:
      return {
        block_messaging: !action.remove_message_block
      };
    default:
      return state
  }
}