import React from 'react';
import { connect } from 'react-redux';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import { Loader } from '../../Shared/Loader';
import '../../../Styles/Organization.css';
import { OrganizationList, OrganizationView } from '.';
import ReactTooltip from "react-tooltip";
import { organizationService, locationsService } from '../../../_services';
import { EditOrganizationForm } from './EditOrganizationForm';
import { EditOrganizationFormMembers } from './EditOrganizationFormMembers';
import { OrganizationExistsList } from './OrganizationExistsList';
import { AcceptedProfileList } from './AcceptedProfileList';
import { PendingProfileList } from './PendingProfileList';
import { PendingInvitationList } from './PendingInvitationList';

const options = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
];

class OrganizationSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { user, submitting, submitted, whitelabel,
            org, organizations, organization_requests, owned_organizations, pending_invitations,
            addOrganization, editOrganization, editOrganizationFunc, viewOrganization,
            organization_names, newOrganization, organization_namesInput, all_organizations,
            organizationNameError, organizationCityError, organizationStateError, organizationCountryError,
            updateOrganizationName, invite_emailInput, accepted_profiles, pending_profiles, pending_invitation_profiles,
            updateOrganizationOwner, confirmOrganizationRequest, cancelOrganizationInvitation,
            transfer, loading, handleNewOrganization, inviteToJoinOrganization,
            city, state, country
        }  = this.props;
        const { organization } = this.props.org;

        var organization_exists_list = null;
        if (all_organizations && organization_namesInput) {
            var total_organizations = Object.values(all_organizations).filter(org => org.organization.name.includes(organization_namesInput));
            organization_exists_list = Object.values(all_organizations).map((org) => {
                return (
                    <OrganizationExistsList 
                        organization_namesInput={organization_namesInput}
                        organization_exists_list={organization_exists_list}
                        all_organizations={all_organizations}
                        total_organizations={total_organizations}
                        org={org}
                        newOrganizationFunc={this.props.newOrganizationFunc}
                    />
                )
            })
        }

        var accepted_profile_list = null;
        if (accepted_profiles) {
            accepted_profile_list = accepted_profiles.map((profile) => {
                return(
                    <AcceptedProfileList 
                        profile={profile}
                        transfer={transfer}
                        org={org}
                        updateOrganizationOwner={updateOrganizationOwner}
                        confirmOrganizationRequest={confirmOrganizationRequest}
                    />
                )
            });
        }

        var pending_profile_list = null;
        if (pending_profiles) {
            pending_profile_list = pending_profiles.map((profile) => {
                return(
                    <PendingProfileList 
                        profile={profile}
                        transfer={transfer}
                        org={org}
                        confirmOrganizationRequest={this.props.confirmOrganizationRequest}
                    />
                )
            });
        }

        var pending_invitation_list = null;
        if (pending_invitation_profiles) {
            pending_invitation_list = pending_invitation_profiles.map((profile, idx) => {
                return(
                    <PendingInvitationList 
                        profile={profile}
                        idx={idx}
                        transfer={transfer}
                        org={org}
                        cancelOrganizationInvitation={cancelOrganizationInvitation}
                    />
                )
            });
        }

        return (
            <div>
                {loading && <Loader />}
                <h1 id="dashHeader">Organization Attributes</h1>
                {this.props.updatedAlert &&
                    <div className={'settingsForm'}>
                        <br /><br />
                        <div id="settingsUpdatedSuccess" className={`alert-success`}>Organization settings have been updated.</div>
                    </div>
                }
                {this.props.updatedErrorAlert &&
                    <div className={'settingsForm'}>
                        <br /><br />
                        {organization.organization_Id && <div id="settingsUpdatedSuccess" className={`alert-error`}>An error occurred while updating the listing. Please try again.</div>}
                        {!organization.organization_Id && <div id="settingsUpdatedSuccess" className={`alert-error`}>An error occurred while adding the listing. Please try again.</div>}
                    </div>
                }
                <hr />
                {viewOrganization > 0 &&
                    <OrganizationView 
                        organization_id={viewOrganization}
                        editOrganization={this.props.editOrganization}
                    />
                }
                {!addOrganization && !editOrganization && viewOrganization == 0 &&
                    <div>
                        <p>You're a part of <strong>{Object.keys(organizations).length}</strong> Organization(s)</p>
                        <div style={{marginBottom: "20px"}}>
                            <Button 
                                color="info" 
                                onClick={this.props.addOrganizationFunc}
                                style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                            >
                                Add Organization
                            </Button>
                        </div>
                        <OrganizationList 
                            organizations={this.props.organizations}
                            organization_requests={this.props.organization_requests}
                            pending_invitations={this.props.pending_invitations}
                            editOrganization={this.props.editOrganizationFunc}
                            viewOrganization={this.props.viewOrganization}
                            removeSelfFromOrg={this.props.removeSelfFromOrg}
                            updateOrganizations={this.props.updateOrganizations}
                            updateInvitations={this.props.updateInvitations}
                            updateState={this.props.updateState}
                            updateOrganizations={this.props.updateOrganizationsFunc}
                            removeSelfFromOrg={this.props.removeSelfFromOrg}
                        />
                    </div>
                }
                {(addOrganization || editOrganization) && viewOrganization == 0 &&
                    <div>
                        {!newOrganization && addOrganization &&
                            <div id="organizationForm">
                                <div className="text_section">
                                    <h2>Company Name</h2>
                                    <input 
                                        id="organization_Name" 
                                        name="organization_namesInput" 
                                        value={organization_namesInput} 
                                        required 
                                        onChange={this.props.handleNewOrganization} 
                                    />
                                    <div style={{marginTop: "15px", display: "flex", flexDirection: "column"}}>
                                        {organization_exists_list}
                                    </div>
                                    <div style={{marginTop: 20, marginBottom: 20}}>
                                        {((!organization_names || !organization_names.includes(organization_namesInput)) ||
                                            (Object.values(all_organizations).filter(org => org.organization.name.includes(organization_namesInput) && 
                                            (!org.users.includes(user.id) || org.organization.owner != user.id) &&
                                            (!org.pending.includes(user.id)) &&
                                            (!org.invitations.includes(user.id))).length > 0)) && organization_names.filter(name => name == organization_namesInput).length == 0 &&
                                            <div style={{display: "inline-block"}}>
                                                {owned_organizations < 5 && organization_names && 
                                                    <Button 
                                                        color="info" 
                                                        style={{marginRight: "20px"}} 
                                                        onClick={() => {this.props.newOrganizationFunc()}}
                                                    >
                                                        Create Organization
                                                    </Button>}
                                                {owned_organizations >= 5 && organization_names && 
                                                    <div style={{display: "inline-block"}} data-tip="You can only create up to 5 organizations">
                                                        <ReactTooltip effect="solid" />
                                                        <Button 
                                                            style={{marginRight: 20, background: whitelabel ? whitelabel.button_Color : "#29a"}}
                                                        >
                                                            Create Organization
                                                        </Button>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        <Button 
                                            onClick={this.props.hideAddEditOrganization}
                                            style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }
                        {(newOrganization || editOrganization) && 
                            <form id="organizationForm" className="settingsForm" onSubmit={this.props.handleSubmit}>
                                <EditOrganizationForm 
                                    org={org}
                                    state={state}
                                    country={country}
                                    options={options}
                                    organization_namesInput={this.props.organization_namesInput}
                                    organizationNameError={this.props.organizationNameError}
                                    organizationStateError={this.props.organizationStateError}
                                    organizationCountryError={this.props.organizationCountryError}
                                    updateOrganizationName={this.props.updateOrganizationName}
                                    handleChange={this.props.handleChange}
                                    handleLocationInput={this.props.handleLocationInput}
                                    handleLocationSelection={this.props.handleLocationSelection}
                                    handleSelectionChange={this.props.handleSelectionChange}
                                    handleCategoryInput={this.props.handleCategoryInput}
                                    handleCategorySelection={this.props.handleCategorySelection}
                                    hideAddEditOrganization={this.props.hideAddEditOrganization}
                                />
                                <EditOrganizationFormMembers
                                    org={org} 
                                    addOrganization={addOrganization}
                                    editOrganization={editOrganization}
                                    invite_emailInput={invite_emailInput}
                                    accepted_profiles={accepted_profiles}
                                    accepted_profile_list={accepted_profile_list}
                                    pending_profiles={pending_profiles}
                                    pending_invitation_profiles={pending_invitation_profiles}
                                    pending_invitation_list={pending_invitation_list}
                                    pending_profiles={pending_profiles}
                                    pending_profile_list={pending_profile_list}
                                    transfer={transfer}
                                    hideAddEditOrganization={this.props.hideAddEditOrganization}
                                    handleSubmit={this.props.handleSubmit}
                                    handleNewOrganization={handleNewOrganization}
                                    inviteToJoinOrganization={inviteToJoinOrganization}
                                    organizationNameError={this.props.organizationNameError}
                                />
                            </form>
                        }
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        organization,
        whitelabel,
    };
}

const connectedOrganizationSettings = connect(mapStateToProps)(OrganizationSettings);
export { connectedOrganizationSettings as OrganizationSettings };