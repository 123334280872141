import React, { useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '../../../Shared/Loader';
import { history } from '../../../../_helpers';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ProgressBar from 'react-bootstrap/ProgressBar';

class EntityType extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            entity_type: [],
            loading: true,
        }
    }

    componentDidMount() {
        this.setState({
            entity_type: {
                ...this.state.entity_type,
                impact_innovation: false,
                business_innovation: false,
                technology_innovation: false,
            }
        })
        
        document.body.scrollTop = 0;
    
        setTimeout(() => {
            this.setState({
            loading: false
            });
        }, 500);
    }

    useStyles = makeStyles((theme) => ({
            root: {
              display: 'flex',
            },
            formControl: {
              margin: theme.spacing(3),
            },
    }));
          
    CheckboxesGroup() {
        const classes = this.useStyles();
    };
          
    handleChange = (event) => {
        const category = [event.target.name];

        if (category == "impact_innovation") {
            this.setState({ 
                entity_type: {
                    impact_innovation: true,
                    business_innovation: false,
                    technology_innovation: false
                },
                user: {
                    ...this.state.user,
                    entity_Type: "Impact Innovation"
                }
            });
        }

        if (category == "business_innovation") {
            this.setState({ 
                entity_type: {
                    impact_innovation: false,
                    business_innovation: true,
                    technology_innovation: false
                },
                user: {
                    ...this.state.user,
                    entity_Type: "Business Innovation"
                }
            });
        }

        if (category == "technology_innovation") {
            this.setState({ 
                entity_type: {
                    impact_innovation: false,
                    business_innovation: false,
                    technology_innovation: true
                },
                user: {
                    ...this.state.user,
                    entity_Type: "Technology Innovation"
                }
            });
        }
    };
    
    handleNavigation = async (type) => {
        var {user, entity_type} = this.state;

        await localStorage.setItem('user', JSON.stringify(user));
        await localStorage.setItem('entity_type', JSON.stringify(entity_type));

        if (this.state.entity_type.impact_innovation == true) {
            history.push('/quickstart/impact-focus');
        }
        else if (this.state.entity_type.business_innovation == true) {
            history.push('/quickstart/products');
        }
        else {
            history.push('/quickstart/funding-timeline');
        }
    }

    render() {     
        const { loading } = this.state;
        const { whitelabel } = this.props;
        const progressBarColor = whitelabel ? whitelabel.accent_Color : "#29a";
        const now = 20;
        const progressInstance = <ProgressBar now={now} label={`${now}%`} />;
        const impactLabel = <div class="entityLabel">
                                <strong>Impact Innovation</strong> - Choose this option if you’re a social impact-focused organization. This is the best route for nonprofits and public benefit corporations.
                            </div>
        const businessLabel =   <div class="entityLabel">
                                    <strong>Business Innovation</strong> - Choose this option if you’re a startup or small business doing innovative work with existing technology.
                                </div>
        const techLabel =   <div class="entityLabel">
                                    <strong>Technology Innovation</strong> - Choose this option if you’re developing new technology. This is the best route for science-based technology companies doing research and development.
                                </div>

        return (
            <div>
                <style>{"\
                    .progress-bar{\
                    background:" + progressBarColor + ";\
                    }\
                    .#NSF_Category_Selector .MuiSvgIcon-root{\
                        fill:" + progressBarColor + ";\
                        }\
                "}</style>
                {loading && <Loader />}
                <div>{progressInstance}</div>
                <div id="fundingSlider1">
                    <h1 id="fundingSliderHeader" style={{margin: 0, marginTop:-50}}>
                        What are you working on?
                    </h1>
                    <h3 id="checkAllH3">
                        Grants are great for funding specific innovations, whether they’re social or technological, for-profit or nonprofit. These questions will assess your eligibility for different types of grants. If you get to the end and don’t feel like it’s a good match, you’re welcome to restart the process and try different answers.
                    </h3>
                    <br /><br />
                </div>
                <div id="NSF_Category_Selector">
                    <FormControl component="fieldset" className="">
                        <FormGroup>
                            <div id="focusNSFArea" className="row" style={{maxWidth:500}}>
                                <h3 id="checkAllH3">Choose the option that best describes your organization.</h3>
                                <hr />
                                {/* <div id="focusCheckLeft" className="col-md-6"> */}
                                    <FormControlLabel
                                        style={{marginBottom: 25}}
                                        control={<Checkbox 
                                                    checked={this.state.entity_type.impact_innovation == true ?? true} 
                                                    onChange={this.handleChange} 
                                                    name="impact_innovation"
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label={impactLabel}
                                    />
                                    <FormControlLabel
                                        style={{marginBottom: 25}}
                                        control={<Checkbox 
                                                    checked={this.state.entity_type.business_innovation == true ?? true} 
                                                    onChange={this.handleChange} 
                                                    name="business_innovation" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label={businessLabel}
                                    />
                                    <FormControlLabel
                                        style={{marginBottom: 25}}
                                        control={<Checkbox 
                                                    checked={this.state.entity_type.technology_innovation == true ?? true} 
                                                    onChange={this.handleChange} 
                                                    name="technology_innovation" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label={techLabel}
                                    />
                                <div id="NSFFocusContinueButton" className="continueButton">
                                    <button 
                                        className="signUpContinue"
                                        onClick={() => this.handleNavigation('next')}
                                        style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                                    >
                                        Continue  →
                                    </button>
                                </div>
                            </div>
                        </FormGroup>
                    </FormControl>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering, nav } = state.registration;
    const { whitelabel } = state.setWhitelabel;
    const { registrationAlert } = state;
    
    return {
        registering, 
        nav, 
        registrationAlert,
        whitelabel,
    };
}

const connectedEntityType = withRouter(connect(mapStateToProps)(EntityType));
export { connectedEntityType as EntityType };