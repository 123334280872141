import { resourcesConstants } from '../_constants';
import { alertActions } from '.';

export const resourcesActions = {
    setExpertsAdvisorsExpanded,
    setGrantsExpanded
};

function setExpertsAdvisorsExpanded() {
    return dispatch => {
        dispatch(request(),
        dispatch(success()),
        );
    };

    function request() { return { type: resourcesConstants.SETEXPERTSADVISORSEXPANDED_REQUEST } }
    function success() { return { type: resourcesConstants.SETEXPERTSADVISORSEXPANDED_SUCCESS } }
}

function setGrantsExpanded() {
    return dispatch => {
        // dispatch(request(),
        dispatch(success())
    };

    // function request() { return { type: resourcesConstants.SETGRANTSSEXPANDED_REQUEST } }
    function success() { return { type: resourcesConstants.SETGRANTSEXPANDED_SUCCESS } }
}