import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { quickstartService, stripeService } from '../../../_services';
import { Loader } from '../../Shared/Loader';

class QuickstartProgram extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            programs: null,
            loading: true,
        }
    }

    componentDidMount = async () => {
        let user = JSON.parse(window.localStorage.getItem('user'));
        let programs = await quickstartService.getProgramInfo(user.id);
        console.log(programs);
        this.setState({
            programs
        });
        setTimeout(() => {
            this.setState({
                loading: false,
            })
        }, 750);
    }

    goToGW() {
        window.open('/checkout/grantwriter', '_blank');
    }

    goToRS() {
        window.open('/checkout/registration', '_blank');
    }

    goToConsulting() {
        window.open('https://calendly.com/sedaleturbovsky/office-hours', '_blank');
    }

    render() {
        console.log("Program Page:" + this.state);
        const { programs, loading } = this.state;
        const { whitelabel } = this.props;
        let displayPrograms = null;

        return (
            <div id="diyPage">
                {loading && <Loader gatheringResources={true}/>}
                <div className="row" id="grantResourcesRow">
                    <div className="col-md-6">
                        <img id="grantHeroIMG" src="https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/opengrants_funding_resource_guides.png" />
                    </div>
                    <div className="col-md-6" id="getHelpChoosingDiv">
                        <h1>Ready to get started on your application?</h1>
                        <br />
                        <p>Based on your selections, we've compiled the resources you need to understand the grant application process for the various programs you'll qualify for.</p>
                        {programs && programs[0] && !programs[0].agency_Label.includes("Impact Innovation:") &&
                            programs.map((program) =>     
                                <a href={program.download_Link} id="grantResourceGuide" target="blank"><strong>{program.agency_Label}</strong><br />-----<br />Grant Application Guide &rarr;</a>
                            )
                        }
                        {programs && programs[0] && programs[0].agency_Label.includes("Impact Innovation:") &&
                            <a href="https://opengrants-guides.s3-us-west-1.amazonaws.com/OpenGrants_Guide_for_Nonprofits.pdf" id="grantResourceGuide" target="blank"><strong>Impact Innovation</strong><br />-----<br />Grant Application Guide &rarr;</a>
                        }
                        {programs && !programs[0] &&
                            <a href="https://opengrants-guides.s3-us-west-1.amazonaws.com/OpenGrants_Guide_for_Businesses.pdf" id="grantResourceGuide" target="blank"><strong>Business Innovation</strong><br />-----<br />Grant Application Guide &rarr;</a>
                        }
                    </div>
                </div>
                <div style={{marginTop: 100}}>
                    <div class="row" id="DIYaddOns">
                        <div className="col-md-6">
                            <div id="maximizeAwardDiv">
                                <h1 id="stillNeedHelpH1">Need professional help?</h1>
                                <hr align="left" style={{width: 100, marginTop: 35, marginBottom: -15}} />
                                <p id="didYouKnow">
                                    <strong>Hire a Grant Writer - $99 deposit</strong>
                                    <br /><br />
                                    Connect with our team of grant writers and industry experts and give your application a boost.</p>
                                <input 
                                    type="button" 
                                    id="getGrantWriterButton" 
                                    value="Get started &rarr;" 
                                    onClick={this.goToGW} 
                                    style={{background: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div id="maximizeAwardDiv">
                                <h1 id="stillNeedHelpH1">Add On Services</h1>
                                <hr align="left" style={{width: 100, marginTop: 35, marginBottom: -15}} />
                                <p id="didYouKnow">
                                    <strong>Federal Registration Service - $250/year</strong>
                                    <br /><br />
                                    Automate your required DUNS, SAM, Grants.Gov and all other federal registrations as needed.</p>
                                <input 
                                    type="button" 
                                    id="getGrantWriterButton" 
                                    value="Purchase &rarr;" 
                                    onClick={this.goToRS} 
                                    style={{background: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <hr style={{marginTop: 100}}/> */}
                    {whitelabel && whitelabel.organization_Name == 'portal.opengrants.io' && 
                        <div id="diyResourcesConsulting">
                            <div id="maximizeAwardDiv" style={{maxWidth: 750}}>
                                    <h1 id="stillNeedHelpH1">
                                        Looking for training and support? 
                                    </h1>
                                    <p id="didYouKnow" style={{marginTop: "5%"}}>
                                    We host free office hours for CEOs and founders working on grant applications. These sessions are limited to 10 people and cover everything from crafting compelling narratives to designing budgets.
                                    </p>
                                    <input type="button" id="getGrantWriterButton" value="Sign up &rarr;" onClick={this.goToConsulting} />
                            </div>
                        </div>
                    }
                    {whitelabel && whitelabel.organization_Name != 'portal.opengrants.io' && 
                        <div style={{padding: "5%"}}></div>
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { whitelabel } = state.setWhitelabel;
    
    return {
        whitelabel,
    };
  }
  
  const connectedQuickstartProgram = withRouter(connect(mapStateToProps)(QuickstartProgram));
  export { connectedQuickstartProgram as QuickstartProgram };