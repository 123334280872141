import { dashboardConstants } from '../_constants';
import { dashboardService } from '../_services';

export const dashboardActions = {
    loadDashboard,
};

function loadDashboard(user) {
    return dispatch => {
        dispatch(request(user));
        dashboardService.loadDashboard(user)
            .then(
                stats => dispatch(success(stats)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: dashboardConstants.LOADDASHBOARD_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.LOADDASHBOARD_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.LOADDASHBOARD_FAILURE, error } }
}