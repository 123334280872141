let whitelabel_Organization = "";
const URL = window.location.hostname;
//  const URL = "portal.opengrants.io";

if (URL.includes("localhost")) {
    whitelabel_Organization = "portal.prospectsv.org"
}
else if (URL.includes("cmc.opengrants.io")) {
    whitelabel_Organization = "grants.californiamobilitycenter.org"
}
else {
    whitelabel_Organization = URL;
}

export { whitelabel_Organization };