import { authenticateConstants, stripeConstants } from '../_constants';
import { authenticateService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';
import { setPartnerInfo } from '../_pages/Shared/Partners';
import { Segment } from '../_pages/Shared/Segment';
//import { useHistory } from "react-router-dom";

export const authenticateActions = {
    login,
    logout,
    setUser,
    sendConfirmationEmail,
    forgotPassword,
    resetPassword
};

function login(username, password, listing_Id) {
    return async dispatch => {
        dispatch(request({ username }));
        await authenticateService.login(username, password)
            .then(
                user => { 
                    Segment.login(user);
                    Segment.track(user, 'Login');

                    if (user.partner !== null)
                    {
                        setPartnerInfo(user.partner)
                        localStorage.setItem('partner', user.partner);
                    }

                    if (!user.partnerClient) {
                        if (listing_Id)
                        {
                            history.push(listing_Id);
                        }
                        else
                        {
                            if (!user.linked_Listing_Id)
                            {
                                history.push('/dashboard');
                                window.location.reload();
                            }
                            else {
                                history.push('/grants/' + user.linked_Listing_Id);
                            }
                        }
                    }

                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.loginError("There was an error processing your request."));
                }
            );
    };

    function request(user) { return { type: authenticateConstants.SETUSER_REQUEST, user } }
    function success(user) { return { type: authenticateConstants.SETUSER_SUCCESS, user } }
    function failure(error) { return { type: authenticateConstants.SETUSER_FAILURE, error } }
    // function paywallAlert() {return {type: stripeConstants.PAYWALL_ALERT}}
}

function logout() {
    authenticateService.logout();
    localStorage.clear();
    return { type: authenticateConstants.LOGOUT };
}

function sendConfirmationEmail(email, server) {
    return dispatch => {
        authenticateService.sendConfirmationEmail(email, server)
            .then(
                (email) => {
                    dispatch(emailSent(email));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function emailSent() {return {type: authenticateConstants.CONFIRMEMAIL_SENTSUCCESS, email}}
    function failure(error) { return { type: authenticateConstants.CONFIRMEMAIL_SENTFAILURE, error } }
}

function forgotPassword(email, server) {
    return dispatch => {
        dispatch(request(email));
        authenticateService.forgotPassword(email, server)
            .then(
                () => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: authenticateConstants.FORGOTPASSWORD_REQUEST, user } }
    function success(user) { return { type: authenticateConstants.FORGOTPASSWORD_SUCCESS, user } }
    function failure(error) { return { type: authenticateConstants.FORGOTPASSWORD_FAILURE, error } }
}

function resetPassword(resetPassword) {
    console.log("RESET BRO: " + JSON.stringify(resetPassword));
    return dispatch => {
        dispatch(request());
        authenticateService.resetPassword(resetPassword)
            .then(
                () => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: authenticateConstants.RESETPASSWORD_REQUEST } }
    function success() { return { type: authenticateConstants.RESETPASSWORD_SUCCESS } }
    function failure(error) { return { type: authenticateConstants.RESETPASSWORD_FAILURE, error } }
}

function setUser(user) {
    return dispatch => {
        dispatch(request(user));
        dispatch(success(user));
    };

    function request(user) { return { type: authenticateConstants.SETUSER_REQUEST, user } }
    function success(user) { return { type: authenticateConstants.SETUSER_SUCCESS, user } }
}