import { layoutConstants } from '../_constants';
import { layoutService } from '../_services';
import { alertActions } from './';

export const layoutActions = {
    subscribe,
    getStats
};

function subscribe(email) {
    return dispatch => {
        dispatch(request(email));
        layoutService.subscribe(email)
            .then(
                email => {
                    dispatch(success());
                    dispatch(alertActions.emailSuccess('Signup successful'));
                    setTimeout(() => {
                        dispatch(alertActions.emailClear());
                    }, 3000);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.emailError('Email address already subscribed.'));
                    setTimeout(() => {
                        dispatch(alertActions.emailClear());
                    }, 3000);
                }
            );
    };

    function request(email) { return { type: layoutConstants.SUBSCRIBE_REQUEST, email } }
    function success(email) { return { type: layoutConstants.SUBSCRIBE_SUCCESS, email } }
    function failure(error) { return { type: layoutConstants.SUBSCRIBE_FAILURE, error } }
}

function getStats() {
    return dispatch => {
        dispatch(request());
        layoutService.getStats()
        .then(
            layout => dispatch(success(layout)),
            error => dispatch(failure(error))
        )
    };

    function request() { return { type: layoutConstants.GETSTATS_REQUEST } }
    function success(layout) { return { type: layoutConstants.GETSTATS_SUCCESS, layout } }
    function failure(error) { return { type: layoutConstants.GETSTATS_FAILURE, error } }
}