import React, { useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '../../../Shared/Loader';
import { history } from '../../../../_helpers';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ProgressBar from 'react-bootstrap/ProgressBar';

class CECFocusAreas extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            focus_areas: JSON.parse(localStorage.getItem('focus_areas')),
            loading: true,
        }
    }

    componentDidMount() {
        this.setState({
            focus_areas: {
                ...this.state.focus_areas,
                clean_energy: false,
                energy_efficiency: false,
                battery_tech: false,
            }
        })
        
        document.body.scrollTop = 0;
    
        setTimeout(() => {
            this.setState({
            loading: false
            });
        }, 500);
    }

    useStyles = makeStyles((theme) => ({
            root: {
              display: 'flex',
            },
            formControl: {
              margin: theme.spacing(3),
            },
    }));
          
    CheckboxesGroup() {
        const classes = this.useStyles();
    };
          
    handleChange = (event) => {
        const category = [event.target.name];

        this.setState({ 
            ...this.state, 
            [event.target.name]: event.target.checked,
            focus_areas: {
                ...this.state.focus_areas,
                [category]: event.target.checked 
            }
        });
    };
    
    handleNavigation = async () => {
        var {user, focus_areas} = this.state;
        await localStorage.setItem('user', JSON.stringify(user));
        await localStorage.setItem('focus_areas', JSON.stringify(focus_areas));

        history.push('/quickstart/finished');
    }

    render() {     
        const { loading } = this.state;
        const { whitelabel } = this.props;
        const progressBarColor = whitelabel ? whitelabel.accent_Color : "#29a";
        const now = 90;
        const progressInstance = <ProgressBar now={now} label={`${now}%`} />;

        return (
            <div>
                <style>{"\
                    .progress-bar{\
                    background:" + progressBarColor + ";\
                    }\
                    .#NSF_Category_Selector .MuiSvgIcon-root{\
                        fill:" + progressBarColor + ";\
                    }\
                "}</style>
                {loading && <Loader />}
                <div>{progressInstance}</div>
                <div id="fundingSlider1">
                    <h1 id="fundingSliderHeader">
                        Do you focus on any of the following areas?
                    </h1>
                </div>
                <div id="NSF_Category_Selector">
                    <FormControl component="fieldset" className="">
                        <FormGroup>
                            <div id="focusNSFArea" className="row">
                                <h3 id="checkAllH3">Check all that apply</h3>
                                <hr />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.clean_energy} 
                                                    onChange={this.handleChange} 
                                                    name="clean_energy" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Clean Energy"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.energy_efficiency} 
                                                    onChange={this.handleChange} 
                                                    name="energy_efficiency" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Energy Efficiency"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.battery_tech} 
                                                    onChange={this.handleChange} 
                                                    name="battery_tech" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Battery Technology"
                                    />
                                <div id="NSFFocusContinueButton" className="continueButton">
                                    <button 
                                        className="signUpContinue"
                                        onClick={() => this.handleNavigation('next')}
                                        style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                                    >
                                        Continue  →
                                    </button>
                                </div>
                            </div>
                        </FormGroup>
                    </FormControl>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering, nav } = state.registration;
    const { whitelabel } = state.setWhitelabel;
    const { registrationAlert } = state;
    
    return {
        registering, 
        nav, 
        registrationAlert,
        whitelabel,
    };
}

const connectedCECFocusAreas = withRouter(connect(mapStateToProps)(CECFocusAreas));
export { connectedCECFocusAreas as CECFocusAreas };