import React, { useLayoutEffect, useState }  from 'react';
import { connect } from 'react-redux';
import { searchService, algoliaService } from '../../_services';
import { favoritesActions } from '../../_actions';
import { Result } from './Result';
import { HitResult } from './HitResult';
import algoliasearch from 'algoliasearch';
import DateRangePicker from "./DateRangePicker";
import CustomCurrentRefinements from "./CurrentRefinements";
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import { unixDate } from '../SearchListings/Result';
import ReactTooltip from "react-tooltip";
import { PowerSearchFilters } from './PowerSearchFilters';
import { InstantSearch, 
            SearchBox, 
            Hits,
            Pagination, 
            VoiceSearch,
            Stats,
            connectHits,
            connectStateResults,
            CurrentRefinements,
            RefinementList,
            Panel,
            RangeInput,
            HitsPerPage
        } from 'react-instantsearch-dom';
       
import '../../Styles/Algolia.css';
import '../../Styles/SearchListings.css';
import '../../Styles/Pagination.css';
import { setWhitelabel } from '../../_reducers/whitelabel.reducer';

const searchClient = algoliasearch('MWF97BUAPS', '943f0970ab9c9143012e9172983aacdc');

/////////////////////////////////////////
/// SEARCH BAR CLASS
/// Handle all Algolia Functionality
/////////////////////////////////////////
class PowerSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total_Grants: null,
      favorites: localStorage.getItem('favorite'),
      searchGroup: {
        search: "",
        user: this.props.user,
        categories: [],
        agencies: [],
        page: 1,
        totalResults: 0,
      },
      loading: true,
      currentSearchState: {},
      savedQueryTitle: '',
      showQueryDiv: false,
      mobilePowerSearch: false,
      width: window.innerWidth, 
    };

    this.handleChange = this.handleChange.bind(this);
    this.Result = this.Result.bind(this);
    this.closeQueryWindow = this.closeQueryWindow.bind(this);
  }

  async componentDidMount() {
    const total_Grants = await searchService.getTotalListings();
    const saved_Searches = await algoliaService.getSavedSearches(this.props.user);

    this.setState({
      total_Grants: total_Grants,
      saved_Searches: saved_Searches,
      loading: false,
    });

    window.addEventListener('resize', this.updateDimensions);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  async toggle() {
    this.setState({
      mobilePowerSearch: !this.state.mobilePowerSearch
    });
  }

  async goToUpgrade() {
    window.location.href="/settings?val=billing";
  }

  async saveListing(listing_Id, user, add) {
    await this.props.dispatch(favoritesActions.saveListing(listing_Id, user));
    await this.props.dispatch(favoritesActions.getFavorites(user));
    setTimeout(1000);
    
    this.setState(prevState => ({
        searchGroup: {
            ...prevState.searchGroup,
        },
        favorites: JSON.parse(localStorage.getItem('favorite')),
        favoritesCount: add === true ? (prevState.favoritesCount - 1) : (prevState.favoritesCount + 1)
    }), () => {});
  };

  async closeQueryWindow() {
    this.setState({
      showQueryDiv: false
    });
  }

  async updateCurrentSearch(searchState) {
    this.setState({
      currentSearchState: searchState
    });
  };

  async loadSavedSearch(savedSearch) {
    sessionStorage.removeItem('from');
    sessionStorage.removeItem('to');

    this.setState({
      currentSearchState: JSON.parse(savedSearch.value)
    });

    const json_Dates = JSON.parse(savedSearch.value).dateRange;
    if (json_Dates) {
      var json_from_date = json_Dates.from;
      var json_to_date = json_Dates.to;
  
      sessionStorage.setItem('from', json_from_date);
      sessionStorage.setItem('from_time', json_from_date / 1000);
      sessionStorage.setItem('to', json_to_date);
      sessionStorage.setItem('to_time', json_to_date / 1000);
    }
  };

  async loadQuerySaver(query, user_Id) {
    this.setState({
      showQueryDiv: true,
      newQuery: {
        query: query,
        user_Id: user_Id
      }
    });
  }

  async saveQuery() {
    this.setState({
      showQueryDiv: false
    });

    const title = this.state.savedQueryTitle;
    const escapeQuery = JSON.stringify(this.state.newQuery.query);
    const user_Id = this.state.newQuery.user_Id;

    await algoliaService.saveQuery(escapeQuery, title, user_Id);
    window.location.reload();
  };

  Result(listing) {
    return <Result listing={listing} />
  };

  exportSearchResults = async (query) => {
    var blob = await algoliaService.exportSearchResults(query);
    const file = window.URL.createObjectURL(blob);

    var aTag = document.createElement("a");
    aTag.download = "SearchResults.csv"
    aTag.href = file;
    aTag.target = "_blank";

    aTag.click();
  }

  render() {
    const { width, total_Grants, currentSearchState, savedQueryTitle, showQueryDiv, mobilePowerSearch } = this.state;
    const saved_Search = JSON.parse(localStorage.getItem('savedQueries'));
    const { user, whitelabel } = this.props;
    const userRole = user.userRoles;
    const saved_Search_Select = [];
    
    saved_Search && saved_Search.forEach(function(search) {
      saved_Search_Select.push({ label: search.query_Title, value: search.query_Body })
    });

    const Results = connectStateResults(
      ({ searchState, searchResults, children }) =>
        searchResults && searchResults.nbHits !== 0 ? ( children) : (
          this.state.loading == false &&
          <div id="noSearchResults">
            <h4>No results found for <strong>{searchState.query}</strong>.</h4>
            <hr align="left" style={{width: "10%"}} />
            {whitelabel && whitelabel.platform_URL != "portal.opengrants.io" &&
              <p>Please enter another query for a program, category, location or agency.</p>
            }
            <br /><br />
            {whitelabel && whitelabel.platform_URL == "portal.opengrants.io" &&
              <div class="row">
                <div class="col-md-3">
                  <img id="grantHeroIMG" src="https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/grant_writing_superheroes.png" style={{width: 200}}/>
                </div>
                <div class="col-md-9">
                <p style={{fontWeight: 600, marginLeft: "5%", marginTop: -15}}>Didn’t find what you’re looking for?</p>
                <ol id="noSearchHits">
                  <li>
                    Grants can be tricky. Sometimes they’re listed with names that you wouldn’t expect. Try searching again with synonyms or related words.
                  </li>
                  <li>
                    Some grants may simply be out of season. Many grant programs are released on a quarterly, semi-annual or annual basis. If you can’t find anything right now, 
                    <a href="/settings?val=organization" style={{fontWeight: 600}}> click here </a> 
                    to create an organization profile so we can notify you when new funding is available.
                  </li>
                  <li>
                    If you’d rather bypass the search process and hire an expert instead, 
                    <a href="/apply" style={{fontWeight: 600}}> click here </a> 
                    to submit a (refundable) deposit and meet with a grant writer.
                  </li>
                  <li>
                    Do you want to add a grant listing to our platform? 
                    <a href="https://airtable.com/shr1UV3Bit2ZQURnZ" target="_blank" style={{fontWeight: 600}}> Fill out this form </a>  
                    so we can start tracking it.
                  </li>
                </ol>
                <br />
                </div>
              </div>
            }
          </div>
        )
    );

    return (
      <div id="searchListingsSearchBar" style={{marginTop: "5%"}}>
        {/* <link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-beta/css/bootstrap.min.css" rel="stylesheet" /> */}
        <h1 id="searchHeader">National Grants Database</h1>
        <div class="row">
          <p id="numRecords">Total No. Grants: 
            <span style={{color: "#555", fontWeight: 600}}> {total_Grants && total_Grants.toLocaleString()}</span>
          </p>
          <p id="numRecords">Average Award: 
            <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="verifiedAward"><polyline points="20 6 9 17 4 12"></polyline></svg>
            <span style={{color: "#555", fontWeight: 600}}> $1.4m</span>
          </p>
        </div>
        <InstantSearch
          searchClient={searchClient} 
          indexName="OpenGrants_Dataset"
          searchState={this.state.currentSearchState}
          onSearchStateChange={searchState => {
            this.updateCurrentSearch(searchState);
          }}
        >
          <div 
            id="searchRowPrimary"
            className="row"
          >
          <div
            // id="powerSearchDesktop"
            className="col-lg-4 col-lg-push-8" 
            data-tip={userRole != "Client_Premium" ? "Upgrade to access the Power Search feature." : null}
            onClick={userRole != "Client_Premium" ? this.goToUpgrade : null}
          >
            {width < 1199 &&
              <div 
                id="powerSearchMobileToggle"
                onClick={() => this.toggle()}
                style={{marginBottom: mobilePowerSearch ? -1 : 15, width: "100%", marginLeft: 0}}
              >
                <h4 id="mobileSearchFilters">
                  ADVANCED FILTERS
                </h4>
                {mobilePowerSearch && <h4 style={{display: "inline", float: "right", color: "#666"}}>&#9650;</h4>}
                {!mobilePowerSearch && <h4 style={{display: "inline", float: "right", color: "#666"}}>&#9660;</h4>}
              </div>
            }
            {(width > 1199 || mobilePowerSearch) &&
            <div 
              id={userRole == "Client_Premium" ? "filterBox" : "filterBox_Disabled"}
              style={{padding: 15, width: "105%"}}
            >
              <h1 id="searchHeader">Power Search</h1>
              <hr />
              <Button onClick={() => {this.loadQuerySaver(currentSearchState, user.id)}} style={{width: "100%", marginBottom: 5}}>
                  <span style={{marginRight: 5}}><strong>+</strong></span>Save Current Query
              </Button>
              <Button onClick={() => this.exportSearchResults(currentSearchState)} style={{width: "100%"}}>
                  <span style={{marginRight: 5}}></span>Export Results
              </Button>
              <br /><br />
              <Panel header="Saved Searches">
                <Select
                  id="saved_Searches_Select"
                  name="saved_Searches"
                  isClearable={false}
                  isSearchable={true}
                  defaultValue={{label: "Your Saved Search Queries", value: ""}}
                  onChange={(search) =>this.loadSavedSearch(search)}
                  options={saved_Search_Select}
                />
              </Panel>
              <br /><br />
              <Panel header="Applied Filters">
                <CustomCurrentRefinements />
              </Panel>
              <br /><br />
              {whitelabel && whitelabel.platform_URL == "grants.californiamobilitycenter.org" &&
                <div>
                  <Panel header="California Mobility Center">
                    <p>
                    Turn the CMC filter ON on to search mobility-related grants only. Turn it off to search ALL U.S. grant funding.
                    </p>
                    <RefinementList 
                      attribute="whitelabel_Tags" 
                      defaultRefinement="CMC"
                      searchable={false}
                      limit={5}
                      showMore={false}
                      showMoreLimit={30}
                      translations={{
                        // showMore(expanded) {
                        //   return expanded ? 'Show less' : 'Show more';
                        // },
                        noResults: 'No results',
                        submitTitle: 'Submit your search query.',
                        resetTitle: 'Clear your search query.',
                        placeholder: 'Search Tags...',
                      }}
                    />
                  </Panel>
                  <br /><br />
                </div>
              }
              <Panel header="Funding Amount ($)">
                <RangeInput min={0} max={100000000000} attribute="funding_Amount" />
              </Panel>
              <br /><br />
              <Panel header="Application Due Dates">
                <DateRangePicker
                  attributes={
                    {
                      from: "application_Due_Date",
                      to: "application_Due_Date"
                    }
                  }
                  restrictResultsToStrictRange
                />
              </Panel>
              <br /><br />
              <Panel header="Agencies">
                <RefinementList 
                  attribute="agency" 
                  searchable={true}
                  limit={5}
                  showMore={true}
                  showMoreLimit={30}
                  translations={{
                    showMore(expanded) {
                      return expanded ? 'Show less' : 'Show more';
                    },
                    noResults: 'No results',
                    submitTitle: 'Submit your search query.',
                    resetTitle: 'Clear your search query.',
                    placeholder: 'Search Agencies...',
                  }}
                />
              </Panel>
              <br /><br />
              {/* <Panel header="Tags / Keywords">
                <RefinementList 
                  attribute="tags" 
                  searchable={true}
                  limit={5}
                  showMore={true}
                  showMoreLimit={30}
                  translations={{
                    showMore(expanded) {
                      return expanded ? 'Show less' : 'Show more';
                    },
                    noResults: 'No results',
                    submitTitle: 'Submit your search query.',
                    resetTitle: 'Clear your search query.',
                    placeholder: 'Search Tags...',
                  }}
                />
              </Panel>
              <br /><br /> */}
              <Panel header="Countries">
                <RefinementList 
                  attribute="locations.country" 
                  searchable={true}
                  limit={3}
                  showMore={true}
                  showMoreLimit={30}
                  style={{color: "#000!important"}}
                  translations={{
                    showMore(expanded) {
                      return expanded ? 'Show less' : 'Show more';
                    },
                    noResults: 'No results',
                    submitTitle: 'Submit your search query.',
                    resetTitle: 'Clear your search query.',
                    placeholder: 'Search Countries...',
                  }}
                />
              </Panel>
              <br /><br />
              <Panel header="US States">
                <RefinementList 
                  attribute="locations.uS_State" 
                  searchable={true}
                  limit={3}
                  showMore={true}
                  showMoreLimit={30}
                  translations={{
                    showMore(expanded) {
                      return expanded ? 'Show less' : 'Show more';
                    },
                    noResults: 'No results',
                    submitTitle: 'Submit your search query.',
                    resetTitle: 'Clear your search query.',
                    placeholder: 'Search States...',
                  }}
                />
              </Panel>
              <br /><br />
              <Panel header="US Cities">
                <RefinementList 
                  attribute="locations.uS_City" 
                  searchable={true}
                  limit={3}
                  showMore={true}
                  showMoreLimit={30}
                  translations={{
                    showMore(expanded) {
                      return expanded ? 'Show less' : 'Show more';
                    },
                    noResults: 'No results',
                    submitTitle: 'Submit your search query.',
                    resetTitle: 'Clear your search query.',
                    placeholder: 'Search Cities...',
                  }}
                />
              </Panel>
              <br />
            </div>
            }
          </div>
          <div className="col-lg-8 col-lg-pull-4">
            <div id="algoliaSearchBox" className="row">
              <SearchBox 
                autoFocus={true}
                searchAsYouType={true}
                showLoadingIndicator={true}
                translations={{ placeholder: 'Search by keyword, category, agency or location...' }}
              />
              <VoiceSearch />
            </div>
            <Stats />
            <Results>
              <Hits 
                hitComponent={this.Result}
                style={{width:"100%!important"}} 
                id="searchHits"
              />
            </Results>
            <Pagination
              // Optional parameters
              defaultRefinement={1}
              showFirst={true}
              showPrevious={true}
              showNext={true}
              showLast={true}
              padding={5}
            />
          </div>
        </div>
        </InstantSearch>
        {showQueryDiv &&
          <div id="showQueryBackground">
            <div id="showQueryDiv">
              <p onClick={this.closeQueryWindow} style={{float: "right", paddingRight: 10, paddingTop: 10, hover: "pointer"}}><strong>X</strong></p>
              <br />
              <h1>Custom Query Builder</h1>
              <hr />
              <TextField
                  required
                  id="saved-query-title"
                  label="SAVED QUERY TITLE"
                  name="savedQueryTitle"
                  value={savedQueryTitle}
                  onChange={this.handleChange}
                  style={{width:"100%", marginBottom:15}}
              />
              <Button onClick={() => {this.saveQuery()}}>
                Save Query
              </Button>
              <br /><br /><br />
              <hr />
              {/* {this.state.newQuery.query.query} */}
              <div id="currentQuery">
                {this.state.newQuery.query.query && 
                  <div>
                    <strong>Search Term: </strong>
                    {this.state.newQuery.query.query}
                  </div>
                }
                {this.state.newQuery.query.dateRange && 
                  <div>
                    <strong>Due After: </strong>
                    {unixDate((this.state.newQuery.query.dateRange.from / 1000))}
                    <br />
                    <strong>Due Before: </strong>
                    {unixDate((this.state.newQuery.query.dateRange.to / 1000))}
                    <br />
                  </div>
                }
                {this.state.newQuery.query.range && 
                 this.state.newQuery.query.range.funding_Amount && 
                  <div>
                    {this.state.newQuery.query.range.funding_Amount.min &&
                      <div>
                        <strong>Funding Amount (min): </strong>
                        ${JSON.stringify(this.state.newQuery.query.range.funding_Amount.min)}
                      </div>
                    }
                    {this.state.newQuery.query.range.funding_Amount.max &&
                      <div>
                        <strong>Funding Amount (max): </strong>
                        ${JSON.stringify(this.state.newQuery.query.range.funding_Amount.max)}
                      </div>
                    }
                  </div>
                }
                {this.state.newQuery.query.refinementList && 
                  <div>
                    {this.state.newQuery.query.refinementList.agency &&
                      <div>
                      <strong>Agencies: </strong>
                      {JSON.stringify(this.state.newQuery.query.refinementList.agency)}
                      </div>
                    }
                    {this.state.newQuery.query.refinementList.tags &&
                      <div>
                      <strong>Keywords / Tags: </strong>
                      {JSON.stringify(this.state.newQuery.query.refinementList.tags)}
                      </div>
                    }
                  </div>
                }
                <br />
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
    const { setUser, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        whitelabel,
    };
}

const connectedAlgoliaPage = connect(mapStateToProps)(PowerSearch);
export { connectedAlgoliaPage as PowerSearch };