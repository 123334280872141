import { authHeader } from '../_helpers';

export const matchesService = {
    getMatches,
    saveMatch,
    discardMatch
};

function getMatches(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/matches/get_user_matches', requestOptions).then(handleResponse);
}

function saveMatch(listing_id, user, match) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/matches/save_match?listing_id=' + listing_id + "&user_id="+ user.id + "&match=" + match, requestOptions).then(handleResponse);
}

function discardMatch(listing_id, user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/matches/discard_match?listing_id=' + listing_id + "&user_id="+ user.id, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok ) {
            if (response.status == "401") {
                window.location.href="/login";
            }
            
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}