import React from 'react';
import { connect } from 'react-redux';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import { Loader } from '../../Shared/Loader';
import { organizationService } from '../../../_services';
import '../../../Styles/Organization.css';
import '../../../Styles/SettingsStyles/Profile.css';
import { OrganizationList } from '.';

class OrganizationView extends React.Component {
    constructor(props) {
        super(props);

        let organization_id = window.location.pathname.split('/').pop();
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),

            organization_id: organization_id != "organizations" && organization_id != "settings" ? 
                organization_id : 
                this.props.organization_id ? 
                    this.props.organization_id : "",
            org: {
                organization: {}
            },

            loading: true
        }
    }

    componentDidMount() {
        this.getOrganization();
    }

    getOrganization = async() => {
        const {user, organization_id} = this.state;
        let results = {};
        let organizations = {};
        if (organization_id) {
            results = await organizationService.getOrganization(organization_id);
        } else {
            organizations = await organizationService.getAllOrganizations(user.id);
        }

        this.setState({
            ...results,
            organizations,
            loading: false
        });
    }

    editOrganization = () => {
        const {organization_id} = this.state;
        this.props.editOrganization(organization_id);
    }

    render() {
        console.log(this.state);
        const {user, organizations, org, loading, organization_id, accepted_profiles} = this.state;
        const organization = org.organization;
        let naicS_CodesTags = null;
        let industryTags = null;
        let socialImpactTags = null;
        let technologyTags = null;

        if (org.naicS_Codes)
        naicS_CodesTags = org.naicS_Codes.map((naicS_Code, idx) => {
            return (
                <div key={"naicS_Codes" + idx} className="tagList">
                    {naicS_Code}
                </div>
            )
        });

        if (org.industries)
        industryTags = org.industries.map((industry, idx) => {
            return (
                <div key={"industry" + idx} className="tagList">
                    {industry}
                </div>
            )
        });
        
        if (org.social_Impact_Categories)
        socialImpactTags = org.social_Impact_Categories.map((socialImpact, idx) => {
            return (
                <div key={"socialImpact" + idx} className="tagList">
                    {socialImpact}
                </div>
            )
        });
        
        if (org.technologies_Used)
        technologyTags = org.technologies_Used.map((technology, idx) => {
            return (
                <div key={"technology" + idx} className="tagList">
                    {technology}
                </div>
            )
        });

        var accepted_profile_list = null;
        if (accepted_profiles) {
            accepted_profile_list = accepted_profiles.map((profile) => {
                return(
                    <div key={profile.email} style={{display: "inline-block", marginBottom: 20, marginRight: 30}}>
                        {!profile.headshot && <div className="profile-circle" style={{position:"relative", height: "60px", width: "60px", lineHeight: "60px", fontSize: "30px", marginRight: 30, verticalAlign: "top"}}>{profile.surname[0]}</div>}
                        {profile.headshot && 
                            <img src={profile.headshot} style={{height: 60, width: 60, marginRight: 30, borderRadius: "50%", verticalAlign: "top"}} /> 
                        }
                        <a href={"/profiles/" + profile.username} target="_blank" className="linkListing" style={{display: "inline-block", color: "black"}}>
                            <h4 style={{margin: 0}}>{profile.name} {profile.surname}</h4>
                            <p>{profile.title}</p>
                        </a>
                    </div>
                )
            });
        }

        return (
            <div>
                {loading && <Loader />}
                {organizations && Object.keys(organizations).length > 0 &&
                    <div>
                        <OrganizationList 
                            organizations={organizations}
                        />
                    </div>
                }
                {organization_id && 
                    <div style={{margin:"0 15px 0 15px"}}>
                        <div id="mainSettings">
                            <div className="row" style={{display: "flex"}}>
                                <div className="col-lg-3 profile_list" style={{flexGrow: 1}}>
                                    <div style={{overflow: "auto"}}>
                                        {organization.logo_URL && <img src={organization.logo_URL} style={{height: "150px", width: "150px", borderRadius: "50%", margin: "15px"}} />}
                                        {!organization.logo_URL && <i className="material-icons" style={{float: "left", color: "black", fontSize: "150px", width: "150px", borderRadius: "50%"}}>business</i>}
                                        <h1>{organization.name}</h1>
                                    </div>
                                    <div style={{marginTop: 15}}>
                                        <p>{organization.address}</p>
                                        <p>{organization.website_URL}</p>
                                        <p>{organization.phone_Number}</p>
                                    </div>
                                </div>
                                <div className="col-lg-8 profile_list" style={{flexGrow: 1}}>
                                    <p>{organization.description}</p>
                                </div>
                            </div>
                            <div className="row profile_list">
                                <div className="col-md-6">
                                    <div className="settingsSelectOption">
                                        <h4>Registered in SAM</h4>
                                        <p>{organization.saM_Number ? "Yes" : "No"}</p>
                                    </div>
                                    <div className="settingsSelectOption">
                                        <h4>8A Business Development</h4>
                                        <p>{organization.businiess_8a_Development ? "Yes" : "No"}</p>
                                    </div>
                                    <div className="settingsSelectOption">
                                        <h4>Woman Owned Business</h4>
                                        <p>{organization.woman_Owned ? "Yes" : "No"}</p>
                                    </div>
                                    <div className="settingsSelectOption">
                                        <h4>Certified Veteran Owned Business</h4>
                                        <p>{organization.veteran_Owned ? "Yes" : "No"}</p>
                                    </div>
                                    <div className="settingsSelectOption">
                                        <h4>Located in a Hubzone</h4>
                                        <p>{organization.hubZone_Owned ? "Yes" : "No"}</p>
                                    </div>
                                    <div className="settingsSelectOption">
                                        <h4>Small Business</h4>
                                        <p>{organization.small_Business ? "Yes" : "No"}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div style={{paddingBottom: 20}}>
                                        <h4>DUNS Number</h4>
                                        <div>{ organization.dunS_Number }</div>
                                    </div>
                                    <div style={{paddingBottom: 20}}>
                                        <h4>NAICS Code(s)</h4>
                                        <div>{naicS_CodesTags}</div>
                                    </div>
                                    <div style={{paddingBottom: 20}}>
                                        <h4>Industry</h4>
                                        <div>{industryTags}</div>
                                    </div>
                                    <div style={{paddingBottom: 20}}>
                                        <h4>Technologies Used</h4>
                                        <div>{technologyTags}</div>
                                    </div>
                                    <div style={{paddingBottom: 20}}>
                                        <h4>Social Impact Categories</h4>
                                        <div>{socialImpactTags}</div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row profile_list">
                                <div style={{paddingBottom: 20}}>
                                    <h4>DUNS Number</h4>
                                    <div>{ organization.dunS_Number }</div>
                                </div>
                                <div style={{paddingBottom: 20}}>
                                    <h4>NAICS Code(s)</h4>
                                    <div>{naicS_CodesTags}</div>
                                </div>
                                <div style={{paddingBottom: 20}}>
                                    <h4>Industry</h4>
                                    <div>{industryTags}</div>
                                </div>
                                <div style={{paddingBottom: 20}}>
                                    <h4>Technologies Used</h4>
                                    <div>{technologyTags}</div>
                                </div>
                                <div style={{paddingBottom: 20}}>
                                    <h4>Social Impact Categories</h4>
                                    <div>{socialImpactTags}</div>
                                </div>
                            </div> */}
                            <div className="row profile_list">
                                <h1 id="dashHeader" style={{marginBottom: 25}}>Members</h1>
                                <div>
                                    {accepted_profile_list}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {organization_id && this.props.editOrganization && <div>
                    <div id="mainSettings">
                        <div className="row">
                            <div className="col-lg-6">
                                <div>
                                    <Button onClick={() => {window.location.href = "/settings?val=1"}}>Back</Button>
                                    <Button onClick={this.editOrganization}>Edit</Button>
                                </div>
                                <div className="text_section">
                                    <img src={organization.logo_URL} style={{maxHeight: "50px"}}></img>
                                </div>
                                <div className="text_section">
                                    <h2>Company Name</h2>
                                    <p>{organization.name}</p>
                                </div>
                                <div className="text_section">
                                    <h2>Website</h2>
                                    <p>{organization.website_URL}</p>
                                </div>
                                <div className="text_section">
                                    <h2>Company Description</h2>
                                    <p>{organization.description}</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="text_section">
                                    <h2>Company Address</h2>
                                    {organization.address}
                                </div>
                                <div className="text_section">
                                    <h2>Phone Number</h2>
                                    <p>{organization.phone_Number}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style={{marginTop: 75}}/>
                    <div id="mainSettings">
                        <div className="row" style={{marginTop: "5%"}}>
                            <h1 id="dashHeader">Advanced Settings</h1>
                            <div className="col-lg-6">
                                <div className="settingsSelectOption">
                                    <h2>Are You Registered in SAM?</h2>
                                    <p>{organization.saM_Number}</p>
                                </div>
                                <div className="settingsSelectOption">
                                    <h2>Are You a 8A Business Development?</h2>
                                    <p>{organization.businiess_8a_Development}</p>
                                </div>
                                <div className="settingsSelectOption">
                                    <h2>Are You a Woman Owned Business</h2>
                                    <p>{organization.woman_Owned}</p>
                                </div>
                                <div className="settingsSelectOption">
                                    <h2>Are You a Certified Veteran Owned Business</h2>
                                    <p>{organization.veteran_Owned}</p>
                                </div>
                                <div className="settingsSelectOption">
                                    <h2>Are You Located in a Hubzone?</h2>
                                    <p>{organization.hubZone_Owned}</p>
                                </div>
                                <div className="settingsSelectOption">
                                    <h2>Are You a Small Business?</h2>
                                    <p>{organization.small_Business}</p>
                                </div>
                            </div>
                            <div id="advancedSettingsTextAreas" className="col-lg-6">
                                <div style={{paddingBottom: 20}}>
                                    <h2>DUNS Number</h2>
                                    <p>{ organization.dunS_Number }</p>
                                </div>
                                <div style={{paddingBottom: 20}}>
                                    <h2>NAICS Code(s)</h2>
                                    <p>{naicS_CodesTags}</p>
                                </div>
                                <div style={{paddingBottom: 20}}>
                                    <h2>Industry</h2>
                                    <div>{industryTags}</div>
                                </div>
                                <div style={{paddingBottom: 20}}>
                                    <h2>Technologies Used</h2>
                                    <div>{technologyTags}</div>
                                </div>
                                <div style={{paddingBottom: 20}}>
                                    <h2>Social Impact Categories</h2>
                                    <div>{socialImpactTags}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style={{marginTop: 75}}/>
                    <div id="mainSettings">
                        <div className="row" style={{marginTop: "5%"}}>
                            <h1 id="dashHeader" style={{marginBottom: 25}}>Members</h1>
                            <div>
                                {accepted_profile_list}
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization } = state;
    const { user } = setUser;
    return {
        user,
        organization
    };
}

const connectedOrganizationView = connect(mapStateToProps)(OrganizationView);
export { connectedOrganizationView as OrganizationView };