import React from 'react';
import { connect } from 'react-redux';
import '../../../Styles/SettingsStyles/Settings.css';
import '../../../Styles/SettingsStyles/Focus.css';

class FocusSelected extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    clearAll = () => {
        this.props.clearAll();
    }

    render() {
        const { user, selected } = this.props;
        return (
            <div id="preferenceBody_right">
                <h3>YOUR CATEGORIES</h3>
                <div id="preference_yourCat"></div>
                <div id="clearAll_categories">
                    {selected && selected.map((category) =>
                        <div className="categoryOption">
                            <input className="selectedCategory" value={category} selected="true" type="button" key={"userCategory_" + category}/>
                        </div>
                    )}
                    <input id="preference_clearAll" type="button" value="Clear All" onClick={this.clearAll} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, categoryList } = state;
    const { user } = setUser;
    return {
        categoryList,
        user
    };
}

const connectedHomePage = connect(mapStateToProps)(FocusSelected);
export { connectedHomePage as FocusSelected };