import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Footer } from '../Shared/Footer';
import { authenticateActions } from '../../_actions';
// import {Helmet} from "react-helmet";
import '../../Styles/MainLanding.css'
import { Registration } from '../Account/RegisterPage'
import '../../Styles/Registration.css';
import '../../Styles/StripeProduct.css';
import {Registration_Products} from '../../_pages/Account/RegisterPage'
import {Loader} from '../Shared/Loader';

class Landing extends Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.dispatch(authenticateActions.logout());
    }

    scrollToView = (id) => {
        var element = document.getElementById(id);
        element.scrollIntoView();
    }

    handleToggle = () => {
        var menuBars = document.getElementsByClassName("icon-bar");

        for (var i = 0; i < menuBars.length; i++) {
            menuBars[i].style.display = menuBars[i].style.display === 'none' ? 'block' : 'none';
        }

        var yourUl = document.getElementById("clear-menu-landing");
        var show = yourUl.style.display === 'none' || yourUl.style.display === '' ? 'block' : 'none';
        yourUl.style.display = show;

        var navbar = document.getElementById("landing-navbar-collapse");
        navbar.style.display = show;
    }

    redirectPage = (page_redirect, page_url) => {
        window.location.href=page_url;
    }

    updatPlanSelection = (planSelection) => {
        localStorage.setItem('planSelection', planSelection);
        window.location.href = '/register';
    }

    render() {
        const { layout, registering, nav } = this.props;
        console.log(registering, nav);
        if (registering == "success")
            window.location.href = '/portal_standard';

        return(
            <div>
                {registering !== "" &&
                    <Loader />
                }
                {/* {registering !== "success" &&  */}
                <div>
                    <div className="navbar navbar-inverse navbar-default" role="navigation">
                        <div className="navbar-header">
                            <button id="landingToggle" type="button" className="navbar-toggle" data-toggle="collapse" data-target="#landing-navbar-collapse" onClick={this.handleToggle}>
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span hidden id="clear-menu-landing" className="clear-mobile-landing">X</span>
                            </button>
                            <h1 className="mainLandingLogo" onClick={() => window.location.href='/'}>opengrants.io</h1>
                        </div>
            
                        <div className="collapse navbar-collapse" id="landing-navbar-collapse">
                            <div id="desktop" className="nav navbar-nav">
                                <nav>
                                    <a onClick={() => this.scrollToView('startups')}>STARTUPS</a>
                                    <a onClick={() => this.scrollToView('grant_writers')}>GRANT WRITERS</a>
                                    <a className="button sign-up" href="/register">Try 14 Days For $1</a>
                                    <a className="button login" href="/login">Login</a>
                                </nav>
                            </div>
                            <div hidden id="mobile" className="nav navbar-nav">
                                <ul>
                                    <li onClick={() => this.scrollToView('startups')}><a>For Startups</a></li>
                                    <li onClick={() => this.scrollToView('grant_writers')}><a>For Grant Writers</a></li>
                                    <li onClick={() => this.redirectPage('Login', '/login')}><a>Login</a></li>
                                    <li className="mobile-sign-up" onClick={() => this.redirectPage('Register', '/register')}><a>Sign Up</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row" id="mintBackground">
                        <div className="col-md-6">
                            <div id="clientRegistration" className="col-md-8 relative">
                                <Registration />
                            </div>
                        </div>
                        <div className="col-md-6 white-color">
                            <h1>Get connected to funding.</h1>
                            <a id="getStarted" className="button register_button" href="/register">Let's get started.</a>
                        </div>
                    </div>
                    <Registration_Products updatPlanSelection={this.updatPlanSelection}></Registration_Products>
                </div>
                <Footer />
            </div>
        );
    }
}


function mapProps(state) {
    const { registering, nav } = state.registration;
    const { layout } = state;
    return {
        layout,
        registering,
        nav
    };
}

const connectedLanding = connect(mapProps)(Landing);
export { connectedLanding as Landing };