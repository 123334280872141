import React, { useReducer } from 'react';
// import { Registration_AutoSuggestion } from '../../RegisterPage/Registration_AutoSuggestion';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '../../../Shared/Loader';
import { history } from '../../../../_helpers';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import ProgressBar from 'react-bootstrap/ProgressBar';

class NSFFocusAreas extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            focus_areas: JSON.parse(localStorage.getItem('focus_areas')),
            loading: true,
        }
    }

    componentDidMount() {
        this.setState({
            focus_areas: {
                ...this.state.focus_areas,
                biological_sciences: false,
                computer_information_science_engineering: false,
                education_human_resources: false,
                engineering: false,
                environmental_research_education: false,
                geosciences: false,
                integrative_actvivities: false,
                international_science_engineering: false,
                math_physical_sciences: false,
                social_behavioral_economic_sciences: false
            }
        })
        
        document.body.scrollTop = 0;
    
        setTimeout(() => {
            this.setState({
            loading: false
            });
        }, 500);
    }

    useStyles = makeStyles((theme) => ({
            root: {
              display: 'flex',
            },
            formControl: {
              margin: theme.spacing(3),
            },
    }));
          
    CheckboxesGroup() {
        const classes = this.useStyles();
    };
          
    handleChange = (event) => {
        const category = [event.target.name];

        this.setState({ 
            ...this.state, 
            [event.target.name]: event.target.checked,
            focus_areas: {
                ...this.state.focus_areas,
                [category]: event.target.checked 
            }
        });
    };
    
    handleNavigation = async () => {
        var {user, focus_areas} = this.state;
        
        await localStorage.setItem('user', JSON.stringify(user));
        await localStorage.setItem('focus_areas', JSON.stringify(focus_areas));
        history.push('/quickstart/info-first');
    }

    render() {     
        const { loading } = this.state;
        const { whitelabel } = this.props;
        const progressBarColor = whitelabel ? whitelabel.accent_Color : "#29a";
        const now = 70;
        const progressInstance = <ProgressBar now={now} label={`${now}%`} />;

        return (
            <div>
                <style>{"\
                    .progress-bar{\
                    background:" + progressBarColor + ";\
                    }\
                    .#NSF_Category_Selector .MuiSvgIcon-root{\
                        fill:" + progressBarColor + ";\
                    }\
                "}</style>
                {loading && <Loader />}
                <div>{progressInstance}</div>
                <div id="fundingSlider1">
                    <h1 id="fundingSliderHeader">
                        Do any of the following fields describe your business?
                    </h1>
                </div>
                <div id="NSF_Category_Selector">
                    <FormControl component="fieldset" className="">
                        <FormGroup>
                            <div id="focusNSFArea" className="row">
                                <h3 id="checkAllH3">Check all that apply</h3>
                                <hr />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.biological_sciences} 
                                                    onChange={this.handleChange} 
                                                    name="biological_sciences" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Biological Sciences"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.computer_information_science_engineering} 
                                                    onChange={this.handleChange} 
                                                    name="computer_information_science_engineering"
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}} 
                                                />}
                                        label="Computer and Information Science and Engineering"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.education_human_resources} 
                                                    onChange={this.handleChange} 
                                                    name="education_human_resources"
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}} 
                                                />}
                                        label="Education and Human Resources"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.engineering} 
                                                    onChange={this.handleChange} 
                                                    name="engineering" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Engineering"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.environmental_research_education} 
                                                    onChange={this.handleChange} 
                                                    name="environmental_research_education" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Environmental Research and Education"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.geosciences} 
                                                    onChange={this.handleChange} 
                                                    name="geosciences" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Geosciences"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.integrative_actvivities} 
                                                    onChange={this.handleChange} 
                                                    name="integrative_actvivities" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Integrative Activities"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.international_science_engineering} 
                                                    onChange={this.handleChange} 
                                                    name="international_science_engineering" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="International Science and Engineering"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.math_physical_sciences} 
                                                    onChange={this.handleChange} 
                                                    name="math_physical_sciences" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Mathematical and Physical Sciences"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.social_behavioral_economic_sciences} 
                                                    onChange={this.handleChange} 
                                                    name="social_behavioral_economic_sciences" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Social, Behavioral and Economic Sciences"
                                    />
                                <div id="NSFFocusContinueButton" className="continueButton">
                                    <button 
                                        className="signUpContinue"
                                        onClick={() => this.handleNavigation('next')}
                                        style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                                    >
                                        Continue  →
                                    </button>
                                </div>
                            </div>
                        </FormGroup>
                    </FormControl>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering, nav } = state.registration;
    const { whitelabel } = state.setWhitelabel;
    const { registrationAlert } = state;
    
    return {
        registering, 
        nav, 
        registrationAlert,
        whitelabel,
    };
}

const connectedNSFFocusAreas = withRouter(connect(mapStateToProps)(NSFFocusAreas));
export { connectedNSFFocusAreas as NSFFocusAreas };