import React from 'react';
import { connect } from 'react-redux';
import { searchService, algoliaService } from '../../_services';
import { favoritesActions } from '../../_actions';
import { Result } from './Result';
import algoliasearch from 'algoliasearch';
import DateRangePicker from "./DateRangePicker";
import CustomCurrentRefinements from "./CurrentRefinements";
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import { InstantSearch, 
            SearchBox, 
            Hits,
            Pagination, 
            VoiceSearch,
            Stats,
            connectStateResults,
            CurrentRefinements,
            RefinementList,
            Panel,
            RangeInput
        } from 'react-instantsearch-dom';
import '../../Styles/Algolia.css';
import '../../Styles/SearchListings.css';
import '../../Styles/Pagination.css';

const searchClient = algoliasearch('MWF97BUAPS', '943f0970ab9c9143012e9172983aacdc');

/////////////////////////////////////////
/// SEARCH BAR CLASS
/// Handle all Algolia Functionality
/////////////////////////////////////////
class BasicSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total_Grants: null,
      favorites: localStorage.getItem('favorite'),
      searchGroup: {
        search: "",
        user: this.props.user,
        categories: [],
        agencies: [],
        page: 1,
        totalResults: 0,
      },
      loading: true,
    };
  }

  async componentDidMount() {
    const total_Grants = await searchService.getTotalListings();
    const saved_Searches = await algoliaService.getSavedSearches(this.props.user);

    this.setState({
      total_Grants: total_Grants,
      saved_Searches: saved_Searches,
      loading: false,
    })
  };

  async saveListing(listing_Id, user, add) {
    await this.props.dispatch(favoritesActions.saveListing(listing_Id, user));
    await this.props.dispatch(favoritesActions.getFavorites(user));
    setTimeout(1000);
    
    this.setState(prevState => ({
        searchGroup: {
            ...prevState.searchGroup,
        },
        favorites: JSON.parse(localStorage.getItem('favorite')),
        favoritesCount: add === true ? (prevState.favoritesCount - 1) : (prevState.favoritesCount + 1)
    }), () => {});
  };

  Result(listing) {
    return <Result listing={listing} />;
  };

  render() {
    const { total_Grants } = this.state;
    const { whitelabel } = this.props;
    const Results = connectStateResults(
      ({ searchState, searchResults, children }) =>
        searchResults && searchResults.nbHits !== 0 ? ( children) : (
          this.state.loading == false &&
          <div id="noSearchResults">
            <h4>No results found for <strong>{searchState.query}</strong>.</h4>
            <hr align="left" style={{width: "10%"}} />
            <p>Please enter another query for a program, category, location or agency.</p>
          </div>
        )
    );

    return (
      <div id="searchListingsSearchBar">
        <h1 id="searchHeader">National Grants Database</h1>
        <div class="row">
          <p id="numRecords">Total No. Grants: 
            <span style={{color: "#555", fontWeight: 600}}> {total_Grants && total_Grants.toLocaleString()}</span>
          </p>
          <p id="numRecords">Average Award: 
            <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="verifiedAward"><polyline points="20 6 9 17 4 12"></polyline></svg>
            <span style={{color: "#555", fontWeight: 600}}> $1.4m</span>
          </p>
        </div>
        <InstantSearch 
          searchClient={searchClient} 
          indexName={whitelabel.algolia_Dataset}
        >
          <div className="row">
            <div className="col-md-12" style={{maxWidth: 1000}}>
              <div id="algoliaSearchBox" className="row">
                <SearchBox 
                  autoFocus={true}
                  searchAsYouType={true}
                  showLoadingIndicator={true}
                  translations={{ placeholder: 'Search by category, agency or location...' }}
                />
                <VoiceSearch />
              </div>
              <Stats />
              <Results>
                <Hits hitComponent={this.Result} style={{width:"100%!important"}} id="searchHits"/>
              </Results>
              <Pagination
                // Optional parameters
                defaultRefinement={1}
                showFirst={true}
                showPrevious={true}
                showNext={true}
                showLast={true}
                padding={5}
              />
            </div>
          </div>
        </InstantSearch>
      </div>
    );
  }
}

function mapStateToProps(state) {
    const { setUser, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        whitelabel,
    };
}

const connectedAlgoliaPage = connect(mapStateToProps)(BasicSearch);
export { connectedAlgoliaPage as BasicSearch };