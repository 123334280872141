import React from 'react';
import Autosuggest from 'react-autosuggest';
// import { Focus_Tagging } from '.';

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

export class Focus_AutoSuggestion extends React.Component {
    constructor(props) {
        super(props);
        const {categories, currentSelectedCategories, multiple} = this.props;
        let value = "";
        if (!multiple && currentSelectedCategories.length > 0) {
            value = currentSelectedCategories[0];
        }

        this.state = {
            categories,
            value: value,
            suggestions: [],
        }
    }

    onSuggestionsFetchRequested = ({ value, reason }) => {
        var suggestions = [];
        if (reason != 'suggestion-selected') {
            suggestions = this.getSuggestions(value);
        } 
        else if (this.props.addTags) {
            value = "";
        }

        this.setState({
            value,
            suggestions
        });
    };

    getSuggestions = value => {
        console.log("GET TAG SECTION SUGGESTIONS: " + value);
        const {categoryName} = this.props;
        const inputLength = value.length;
        const escapedValue = escapeRegexCharacters(value.trim());
        const regex = new RegExp('\\b' + escapedValue, 'i');

        return this.props.categories[categoryName]
          .map(section => {
            return {
              category: section.category,
              tags: section.tags.filter(tag => regex.test(tag))
            };
          })
          .filter(sect => sect.tags.length > 0) 
      }
      
    getSuggestionValue(suggestion) {
        return suggestion;
    }

    getSectionSuggestions(section) {
        return section.tags;
    }

    shouldRenderSuggestions(value, reason) {
        return value.trim().length >= 0;
      }
      
    renderSuggestion = suggestion => (
        <div>
          {suggestion}
        </div>
    );

    renderSectionTitle(section) {
        return (
            <div id="autoSuggest-Title">
                <strong>{section.category}</strong>
          </div>
        );
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    handleLocationChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
        });

        const {categoryName, handleCategoryInput} = this.props;
        let categoryInput = {}
        categoryInput[categoryName+"Input"] = newValue;
        handleCategoryInput(categoryInput);
    }

    onSuggestionSelected = (event, { suggestion }) => {
        event.preventDefault();

        const {categoryName, handleCategorySelection, handleCategoryInput, multiple, addTags} = this.props;
        let currentSelectedCategories = this.props.currentSelectedCategories.slice();

        if (!currentSelectedCategories.includes(suggestion)) {
            if (addTags) {
                if (multiple) {
                    currentSelectedCategories.push(suggestion);
                    let categories = {};
                    categories[categoryName] = currentSelectedCategories;
                    handleCategorySelection(categories);
                } 
                else {
                    let categories = {};
                    categories[categoryName] = [suggestion];
                    handleCategorySelection(categories);
                }
            } 
            else {
                let categories = {};
                categories[categoryName] = suggestion;
                handleCategorySelection(categories);
            }
        } 
    }

    //remove option tag
    removeOptionTag = (optionValue) => {
        const {categoryName, handleCategorySelection} = this.props;
        let currentSelectedCategories = this.props.currentSelectedCategories.slice();
        currentSelectedCategories = currentSelectedCategories.filter(category => {
            return (category !== optionValue);
        });
        let categories = {};
        categories[categoryName] = currentSelectedCategories;
        handleCategorySelection(categories);
    }

    //clear all selected tags
    clearTags = () => {
        const {categoryName, handleCategorySelection} = this.props;
        let categories = {};
        categories[categoryName] = [];
        handleCategorySelection(categories);
    }

    render() {
        // console.log(this.props);
        // console.log(this.state);
        const {addTags, currentSelectedCategories, submitted, requiredInput, errorInput} = this.props;
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: "Click or start typing to select a tag...",
            value,
            onChange: this.handleLocationChange
        };

        //create tag for each inputs
        const tags = currentSelectedCategories.map((category) => {
            return (
                <div key={"tags_" + category} className="categoryTag">
                    {category}
                    <div className="removeTag" onClick={() => this.removeOptionTag(category)}>X</div>
                </div>
            )
        });
        
        return (
            <>
            <div className="align-left">
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={this.getSuggestionValue}
                    getSectionSuggestions={this.getSectionSuggestions}
                    renderSuggestion={this.renderSuggestion}
                    renderSectionTitle={this.renderSectionTitle}
                    shouldRenderSuggestions = {this.shouldRenderSuggestions}
                    inputProps={inputProps}
                    alwaysRenderSuggestions={true}
                    multiSection={true}
                /> 
                {/* {this.props.categories && 
                    JSON.stringify(this.props.categories)
                } */}
            </div>
            {/* {submitted && requiredInput && value.length == 0 &&
             <p className="error align-left">This field is required</p>} */}
            {submitted && errorInput &&
            <p className="error align-left">Please select a value from the dropdown</p>}
                
            {addTags && currentSelectedCategories.length > 0 && 
            <div className="align-left">
                <a id="clearAll" className="showClearAll" onClick={this.clearTags}>Clear</a>
                <div id="tags">{tags}</div>
            </div>
            }
            </>    
        );
    }
}