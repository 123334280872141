import React from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import { history } from '../../_helpers';
// @material-ui/icons
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
// core components
import GridContainer from "../../_components/dashboardComponents/Grid/GridContainer.js";
import Card from "../../_components/dashboardComponents/Card/Card.js";
import CardHeader from "../../_components/dashboardComponents/Card/CardHeader.js";
import CardBody from "../../_components/dashboardComponents/Card/CardBody.js";
import styles from "../../_assets/jss/material-dashboard-react/views/dashboardStyle.js";
import '../../_assets/css/material-dashboard-react.css';

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 600,
      lg: 900,
      xl: 1400
    }
  }
});

const goToSearch = () => {
  history.push('/search');
}

const goToGrantwriters = (props) => {
  props.expandGrantsMenu();
  history.push('/grantwriters');
}

const goToExperts = () => {
  history.push('/service-providers');
}

const goToAdvisors = (props) => {
  props.expandExpertsAdvisorsMenu();
  history.push('/advisors');
}

export default function DashboardView_ProspectSV(props) {
  const classes = useStyles();
  const { dashboard, user, purchasedGrantWriting, proposalSubmission, records, loading, user_notes, whitelabel } = props;
  const user_role = user.userRoles;
  const notes_links = (user_role == 'Client_Free') ? "/settings?val=billing" : "/notes"
  // console.log(props);

  let user_notes_list = null;
  if (user_notes) {
    user_notes_list = user_notes.map((note) => {
      return (
        <div className={classes.cardCategory}>
          <hr style={{marginTop: 0}} />
          <a href={"/grants/"+note.listing_Id} target="_blank" style={{float: "right"}}>View Grant</a>
          <p>{note.modifiedDate}</p>
          <p>{note.user_Notes}</p>
        </div>
      );
    });
  }

  return (
    <div className="dashboardView" id="prospectSVDashboard" style={{paddingLeft: "2.5%", paddingRight: "2.5%", marginTop: -60}}>
      <MuiThemeProvider theme={theme}>
        <GridContainer>
          <Card chart id="prospectSVChart">
            <CardBody 
              style={{overflow: "scroll", textAlign: "center"}}
            >
              <br />
              <h1 style={{fontWeight: 600, marginBottom: 0, color: whitelabel ? whitelabel.dashboard_Color : "#333"}}>Welcome to Prospect Silicon Valley!</h1>
              <br />
              <h2 
                className={classes.cardTitle} 
                id="dashFundingOpportunities"
                style={{fontFamily: "open sans", fontWeight: 500, lineHeight: "1.25em", maxWidth: 700, textAlign: "center", margin: "auto"}}
              >
                This is our ecosystem portal, where you can find grants and grant writers, as well as engage with ProspectSV startup advisors and other expert service providers. Use the boxes below or the navigation bar to find what you're looking for.
            </h2>
            <br />
            </CardBody>
          </Card>
        </GridContainer>
        <GridContainer>
          <div class="row" style={{width: "100%"}}>
          <div class="col-lg-6" style={{marginTop: 50}}>
            <h2 id="prospectSVDashboardH2" style={{textAlign: "center",marginBottom: "-25px!important"}}>Startup Resources</h2>
            <div class="col-lg-6">
              <Card chart id="dashboardMiddleSecondary">
                <CardHeader 
                  color="info"
                  style={{background: whitelabel ? whitelabel.accent_Color : "#29a", margin: 0, height: 75}}
                >
                <h1 id="dashHeaderSecondary" style={{fontWeight: 400, fontSize: 24, textAlign: "center"}}>Advisors</h1>
                </CardHeader>
                <CardBody 
                  style={{height:250, overflow: "scroll", textAlign: "center"}}
                >
                  <br />
                  <img src="https://i0.wp.com/www.gumbranch.org/wp-content/uploads/sites/31/2016/08/connect-icon.png?fit=500%2C500&ssl=1" type="image/x-icon" height="100"/>
                  <br /><br /><br />
                  <Button 
                    style={{width: "100%", background: "#666"}}
                    onClick={() => {goToAdvisors(props)}}
                  >
                    Find an Advisor
                    <ArrowForwardIos style={{float: "right", fontSize:32, marginTop: 0, marginLeft:10, marginRight:-10}}/>
                  </Button>
                </CardBody>
              </Card>
            </div>
            <div class="col-lg-6">
              <Card chart id="dashboardMiddleSecondary">
                <CardHeader 
                  color="info"
                  style={{background: whitelabel ? whitelabel.accent_Color : "#29a", margin: 0, height: 75}}
                >
                <h1 id="dashHeaderSecondary" style={{fontWeight: 400, fontSize: 24, textAlign: "center"}}>Service Providers</h1>
                </CardHeader>
                <CardBody 
                  style={{height:250, overflow: "scroll", textAlign: "center"}}
                >
                  <br />
                  <img src="https://s3-us-west-1.amazonaws.com/grants.prospectsv.org/images/prospectsv_experts.png" type="image/x-icon" height="100"/>
                  <br /><br /><br />
                  <Button 
                    // color={whitelabel ? whitelabel.accent_Color : "#29a"}
                    style={{width: "100%", background: "#666"}}
                    onClick={() => {goToExperts()}}
                  >
                    Browse Experts
                    <ArrowForwardIos style={{float: "right", fontSize:32, marginTop: 0, marginLeft:10, marginRight:-10}}/>
                  </Button>
                </CardBody>
              </Card>
              </div>
            </div>
            <div class="col-lg-6" style={{marginTop: 50}}>
              <h2 id="prospectSVDashboardH2" style={{textAlign: "center",marginBottom: "-25px!important"}}>Grant Resources</h2>
              <div class="col-lg-6">
                <Card chart id="dashboardMiddleSecondary">
                  <CardHeader 
                    color="info"
                    style={{background: whitelabel ? whitelabel.dashboard_Color : "#29a", margin: 0, height: 75}}
                  >
                  <h1 id="dashHeaderSecondary" style={{fontWeight: 400, fontSize: 24, textAlign: "center"}}>Search Grants</h1>
                  </CardHeader>
                  <CardBody 
                    style={{height:250, overflow: "scroll", textAlign: "center"}}
                  >
                    <br />
                    <img src="https://s3-us-west-1.amazonaws.com/grants.prospectsv.org/images/prospectsv_search_icon.png" type="image/x-icon" height="100"/>
                    <br /><br /><br />
                    <Button 
                      style={{width: "100%", background: "#666"}}
                      onClick={() => {goToSearch()}}
                    >
                        Find a Grant
                        <ArrowForwardIos style={{float: "right", fontSize:32, marginTop: 0, marginLeft:10, marginRight:-10}}/>
                    </Button>
                  </CardBody>
                </Card>
              </div>
              <div class="col-lg-6">
                <Card chart id="dashboardMiddleSecondary">
                  <CardHeader 
                    color="info"
                    style={{background: whitelabel ? whitelabel.dashboard_Color : "#29a", margin: 0, height: 75}}
                  >
                  <h1 id="dashHeaderSecondary" style={{fontWeight: 400,fontSize: 24, textAlign: "center"}}>Grantwriters</h1>
                  </CardHeader>
                  <CardBody 
                    style={{height:250, overflow: "scroll", textAlign: "center"}}
                  >
                    <br />
                    <img src="https://s3-us-west-1.amazonaws.com/grants.prospectsv.org/images/prospectsv_notes_icon.png" type="image/x-icon" height="100"/>
                    <br /><br /><br />
                    <Button 
                      style={{width: "100%", background: "#666"}}
                      onClick={() => {goToGrantwriters(props)}}
                    >
                        Get Started
                        <ArrowForwardIos style={{float: "right", fontSize:32, marginTop: 0, marginLeft:10, marginRight:-10}}/>
                    </Button>
                  </CardBody>
                </Card>
              </div>
            </div>
          
          </div>
        </GridContainer>
      </MuiThemeProvider>
    </div>
  );
}