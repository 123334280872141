import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '../../../Shared/Loader';
import { quickstartService } from '../../../../_services';
import { quickstartActions } from '../../../../_actions';
import ProgressBar from 'react-bootstrap/ProgressBar';
import '../../../../Styles/QuickstartSurvey.css';

class Finished extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            focus_areas: JSON.parse(localStorage.getItem('focus_areas')),
            impact_groups: JSON.parse(localStorage.getItem('impact_groups')),
            submitted: false,
            loading: true,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        document.body.scrollTop = 0;
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 500);
    }

    handleChange(e) {
        const {name, value} = e.target;
        let {user} = this.state;
        user[name] = value;
        this.setState({
            user: user
        })
    }

    handleNavigation = async (type) => {
        var {user} = this.state;
        
        this.setState({
            loading: true
        })

        this.setState({
            user: {
                ...this.state.user,
                RegistrationComplete: true,
                app_Registration: true
            },
        });

        let focus_area_list = [];
        for (let key in this.state.focus_areas) { 
            if (this.state.focus_areas.hasOwnProperty(key)) { 
                let value = this.state.focus_areas[key];
                if (value)
                { 
                    focus_area_list.push(key);
                }
            } 
        }

        let impact_groups_list = [];
        for (let key in this.state.impact_groups) { 
            if (this.state.impact_groups.hasOwnProperty(key)) { 
                let value = this.state.impact_groups[key];
                if (value)
                { 
                    impact_groups_list.push(key);
                }
            } 
        }

        this.setState(prevState => ({
            user: {
                ...this.state.user,
                registrationComplete: true,
                app_Registration: true,
                focus_areas: focus_area_list,
                impact_groups: impact_groups_list
            },
        }), async () => {
            if (this.state.user.entity_Type == "Technology Innovation") {
                await quickstartService.updateQuickstartTechnologyUser(this.state.user);
            }
            if (this.state.user.entity_Type == "Impact Innovation") {
                await quickstartService.updateQuickstartImpactUser(this.state.user);
            }
            await localStorage.setItem('user', JSON.stringify(this.state.user));

            window.location.href = '/quickstart/dashboard';
        });
    }

    useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '100ch',
            },
        },
    }));   

    render() {
        const { loading } = this.state;
        const { whitelabel } = this.props;
        const progressBarColor = whitelabel ? whitelabel.accent_Color : "#29a";
        const now = 100;
        const progressInstance = <ProgressBar now={now} label={`${now}%`} />;

        return (
            <div>
                <style>{"\
                    .progress-bar{\
                    background:" + progressBarColor + ";\
                    }\
                "}</style>
                {loading && <Loader />}
                <div>{progressInstance}</div>
                <div id="fundingSlider1">
                    <h1 
                        id="signUpWelcomeH1"
                        style={{color: whitelabel && whitelabel.organization_Name == "portal.opengrants.io" ? "#29a" : "#666"}}
                    >
                        You're all set!
                    </h1>
                    <hr id="signUpSliderHR" />
                    <h1 id="askQuestions">
                        Now let's look at your funding opportunities.
                    </h1>
                </div>
                <div className="continueButton">
                    <button 
                        className="signUpContinue"
                        onClick={() => this.handleNavigation('next')}
                        style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                    >
                        Show me the money  →
                    </button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering, nav } = state.registration;
    const { whitelabel } = state.setWhitelabel;
    const { registrationAlert } = state;
    
    return {
        registering, 
        nav, 
        registrationAlert,
        whitelabel,
    };
}

const connectedFinished = connect(mapStateToProps)(Finished);
export { connectedFinished as Finished };