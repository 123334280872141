import { authHeader } from '../_helpers';

export const authenticateService = {
    login,
    logout,
    refreshToken,
    forgotPassword,
    resetPassword,
    checkUsername
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch('https://refactor.opengrants.io/api/authenticate/authenticate_portal_user', requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            var userCopy = Object.assign({}, user);
            userCopy.partnerClient = "";
            localStorage.setItem('user', JSON.stringify(userCopy));
            // getUserRoles(user);

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

async function refreshToken(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/authenticate/refresh_portal_user_token', requestOptions)
        .then(handleResponse)
        .then(async user => {
            await localStorage.setItem('user', JSON.stringify(user));

            const favorites = localStorage.getItem('favorite');
            if (!favorites) {
                var emptyFavorites = []
                localStorage.setItem('favorite', JSON.stringify(emptyFavorites));
            }

            return user;
        });
}

function forgotPassword(email, server) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch('https://refactor.opengrants.io/api/authenticate/forgot_password?email=' + email + '&server=' + server, requestOptions).then((handleError) => handleResponse(handleError));
}

function resetPassword(resetPassword) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(resetPassword)
    };

    return fetch('https://refactor.opengrants.io/api/authenticate/reset_password', requestOptions).then((handleError) => handleResponse(handleError));
}

function checkUsername(username) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/authenticate/check_username?username='+username, requestOptions).then((handleError) => handleResponse(handleError));
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok ) {
            if (response.status == "401") {
                window.location.href="/login";
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}