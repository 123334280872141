import React from 'react';
import { connect } from 'react-redux';
import { MarketplaceResult } from './MarketplaceResult';
import CustomCurrentRefinements from "../SearchListings/CurrentRefinements";
import algoliasearch from 'algoliasearch';
import { serviceProviderService } from '../../_services';
import { MarketplaceToS } from "./MarketplaceToS"
import {
  InstantSearch,
  SearchBox,
  Hits,
  Pagination,
  VoiceSearch,
  Stats,
  connectStateResults,
  RefinementList,
  Panel,
} from 'react-instantsearch-dom';
import '../../Styles/MarketplaceSearch.css'

const searchClient = algoliasearch('MWF97BUAPS', '943f0970ab9c9143012e9172983aacdc');

/////////////////////////////////////////
/// SEARCH BAR CLASS
/// Handle all Algolia Functionality
/////////////////////////////////////////
class MarketplaceSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total_Service_Providers: null,
      searchGroup: {
        search: "",
        user: this.props.user,
        categories: [],
        agencies: [],
        page: 1,
        totalResults: 0,
      },
      loading: true,
      currentSearchState: {},
      mobileMarketplaceSearch: false,
      width: window.innerWidth,
    };

    this.handleChange = this.handleChange.bind(this);
    this.MarketplaceResult = this.MarketplaceResult.bind(this);
  }

  async componentDidMount() {
    const total_Service_Providers = await serviceProviderService.getServiceProviderCount();

    this.setState({
      total_Service_Providers: total_Service_Providers,
      loading: false,
    });

    window.addEventListener('resize', this.updateDimensions);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  async toggle() {
    this.setState({
      mobileMarketplaceSearch: !this.state.mobileMarketplaceSearch
    });
  }

  async goToUpgrade() {
    window.location.href = "/settings?val=billing";
  }

  async updateCurrentSearch(searchState) {
    this.setState({
      currentSearchState: searchState
    });
  };

  MarketplaceResult(service_Provider) {
    document.body.scrollTo(0, 0);
    return <MarketplaceResult service_Provider={service_Provider} />
  };

  render() {
    const { width, mobileMarketplaceSearch } = this.state;
    const { user } = this.props;

    const MarketplaceResults = connectStateResults(
      ({ searchState, searchResults, children }) =>
        searchResults && searchResults.nbHits !== 0 ? (children) : (
          this.state.loading == false &&
          <div id="noSearchResults">
            <h4>No results found for <strong>{searchState.query}</strong>.</h4>
            <hr align="left" style={{ width: "10%" }} />
            <p>Please enter another query for a program, category, location or agency.</p>
            <br /><br />
            <div class="row">
              <div class="col-md-3">
                <img id="grantHeroIMG" src="https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/grant_writing_superheroes.png" style={{ width: 200 }} />
              </div>
              <div class="col-md-9">
                <p style={{ fontWeight: 600, marginLeft: "5%", marginTop: -15 }}>Didn’t find what you’re looking for?</p>
                <ol id="noSearchHits">
                  <li>
                    Grants can be tricky. Sometimes they’re listed with names that you wouldn’t expect. Try searching again with synonyms or related words.
                  </li>
                  <li>
                    Some grants may simply be out of season. Many grant programs are released on a quarterly, semi-annual or annual basis. If you can’t find anything right now,
                    <a href="/settings?val=organization" style={{ fontWeight: 600 }}> click here </a>
                    to create an organization profile so we can notify you when new funding is available.
                  </li>
                  <li>
                    If you’d rather bypass the search process and hire an expert instead,
                    <a href="/apply" style={{ fontWeight: 600 }}> click here </a>
                    to submit a (refundable) deposit and meet with a grant writer.
                  </li>
                  <li>
                    Do you want to add a grant listing to our platform?
                    <a href="https://airtable.com/shr1UV3Bit2ZQURnZ" target="_blank" style={{ fontWeight: 600 }}> Fill out this form </a>
                    so we can start tracking it.
                  </li>
                </ol>
                <br />
              </div>
            </div>
          </div>
        )
      );

    return (
      <div id="searchListingsSearchBar">
        {/* If user hasnt agreed to Terms and service */}
        <MarketplaceToS user={user} />

        {/* SideNav Search */}
        <h1 id="searchHeader" style={{ marginTop: '2.5%' }}>Talent Finder</h1>
        <InstantSearch
          searchClient={searchClient}
          indexName="OpenGrants_ServiceProviders"
          searchState={this.state.currentSearchState}
          onSearchStateChange={searchState => {
            this.updateCurrentSearch(searchState);
          }}
        >
          <div className="row">
            <div className="col-lg-4 col-lg-push-8 n-padding">
              {width < 1199 &&
                <div
                  id="MarketplaceSearchMobileToggle"
                  onClick={() => this.toggle()}
                  style={{ margin: "15px 0", width: "100%", marginLeft: 0 }}
                >
                  <h4 id="mobileSearchFilters">
                    ADVANCED FILTERS
                  </h4>
                  {mobileMarketplaceSearch && <h4 className="advancedFiltersArrow" style={{ display: "inline", fontSize: "24px", marginLeft: "10px", color: "#666" }}>&#9650;</h4>}
                  {!mobileMarketplaceSearch && <h4 className="advancedFiltersArrow" style={{ display: "inline", fontSize: "24px", marginLeft: "10px", color: "#666" }}>&#9660;</h4>}
                </div>
              }
              {(width > 1199 || mobileMarketplaceSearch) &&
                <div
                  id="filterBox"
                  style={{ padding: 15, width: "105%" }}
                >
                  <h1 id="searchHeader">Service Provider Filters</h1>
                  <br />
                  <br />
                  <Panel header="Applied Filters">
                    <CustomCurrentRefinements />
                  </Panel>
                  <br /><br />
                  <Panel header="Industries">
                    <RefinementList
                      attribute="sP_Industry_Focus_Tags"
                      searchable={true}
                      limit={5}
                      showMore={true}
                      showMoreLimit={30}
                      translations={{
                        showMore(expanded) {
                          return expanded ? 'Show less' : 'Show more';
                        },
                        noResults: 'No results',
                        submitTitle: 'Submit your search query.',
                        resetTitle: 'Clear your search query.',
                        placeholder: 'Search industry types...',
                      }}
                    />
                  </Panel>
                  <br /><br />
                  <Panel header="Services">
                    <RefinementList
                      attribute="sP_Industry_Services_Tags"
                      searchable={true}
                      limit={5}
                      showMore={true}
                      showMoreLimit={30}
                      translations={{
                        showMore(expanded) {
                          return expanded ? 'Show less' : 'Show more';
                        },
                        noResults: 'No results',
                        submitTitle: 'Submit your search query.',
                        resetTitle: 'Clear your search query.',
                        placeholder: 'Search service types...',
                      }}
                    />
                  </Panel>
                  <br /><br />
                  <Panel header="Roles">
                    <RefinementList
                      attribute="sP_Role_Tags"
                      limit={5}
                      showMore={true}
                      showMoreLimit={30}
                      translations={{
                        showMore(expanded) {
                          return expanded ? 'Show less' : 'Show more';
                        },
                        noResults: 'No results',
                        submitTitle: 'Submit your search query.',
                        resetTitle: 'Clear your search query.',
                        placeholder: 'Search service types...',
                      }}
                    />
                  </Panel>
                  <br /><br />
                  <Panel header="Grant Types">
                    <RefinementList
                      attribute="gW_GrantType_Tags"
                      limit={5}
                      showMore={true}
                      showMoreLimit={30}
                      translations={{
                        showMore(expanded) {
                          return expanded ? 'Show less' : 'Show more';
                        },
                        noResults: 'No results',
                        submitTitle: 'Submit your search query.',
                        resetTitle: 'Clear your search query.',
                        placeholder: 'Search grant types...',
                      }}
                    />
                  </Panel>
                  <br />
                </div>
              }
            </div>
            <div className="col-lg-8 col-lg-pull-4 n-padding">
              <div id="algoliaSearchBox" className="row">
                <SearchBox
                  autoFocus={true}
                  searchAsYouType={true}
                  showLoadingIndicator={true}
                  translations={{ placeholder: 'Search by name, focus/service areas or grant types...' }}
                />
                <VoiceSearch />
              </div>
              {/* How many Results where found */}
              <Stats />
              <br />
              {/* Hits is surounded by marketplace results because when you search and get no results the <MarketplaceResults /> is rendered. */}
              <MarketplaceResults>
                {/* All of the results  */}
                <Hits
                  hitComponent={this.MarketplaceResult}
                  style={{ width: "100%!important" }}
                  id="searchHits"
                />
              </MarketplaceResults>
              <Pagination
                defaultRefinement={1}
                showFirst={true}
                showPrevious={true}
                showNext={true}
                showLast={true}
                padding={5}
              />
            </div>
          </div>
        </InstantSearch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { setUser } = state;
  const { user } = setUser;

  return {
    user,
  };
}

const connectedAlgoliaPage = connect(mapStateToProps)(MarketplaceSearch);
export { connectedAlgoliaPage as MarketplaceSearch };