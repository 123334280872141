import React from 'react';
import './ImageUpload.jsx';
import { Previews } from '../../Settings/ImageUpload';
import '../../../Styles/SettingsStyles/ImageUpload.css';

export class Registration_CompleteProfile extends React.Component {
    constructor(props) {
        super(props);
        const {user, nav} = this.props;

        this.state = {
            user: user,
            nav: nav,
            submitted: false,
            themeColor: localStorage.getItem('themeColor')
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const {name, value} = e.target;
        let {user} = this.state;
        user[name] = value;
        this.setState({
            user: user
        })
    }

    handleNavigation = (type) => {
        var {user, nav} = this.state;
        if (type === 'next') {
            nav++;
        } else if (type === "prev") {
            nav--;
        }

        this.props.handleFormInputs({user, nav});
    }

    render() {
        const {user, submitted} = this.state;

        return (
            <div id="form_5" className="forms">
                <h1 className="regHeader" style={{color: this.state.themeColor}}>Let's complete your profile.</h1>
                <hr/>
                    <h2>
                        <strong>These fields are optional.</strong>
                    </h2>
                <div>
                    <div>
                        <div className="question_section">
                            <label>Personal Twitter Profile (optional)</label>
                            <input name="Twitter" value={user.Twitter} onChange={this.handleChange} />
                        </div>
                        <div className="question_section">
                            <label required>Personal LinkedIn Profile (optional)</label>
                            <input name="LinkedIn" value={user.LinkedIn} onChange={this.handleChange} />
                        </div>
                        <div className="question_section">
                            <label>Upload Headshot (Max 5Mb)</label >
                            <input type="hidden" name="headshot" value={user.headshot} />
                            <span id="image_error" className="error align-left"></span>
                            <div id="profileImage">
                                <Previews />
                            </div>
                        </div>
                    </div>
                    <div>
                        <button 
                            className="backButton" 
                            onClick={() => this.handleNavigation('prev')}
                            style={{color: this.state.themeColor, borderColor: this.state.themeColor}}
                            >
                                Back
                            </button>
                        <button 
                            className="nextButton" 
                            onClick={() => this.handleNavigation('next')}
                            style={{background: this.state.themeColor, border: this.state.themeColor}}
                        >
                            Sign Up!
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}