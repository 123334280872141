import { authHeader } from '../_helpers';

export const quickstartService = {
    updateQuickstartTechnologyUser,
    updateQuickstartImpactUser,
    resetQuickstartUser,
    getPrograms,
    getProgramInfo,
    getImpactPrograms,
    getImpactProgramInfo,
    checkState
};

function updateQuickstartTechnologyUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/quickstart/update_quickstart_technology_user', requestOptions)
    .then(handleResponse)
    .then(user => {
        var userCopy = Object.assign({}, user);
        userCopy.partnerClient = "";
        localStorage.setItem('user', JSON.stringify(userCopy));

        return user;
    });
}

function updateQuickstartImpactUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/quickstart/update_quickstart_impact_user', requestOptions)
    .then(handleResponse)
    .then(user => {
        var userCopy = Object.assign({}, user);
        userCopy.partnerClient = "";
        localStorage.setItem('user', JSON.stringify(userCopy));

        return user;
    });
}

function resetQuickstartUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/quickstart/reset_quickstart_user_programs', requestOptions)
    .then(handleResponse);
}


function getPrograms(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/quickstart/get_quickstart_user_programs', requestOptions)
    .then(handleResponse);
}

function getProgramInfo(userId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        // body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/quickstart/get_quickstart_user_program_info?userId=' + userId, requestOptions).then(handleResponse);
}

function getImpactPrograms(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/quickstart/get_quickstart_impact_user_programs', requestOptions)
    .then(handleResponse);
}

function getImpactProgramInfo(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        // body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/quickstart/get_quickstart_impact_user_program_info?userId=' + userId, requestOptions).then(handleResponse);
}

function checkState(user, zipcode) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/quickstart/check_US_State?zipcode=' + zipcode, requestOptions)
    .then(handleTextResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok ) {
            if (response.status == "401") {
                window.location.href="/login";
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleTextResponse(response) {
    return response.text().then(text => {
        const data = text;
        if (!response.ok ) {
            if (response.status == "401") {
                window.location.href="/login";
            }
            
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}