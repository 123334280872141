import React from 'react';
import { connect } from 'react-redux';
import { registrationActions } from '../../../_actions';
import { searchService } from '../../../_services';
import { setPartnerInfo } from '../../Shared/Partners';
import '../../../Styles/Registration.css';

class Registration_Navigation extends React.Component {
    constructor(props) {
        super(props);
        const {nav} = this.props;

        this.state = {
            user: {
                Discriminator: 'Client',
                Linked_Listing_Id: '',

                Email: '',
                Password: '',
                Password_Confirm: '',

                Name: '',
                Surname: '',
                Phone_Number: '',

                Organization_Name: '',
                Website: '',
                Title: '',
                Organization_Description: '',

                category: '',
                category_Tags: [],

                EIN_Number: '',
                DUNS_Number: '',
                SAM_Registration: '',
                Woman_Owned: '',
                Veteran_Owned: '',
                Hubzone_Owned: '',

                Organization_Address: '',
                City: '',
                Country: '',

                Referral: '',

                Twitter: '',
                LinkedIn: '',
                file: '',
                partner: '',
            },
            categories:[],
            nav: nav,
            listing_Id: 0
        };
    }

    async componentDidMount() {
        localStorage.removeItem('user');
        let category = await searchService.getAllCategories();
        let categoryTags = await searchService.getAllCategoryTags();

        this.setState ({
            category: category,
            categoryTags: categoryTags,
        });

        const path = window.location.pathname;
        if (path.toLowerCase() != "/register")
        {
            const pathSplit = path.split('/').pop();
            if (isNaN(pathSplit)) 
            {
                const partnerName = pathSplit;
                setPartnerInfo(partnerName)
                localStorage.setItem('partner', partnerName);

                this.setState(prevState => ({
                    partnerLogin: true,
                    leftPartnerLogin: 'left' + partnerName + 'Login',
                    partnerLoginSubmitButton: partnerName + 'LoginSubmitButton',
                    partnerLoginRegisterButton: partnerName + 'LoginRegisterButton',
                    user: {
                        ...prevState.user,
                        partner: partnerName,
                    }
                }));
            }
            else
            {
                this.setState({
                    listing_Id: this.props.match.params.grant,
                });
            }
        }
    }

    handleSubmit = (user) => {
        // event.preventDefault();
        const { dispatch } = this.props;
        if (this.state.listing_Id !== 0)
        {
            user.Linked_Listing_Id = this.state.listing_Id;
        }
        const server = window.location.protocol + '//' + window.location.host;
        dispatch(registrationActions.register(user, server));

        user.Password = '';
        user.Password_Confirm = '';
        this.setState({
            user: user,
        });
    }

    handleFormInputs = (inputs) => {
        let {user, nav} = inputs;

        let registrationNavigation = document.getElementById("registrationNavigation");
        if (nav === registrationNavigation.children.length) {
            user.file_string = localStorage.getItem('fileBase64');
            nav = 0;
            this.handleSubmit(user);
        }
        this.setState({
            user: user,
            nav: nav,
        });
    }

    render() {
        console.log(this.state);
        const {registering, registrationAlert} = this.props;
        const { user, nav, category, categoryTags } = this.state;
        let categoryOptions = {"category": category, "category_Tags": categoryTags};
        let windowState = (window.innerWidth >= 1000) ? true : false;
        const partner = localStorage.getItem('partner');

        return (
            <div className="navigation" >
                {!partner &&
                    <h1 className="registrationLogo">opengrants.io</h1>
                }
                {partner &&
                    <h1 className="registrationLogo" style={{fontSize: 16, marginTop: 50}}>Powered by opengrants.io.</h1>
                }
                {/* {partner &&
                    <h1 className="registrationLogo">{partner}</h1>
                } */}
                <nav id="registrationNavigation">
                    <a id="nav_0" className="navigation-active">Create an Account</a>
                    <a id="nav_1" className={nav > 0 ? "navigation-active" : ""}>Complete Your Profile</a>
                    <a id="nav_2" className={nav > 1 ? "navigation-active" : ""}>Organization Details</a>
                    <a id="nav_3" className={nav > 2 ? "navigation-active" : ""}>Area of Focus</a>
                    <a id="nav_4" className={nav > 3 ? "navigation-active" : ""}>Legal Details</a>
                    <a id="nav_5" className={nav > 4 ? "navigation-active" : ""}>Location Information</a>
                    {!partner && <a id="nav_6" className={nav > 5 ? "navigation-active" : ""}>Referral</a>}
                    <a id={partner ? "nav_6" : "nav_7"} className={nav > 6 || (partner && nav > 5) ? "navigation-active" : ""}>Final Steps</a>
                </nav>
            
                <h1 id="navFooter">
                    <span>The easy way to find, win and manage grants.</span>
                </h1>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering, nav } = state.registration;
    const {registrationAlert} = state;
    return {
        registering, nav, registrationAlert
    };
}

const connectedRegistration_Navigation = connect(mapStateToProps)(Registration_Navigation);
export { connectedRegistration_Navigation as Registration_Navigation };