import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Button from '../../_components/dashboardComponents/CustomButtons/Button';
import { history } from '../../_helpers';
import { marketplaceActions, authenticateActions } from '../../_actions';

class MarketplaceToS extends Component {

  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('user'));
    this.state = {
      user: user,
    }
  }

  handleAccept = async () => {
    const { dispatch } = this.props;
    const update_user = this.state.user;

    this.setState({
      user: {
        ...this.state.user,
        marketplace_Terms_Service: true
      }
    })
    await dispatch(marketplaceActions.updateToS(this.state.user))
    await this.props.dispatch(authenticateActions.setUser(this.state.user));
  }

  handleDecline = () => {
    history.push("/dashboard")
  }

  render() {
    const { user } = this.state;

    return (
      <div id="listingPreview">
        <Modal id="listingPreviewModal"
          isOpen={!user.marketplace_Terms_Service}
          fade={false}
        >
          <ModalHeader>
            <h1 id="modalTitle">Terms Of Service</h1>
          </ModalHeader>
          <ModalBody>
            <p>
              OpenGrants service providers work on hourly and/or project-based fees, NOT on commission. Grant writing on commission is seen as bad practice, and in some cases is illegal. I agree that I will not ask or expect any OpenGrants service provider to work on commission, and I understand that payment is due at time of work.
            </p>
          </ModalBody>
          <ModalFooter style={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              color={"info"}
              style={{ minWidth: 200 }}
              onClick={() => this.handleAccept()}
            >
              Accept
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { setUser, profile } = state;
  const { user } = setUser;
  return {
    user,
    profile,
  };
}

const connectedMarketplaceToS = connect(mapStateToProps)(MarketplaceToS)
export { connectedMarketplaceToS as MarketplaceToS };