import { favoritesConstants } from '../_constants';
import { favoritesService } from '../_services';
import { notificationsActions } from '../_actions';
import { alertActions } from '.';
import { history } from '../_helpers';

export const favoritesActions = {
    getFavorites,
    saveListing
};

function getFavorites(user) {
    return dispatch => {
        dispatch(request(user));
        favoritesService.getFavorites(user)
            .then(
                favorites => dispatch(success(favorites)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: favoritesConstants.GETFAVORITES_REQUEST } }
    function success(favorites) {  return { type: favoritesConstants.GETFAVORITES_SUCCESS, favorites } }
    function failure(error) { return { type: favoritesConstants.GETFAVORITES_FAILURE, error } }
}

function saveListing(listing_id, user) {
    return async dispatch => {
        dispatch(request())
        await favoritesService.saveListing(listing_id, user)
        .then(
            () => {
                dispatch(success());
                dispatch(notificationsActions.getAllNotifications(JSON.parse(localStorage.getItem('user')).id));
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );

        favoritesService.getFavorites(user);
    };



    function request() { return { type: favoritesConstants.SAVELISTING_REQUEST } }
    function success() { return { type: favoritesConstants.SAVELISTING_SUCCESS } }
    function failure(error) { return { type: favoritesConstants.SAVELISTING_FAILURE, error } }
}