import React from "react";
import { connect } from 'react-redux';
import { Loader } from '../Shared/Loader';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import Label from "@material-ui/icons/Label";
import Class from "@material-ui/icons/Class";
import GridItem from "../../_components/dashboardComponents/Grid/GridItem.js";
import GridContainer from "../../_components/dashboardComponents/Grid/GridContainer.js";
import Table from "../../_components/dashboardComponents/Table/Table.js";
import CustomTabs from "../../_components/dashboardComponents/CustomTabs/CustomTabs.js";
import Card from "../../_components/dashboardComponents/Card/Card.js";
import CardHeader from "../../_components/dashboardComponents/Card/CardHeader.js";
import CardBody from "../../_components/dashboardComponents/Card/CardBody.js";
import '../../Styles/Resources.css';

class ProspectSV_Grantwriters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount() {

    }

    render() {
        const { user, whitelabel } = this.props;

        return (
            <div id="prospectSVResources">
                {/* <h1 id="dashboardHeader" style={{marginBottom: 0}}>FIND AN EXPERT</h1> */}
                {!user && <Loader />}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                    <CustomTabs
                        marginRight={25}
                        initialValue={0}
                        headerColor={whitelabel ? whitelabel.dashboard_Color : "#29a"}
                        tabs={[
                        {
                            tabName: "Grant Writers",
                            tabIcon: Label,
                            tabContent: (
                                <div>
                                    <br />
                                    <p id="prospectSVExpertDesc">
                                        Grant writers are professionals with experience writing grants for startups. Each writer specializes in different programs, from NIH to DOD. Typically, grants are available to organizations that are working on technological and/or social innovation. Click on their profiles to read more, connect with them, and learn about the grant application process.                                    </p>
                                    <iframe 
                                        class="airtable-embed" 
                                        src="https://airtable.com/embed/shr5VoD8nclnX9bvF?backgroundColor=cyan&viewControls=on"
                                        frameborder="0" 
                                        onmousewheel="" 
                                        width="100%" 
                                        height="533" 
                                        style={{background: "transparent", border: "1px solid #ccc"}}
                                    >
                                    </iframe>
                                    <br />
                                </div>
                            )
                        },
                        ]}
                    />
                    </GridItem>
                </GridContainer>
                {/* {user && (whitelabel && whitelabel.features.feature_Expert_Resources) &&
                    <div id="findExperts">
                        
                    </div>
                } */}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        whitelabel
    };
}

const connectedProspectSV_Grantwriters = connect(mapStateToProps)(ProspectSV_Grantwriters);
export { connectedProspectSV_Grantwriters as ProspectSV_Grantwriters };
