import React from 'react';
import { connect } from 'react-redux';
import { organizationService } from '../../../_services';
import { Registration_AutoSuggestion } from '../../Account/RegisterPage';
import '../../../Styles/Organization.css';

class EditOrganizationNAICS extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            org: this.props.org,
            industries: [],
        }
    }

    componentDidMount() {
        this.getNAICSCodes();
    }

    getNAICSCodes = async() => {
        const { user } = this.props;
        var naicS_Codes = await organizationService.getNAICSCodes(user.id);

        this.setState({
            naicS_Codes
        });
    }


    render() {
        const { naicS_Codes }  = this.state;
        const { org } = this.props;

        let naicS_Codes_Cat = {"naicS_Codes": naicS_Codes};

        return (
            <div style={{paddingBottom: 20}}>
                <h2>NAICS Code(s)</h2>
                <Registration_AutoSuggestion 
                    categories={naicS_Codes_Cat} 
                    categoryName="naicS_Codes"
                    currentSelectedCategories={org.naicS_Codes}
                    // submitted={submitted}
                    handleCategorySelection={this.props.handleCategorySelection}
                    addTags={true}
                    multiple={true}
                    handleCategoryInput={this.props.handleCategoryInput}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        organization,
        whitelabel,
    };
}

const connectedEditOrganizationNAICS = connect(mapStateToProps)(EditOrganizationNAICS);
export { connectedEditOrganizationNAICS as EditOrganizationNAICS };