import React, { Component } from 'react';

export class HeaderTags extends Component {
    static displayName = HeaderTags.name;

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div id="headerTags">
                {/* <script async src="https://www.googletagmanager.com/gtag/js?id=UA-159663079-1"></script>
                <script>
                    window.dataLayer = window.dataLayer || [];
                        function gtag() { dataLayer.push(arguments) }
                        gtag('js', new Date());
                        gtag('config', 'UA-159663079-1');
                </script> */}
                {/* <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script> */}
                {/* <script src="/lib/bootstrap/dist/js/bootstrap.js"></script>
                <script src="/lib/signalr/dist/browser/signalr.js"></script>
                <script src="/js/sweetalert.js"></script>
                <script src="/js/site.js"></script>
                <script src="/js/delete.js"></script>
                <script src="/js/easy-autocomplete.js"></script>
                <script src="/js/bootstrap-multiselect.js"></script> */}
                <meta name="google-site-verification" content="HSDsmsILNBLnHI0Q-yqVqMrlQLGSEbmlkmxl1aQOQkc" />
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                {/* <link rel="icon" href="~/images/egeria/opengrantsSM.png" type="image/x-icon" /> */}
                <link href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-multiselect/0.9.15/css/bootstrap-multiselect.css" rel="stylesheet" type="text/css" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                <link href="https://fonts.googleapis.com/css?family=Ubuntu:100,300,700&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css?family=Raleway&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css?family=Montserrat:700&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,700&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css?family=Oswald:700&display=swap" rel="stylesheet" />
            </div>
        );
    }
}