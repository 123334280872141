import { authHeader } from '../_helpers';
import { API_Route } from '../App/API_Route';

export const chatService = {
    create_conversation,
    delete_conversation,
    add_participants,
    generate_token,
    updateChatInfo,

    send_email_notification_about_dm
};

function create_conversation(conversation_name) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(conversation_name)
    };

    return fetch(API_Route + '/api/chat/create-conversation', requestOptions)
    .then(handleResponse)
}

function delete_conversation(channel) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(channel)
    };

    return fetch(API_Route + '/api/chat/delete-conversation', requestOptions)
    .then(handleResponse)
}

function add_participants(chat) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(chat)
    };

    return fetch(API_Route + '/api/chat/add-participants', requestOptions)
    .then(handleResponse)
}

function generate_token(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/chat/generate-token?user_id='+user_id, requestOptions)
    .then(handleResponse)
}

function send_email_notification_about_dm(from_user_id, to_username) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/chat/send_email_notification_about_dm?from_user_id=' + from_user_id + '&to_username=' + to_username, requestOptions)
    .then(handleResponse)
}

function updateChatInfo(messageInfo) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(messageInfo)
    };

    return fetch(API_Route + '/api/chat/update_chat_info', requestOptions)
    .then(handleResponse)
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok ) {
            if (response.status == "401") {
                sessionStorage.setItem('redirect', window.location.href);
                window.location.href="/login";
            }
            
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}