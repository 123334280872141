import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '../../../Shared/Loader';
import { history } from '../../../../_helpers';
import ProgressBar from 'react-bootstrap/ProgressBar';
import '../../../../Styles/QuickstartSurvey.css';

class LearnMore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            submitted: false,
            loading: true,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        document.body.scrollTop = 0;

        setTimeout(() => {
            this.setState({
            loading: false
            });
        }, 500);
    }

    handleChange(e) {
        const {name, value} = e.target;
        let {user} = this.state;
        user[name] = value;
        this.setState({
            user: user
        })
    }

    handleNavigation = async(e) => {      
        history.push('/quickstart/how-it-works');
    }

    useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '100ch',
            },
        },
    }));   

    render() {
        const { loading } = this.state;
        const { whitelabel } = this.props;
        const now = 10;
        const progressInstance = <ProgressBar now={now} label={`${now}%`} />;
        const progressBarColor = whitelabel ? whitelabel.accent_Color : "#29a";

        return (
            <div>
                <style>{"\
                    .progress-bar{\
                    background:" + progressBarColor + ";\
                    }\
                "}</style>
                {loading && <Loader />}
                <div>{progressInstance}</div>
                <div id="fundingSlider2">
                    <h1 id="askQuestions">
                        We'll ask you some basic questions about your organization and match you with the funding that suits you best.
                    </h1>
                </div>
                <div className="continueButton">
                    <button 
                        className="signUpContinue"
                        onClick={() => this.handleNavigation('next')}
                        style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                    >
                        Learn More  →
                    </button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering, nav } = state.registration;
    const {registrationAlert} = state;
    const { whitelabel } = state.setWhitelabel;

    return {
        registering, 
        nav, 
        registrationAlert,
        whitelabel,
    };
}

const connectedLearnMore = connect(mapStateToProps)(LearnMore);
export { connectedLearnMore as LearnMore };