import React from 'react';
import { connect } from 'react-redux';
import { stripeService } from '../../../_services';
import { stripeActions } from '../../../_actions';
import {StripeMultipleCheckout, StripeBillingUpdated} from '.';
import { Footer } from '../../Shared/Footer';
import '../../../Styles/StripeProduct.css';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";

class StripeCheckout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            invoiceId: null,
            couponId: '',

            allPrices: [],

            consolidatedProducts: [],
            selectedPrices: {},
            selectedPriceIds: [],
            selectedRecurringPriceIds: [],
            total: 0,

            purchaseSuccess: false,

            allowRemove: true,
            productType: this.props.match ? this.props.match.params["group"] : this.props.productType,
            page: this.props.match ? this.props.location.pathname.split("/")[1] : '',
            listingId: this.props.location ? this.props.location.search.includes('grantId') ? this.props.location.search.split("=")[1] : 0 : 0,

            discount: null,
            discountStatus: {
                status: '',
                message: ''
            },
            invoiceDiscount: null,

            errorMessage: ''
        }
    }

    async componentDidMount() {
        await localStorage.setItem('checkout', 1);
        let {page, invoiceId, allowRemove, productType} = this.state;
        let defaultProductId = productType + " default";
        let defaultPrice = "";
        let consolidatedProducts = {};
        let invoiceDiscount = this.state.invoiceDiscount;

        var allPrices = null;
        var prices = null;

        if (!productType) productType = page;

        //set all prices
        allPrices = await stripeService.getAllProducts();
        // allPrices = allPrices["data"];

        //sort all prices - asc, month, year - prioritize product in relation to page
        allPrices = allPrices.sort((a, b) => {
            if (defaultProductId != "") {
                if (a.product.metadata["group"] == defaultProductId && b.product.metadata["group"] != defaultProductId) {
                    return -1
                } else if (a.product.metadata["group"] != defaultProductId && b.product.metadata["group"] == defaultProductId) {
                    return 1
                }
            }
            if (!a.recurring) return -1
            if (!b.recurring) return 1
            if (a.recurring && b.recurring) {
                    if (a.recurring.interval == "month" && b.recurring.interval == "year") return -1
                    if (a.recurring.interval == "year" && b.recurring.interval == "month") return 1
                return a.unit_amount - b.unit_amount;
            }
            if (a.recurring && !b.recurring)
                return 1
            if (!a.recurring && b.recurrig)
                return -1
            return a.unit_amount - b.unit_amount;
        });

        //set available prices, set consolidated products, add default prices to cart
        let selectedProduct = {};
        allPrices.filter((price) => {
            if (price.active && price.product.active) {
                if (!price.metadata["customer"]) {
                    const priceGroup = price.product.metadata["group"];

                    if (priceGroup.includes(productType + " default"))  {
                        if (!selectedProduct[price.product.id]) {
                            selectedProduct[price.product.id] = price.id;
                            this.addToCart([price], [], [], 0);
                        }
                    }

                    if (priceGroup.includes(productType)) {
                        if (consolidatedProducts[price.product.id]) {
                            var prodList = consolidatedProducts[price.product.id];
                            prodList.push(price);
                            consolidatedProducts[price.product.id] = prodList;
                        } else {
                            consolidatedProducts[price.product.id] = [price];
                        }

                        return price;
                    }
                }
            }
        });

        //add to cart default prices
        // if (defaultPrice != "") this.addToCart([defaultPrice], [], [], 0);

        this.setState({
            allowRemove,
            invoiceDiscount,
            consolidatedProducts,
            invoiceId,
            allPrices,
            page,
            productType
        });
    }

    async componentWillUnmount() {
        localStorage.setItem('checkout', 0)
    }

    //returns true if user details is empty
    userDetailsIsEmpty = (userDetails) => {
        const {name, email} = userDetails;

        if (name === '' && email === '') {
            return true;
        }
        return false;
    }

    //returns true if production details is empty
    productDetailsEmpty = (productDetails) => {
        const {priceIds, recurringPriceIds, paymentMethodId} = productDetails;

        if (priceIds.length === 0 && recurringPriceIds.length === 0) {
            return true;
        }

        if (paymentMethodId === "") {
            return true;
        }

        return false;
    }

    //purchase product
    purchaseProductWithMethod = async (paymentDetails, userDetails) => {
        const {paymentMethodId} = paymentDetails;
        const {selectedPriceIds,selectedRecurringPriceIds, couponId, total, listingId} = this.state;

        //process card input if no card found
        if (!paymentMethodId) {
            document.getElementById("cardFormSubmit").click();
            return;
        }

        let userNotes = document.getElementById('userNotes') ? document.getElementById('userNotes').value : "";

        //set product details for purchasing
        var productDetails = {
            userDetails: {
                ...userDetails,
                userNotes
            },
            productDetails: {
                invoiceId: this.state.invoiceId,
                priceIds: selectedPriceIds,
                recurringPriceIds: selectedRecurringPriceIds,
                paymentMethodId,
                couponId,
                listingId
            }
        };

        //verify user details not empty
        if (this.userDetailsIsEmpty(productDetails.userDetails)) {
            return;
        }
        //verify prodcut details not empty
        if (this.productDetailsEmpty(productDetails.productDetails)) {
            return;
        }

        //purchase product
        this.props.dispatch(stripeActions.purchaseProduct(productDetails));
    }

    //scroll to error message
    scrollToErrorMessage = () => {
        const {statusType} = this.props.purchaseProduct;

        if (!localStorage.getItem('user')) {
            if (statusType === "card") {
                //scroll to payment info
                var paymentForm = document.getElementById("cardForm").scrollHeight;
                document.getElementsByClassName("stripe-product")[0].scrollTo(0,paymentForm-300);
            } else if (statusType === "general") {
                //scroll to top
                document.getElementsByClassName("stripe-product")[0].scrollTo(0,0);
            }
        } else {
            if (statusType === "card") {
                //scroll to payment info
                var paymentForm = document.getElementById("cardForm").scrollHeight;
                document.body.scrollTo(0,paymentForm+100);
            } else if (statusType === "general") {
                //scroll to top
                document.body.scrollTo(0,0);
            }
        }
    }

    scrollToTop = () => {
        if (!localStorage.getItem('user')) {
            document.getElementsByClassName("stripe-product")[0].scrollTo(0,0);
        } else {
            document.body.scrollTo(0,0);
        }
    }

    //remove prodcut from cart
    removeFromCart = async (productId) => {
        var selectedPriceIds = Object.assign([], this.state.selectedPriceIds);
        var selectedRecurringPriceIds = Object.assign([], this.state.selectedRecurringPriceIds);

        var total = this.state.total;
        var pricesToRemove = this.state.consolidatedProducts[productId];

        //remove prices from selected
        pricesToRemove.map((price) => {
            if (selectedPriceIds.includes(price.id))
                selectedPriceIds = selectedPriceIds.filter((priceId) => {
                    if (priceId != price.id) {
                        return price;
                    } else {
                        if (price.product.metadata["trialPrice"]) {
                            total -= parseInt(price.product.metadata["trialPrice"]);
                        } else {
                            total -= price.unit_amount;
                        }
                    }
                });

            if (selectedRecurringPriceIds.includes(price.id))
                selectedRecurringPriceIds = selectedRecurringPriceIds.filter((priceId) => {
                    if (priceId != price.id) {
                        return price;
                    } else {
                        if (price.product.metadata["trialPrice"]) {
                            total -= parseInt(price.product.metadata["trialPrice"]);
                        } else {
                            total -= price.unit_amount;
                        }
                    }
                });
        })

        //remove product
        let selectedPrices = Object.assign({}, this.state.selectedPrices);
        delete selectedPrices[productId];

        // const discount = await this.handleCoupon(null, total);

        this.setState({
            total,
            selectedPrices,
            selectedPriceIds,
            selectedRecurringPriceIds,
            // ...discount
        })
    }

    //add price to cart to purchase
    addToCart = async (prices, selectedPriceIds, selectedRecurringPriceIds, total) => {
        let selectedPrices = Object.assign({}, this.state.selectedPrices);
        // let prices = Object.assign({}, this.state.prices);
        let priceIds = [];

        prices.filter((price) => {
            let priceId = price.id;

            //add recurring price
            if (price.recurring) {
                if (!selectedRecurringPriceIds.includes(priceId)) {
                    selectedRecurringPriceIds.push(priceId);
                    priceIds.push(priceId);
                }
            }
            //add price
            else {
                if (!selectedPriceIds.includes(priceId)) {
                    selectedPriceIds.push(priceId);
                    priceIds.push(priceId);
                }
            }

            //update product price
            if (Object.keys(price.metadata).length == 0 || (price.metadata["Type"] && price.metadata["Type"] !== "Coupon")) {
                let productId = price.product.id;
                selectedPrices[productId] = price;
            }

            //update total
            var includeInTotal = true;
            if (price.product.metadata["group"].includes(this.state.productType + " default")) {
                if (this.props.billedToday) {
                    includeInTotal = false;
                }
            }

            if (includeInTotal) {
                if (price.product.metadata["trialPrice"]) {
                    total += parseInt(price.product.metadata["trialPrice"]);
                } else {
                    total += price.unit_amount;
                }
            }
        });

        // const discount = await this.handleCoupon(null, total);

        this.setState({
            selectedPrices,
            selectedPriceIds,
            selectedRecurringPriceIds,
            total,
            // ...discount
        });
    }

    //update selected price
    updateSelectedProduct = (newPrice) => {
        let selectedPriceIds = Object.assign([], this.state.selectedPriceIds);
        let selectedRecurringPriceIds = Object.assign([], this.state.selectedRecurringPriceIds);
        var oldPris = {};
        var oldPriIds = [];

        //get unselected price
        var prod = this.state.consolidatedProducts[newPrice.product.id];
        prod.filter((pri) => {
            if (pri.id != newPrice.id) {
                oldPris[pri.id] = pri;
                return pri
            }
        });
        oldPriIds = Object.keys(oldPris);

        //remove unselected price from cart
        let newSelectedPriceIds = [];
        let newSelectedRecurringPriceIds = [];
        var total = this.state.total;
        let oldSelectPriceIds = [];

        //set new selected price ids
        newSelectedPriceIds = selectedPriceIds.filter((id) => {
            if (!oldPriIds.includes(id)) {
                return id
            } else {
                oldSelectPriceIds.push(id);
                let price = oldPris[id];
                if (price.product.metadata["trialPrice"]) {
                    total -= parseInt(price.product.metadata["trialPrice"]);
                } else {
                    total -= price.unit_amount;
                }
            }
        });

        //set new recurring selected price ids
        newSelectedRecurringPriceIds = selectedRecurringPriceIds.filter((id) => {
            if (!oldPriIds.includes(id)) {
                return id
            } else {
                oldSelectPriceIds.push(id);
                let price = oldPris[id];
                if (price.product.metadata["trialPrice"]) {
                    total -= parseInt(price.product.metadata["trialPrice"]);
                } else {
                    total -= price.unit_amount;
                }
            }
        });

        //add selected price to card
        this.addToCart([newPrice], newSelectedPriceIds, newSelectedRecurringPriceIds, total)
    }

    //returns coupon data
    getCoupon = async (coupon) => {
        const {selectedPriceIds, selectedRecurringPriceIds} = this.state;

        var paymentDetails = {
            userDetails: {},
            productDetails: {
                priceIds: selectedPriceIds,
                recurringPriceIds: selectedRecurringPriceIds,
                couponId: coupon
            }
        }
        var discount = await stripeService.getCouponDiscount(paymentDetails);

        return discount;
    }

    //handle adding a coupon
    handleCoupon = async (coupon, total) => {
        let oldDiscount = this.state.discount;
        if (!oldDiscount && !coupon) {
            let discount = null;
            let discountStatus = "";
            return {discount, discountStatus};
        }
        let couponId = coupon;
        const {selectedPriceIds, selectedRecurringPriceIds} = this.state;
        var valid = true;
        let discountStatus = Object.assign({}, this.state.discountStatus);
        let status = "success";
        if(total == null) total = this.state.total;

        //get discount data
        var discount = (coupon && (!oldDiscount || oldDiscount.name === null || oldDiscount.name !== coupon)) ?
            await this.getCoupon(coupon) :
            oldDiscount;
        let discountMessage = coupon;

        //process discount
        if (discount) {
            //verify coupon is applicable
            if (discount.type == "amount") {
                //fail if discount product not selected
                // if (discount.product && discount.product != "") {
                //     if ((!selectedPriceIds.includes(discount.product)
                //             && !selectedRecurringPriceIds.includes(discount.product))) {
                //         couponId = '';
                //         status = "failed";
                //         valid = false;
                //         discount = null;
                //     }
                // }
                // //fail if discount is less than total cart
                // else if (total < discount.amount) {
                //     couponId = '';
                //     status = "failed";
                //     valid = false;
                //     discount = null;
                // }  else {
                //     discountMessage += " ($" + discount.amount + " off)"
                // }
                discountMessage += " ($" + discount.amount + " off)"
            } else if (discount.type == "percent") {
                discountMessage += " (" + discount.amount + "% off)"
            }
        }
        else {
            couponId = '';
            discount = null;
            status = "failed";
            valid = false;
        }

        discountStatus["status"] = status;
        if (!oldDiscount || (oldDiscount && oldDiscount.name !== coupon)) {
            if (status == "failed") {
                if (coupon)
                    discountMessage = "Could not apply promo code: " + discountMessage;
                else
                    discountMessage = "";
            }
            discountStatus["message"] = discountMessage;

            this.setState({
                couponId,
                discount,
                discountStatus
            });
        }
        return {discount, discountStatus};
    }

    render() {
        const checkoutState = this.state;
        const {productType} = this.state;
        const purchaseProduct = this.props.purchaseProduct;
        const {purchaseSuccess} = this.props.purchaseProduct;
        const {updatePlan, resetProductType} = this.props;
        let {allPrices, prices, invoiceId} = checkoutState;

        if (purchaseProduct.message && purchaseProduct.message != "") {
            this.scrollToErrorMessage();
        }

        if (purchaseSuccess == true) {
            this.scrollToTop();
        }

        return (
            <div id="stripeCheckout">
                {updatePlan &&
                    <Button 
                        style={{marginBottom:20, float: "none"}} 
                        onClick={() => {this.props.updateBillingInfo()}}
                    >
                        {"<<< Return To Billing"}
                    </Button>
                }
                {resetProductType && !updatePlan &&
                    <Button 
                        style={{marginBottom:"20px"}} 
                        onClick={resetProductType}
                    >
                        {"<<< Back"}
                    </Button>
                }

                {allPrices && <StripeMultipleCheckout
                    checkoutState={checkoutState}
                    purchaseProduct={purchaseProduct}
                    purchaseProductWithMethod={this.purchaseProductWithMethod}
                    removeFromCart={this.removeFromCart}
                    updateSelectedProduct={this.updateSelectedProduct}
                    handleCoupon={this.handleCoupon}
                    scrollToErrorMessage={this.scrollToErrorMessage}
                    productType={productType}
                    updatePlan={updatePlan}
                    updateBillingInfo={this.props.updateBillingInfo}
                    billedToday={this.props.billedToday}
                />}

                {invoiceId && !allPrices &&
                    <div className='adjustable-width col-md-12'>
                        <StripeBillingUpdated invoiceId={invoiceId} />
                    </div>
                }

                {
                    !localStorage.getItem('user') &&
                    <Footer />
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { purchaseProduct } = state;
    return {
        purchaseProduct
    };
}

const connectedStripeCheckoutPage = connect(mapStateToProps)(StripeCheckout);
export { connectedStripeCheckoutPage as StripeCheckout };