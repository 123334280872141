import React from 'react';
import { CreateInputs } from './CreateInputs';
import { CreateTags } from './CreateTags';

class DropDownUpdated extends React.Component {
    constructor(props) {
        super(props);
        const {selectedCategories} = this.props;

        this.state = {
            categories: selectedCategories
        }
    }
    //add clicked option
    updateSelectedCategories = (option_id, idx) => {
        const {categoryList, multiple, selectedCategories} = this.props;
        var options = categoryList;
        // var options = categoryList[option_id];
        var optionValue = options[idx];

        //update category
        const categories = Object.assign({}, this.state.categories);
        if (multiple) {
            if (!selectedCategories.includes(optionValue)) {
                var currentSelectedCategory = selectedCategories.slice();
                currentSelectedCategory.push(optionValue);
                categories[option_id] = currentSelectedCategory;
            }
        }
        else 
            categories[option_id] = optionValue;

        this.setState({
            categories: categories
        });

        //update parent categories
        const {handleCategorySelection} = this.props;
        handleCategorySelection(categories);
    }

    resetSelectedCategories = (option_id) => {
        const categories = {
            [option_id]: []
        }
        const {handleCategorySelection} = this.props;
        handleCategorySelection(categories);
    }

    render() {
        const {categories} = this.state;
        const {submitted, categoryList, requiredInput, multiple, selectedCategories, option_id} = this.props;

        //create tag for each inputs
        // let tags = null;
        // if (multiple)
        //     tags = selectedCategories.map((category) => {
        //         return (
        //             <div key={"tags_" + category} className="categoryTag">
        //                 {category}
        //                 <div className="removeTag" onClick={() => this.removeOptionTag(category)}>X</div>
        //             </div>
        //         )
        //     });

        return(
            <>
                <CreateInputs 
                    option_id={option_id}
                    submitted={submitted} 
                    selectedCategories={this.props.selectedCategories} 
                    categoryList={categoryList} 
                    requiredInput={requiredInput}
                    updateSelectedCategories={this.updateSelectedCategories}
                    multiple={multiple} />
                
                {/* {multiple && selectedCategories.length > 0 && 
                <div className="align-left">
                    <a id="clearAll" className="showClearAll" onClick={this.clearTags}>Clear</a>
                    <div id="tags">{tags}</div>
                </div>
                } */}
                
                {multiple && 
                    <CreateTags 
                        selectedCategories={selectedCategories} 
                        option_id={option_id}
                        resetSelectedCategories={this.resetSelectedCategories}
                        handleCategorySelection={this.props.handleCategorySelection}
                    />
                }
            </>
        )
    }
}

export { DropDownUpdated };