import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import connectClearRefinements from './connectClearRefinement';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";

class CustomClearAllRefinments extends Component {

  onClear = () => {
    this.props.refine({});
    sessionStorage.removeItem('from');
    sessionStorage.removeItem('to');
  }

  render() {
    return (
      <div>
          <button id="clearFilters" onClick={this.onClear}>
            Clear Filters
          </button>
      </div>
    );
  }
}

export default connectClearRefinements(CustomClearAllRefinments);