import React from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import TextTruncate from '../../../node_modules/react-text-truncate';
import stripHtml from "string-strip-html";
import he from '../../../node_modules/he';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import ReactTooltip from "react-tooltip";
import { favoritesActions } from '../../_actions';
import { Highlight, connectHighlight, } from 'react-instantsearch-dom';
import '../../Styles/Algolia.css';

export const unixDate = (timestamp) => {
    var a = new Date(timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var time = month + ' ' + date + ', ' + year;
    
    return time;
};

const viewListing = (id) => {
    const server = window.location.protocol + '//' + window.location.host;
    const source_link = server + "/grants/" + id;
  
    window.open(source_link, "_blank")
};

const CustomHighlight = connectHighlight(({ highlight, attribute, hit }) => {
    const parsedHit = highlight({
      highlightProperty: '_highlightResult',
      attribute,
      hit
    });
  
    return (
      <div>
        {parsedHit.map(
          part => (part.isHighlighted ? <mark>{part.value}</mark> : part.value)
        )}
      </div>
    );
  });

class Result extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props);
        this.state = {
            isFavorite: false,
            searchGroup: {
                search: "",
                user: this.props.user,
                categories: [],
                agencies: [],
                page: 1,
                totalResults: 0,
            },
        };
        this.saveListing = this.saveListing.bind(this);
    }

    componentDidMount() {
        const user_role = this.props.user.userRoles;

        if (user_role != 'Client_Free')
        {
          this.setState({
            isFavorite: JSON.parse((localStorage.getItem('favorite'))).some(item => item.listing_Id == this.props.listing.hit.objectID) ? true : false
          })
        }
    }

    async saveListing(e) {
        e.stopPropagation();
        ReactTooltip.hide();

        const listing = this.props.listing.hit;
        const { user } = this.props;
        const { isFavorite } = this.state;
        const add = true;

        await this.setState(prevState => ({
            isFavorite: !prevState.isFavorite,
            //favorites: localStorage.getItem('favorite'),
        })); 

        await this.props.dispatch(favoritesActions.saveListing(listing.objectID, user));
        await this.props.dispatch(favoritesActions.getFavorites(user));
        setTimeout(1000);
        
        this.setState(prevState => ({
            searchGroup: {
                ...prevState.searchGroup,
            },
            favorites: JSON.parse(localStorage.getItem('favorite')),
            favoritesCount: add === true ? (prevState.favoritesCount - 1) : (prevState.favoritesCount + 1)
        }), () => {});
    };

    render() {
        const listing = this.props.listing.hit;
        const funding = listing.funding_Amount;
        const app_due_date = listing.application_Due_Date;
        const normalized_due_date = unixDate(app_due_date);
        const description = stripHtml(listing.description, {
                               stripTogetherWithTheirContents: [
                                 'script', // default
                                 'style',  // default
                                 'xml',    // default
                                 'pre', // <-- custom-added
                               ]}
                             ).result;
         const { user, whitelabel } = this.props;
   
         return (
           <div id="searchHitItem">
             {/* TITLE OUTPUT */}
             <ReactTooltip effect="solid" />
             {whitelabel && whitelabel.features.feature_Favorites &&
              !this.state.isFavorite && user.userRoles != 'Client_Free' &&
               <div class="saveMatchDiv"
                 onClick={this.saveListing}
                 data-tip="Add to favorites."
               >
                 <i class="material-icons" style={{marginRight: -3.5, color: "#3a4570c7"}}>save</i>
               </div>
             }
             {whitelabel && whitelabel.features.feature_Favorites && 
              this.state.isFavorite && user.userRoles != 'Client_Free' &&
               <div class="clearMatchDiv"
                   onClick={this.saveListing}
                   data-tip="Discard from favorites."
               >
                   <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="">
                       <polyline points="3 6 5 6 21 6"></polyline>
                       <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                   </svg>
               </div>
             }
             <div className="hit-title">
               <CustomHighlight attribute="title" hit={listing} />
               <TextTruncate
                 line={2}
                 element="class"
                 truncateText="…"
                 text={he.decode(listing.title)}
                 style={{fontWeight: 900}}
               />
             </div>
   
             {/* AGENCY OUTPUT */}
             <div className="hit-agency" style={{marginTop:5}}>
               <CustomHighlight attribute="description" hit={listing} />
               {listing.agency}
             </div>
             <hr />
   
             {/* DESCRIPTION OUTPUT */}
             <TextTruncate
                 line={3}
                 element="class"
                 truncateText="…"
                 text={he.decode(description.trim())}
                 style={{color: "#333", fontSize:14}}
             />
   
             {/* FUNDING AMOUNT OUTPUT */}
             <br /><br /><br />
             <div className="hit-funding">
               <Highlight attribute="description" hit={listing} />
               {(listing.funding_Amount != null && listing.funding_Amount > 0) &&
                 <NumberFormat value={listing.funding_Amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
               }
               {(funding == 0 || !funding) && 
                 <p>Funding varies.</p>
               }
             </div>
   
             {/* DUE DATE OUTPUT */}
             <div className="hit-description">
               <Highlight attribute="description" hit={listing} />
               {app_due_date && <p><strong>Due: </strong>{normalized_due_date}</p>}
               {!app_due_date && <p>Applications ongoing.</p>}
             </div>
             <hr />
   
             {/* CATEGORY OUTPUT */}
             <div className="hit-category">
                <Button color={whitelabel ? whitelabel.button_Color : "#29a"} onClick={() => {viewListing(listing.objectID)}}>
                    View Grant
                    <ArrowForwardIos style={{float: "right", fontSize:32, marginTop: 0, marginLeft:10, marginRight:-10}}/>
                </Button>
             </div>
           </div>
         );
       }
}

function mapStateToProps(state) {
    const { setUser, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        whitelabel,
    };
}

const connectedResult = connect(mapStateToProps)(Result);
export { connectedResult as Result };