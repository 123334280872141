import React from 'react';
import { connect } from 'react-redux';
import { SaveListing } from './SaveListing';
import { ShareListing } from './ShareListing';
import { history } from '../../_helpers';
import '../../Styles/Listing.css'

class ListingAdditionalInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            favorites: localStorage.getItem('favorite') ? localStorage.getItem('favorite').includes(this.props.listing.listing_Id) : false
        }

        this.handleFindGrantWriter = this.handleFindGrantWriter.bind(this);
        this.moreInfo = this.moreInfo.bind(this);
        this.viewOriginalListing = this.viewOriginalListing.bind(this);
    }

    moreInfo() {
        window.open('https://experience.opengrants.io/learn-grants');
    }

    handleFindGrantWriter() {
        var metadata = {
            listing_Name: this.props.listing.title,
            listng_Url: window.location.origin + "/grants/" + this.props.listing.listing_Id,
          };
        Intercom('trackEvent', 'find a grant writer', metadata); // eslint-disable-line
        history.push("/grantwriters");
    }

    viewOriginalListing() {
        const listing = this.props.listing;
        const source_link = listing.agency_Link !== null ? listing.agency_Link : listing.sbiR_Link;

        window.open(source_link, "_blank")
    }

    openChat(type) {
        window.Intercom('trackEvent', type);
        window.Intercom('showNewMessage');
    }

    render() {
        const listing  = this.props.listing;
        return (
            <div className="additionalInfo">
                <SaveListing listing={listing} />
                <button className="requestInfoButton" onClick={() => this.openChat('Request Information')}>
                    REQUEST INFORMATION
                </button>
                <button className="findWriterButton" onClick={this.handleFindGrantWriter}>
                    FIND A GRANT WRITER
                </button>
                {(listing.sbiR_Link !== null || listing.agency_Link !== null) &&
                    <button className="findWriterButton" onClick={this.viewOriginalListing}>
                        VIEW POSTING
                    </button>
                }
                <div id="sideInfo">
                    { listing.program && listing.phase && listing.year &&
                        <div id="additonalInfo">
                            <hr />
                            <h4>ADDITIONAL INFO</h4>
                            <p><strong>Program:</strong> { listing.program }</p>
                            <p><strong>Phase:</strong> { listing.phase }</p>
                            <p><strong>Year:</strong> { listing.year }</p>
                        </div>
                    }
                    <hr />
                    <div id="listingViews">
                        <h4>RECENT ACTIVITY</h4>
                        <p>Listing viewed { this.props.listingViews } times.</p>
                    </div>
                    <hr />
                    <ShareListing id={ listing.listing_Id } value={ listing } />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser } = state;
    const { user } = setUser;
    return {
        user
    };
}

const connectedListingAdditionalInfo = connect(mapStateToProps)(ListingAdditionalInfo);
export { connectedListingAdditionalInfo as ListingAdditionalInfo };