import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { settingsActions } from '../../_actions';
import { authenticateActions } from '../../_actions';
import { personalprofileService } from '../../_services';
import { Previews } from './ImageUpload';
import { Loader } from '../Shared/Loader';
import './ImageUpload.jsx';
import '../../Styles/SettingsStyles/Profile.css';
import '../../Styles/Organization.css';
import {ProfileList} from '.';

import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import Class from "@material-ui/icons/Class";

const styles = {
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    }
  };

class ProfileView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: "",
            organizations: "",
            loading: true,

            imgSizes: {}
        }
    }

    componentDidMount = async() => {
        let username = window.location.pathname.split('/').pop();
        if (username) {
            var result = await personalprofileService.getProfile(username);

            if (result) {
                this.setState({
                    profile: result.profile,
                    organizations: result.organizations,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        } else {
            this.setState({
                loading: false
            });
        }
    }

    openOrganization = (organization_Id) => {
        if (organization_Id) {
            var a = document.createElement("a");
            a.href = "/organizations/"+organization_Id;
            a.target = "_blank";
            a.click();
        }
    }
    
    getMeta = async (name, url, callback) => {
        var img = new Image();
        img.src = url;
        img.onload = (e) => { 
            var width = e.target.width;
            var height = e.target.height;
            var marginleft = 0;

            if (width > height) {
                var ratio = 150/height;
                marginleft = (150 - width*ratio)/2

                height = "200px";
                width = "auto";
            } else if (height > width) {
                width = "200px";
                height = "auto";
            } else {
                height = "200px";
                width = "200px";
            }

            let imgSizes = Object.assign({}, this.state.imgSizes);
            this.setState({
                imgSizes: {
                    ...imgSizes,
                    [name]: {
                        width,
                        height, 
                        marginleft
                    }
                }
            })

            // this.setState({
            //     width,
            //     height,
            //     marginleft
            // });

            return {width, height, marginleft};
        }
    }

    render() {
        const { profile, organizations, loading,
            imgSizes
        }  = this.state;
        console.log(this.state);
        
        if (profile.headshot && !imgSizes.headshot)
            this.getMeta("headshot", profile.headshot);

        var organizationTags = null;
        if (organizations) {
            organizationTags = organizations.map((organization) => {
                return(
                    <div key={organization.organization_Id} href={"/organizations/"+organization.organization_Id} target="_blank" style={{display: "inline-block", padding: "20px", width: "300px", color: "black"}}>
                        {organization.logo_URL && <img src={organization.logo_URL} style={{height: "60px", width: "60px", borderRadius: "50%", margin: "0 10px 0 0", marginLeft: -10, verticalAlign: "top"}} />}
                        {!organization.logo_URL && <i className="material-icons" style={{float: "left", color: "black", fontSize: "60px", width: "60px", borderRadius: "50%"}}>business</i>}
                        <a href={"/organizations/"+organization.organization_Id} target="_blank" className="linkListing" style={{display: "inline-block", color: "black"}}>
                            <p><strong>{organization.name}</strong></p>
                            <p>{!organization.description || organization.description.length < 50 ? organization.description : organization.description.substring(0, 50) + "..."}</p>
                        </a>
                    </div>

                    
                    // <a href="#" class="organizationList">
                    //     <div>
                    //         <div>
                    //             <h2>Heading here</h2>
                    //             <p>Text content here.</p>
                    //         </div>
                    //     </div>
                    // </a>
                )
            });
        }

        return (
            <div>
                <div id="mainSettings">
                    {loading && <Loader />}
                    {!loading && !profile &&
                        <ProfileList />
                    }
                    {profile && 
                    <div>
                        <div className="row">
                            <div>
                                <div style={{display: "flex"}}>
                                    <div className="profile_list" style={{flexGrow: 1}}>
                                    {!profile.headshot && <div className="profile-circle" style={{position:"relative", height: "150px", width: "150px", lineHeight: "150px", fontSize: "45px"}}>{profile.surname[0]}</div>}
                                    {profile.headshot && imgSizes.headshot && <div style={{position: "relative", overflow: "hidden"}}>
                                        <img src={profile.headshot} style={{height: imgSizes.headshot.height, width: imgSizes.headshot.width, marginLeft: imgSizes.headshot.marginleft}} /> 
                                        <div style={{position: "absolute", top: 0, left: 0, borderRadius: "50%", width: "200px", height: "200px", boxShadow: "0 0 0 100px #fff"}}></div>
                                    </div>
                                    }
                                    <div style={{marginBottom: "20px"}}>
                                        <h3>{profile.name} {profile.surname}</h3>
                                        <h4>{profile.title}</h4>
                                    </div>
                                    <div>
                                        {profile.twitter && <div>
                                            <label><strong>Twitter:</strong> {profile.twitter}</label>
                                        </div>}
                                        {profile.linkedin_Profile && <div>
                                            <label><strong>LinkedIn:</strong> {profile.linkedin_Profile}</label>
                                        </div>}
                                    </div>
                                    </div>
                                    <div className="profile_list" style={{flexGrow: 3}}>
                                    <div>
                                        <h3>Bio</h3>
                                        <p>{profile.bio}</p>
                                    </div>
                                    </div>
                                </div>
                                {/* {profile.bio &&
                                <div className="profile_list">
                                    <h3>Bio</h3>
                                    <p>{profile.bio}</p>
                                </div>} */}
                                {/* {organizationTags && 
                                <div className="profile_list">
                                    <h3>Organizations</h3>
                                    <div>
                                        {organizationTags}
                                    </div>
                                </div>} */}
                                <div className="profile_list">
                                    <h3>Organizations</h3>
                                    <div>
                                        {organizationTags}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
}

const connectedRegisterPage = connect(mapStateToProps)(ProfileView);
export { connectedRegisterPage as ProfileView };