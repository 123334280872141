import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import { format, parseISO } from "date-fns";
import { TruncateFunding } from '../Shared/Listing';
import { NavLink } from 'react-router-dom';
import { history } from '../../_helpers';
// @material-ui/icons
import AccountBalance from "@material-ui/icons/AccountBalance";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import LocationCity from "@material-ui/icons/LocationCity";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Save from "@material-ui/icons/Save";
import Label from "@material-ui/icons/Label";
import Class from "@material-ui/icons/Class";
import Update from "@material-ui/icons/Update";
// core components
import GridItem from "../../_components/dashboardComponents/Grid/GridItem.js";
import GridContainer from "../../_components/dashboardComponents/Grid/GridContainer.js";
import Table from "../../_components/dashboardComponents/Table/Table.js";
import CustomTabs from "../../_components/dashboardComponents/CustomTabs/CustomTabs.js";
import Danger from "../../_components/dashboardComponents/Typography/Danger.js";
import Card from "../../_components/dashboardComponents/Card/Card.js";
import CardHeader from "../../_components/dashboardComponents/Card/CardHeader.js";
import CardIcon from "../../_components/dashboardComponents/Card/CardIcon.js";
import CardBody from "../../_components/dashboardComponents/Card/CardBody.js";
import CardFooter from "../../_components/dashboardComponents/Card/CardFooter.js";
import TextTruncate from '../../../node_modules/react-text-truncate';
import he from '../../../node_modules/he';
import styles from "../../_assets/jss/material-dashboard-react/views/dashboardStyle.js";
import '../../_assets/css/material-dashboard-react.css';
import ReactTooltip from "react-tooltip";
import { ProjectProposalList } from '../GrantWriters';
import { ListingNotes } from '../Listing';

const useStyles = makeStyles(styles);
const favorites = JSON.parse(localStorage.getItem('favorite'));

const goToBillings = () => {
  history.push('/settings?val=billing');
}

const goToCategories = () => {
  history.push('/settings?val=categories');
}

const goToQuickstart = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user.app_Registration) {
    history.push('/quickstart/dashboard');
  }
  else {
    history.push('/quickstart/get-started');
  }
}

const goToSubmitProgram = () => {
  window.open('https://airtable.com/shr1UV3Bit2ZQURnZ', '_blank');
}

const goToApply = () => {
  history.push('/apply');
}

const createTable = (dashboard) => {
  var arr = [];
  const apps = dashboard.items.upcoming_Applications;

  if (apps.length != 0) {
    apps.map(function (dataPoint) {
      var cleanTitle = <TextTruncate
        line={2}
        element="class"
        truncateText="…"
        text={he.decode(dataPoint.title)}
      />
      return (
        arr.push([cleanTitle, format(parseISO(dataPoint.application_Due_Date), "MMMM do, yyyy")])
      );
    })
  }
  else {
    arr.push(["You have no upcoming submissions at this time.", ""])
  }

  return arr;
}

const openChat = (type) => {
  window.Intercom('trackEvent', type);
  window.Intercom('showNewMessage');
}

export default function DashboardView(props) {
  const classes = useStyles();
  const { dashboard, user, purchasedGrantWriting, proposalSubmission, records, loading, user_notes, whitelabel, profileComplete, organizationComplete } = props;
  const user_role = user.userRoles;
  const notes_links = (user_role == 'Client_Free') ? "/settings?val=billing" : "/notes"
  // console.log(props);

  let user_notes_list = null;
  if (user_notes) {
    user_notes_list = user_notes.map((note) => {
      return (
        <div className={classes.cardCategory}>
          <hr style={{ marginTop: 0 }} />
          <a href={"/grants/" + note.listing_Id} target="_blank" style={{ float: "right" }}>View Grant</a>
          <p>{note.modifiedDate}</p>
          <p>{note.user_Notes}</p>
        </div>
      );
    });
  }

  return (
    <div className="dashboardView">
      <GridContainer>
        {whitelabel.organization_Name != "portal.chicostart.com" && (
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <div
                className='CardTopColorClass'
                style={{ background: whitelabel.CardTopColor == null || undefined ? null : whitelabel.CardTopColor }}
              />
              <CardHeader color="info" stats icon>
                <CardIcon
                  color="info"
                  className={whitelabel.CardIconClass ? whitelabel.CardIconClass : null}
                  style={{background: whitelabel ? whitelabel.dashboard_Color : "#29a"}}
                >
                  <ThumbUp />
                </CardIcon>
                <p className={classes.cardCategory}>Your Matches</p>
                <h3 className={classes.cardTitle}>
                  {user_role != 'Client_Free' &&
                    <strong>+ {dashboard.items && dashboard.items.new_Matches_Count}</strong>
                  }
                  {user_role == 'Client_Free' &&
                    <strong>0</strong>
                  }
                </h3>
              </CardHeader>
              <CardFooter stats>
                {user_role == 'Client_Premium' &&
                  <NavLink to="/matches" className="dashboardNavLink">
                    <div className={classes.stats} >
                      <span style={{ color: "#444" }}>Explore Recommendations</span>
                    </div>
                    <ArrowForwardIos id="dashLinkArrow" />
                  </NavLink >
                }
                {user_role != 'Client_Premium' &&
                  <div
                    id="matchesDataTip"
                    data-tip="Upgrade your account to view matches."
                  >
                    <ReactTooltip effect="solid" style={{ width: "100%!important" }} />
                    <NavLink to="/settings?val=billing" className="dashboardNavLink">
                      <div className={classes.stats} >
                        <span style={{ color: "#444" }}>Explore Recommendations</span>
                      </div>
                      <ArrowForwardIos id="dashLinkArrow" />
                    </NavLink >
                  </div>
                }
              </CardFooter>
            </Card>
          </GridItem>
        )}
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <div
              className='CardTopColorClass'
              style={{ background: whitelabel.CardTopColor == null || undefined ? null : whitelabel.CardTopColor }}
            />
            <CardHeader color="info" stats icon>
              <CardIcon
                color="info"
                className={whitelabel.CardIconClass ? whitelabel.CardIconClass : null}
                style={{background: whitelabel ? whitelabel.dashboard_Color : "#29a"}}
              >
                <AccountBalance />
              </CardIcon>
              <p className={classes.cardCategory}>New Grants</p>
              <h3 className={classes.cardTitle}><strong>+ {dashboard.items && dashboard.items.new_Grants_Count}</strong></h3>
            </CardHeader>
            <CardFooter stats>
              <NavLink to="/search" className="dashboardNavLink">
                <div className={classes.stats}>
                  <span style={{ color: "#444" }}>Search New Programs</span>
                </div>
                <ArrowForwardIos id="dashLinkArrow" />
              </NavLink>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <div
              className='CardTopColorClass'
              style={{ background: whitelabel.CardTopColor == null || undefined ? null : whitelabel.CardTopColor }}
            />
            <CardHeader color="info" stats icon>
              <CardIcon
                color="info"
                className={whitelabel.CardIconClass ? whitelabel.CardIconClass : null}
                style={{background: whitelabel ? whitelabel.dashboard_Color : "#29a"}}
              >
                <Save />
              </CardIcon>
              <p className={classes.cardCategory}>Grants Saved</p>
              <h3 className={classes.cardTitle}>
                {user_role != 'Client_Free' &&
                  <strong>{dashboard.items && dashboard.items.favorites_Count}</strong>
                }
                {user_role == 'Client_Free' &&
                  <strong>0</strong>
                }
              </h3>
            </CardHeader>
            <CardFooter stats>
              {user_role != 'Client_Free' &&
                <NavLink to="/favorites" className="dashboardNavLink">
                  <div className={classes.stats} >
                    <span style={{ color: "#444" }}>View Your Favorites</span>
                  </div>
                  <ArrowForwardIos id="dashLinkArrow" />
                </NavLink >
              }
              {user_role == 'Client_Free' &&
                <div
                  id="favoritesDataTip"
                  data-tip="Upgrade your account to save listings."
                >
                  <ReactTooltip effect="solid" style={{ width: "100%!important" }} />
                  <NavLink to="/settings?val=billing" className="dashboardNavLink" style={{ width: "100%!important" }}>
                    <div className={classes.stats} >
                      <span style={{ color: "#444" }}>View Your Favorites</span>
                    </div>
                    <ArrowForwardIos id="dashLinkArrow" />
                  </NavLink >
                </div>
              }
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <div
              className='CardTopColorClass'
              style={{ background: whitelabel.CardTopColor == null || undefined ? null : whitelabel.CardTopColor }}
            />
            <CardHeader color="info" stats icon>
              <CardIcon
                color="info"
                className={whitelabel.CardIconClass ? whitelabel.CardIconClass : null}
                style={{background: whitelabel ? whitelabel.dashboard_Color : "#29a"}}
              >
                <LocationCity />
              </CardIcon>
              <p className={classes.cardCategory}>Awards</p>
              <h3 className={classes.cardTitle} style={{ marginBottom: 0 }}>
                <strong>
                  {dashboard.items && TruncateFunding(dashboard.items.total_Awards)}
                </strong>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                <span style={{ color: "#444" }}>Just Updated</span>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {whitelabel && whitelabel.features.feature_Quickstart &&
          <GridItem xs={12} sm={12} md={12} lg={4}>
            <Card chart id="dashboardMiddle">
              <div
                className='CardTopColorClass'
                style={{ background: whitelabel.CardTopColor == null || undefined ? null : whitelabel.CardTopColor }}
              />
              <CardHeader
                color="info"
                style={{ background: whitelabel ? whitelabel.dashboard_Color : "#29a" }}
              >
                <h1>QUICKSTART</h1>
              </CardHeader>
              <CardBody>
                <h4 className={classes.cardTitle} id="dashExpertsOnStaff">
                  Rapid Funding Discovery
                  <br />
                  ---
                </h4>
                <p className={classes.cardCategory}>
                  After you take a few minutes to answer a few basic questions, you'll get instant feedback about programs you might qualify for.
                </p>
              </CardBody>
              <CardFooter chart
                className="dashboardFooterLink"
                onClick={() => { goToQuickstart() }}
              >
                <div className={classes.stats} id="dashboardProfiles">
                  <span style={{ color: "#444" }}>Use the Quickstart Tool</span>
                </div>
                <ArrowForwardIos style={{ float: "right", fontSize: 20, marginTop: 0, color: "#777" }} />
              </CardFooter>
            </Card>
          </GridItem>
        }
        {whitelabel && whitelabel.features.feature_Notes &&
          <GridItem xs={12} sm={12} md={12} lg={4}>
            {user_role != 'Client_Free' && user_notes.length > 0 &&
              <Card chart id="dashboardMiddle">
                <CardHeader
                  color="info"
                  className={whitelabel.CardIconClass ? whitelabel.CardIconClass : null}
                  style={{background: whitelabel ? whitelabel.dashboard_Color : "#29a"}}
                >
                  <h1>NOTES</h1>
                </CardHeader>
                <CardBody style={{ height: 250, overflow: "scroll" }}>
                  <h4 className={classes.cardTitle} id="dashFundingOpportunities">
                    <strong>
                      {user_notes.length}
                    </strong> {user_notes.length > 1 ? "Notes" : "Note"}<br />---</h4>
                  <div className={classes.cardCategory}>
                    <ListingNotes
                      user_notes={user_notes}
                      getNotes={props.getNotes}
                    />
                  </div>
                </CardBody>
                <CardFooter chart
                  className="dashboardFooterLink"
                  onClick={() => { history.push("/notes") }}
                >
                  <div className={classes.stats}>
                    <span style={{ color: "#444" }}>See All</span>
                  </div>
                  <ArrowForwardIos style={{ float: "right", fontSize: 20, marginTop: 0, color: "#777" }} />
                </CardFooter>
              </Card>
            }
            {user_notes.length == 0 &&
              <Card chart id="dashboardMiddle">
                <div
                  className='CardTopColorClass'
                  style={{ background: whitelabel.CardTopColor == null || undefined ? null : whitelabel.CardTopColor }}
                />
                <CardHeader
                  color="info"
                  className={whitelabel.CardIconClass ? whitelabel.CardIconClass : null}
                  style={{background: whitelabel ? whitelabel.dashboard_Color : "#29a"}}
                >
                  <h1>NOTES</h1>
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle} id="dashFundingOpportunities">
                    No Notes<br />---</h4>
                </CardBody>
                <CardFooter chart
                  className="dashboardFooterLink"
                  onClick={() => { history.push(notes_links) }}
                >
                  <div className={classes.stats} data-tip="Upgrade your account to add notes to listings.">
                    <span style={{ color: "#444" }}>View Notes</span>
                  </div>
                  <ArrowForwardIos style={{ float: "right", fontSize: 20, marginTop: 0, color: "#777" }} />
                </CardFooter>
              </Card>
            }
          </GridItem>
        }
        {whitelabel && whitelabel.features.feature_Proposals &&
          <GridItem xs={12} sm={12} md={12} lg={4}>
            <Card chart id="dashboardMiddle">
              <CardHeader
                color="info"
                style={{ background: whitelabel ? whitelabel.dashboard_Color : "#29a" }}
              >
                <h1>REVIEW PROPOSALS</h1>
              </CardHeader>
              {proposalSubmission && <CardBody style={{ height: 250, overflow: "scroll" }}>
                <h4 className={classes.cardTitle} id="dashFundingOpportunities">
                  <strong>
                    {records && records.length}
                  </strong>
                  {!records || (records && (records.length === 0 || records.length > 1)) ? " Proposals" : " Proposal"}
                  <br />
                  ---
                </h4>
                <ProjectProposalList records={records} />
              </CardBody>}
              {purchasedGrantWriting && (records && records.length === 0) && !proposalSubmission && <CardBody style={{ height: 250, overflow: "scroll" }}>
                <p className={classes.cardCategory} style={{ marginTop: "20px" }}>
                  We're finding the perfect Grant Developer to match you to. Please allow 1-2 weeks for an introduction to one (or more) Grant Developer(s). If you have questions, reach out using the chat button in the lower right of this page.
                </p>
              </CardBody>}
              {records && records.length > 0 &&
                <CardFooter chart
                  className="dashboardFooterLink"
                  onClick={() => { history.push("/projectproposal") }}
                >
                  <div className={classes.stats}>
                    <span style={{ color: "#444" }}>See All</span>
                    <ArrowForwardIos style={{ float: "right", fontSize: 20, marginTop: 0, color: "#777" }} />
                  </div>
                </CardFooter>
              }
              {(!records || (records && records.length == 0)) &&
                <CardFooter chart
                >
                  <div>
                    <h4 className={classes.cardTitle} style={{ marginRight: "10%", lineHeight: "30px", fontSize: 18 }}>You do not have any proposals to review at this time.</h4>
                    <br />
                  </div>
                </CardFooter>
              }
            </Card>
          </GridItem>
        }
        {whitelabel && whitelabel.organization_Name == "portal.chicostart.com" && ( 
            <GridItem xs={12} sm={12} md={12} lg={6}>
              <Card>
                <CardHeader
                  color="info"
                  style={{ background: whitelabel ? whitelabel.dashboard_Color : "#29a" }}
                >
                  <h4 className={classes.cardTitleWhite} style={{ fontSize: 14, fontWeight: 600 }}>Upcoming Submissions</h4>
                  <h5 className={classes.cardCategoryWhite} style={{ fontSize: 16, paddingTop: "10px", fontWeight: 300 }}>
                    Grants with applications due in next 60 days.
                  </h5>
                </CardHeader>
                <CardBody>
                  {dashboard.items && dashboard.items.upcoming_Applications &&
                    <div id="upcomingAppsTableDiv" style={{ paddingBottom: 50 }}>
                      <Table
                        tableHeaderColor="info"
                        tableHead={["Title", "Due Date"]}
                        tableData={createTable(dashboard)}
                        id="upcomingAppsTable"
                      />
                    </div>
                  }
                  {dashboard.items && !dashboard.items.upcoming_Applications &&
                    <div id="upcomingAppsTableDiv" style={{ paddingBottom: 50 }}>
                      <Table
                        tableHeaderColor="info"
                        tableHead={["Title", "Due Date"]}
                        tableData={createTable(dashboard)}
                        id="upcomingAppsTable"
                      />
                    </div>
                  }
                </CardBody>
              </Card>
            </GridItem>
        )}
      </GridContainer>
      {user_role != 'Client_Free' &&
        <GridContainer>
          {whitelabel && whitelabel.organization_Name == "portal.chicostart.com" ? 
            null
          :
            <GridItem xs={12} sm={12} md={12} lg={6} >
              <CustomTabs
                initialValue={0}
                title="Preferences:"
                tabsClass={"dashboardTabs"}
                tabsWidth={"100%"}
                headerColor={whitelabel ? whitelabel.dashboard_Color : "#29a"}             
                tabs={[
                  {
                    tabName: "Tags",
                    tabIcon: Label,
                    tabContent: (
                      <div>
                        <br />
                        <h1 id="dashHeader">Tags You Track</h1>
                        <hr />
                        {user.category_Tags && user.category_Tags.length > 0 &&
                          user.category_Tags.map(function (tag) {
                            return (
                              <h6 id="hitCategoryDash">{tag}</h6>
                            );
                          })
                        }
                        {user.category_Tags && user.category_Tags.length == 0 &&
                          <p style={{ fontSize: 15, fontWeight: 300, color: "#666", maxWidth: "95%" }}> 
                            You have not yet selected any categories to track.
                            Visit your profile to view or update your current preferences and settings.
                          </p>
                        }
                        <hr style={{ marginTop: 35 }} />
                        <h4 className={classes.cardTitle} style={{ fontWeight: 500 }}>
                          <Button
                            color={whitelabel ? whitelabel.button_Color : "#29a"}
                            onClick={() => { goToCategories() }}
                          >
                            Update Profile
                            <ArrowForwardIos style={{ float: "right", fontSize: 32, marginTop: 0, marginLeft: 10, marginRight: -10 }} />
                          </Button>
                        </h4>
                        <br />
                      </div>
                    )
                  },
                  {
                    tabName: "Categories",
                    tabIcon: Class,
                    tabContent: (
                      <div>
                        <br />
                        <h1 id="dashHeader">Your Areas of Interest</h1>
                        <hr />
                        {user.parent_Categories && user.parent_Categories.length > 0 &&
                          user.parent_Categories.map(function (category) {
                            return (
                              <h6 id="hitCategoryDash">{category}</h6>
                            );
                          })
                        }
                        {user.parent_Categories && user.parent_Categories.length == 0 &&
                          <p style={{ fontSize: 15, fontWeight: 300, color: "#666", maxWidth: "95%" }}>
                            You have not yet selected any categories and tags to track.
                            Visit your profile to view or update your current preferences and settings.
                          </p>
                        }
                        <hr style={{ marginTop: 35 }} />
                        <h4 className={classes.cardTitle} style={{ fontWeight: 500 }}>
                          <Button
                            color={whitelabel ? whitelabel.button_Color : "#29a"}
                            // style={{background: "#29a"}}
                            onClick={() => { goToCategories() }}
                          >
                            Update Profile
                            <ArrowForwardIos style={{ float: "right", fontSize: 32, marginTop: 0, marginLeft: 10, marginRight: -10 }} />
                          </Button>
                        </h4>
                        <br />
                      </div>
                    )
                  },
                ]}
              />
            </GridItem>
          }
          {whitelabel && whitelabel.organization_Name == "portal.chicostart.com" ? 
            null
          :
            <GridItem xs={12} sm={12} md={12} lg={6}>
              <Card>
                <CardHeader
                  color="info"
                  style={{ background: whitelabel ? whitelabel.dashboard_Color : "#29a" }}
                >
                  <h4 className={classes.cardTitleWhite} style={{ fontSize: 14, fontWeight: 600 }}>Upcoming Submissions</h4>
                  <h5 className={classes.cardCategoryWhite} style={{ fontSize: 16, paddingTop: "10px", fontWeight: 300 }}>
                    Grants with applications due in next 60 days.
                  </h5>
                </CardHeader>
                <CardBody>
                  {dashboard.items && dashboard.items.upcoming_Applications &&
                    <div id="upcomingAppsTableDiv" style={{ paddingBottom: 50 }}>
                      <Table
                        tableHeaderColor="info"
                        tableHead={["Title", "Due Date"]}
                        tableData={createTable(dashboard)}
                        id="upcomingAppsTable"
                      />
                    </div>
                  }
                  {dashboard.items && !dashboard.items.upcoming_Applications &&
                    <div id="upcomingAppsTableDiv" style={{ paddingBottom: 50 }}>
                      <Table
                        tableHeaderColor="info"
                        tableHead={["Title", "Due Date"]}
                        tableData={createTable(dashboard)}
                        id="upcomingAppsTable"
                      />
                    </div>
                  }
                </CardBody>
              </Card>
            </GridItem>
          }
        </GridContainer>
      }
    </div>
  );
}