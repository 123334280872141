import React from 'react';
import '../../../Styles/Registration.css';
import '../../../Styles/StripeProduct.css';

export class Onboarding_Partner extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const user = JSON.parse(localStorage.getItem('user'));
        const path = window.location.pathname;
        const pathSplit = path.split('/').pop();
        let link = "http://opengrants.io/"+pathSplit;
        return (
            <div>
                {pathSplit !== "grantwriter" &&
                <div className="center gap-top-lg adjustable-width-sm">
                    <h1 className="og-color">Thank you for registering!</h1>
                    <p className="gap-top">You are now connected to the OpenGrants Stripe account. You can close this page or click <a href={link}>here</a> to return to OpenGrants.</p>
                </div>
                }
                {pathSplit === "grantwriter" &&
                <div className="center gap-top-lg adjustable-width-sm">
                    <h1 className="og-color">Thank you for registering!</h1>
                    <p className="gap-top">You are now connected to the OpenGrants Stripe account. You can close this page or click <a href={"https://portal.opengrants.io/login"}>here</a> to login to OpenGrants Portal.</p>
                </div>
                }
            </div>
        );
    }
}