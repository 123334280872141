import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { LoginPartialDropdown } from '../LoginPartialDropdown';
import '../../../Styles/NavMenu.css';
import ReactTooltip from "react-tooltip";

class DesktopNavMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            themeColor: localStorage.getItem('themeColor')
        }

        this.goToKB = this.goToKB.bind(this);
    }

    openChat(type) {
        const user = JSON.parse(localStorage.getItem('user'));

        window.Intercom('trackEvent', type);
        window.Intercom('showNewMessage');
    }

    goToKB = () => {
        window.open('https://kb.opengrants.io/', '_blank');
    }

    truncate = (text) => {
        return text.length > 25 ? text.substring(0, 22) + "..." : text;
    }

    render() {
        const user = JSON.parse(localStorage.getItem('user'));
        const whitelabel = this.props.whitelabel;
        const navLinkBackground = whitelabel ? whitelabel.active_Color : "#1215218c";
        const navLinkAccent = whitelabel ? whitelabel.accent_Color : "#29a";

        return (
            <div>
                <style>{"\
                    a#navLinkDashboard.active{\
                    background:" + navLinkBackground + "!important;\
                    border-left: 3px solid " + navLinkAccent + "!important;\
                    }\
                    a#navLinkSearch.active{\
                        background:" + navLinkBackground + "!important;\
                        border-left: 3px solid " + navLinkAccent + "!important;\
                        }\
                    a#navLinkTalent.active{\
                        background:" + navLinkBackground + "!important;\
                        border-left: 3px solid " + navLinkAccent + "!important;\
                        }\
                    a#navLinkQuickstart.active{\
                        background:" + navLinkBackground + "!important;\
                        border-left: 3px solid " + navLinkAccent + "!important;\
                        }\
                    a#navLinkMatches.active{\
                        background:" + navLinkBackground + "!important;\
                        border-left: 3px solid " + navLinkAccent + "!important;\
                        }\
                    a#navLinkFavorites.active{\
                        background:" + navLinkBackground + "!important;\
                        border-left: 3px solid " + navLinkAccent + "!important;\
                        }\
                    a#navLinkApply.active{\
                        background:" + navLinkBackground + "!important;\
                        border-left: 3px solid " + navLinkAccent + "!important;\
                        }\
                    a#navLinkSettings.active{\
                        background:" + navLinkBackground + "!important;\
                        border-left: 3px solid " + navLinkAccent + "!important;\
                        }\
                "}</style>
                {(user || !user) &&
                    <div
                        id="desktop-nav"
                        className="nav navbar-nav"
                        style={{ background: whitelabel ? whitelabel.menu_Color : "#000" }}
                    >
                        {whitelabel && whitelabel.organization_Name == "portal.opengrants.io" &&
                            <div id="whitelabel-Nav-Opengrants">
                                <div id="desktop-title">
                                    <img src="https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrantsSM_white.png" style={{ width: 45, display: "inline" }} />
                                    <h1 id="portal" style={{ fontWeight: 700 }}>PORTAL</h1>
                                </div>
                                <div id="desktop-title">
                                    <h1 id="portalPoweredBy">Powered by OpenGrants</h1>
                                </div>
                            </div>
                        }
                        {whitelabel && whitelabel.organization_Name == "grants.wiaseresearch.com" &&
                            <div id="whitelabel-Nav-Opengrants">
                                <div id="desktop-title">
                                    <img src={whitelabel.logo_URL} style={{ width: 125, display: "inline" }} />
                                </div>
                            </div>
                        }
                        {whitelabel && whitelabel.organization_Name == "grants.californiamobilitycenter.org" &&
                            <div id="whitelabel-Nav-Opengrants">
                                <div id="desktop-title">
                                    <img src={whitelabel.logo_URL} style={{ width: 175, display: "inline" }} />
                                </div>
                            </div>
                        }
                        {whitelabel && whitelabel.organization_Name == "fund.gbbp.org" &&
                            <div id="whitelabel-Nav-Opengrants">
                                <div id="desktop-title">
                                    <img src={whitelabel.logo_URL} style={{ width: 175, display: "inline", background: "#fff" }} />
                                </div>
                            </div>
                        }
                        {whitelabel && whitelabel.organization_Name == "portal.chicostart.com" &&
                            <div id="whitelabel-Nav-Opengrants">
                                <div id="desktop-title">
                                    <img src={whitelabel.logo_URL2} style={{ width: 200, marginLeft: '20%', display: "inline", background: "transparent" }} />
                                </div>
                            </div>
                        }
                        {whitelabel && whitelabel.organization_Name == "grantfinder.epri.com" &&
                            <div id="whitelabel-Nav-Opengrants">
                                <div id="desktop-title-epri">
                                    <img src={whitelabel.logo_URL3} style={{ width: 140, display: "inline", background: "black" }} />
                                </div>
                            </div>
                        }
                        {whitelabel && whitelabel.organization_Name == "demo.opengrants.io" &&
                            <div id="whitelabel-Nav-Opengrants">
                                <div id="desktop-title">
                                    <img src={whitelabel.logo_URL} style={{ width: 175, display: "inline", background: "transparent" }} />
                                </div>
                            </div>
                        }
                        {/* DESKTOP NAV CONTAINER */}
                        <div id={whitelabel.desktopNavClass == null || undefined ? "desktopNav-Container" : `${whitelabel.desktopNavClass}`}>
                            <div id="desktopNavLinks">
                                {whitelabel && whitelabel && whitelabel.features.feature_Custom_Dashboard_Primary &&
                                    <NavLink to="/dashboard" id="navLinkDashboard">
                                        <i className="material-icons">dashboard</i>
                                        Start Here
                                    </NavLink>
                                }
                                {whitelabel && whitelabel && whitelabel.features.feature_Opengrants_Dashboard &&
                                    <NavLink to="/dashboard" id="navLinkDashboard">
                                        <i className="material-icons">dashboard</i>
                                        Dashboard
                                    </NavLink>
                                }
                                {whitelabel && whitelabel.features.feature_Basic_Search &&
                                    <NavLink
                                        to="/search"
                                        id="navLinkSearch"
                                        isActive={() => window.location.pathname.includes('/search') || window.location.pathname.includes('/grants')}
                                    >
                                        <i className="material-icons">search</i>
                                        Search
                                    </NavLink>
                                }
                                {/* Setting this as the feature_Basic_Search */}
                                {whitelabel && whitelabel.features.feature_Basic_Search &&
                                    <NavLink
                                        to="/marketplace"
                                        id="navLinkTalent"
                                        isActive={() =>
                                            window.location.pathname.includes("/marketplace")
                                        }
                                    >
                                        <i className="material-icons">assignment</i>
                                        Talent Finder
                                    </NavLink>
                                }
                                {((user && user.app_Registration == false) || !user) &&
                                    whitelabel && whitelabel.features.feature_Quickstart &&
                                    <NavLink
                                        to="/quickstart/get-started"
                                        id="navLinkApply"
                                        isActive={() => window.location.pathname.includes('/quickstart/')}
                                    >
                                        <i className="material-icons">speed</i>
                                        Quickstart
                                    </NavLink>
                                }
                                {user && user.app_Registration == true &&
                                    whitelabel && whitelabel.features.feature_Quickstart &&
                                    <NavLink
                                        to="/quickstart/dashboard"
                                        id="navLinkApply"
                                        isActive={() => window.location.pathname.includes('/quickstart/')}
                                    >
                                        <i className="material-icons">speed</i>
                                        Quickstart
                                    </NavLink>
                                }
                                {((user && user.userRoles == 'Client_Premium') || !user) && whitelabel.organization_Name != "portal.chicostart.com" &&
                                    <NavLink to="/matches" id="navLinkMatches">
                                        <i className="material-icons">thumb_up</i>
                                        Matches
                                    </NavLink>
                                }
                                {(user && (user.userRoles == 'Client_Free' || user.userRoles == 'Client_Basic')) &&
                                    whitelabel && whitelabel.features.feature_Matches &&
                                    <div
                                        data-tip="Upgrade your account to view matches."
                                    >
                                        <ReactTooltip effect="solid" />
                                        <a href='/settings?val=billing' id="navLinkResources" onClick={this.goToBillings}>
                                            <i className="material-icons" style={{ color: "grey" }}>thumb_up</i>
                                            <p style={{ display: "inline", marginTop: "-5px", color: "grey" }}>Matches  *</p>
                                        </a>
                                    </div>
                                }
                                {((user && user.userRoles != 'Client_Free') || !user) &&
                                    whitelabel && whitelabel.features.feature_Favorites &&
                                    <NavLink to="/favorites" id="navLinkFavorites">
                                        <i className="material-icons">save</i>
                                        Favorites
                                    </NavLink>
                                }
                                {(user && user.userRoles == 'Client_Free') &&
                                    <div
                                        data-tip="Upgrade your account to save listings."
                                    >
                                        <ReactTooltip effect="solid" />
                                        <a href='/settings?val=billing' id="navLinkResources" onClick={this.goToBillings}>
                                            <i className="material-icons" style={{ color: "grey" }}>save</i>
                                            <p style={{ display: "inline", marginTop: "-5px", color: "grey" }}>Favorites  *</p>
                                        </a>
                                    </div>
                                }
                                {whitelabel && whitelabel.features.feature_Apply &&
                                    <NavLink to="/apply" id="navLinkApply">
                                        <i className="material-icons">fact_check</i>
                                        Apply
                                    </NavLink>
                                }
                                {whitelabel && whitelabel.features.feature_Expert_Resources &&
                                    <NavLink to="/find-an-expert" id="navLinkApply">
                                        <i className="material-icons">fact_check</i>
                                        Find an Expert
                                    </NavLink>
                                }
                                <NavLink to="/settings?val=manage" id="navLinkApply">
                                    <i className="material-icons">settings</i>
                                    Settings
                                </NavLink>
                            </div>
                        </div>
                        {user &&
                            <div id="desktop-user" style={{ background: whitelabel ? whitelabel.menu_Color : "#23283a" }}>
                                {user && user.headshot &&
                                    <div class="profile-img-circle">
                                        <img id="profileImg" src={user.headshot} height="50" width="50" />
                                    </div>
                                }
                                {user && !user.headshot &&
                                    <div
                                        class="profile-circle"
                                        style={{ background: whitelabel ? whitelabel.accent_Color : "#29a" }}
                                    >
                                        {user.surname[0]}
                                    </div>
                                }
                                <div id="userInfo">
                                    <p>
                                        <span style={{ color: "#fff", fontSize: 16 }}>
                                            {user && user.name} {user && user.surname}
                                        </span><br />
                                        <span style={{ color: "#ddd", overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {user && this.truncate(user.email)}

                                        </span>
                                    </p>
                                    <LoginPartialDropdown />
                                </div>
                            </div>
                        }
                        {!user &&
                            <div id="desktop-user">
                                <div class="profile-circle">G</div>
                                <div id="userInfo">
                                    <p>
                                        <span style={{ color: "#fff", fontSize: 16 }}>
                                            Grants Portal
                                        </span><br />
                                        <span style={{ color: "#929eaa", overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            support@opengrants.io
                                        </span>
                                    </p>
                                    {/* <LoginPartialDropdown /> */}
                                </div>
                            </div>
                        }
                        <div id="desktop-resources">
                            <div class="col-sm-4">
                                <p onClick={() => this.openChat('Feedback')}>Feedback</p>
                            </div>
                            <div class="col-sm-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 32 32">
                                    <g fill="none" stroke="#628bdc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7">
                                        <path d="M16.7 7.3l6.4 3.1c.6.3.9.8.9 1.4v7.4c0 .6-.3 1.1-.9 1.4l-6.4 3c-.4.3-1 .3-1.4 0l-6.4-3c-.6-.3-.9-.8-.9-1.4v-7.4c0-.6.3-1.1.9-1.4l6.4-3c.4-.3 1-.3 1.4 0z"></path>
                                        <path d="M8.3 11l7.7 3.7 7.7-3.7M16 23.8v-9"></path>
                                    </g>
                                </svg>
                                <p onClick={() => { this.goToKB() }}>Docs</p>
                            </div>
                            <div class="col-sm-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20">
                                    <g fill="none" stroke="#628bdc" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.75">
                                        <path d="M17.659,3.681H8.468c-0.211,0-0.383,0.172-0.383,0.383v2.681H2.341c-0.21,0-0.383,0.172-0.383,0.383v6.126c0,0.211,0.172,0.383,0.383,0.383h1.532v2.298c0,0.566,0.554,0.368,0.653,0.27l2.569-2.567h4.437c0.21,0,0.383-0.172,0.383-0.383v-2.681h1.013l2.546,2.567c0.242,0.249,0.652,0.065,0.652-0.27v-2.298h1.533c0.211,0,0.383-0.172,0.383-0.382V4.063C18.042,3.853,17.87,3.681,17.659,3.681 M11.148,12.87H6.937c-0.102,0-0.199,0.04-0.27,0.113l-2.028,2.025v-1.756c0-0.211-0.172-0.383-0.383-0.383H2.724V7.51h5.361v2.68c0,0.21,0.172,0.382,0.383,0.382h2.68V12.87z M17.276,9.807h-1.533c-0.211,0-0.383,0.172-0.383,0.383v1.755L13.356,9.92c-0.07-0.073-0.169-0.113-0.27-0.113H8.851v-5.36h8.425V9.807z"></path>
                                    </g>
                                </svg>
                                <p onClick={() => this.openChat('Support')}>Support</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        whitelabel
    };
}

const connectedLoginPage = connect(mapStateToProps)(DesktopNavMenu);
export { connectedLoginPage as DesktopNavMenu };