import React from 'react';

export class Registration_Organization extends React.Component {
    constructor(props) {
        super(props);
        const {user, nav} = this.props;

        this.state = {
            user: user,
            nav: nav,
            submitted: false,
            themeColor: localStorage.getItem('themeColor')
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const {name, value} = e.target;
        let {user} = this.state;
        user[name] = value;
        this.setState({
            user: user
        })
    }

    handleNavigation = (type) => {
        var {user, nav} = this.state;
        var valid = true;
        if (type === 'next') {
            if (!this.formIsValid(user)) {
                this.setState({submitted: true });
                valid = false;
            } else {
                nav++;
            }
        } else if (type === "prev") {
            nav--;
        }

        if (valid) {
            this.props.handleFormInputs({user, nav});
        }
    }

    formIsValid(user) {
        const user_email = user.Email;
        var valid = false;
        if (user.Organization_Name !== "") {
            // ("not empty");
            valid = true;
        } 
        return valid;
    }

    render() {
        const {user, submitted} = this.state;

        return (
            <div id="form_2" className="forms">
                <h1 className="regHeader" style={{color: this.state.themeColor}}>Tell us about company.</h1>
                <hr/>
                <div>
                    <div>
                        <div className="question_section">
                            <label required>Company Name<span className="registerationRequired"> *</span></label>
                            <input name="Organization_Name" value={user.Organization_Name} onChange={this.handleChange} />
                            {submitted && !user.Organization_Name &&
                            <p className="error align-left">This field is required</p>}
                        </div>
                        <div className="question_section">
                            <label required>Company Website (optional)</label>
                            <input name="Website" value={user.Website} onChange={this.handleChange} />
                        </div>
                        <div className="question_section">
                            <label required>Your Title (optional)</label>
                            <input name="Title" value={user.Title} onChange={this.handleChange} />
                        </div>
                        <div className="question_section">
                            <label required>Company Description or Executive Summary (optional)</label>
                            <textarea name="Organization_Description" value={user.Organization_Description} onChange={this.handleChange} />
                        </div>
                    </div>
                    <div>
                        {/* <button className="backButton" onClick={() => this.handleNavigation('prev')}>Back</button>
                        <button className="nextButton" onClick={() => this.handleNavigation('next')}>Next</button> */}
                        <button 
                            className="backButton" 
                            onClick={() => this.handleNavigation('prev')}
                            style={{color: this.state.themeColor, borderColor: this.state.themeColor}}
                            >
                                Back
                            </button>
                        <button 
                            className="nextButton" 
                            onClick={() => this.handleNavigation('next')}
                            style={{background: this.state.themeColor, border: this.state.themeColor}}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}