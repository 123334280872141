import React from 'react';
import { connect } from 'react-redux';
import { registrationActions } from '../../../_actions';
import {
    Registration_Form_Partner,
    // Registration_Account,
    // Registration_Profile,
    // Registration_Organization,
    // Registration_Focus,
    // Registration_LegalDetails,
    // Registration_Location,
    // Registration_CompleteProfile,
    // Registration_ReferralSource,
    // Registration_Products
    } from '../RegisterPage_Partner';
import { searchService } from '../../../_services';
import { history } from '../../../_helpers';
import { Loader } from '../../Shared/Loader';
import { setPartnerInfo } from '../../Shared/Partners';
import '../../../Styles/Registration.css';

class Registration_Partner extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        document.body.scrollTo(0,0);

        localStorage.removeItem('user');
        let category = await searchService.getAllCategories();
        let categoryTags = await searchService.getAllCategoryTags();

        this.setState ({
            category: category,
            categoryTags: categoryTags,
        });
    }

    handleSubmit = async (user) => {
        const { dispatch } = this.props;
        // if (this.state.listing_Id !== 0)
        // {
        //     user.Linked_Listing_Id = this.state.listing_Id;
        // }
        const server = window.location.protocol + '//' + window.location.host;

        // var userInfo = {
        //     email: user.Email,
        //     password: user.Password
        // };
        // this.props.updateUserInfo(userInfo);

        await dispatch(registrationActions.register(user, server, window.location.hostname));
        // user.Password = '';
        // user.Password_Confirm = '';
        // localStorage.setItem('user', JSON.stringify(user));

        // const planSelection = localStorage.getItem('planSelection');
        // if (planSelection && planSelection != '') {
        //     window.location.href = planSelection;
        // }
    }

    handleFormInputs = (inputs) => {
        let {user, nav} = inputs;

        this.handleSubmit(user);

        this.setState({
            user: user,
        });
    }

    render() {
        console.log(this.state);
        console.log(this.props);

        return (
            <div id="rightRegistration">
                <Registration_Form_Partner handleFormInputs={this.handleFormInputs}></Registration_Form_Partner>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering, nav } = state.registration;
    const {registrationAlert} = state;
    return {
        registering, nav, registrationAlert
    };
}

const connectedRegistration = connect(mapStateToProps)(Registration_Partner);
export { connectedRegistration as Registration_Partner };