import { combineReducers } from 'redux';

import { emailAlert, loginAlert, registrationAlert, updatePasswordAlert, settingsAlert } from './alert.reducer';
import { getPrograms } from './quickstart.reducer';
import { setUser, authenticateUser, updatePassword, confirmEmail } from './authenticate.reducer';
import { dashboard } from './dashboard.reducer';
import { getMatches, saveMatch, discardMatch } from './matches.reducer';
import { getFavorites, saveListing } from './favorites.reducer';
import { layout } from './layout.reducer';
import { listing } from './listing.reducer';
import { categories, locations, searchResults } from './search.reducer';
import { profile, focus, organization, advanced } from './settings.reducer';
import { registration } from './registration.reducer';
import { createStripeAccount, handleStripeToken, getBillingAddress, purchaseProduct, paywallAlert, updateBilling, cancelSubscription, updateSubscription } from './stripe.reducer';
// import { getAllNotifications, markAllNotificationsSeen } from './notifications.reducer';
import { setWhitelabel } from './whitelabel.reducer';
import { setExpertsAdvisorsExpanded, setGrantsExpanded } from './resources.reducer';
import {
    chatalert_updateconversations,
    chatalert_setconversation,
    chatalert_setchannel,
    chat_messageuserrequest
} from './chat.reducer';
import {
    organization_createorganizationalert,
    organization_noorganizationalert,
    organization_removemessageblock
} from './organization.reducer';
import { getAllNotifications,
    markAllNotificationsSeen,
    get_email_notification_settings,
    update_email_notification_settings
} from './notifications.reducer';

const rootReducer = combineReducers({
    emailAlert,
    loginAlert,
    registrationAlert,
    updatePasswordAlert,
    settingsAlert,
    setUser,
    authenticateUser,
    categories,
    locations,
    getFavorites,
    layout,
    profile,
    focus,
    organization,
    advanced,
    dashboard,
    getPrograms,
    getMatches,
    saveMatch,
    discardMatch,
    listing,
    registration,
    searchResults,
    saveListing,
    updatePassword,
    confirmEmail,
    createStripeAccount, handleStripeToken, getBillingAddress, purchaseProduct, updateBilling, cancelSubscription, updateSubscription,
    getAllNotifications, markAllNotificationsSeen, 
    get_email_notification_settings,
    update_email_notification_settings,
    paywallAlert,
    setWhitelabel,
    setExpertsAdvisorsExpanded,
    setGrantsExpanded,
    chatalert_updateconversations,
    chatalert_setconversation,
    chatalert_setchannel,
    chat_messageuserrequest,
    organization_createorganizationalert,
    organization_noorganizationalert,
    organization_removemessageblock
});

export default rootReducer;
