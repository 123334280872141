import React from 'react';
import { registrationService } from '../../../_services';
import ReactPasswordStrength from 'react-password-strength';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ReCAPTCHA from "react-google-recaptcha";

export class Registration_Form extends React.Component {
    constructor(props) {
        super(props);
        const {user, nav, categories} = this.props;

        this.state = {
            user: user,
            nav: nav,
            submitted: false,
            captcha: false,
            emailExists: false,
            emailFormat: true,
            confirmPasswordValid: false,
            password: '',
            isValid: false,
            themeColor: '',
            
            categories,

            categoryInput: user.category,
            category_TagsInput: "",

            categoryError: false,
            category_TagsError: false,

            themeColor: '',

            selectedCategory: {"category": ''},
            selectedCategoryTags: {"category_Tags": []}
        }
    }

    componentDidMount() {
        const path = window.location.pathname;
        if (path.toLowerCase() != "/register")
        {
            const pathSplit = path.split('/').pop();
            if (isNaN(pathSplit))
            {
                const partnerName = pathSplit;
                this.setState(prevState => ({
                    user: {
                        ...prevState.user,
                        partner: partnerName,
                    }
                }));
            }
            else
            {
                if (this.props.match)
                    this.setState({
                        listing_Id: this.props.match.params.grant,
                    });
            }
        }
    }

    goToLogin = () => {
        // history.push('/login');
        window.location.href="/login";
    }

    handleChange = (e) => {
        const {name, value} = e.target;
        let {user} = Object.assign({}, this.state);
        user[name] = value;
        this.setState({
            user: user
        })
    }

    handleCategoryInput = (categoryInputs) => {
        this.setState(
            categoryInputs
        )
    }

    handleCategorySelection = (categories) => {
        const {user} = this.state;
        this.setState({
            user: {
                ...user,
                ...categories
            }
        })
    }

    handleNavigation = async (type) => {
        var {user, nav} = this.state;
        let emailExists = false;
        let emailFormat = true;
        let valid = true;

        if (type === 'next') {
            nav++;
        } else if (type === "prev") {
            nav--;
        }

        if (!user.Name || !user.Surname || !this.state.captcha)
            valid = false;

        if (user.Email === "") {
            valid = false;
        } 
        else 
        {
            let emailState = await registrationService.checkEmail(user);

            if (!emailState.valid) {
                if (emailState.type === "format") {
                    valid = false;
                    emailFormat = true;
                } else if (emailState.type === "present") {
                    valid = false;
                    emailExists = true;
                }
            }
        }

        if (valid && this.state.isValid)
            this.props.handleFormInputs({user, nav});
        
        this.setState({ 
            ...this.state,
            emailExists: emailExists,
            emailFormat: emailFormat,
            submitted: true });
    }

    changeCallback = state => {
        this.setState(prevState => ({
            passLength: state.password.length,
            isValid: state.isValid,
            user: {
                ...prevState.user,
                Password: state.password,
            }
        }), () => {});
    }

    useStyles = makeStyles((theme) => ({
        root: {
          '& > *': {
            margin: theme.spacing(1),
            width: '100ch',
          },
        },
    }));
    
    render() {
        const {
            user, 
            submitted, 
            emailExists, 
            emailFormat,
            captcha, 
            confirmPasswordValid} = this.state;
        const {registrationAlert, whitelabel} = this.props;
        const { categoryError, category_TagsError } = this.state;
        const {categories} = this.props;
        var userCategory = user.category != "" ? [user.category] : [];
        var selectedCategories = {
            category: []
        };
        const classes = this.useStyles;
        
        return (
            <div id="form_0" className="forms">
                {whitelabel && whitelabel.organization_Name == "portal.opengrants.io" &&
                    <h1 id="regPortalLogo">opengrants.io</h1>
                }
                {/* {whitelabel && whitelabel.organization_Name == "portal.opengrants.io" &&
                    <div className="reg-link-list">
                        <a href="https://airtable.com/shrhMg2MLsdmrKIVa" target="_blank" id="signUpLinkWrite">Write For OpenGrants.</a>
                        <a href="http://www.opengrants.io/join-opengrants-writing/" target="_blank" id="signUpLinkHIW">Represent an Agency?</a>
                    </div>
                } */}
                {whitelabel && whitelabel.organization_Name == "portal.opengrants.io" &&
                    <h1 id="signUpTagLine">
                        <span style={{fontSize: 48}}>PORTAL</span>
                        <p>{user.partner}</p>
                        <hr />
                    </h1>
                }
                {whitelabel && whitelabel.organization_Name == "portal.opengrants.io" &&
                    <div id="mobileRegPoweredBy">
                        <hr style={{width: "25%"}} />
                        <p id="mobileRegPoweredByP">Powered by Opengrants.io</p>
                        <hr />
                    </div>
                }
                {whitelabel && (whitelabel.organization_Name == "grants.wiaseresearch.com" 
                || whitelabel.organization_Name == "grants.californiamobilitycenter.org"
                || whitelabel.organization_Name == "fund.gbbp.org") &&
                    <div style={{marginBottom: "-10%"}}>
                        <h1 id="signUpTagLine">
                            <span style={{fontSize: 48}}>Grants Portal</span>
                            <p>{user.partner}</p>
                        </h1>
                        <hr />
                    </div>
                }
                {whitelabel && whitelabel.organization_Name == "portal.prospectsv.org" &&
                    <div style={{marginBottom: "-10%"}}>
                        <img 
                            src="https://s3-us-west-1.amazonaws.com/grants.prospectsv.org/images/Prospect_SV_Logo.png" 
                            width="250" 
                            style={{margin: "auto", display: "block", marginBottom: 35, paddingRight: 10}}
                        />
                        <h1 id="signUpTagLine">
                            <span style={{fontSize: 48}}>Ecosystem Portal</span>
                        </h1>
                        <hr />
                    </div>
                    
                }
                {whitelabel && whitelabel.organization_Name == "portal.chicostart.com" &&
                    <div style={{marginBottom: "-10%"}}>
                        <img 
                            src={whitelabel.logo_URL} 
                            width="250" 
                            style={{margin: "auto", display: "block", marginBottom: 35, paddingRight: 10}}
                        />
                        <hr />
                    </div>
                    
                }
                {whitelabel && whitelabel.organization_Name == "portal.opengrants.io" &&
                    <p style={{textAlign: "center", fontSize: 18, lineHeight: 1.5}}>Discover funding effortlessly. Apply with the experts. <br />Manage your award like a pro.</p>
                }
                <div id="signUpForm">
                    <form className={classes.root} noValidate autoComplete="off">
                        <div className="row" id="portalRegRow">
                            <div className="col-md-12">
                                <TextField 
                                    required 
                                    id="standard-basic" 
                                    label="FIRST NAME" 
                                    name="Name"  
                                    value={user.Name} 
                                    onChange={this.handleChange}
                                />
                                {submitted && !user.Name &&
                                    <p className="error">*required</p>
                                }
                            </div>
                            <div className="col-md-12">
                                <TextField 
                                    required 
                                    id="standard-basic" 
                                    label="LAST NAME" 
                                    name="Surname" 
                                    value={user.Surname} 
                                    onChange={this.handleChange}
                                />
                                {submitted && !user.Surname &&
                                    <p className="error">*required</p>
                                }
                            </div>
                            <div className="col-md-12">
                                <TextField 
                                    required 
                                    id="standard-basic" 
                                    label="EMAIL ADDRESS" 
                                    name="Email" 
                                    value={user.Email} 
                                    onChange={this.handleChange} 
                                />
                                {submitted && !user.Email &&
                                    <p className="error">* required</p>
                                }
                                {submitted && !emailFormat &&
                                    <p className="error">* this email has an invalid format.</p>
                                }
                                {submitted && emailExists &&
                                    <p className="error">* this email is already in use.</p>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12" id="opengrantsPassword">
                                <ReactPasswordStrength
                                    className="passwordStrength"
                                    minLength={8}
                                    minScore={2}
                                    scoreWords={['poor', 'weak', 'good!', 'strong!', 'stronger!!']}
                                    changeCallback={this.changeCallback}
                                    inputState={{ name: "Password", autoComplete: "off", className: "form-control", onChange: this.handleChange }}
                                    inputProps={{placeholder: "CREATE PASSWORD *"}}
                                />
                                {submitted && !user.Password &&
                                    <p className="error">*required</p>
                                }
                            </div>
                        </div>
                        <div className="row" style={{margin: 15, marginTop: 30}}>
                            <ReCAPTCHA
                                sitekey="6LdkE7McAAAAAPLR5rJfnK6TRdDFa0aMEx77hdwE"
                                onChange={() => this.setState({ captcha: true })}
                            />
                            {submitted && !captcha &&
                                <p className="error">*required</p>
                            }
                        </div>
                        <div className="row">
                            <button 
                                id="signUpLetsGo"
                                type="button"
                                style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                                onClick={() => this.handleNavigation('next')}
                            >
                                Register
                            </button>
                        </div>
                        <div className="row" id="alreadySignedUp">
                            <p>Already signed up? 
                                <span style={{color: whitelabel ? whitelabel.button_Color : "#29a", cursor: "pointer"}} onClick={() => this.goToLogin()}> Log in.</span>
                            </p>
                        </div>
                        <div className="row" id="signUpTerms">
                            {/* <p style={{fontSize:14}}>*By clicking "Try 14 days for $1." you agree to the */}
                            <p style={{fontSize:14}}>*By clicking "Register" you agree to the
                                <a href="https://www.opengrants.io/opengrants-legal/"><span style={{color: whitelabel ? whitelabel.button_Color : "#29a"}}> privacy policy</span></a> and 
                                <a href="https://www.opengrants.io/opengrants-legal/"><span style={{color: whitelabel ? whitelabel.button_Color : "#29a"}}> terms of service.</span></a>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}