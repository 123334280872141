import React from 'react';
import { connect } from 'react-redux';
import { listingActions, dashboardActions } from '../../_actions';
import { listingService, favoritesService, listingNotesService } from '../../_services';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import { history } from '../../_helpers';
import { Helmet } from "react-helmet";
import { format, parseISO } from "date-fns";
import { favoritesActions } from '../../_actions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from "react-tooltip";
import he from '../../../node_modules/he';
import stripHtml from "string-strip-html";
import {Segment} from '../Shared/Segment';

const lineBreaks = (string) => {
    let paragraphWithBreaks = string.split('. ')
                                .reduce((accum, curr, idx) => {
                                if (idx && (idx+1)%3 === 1) {
                                return [...accum,  "</br><br />", curr]
                                } else return [...accum, curr]
                                }, [])
                                .join('. ')

    return paragraphWithBreaks;
}

class Listing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listingViews: 0,
            themeColor: '',
            loginURL: '',
            registerURL: '/register',
            searchGroup: {
                search: "",
                user: this.props.user,
                page: 1,
                totalResults: 0,
            },
            isOpen: false,
            note: {
                user_notes: "",
                edit: false
            }
        }

        if (this.props.match !== undefined)
        {
            this.props.dispatch(listingActions.getListing(this.props.match.params.listing_Id));
                this.setState(prevState => ({
                    ...prevState,
                    modal: false,
            }));
        }
        else
        {
            this.state = {
                modal: true
            }
        }

        this.handleLogin = this.handleLogin.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.viewOriginal = this.viewOriginal.bind(this);
        this.linkCopied = this.linkCopied.bind(this);
        this.trimDescription = this.trimDescription.bind(this);
        this.checkHTML = this.checkHTML.bind(this);
    }

    async componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'));

        const favorites = localStorage.getItem('favorite');
        if (!favorites)
        {
            favoritesService.getFavorites(this.props.user);
        }

        if (this.props.match === undefined)
        {
            const listing = await listingService.getListing(this.props.value.listing_Id);
            this.setState({
                listingViews: listing.views,
            }); 
        }

        const path = window.location.search;
        if (path.includes('partner='))
        {
            const partner = path.split('=').pop();
            this.setState({
                loginURL: '/login/' + partner,
                registerURL: '/register/' + partner,
            })
        }
        else
        {
            this.setState({
                loginURL: '/login',
                registerURL: '/register',
            })
        };

        const note = await listingNotesService.getListingNotes(user.id, this.props.match.params.listing_Id);

        this.setState({
            themeColor: localStorage.getItem('themeColor'),
            favorites: localStorage.getItem('favorite') ? localStorage.getItem('favorite').includes(this.props.match.params.listing_Id) : false,
            note: {...note, edit: false}
        })
    }

    trimDescription = (desc) => {
        const description = stripHtml(desc, {
            stripTogetherWithTheirContents: [
                'script', // default
                'style',  // default
                'xml',    // default
                'pre', // <-- custom-added
            ]}
            ).result;

        return description;
    }

    linkCopied = (event) => {
        event.preventDefault();
        var user = JSON.parse(localStorage.getItem('user'));
        this.setState({
            updatedAlert: true,
        });

        setTimeout(() => {
            this.setState({
              updatedAlert: false
            });
          }, 3000);
    }

    handleRegister() {
        if (this.state.registerURL === '/register')
        {
            history.push(this.state.registerURL + "/" + this.props.listing.listing.listing_Id);
        }
        else
        {
            window.location.href=this.state.registerURL;
        }
    }

    handleLogin() {
        history.push(this.state.loginURL + "/" + this.props.listing.listing.listing_Id);
    }

    applyNow() {
        const user = JSON.parse(localStorage.getItem('user'));
        var listing = this.props.listing.listing;
        Segment.track(user, 'Listing', 
        {
            listing_action: 'apply',
            listing_id: listing.listing_Id,
            listing_title: listing.title
        });
        window.open('/apply?grantId='+listing.listing_Id);
    }

    viewOriginal() {
        const listing = this.props.listing.listing;
        const source_link = listing.listing_URL != null ? listing.listing_URL : listing.agency_Link;
        const user = JSON.parse(localStorage.getItem('user'));
        Segment.track(user, 'Listing', 
        {
            listing_action: 'view',
            listing_id: listing.listing_Id,
            listing_title: listing.title
        });

        if (source_link) {
            window.open(source_link, "_blank");
        }
    }

    requestInformation() {
        const user = JSON.parse(localStorage.getItem('user'));
        var listing = this.props.listing.listing;
        Segment.track(user, 'Listing', 
        {
            listing_action: 'request info',
            listing_id: listing.listing_Id,
            listing_title: listing.title
        });
        return;
    }

    openModal = () => {
        console.log("open modal");

        this.setState({
            isOpen: true
        });
    }

    openChat(type) {
        window.Intercom('trackEvent', type);
        window.Intercom('showNewMessage');
    }

    checkHTML(desc) {
        var doc = new DOMParser().parseFromString(desc, "text/html");
        var htmlPresent = Array.from(doc.body.childNodes).some(node => node.nodeType === 1);

        return htmlPresent;
    }

    handleSaveListing = (listing_Id, user) => {
        var listing = this.props.listing.listing;
        Segment.track(user, 'Listing', 
        {
            listing_action: 'save',
            listing_id: listing.listing_Id,
            listing_title: listing.title
        });
        this.props.dispatch(favoritesActions.saveListing(listing_Id, user));
        this.setState ({
            favorites: !this.state.favorites
        });
    }

    openChat = (type) => {
        window.Intercom('trackEvent', type);
        window.Intercom('showNewMessage');
    }

    editNote = (val, user_notes) => {
        var listing = this.props.listing.listing;
        let note = Object.assign({}, this.state.note);
        if (note.user_notes) {
            var user = JSON.parse(localStorage.getItem('user'));
            var listing = this.props.listing.listing;
            Segment.track(user, 'Listing', 
            {
                listing_action: 'add notes',
                listing_id: listing.listing_Id,
                listing_title: listing.title
            });
        } else {
            var user = JSON.parse(localStorage.getItem('user'));
            var listing = this.props.listing.listing;
            Segment.track(user, 'Listing', 
            {
                listing_action: 'edit notes',
                listing_id: listing.listing_Id,
                listing_title: listing.title
            });
        }

        if (user_notes) {
            note.user_notes = user_notes;
        }
        this.setState({
            note: {
                ...note,
                edit: val
            }
        });
    }

    saveNote = async () => {
        var save_notes_el = document.getElementById("save_notes");
        save_notes_el.disabled = true;

        var user_notes_el = document.getElementById("user_notes");
        var user_notes = user_notes_el.value;

        const user = JSON.parse(localStorage.getItem('user'));
        await listingNotesService.saveListingNotes(user.id, this.props.match.params.listing_Id, user_notes);
        save_notes_el.disabled = false;
        this.editNote(false, user_notes);
    }

    deleteNotes = async() => {
        this.setState({
            loading: true
        });
        const user = JSON.parse(localStorage.getItem("user"));
        var listing = this.props.listing.listing;
        Segment.track(user, 'Listing', 
        {
            listing_action: 'delete notes',
            listing_id: listing.listing_Id,
            listing_title: listing.title
        });
        await listingNotesService.deleteListingNotes(user.id, listing.listing_Id);

        this.setState({
            note: {
                user_notes: "",
                edit: false
            }
        })
    }

    render() {
        const { modal, favorites, updatedAlert, note } = this.state;
        const listing = (modal === true ? this.props.value : this.props.listing.listing);
        const { whitelabel } = this.props;
        var user = JSON.parse(localStorage.getItem('user'));
        const user_role = user ? user.userRoles : null;
        const server = window.location.protocol + '//' + window.location.host;

        return (
            <div id="listingMain">
                {listing &&
                    <div className="listingPartialDiv">
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>opengrants.io</title>
                            <meta property="og:type" content="website"></meta>
                            <meta name="og:description" content={listing.title} />
                            <meta name="description" content={listing.title} />
                            <meta property="og:title" content="opengrants.io" />
                            <meta property="og:url" content={server + "/grants/" + listing.listing_Id} />
                            <meta property="og:image" content="https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_graph.jpg" />
                            <meta name="twitter:title" content="opengrants.io" />
                            <meta name="twitter:description" content={listing.title} />
                            <meta name="twitter:image" content="https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_graph.jpg" />
                            <meta name="twitter:card" content="summary_large_image" />
                        </Helmet>
                        <div>
                            <div className="row">
                                <div className="col-md-12" id="listingArea">
                                    <ReactTooltip effect="solid" />
                                    <CopyToClipboard text={ window.location.origin + "/grants/" + listing.listing_Id }>
                                        <i className="material-icons" 
                                            onClick={this.linkCopied}
                                            id="listingShareIcon"
                                            data-tip="Share this Opportunity"
                                        >send</i>
                                    </CopyToClipboard>   
                                    {updatedAlert == true && <p id="linkCopied">Link copied!</p>}                                     
                                    <h1 class="hit-title">
                                        {he.decode(listing.title)}
                                    </h1>
                                    <h2 class="hit-agency" style={{marginBottom:15}}>
                                    <span class="material-icons" id="listingAgencyIcon">
                                        account_balance
                                    </span>
                                        {listing.agency}
                                    </h2>
                                    <a style={{color: "#03acc1", fontWeight: 500}} onClick={() => this.openChat('Suggest an update')}>Suggest an update.</a>
                                    <hr />
                                    <div class="row">
                                    <div class="col-md-8" style={{marginTop:15}}>
                                        <div className="hit-funding" style={{fontSize: 20, marginBottom: "5%"}}>
                                            {(listing.funding_Amount != null && listing.funding_Amount > 0) &&
                                                <p style={{marginLeft:-10}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="verifiedAward" style={{marginRight: 5}}>
                                                        <polyline points="20 6 9 17 4 12"></polyline>
                                                    </svg>  ${listing.funding_Amount.toLocaleString()}
                                                </p>
                                            }
                                            {!listing.funding_Amount && 
                                                <p>Funding varies.</p>
                                            }
                                        </div>
                                        {/* DUE DATE OUTPUT */}
                                        <div className="hit-description" style={{color: "#333", marginTop: 5}}>
                                            {listing.application_Due_Date && <p><strong>Due: </strong>{format(parseISO(listing.application_Due_Date), "MMMM do, yyyy")}</p>}
                                            {!listing.application_Due_Date && <p><strong>Due: </strong>Applications ongoing.</p>}
                                        </div>
                                        <div className="hit-description" style={{color: "#333", marginTop: 5}}>
                                            {listing.match_Required && listing.match_Required == true &&
                                                <p><strong>Match Required: </strong>Yes</p>
                                            }
                                            {((listing.match_Required && listing.match_Required == false) || !listing.match_Required) &&
                                                <p><strong>Match Required: </strong>No</p>
                                            }
                                        </div>
                                        <h2 class="hit-agency" style={{color: "#444", fontSize:14, margin:0}}>
                                        <strong>Tagged Under: </strong><br />
                                        {listing.listing_Tags &&
                                            listing.listing_Tags.map(function (tag) {
                                                return (
                                                    <h6 id="hitCategoryDash" style={{minWidth:125}}>{tag.tag != null ? tag.tag : tag.category_Tag}</h6>
                                                );
                                            })
                                        }
                                        {(listing.listing_Tags.length < 1 || !listing.listing_Tags) &&
                                            <h6 id="hitCategoryDash" style={{minWidth:125}}>General Funding Opportunities</h6>
                                        }
                                        </h2>
                                    </div>
                                    <div class="col-md-4" id="listingButtons" style={{marginTop:15}}>
                                        {whitelabel && whitelabel.features.feature_Apply &&
                                            <h4 id="applyButton">
                                                <Button 
                                                    id="applyButton" 
                                                    color="info" 
                                                    style={{width:200, background: whitelabel ? whitelabel.button_Color : "#29a"}} 
                                                    onClick={() => {this.applyNow()}}
                                                >
                                                    Apply Now
                                                </Button>
                                            </h4>
                                        }
                                        {(listing.listing_URL || listing.agency_Link) &&
                                            <h4 id="applyButton">
                                                <Button 
                                                    color="info" 
                                                    style={{width:200, background: whitelabel ? whitelabel.button_Color : "#29a"}} 
                                                    onClick={() => {this.viewOriginal()}}
                                                >
                                                    View Original
                                                </Button>
                                            </h4>
                                        }
                                        <h4 id="applyButton">
                                            <Button 
                                                color="info" 
                                                style={{width:200, background: whitelabel ? whitelabel.button_Color : "#29a"}} 
                                                onClick={() => this.openChat('Request Information')}
                                            >
                                                Request Information
                                            </Button>
                                        </h4>
                                        {user && !favorites && user.userRoles != 'Client_Free' &&
                                            <h4 id="applyButton">
                                                <Button 
                                                    color="info" 
                                                    style={{width:200, background: whitelabel ? whitelabel.button_Color : "#29a"}} 
                                                    onClick={() => {this.handleSaveListing(listing.listing_Id, user)}}
                                                >
                                                    Save Listing
                                                </Button>
                                            </h4>
                                        }
                                        {favorites && 
                                            <h4 id="applyButton">
                                                <Button 
                                                    color="info" 
                                                    style={{width:200, background: whitelabel ? whitelabel.button_Color : "#29a"}} 
                                                    onClick={() => {this.handleSaveListing(listing.listing_Id, user)}}
                                                >
                                                    <strong>Saved!</strong>
                                                </Button>
                                            </h4>
                                        }
                                        </div>
                                    </div>
                                    <hr style={{marginTop:35, marginBottom:50}}/>
                                    {whitelabel && whitelabel.features.feature_Notes &&
                                        <div>
                                            <h1 style={{color: "#666", fontSize:20, display: "inline-block"}}><strong>Notes</strong></h1>
                                                    {user_role != 'Client_Free' && !note.edit && note.user_notes &&
                                                        <div style={{float: "right"}}>
                                                            <Button 
                                                                color="info" 
                                                                style={{width:200, background: whitelabel ? whitelabel.button_Color : "#29a"}} 
                                                                onClick={() => {this.editNote(true)}}
                                                            >
                                                                Edit Notes
                                                            </Button>
                                                            <a onClick={this.deleteNotes} style={{display: "block", textAlign:"center", marginTop:"10px"}}>Delete Notes</a>
                                                        </div>
                                                    }
                                                    {user_role != 'Client_Free' && !note.edit && !note.user_notes &&
                                                        <Button 
                                                            color="info" 
                                                            style={{display: "block", background: whitelabel ? whitelabel.button_Color : "#29a"}} 
                                                            onClick={() => {this.editNote(true)}}
                                                        >
                                                            Add Notes
                                                        </Button>
                                                    }
                                            {user_role != 'Client_Free' &&
                                                <div>
                                                    {!note.edit && 
                                                        <p>{note.user_notes}</p>
                                                    }
                                                    {note.edit && 
                                                        <div>
                                                            <textarea id="user_notes" style={{width: "100%", padding: "15px"}}>{note.user_notes}</textarea>
                                                            <div style={{marginTop: "15px"}}>
                                                                <Button 
                                                                    id="save_notes" 
                                                                    // color="info" 
                                                                    color={whitelabel ? whitelabel.button_Color : "#29a"}

                                                                    onClick={this.saveNote} 
                                                                    style={{marginRight: "20px"}}
                                                                >
                                                                    Save
                                                                </Button>
                                                                <Button 
                                                                    onClick={() => {this.editNote(false)}}
                                                                    style={{background: whitelabel ? whitelabel.button_Color : "29a"}}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {user_role == 'Client_Free' && 
                                                <div>
                                                    <div style={{display: "inline-block"}} data-tip="Upgrade your account to add notes to listings.">
                                                        <Button 
                                                            onClick={() => {window.location.href = "/settings?val=billing"}}
                                                            style={{background: whitelabel ? whitelabel.button_Color : "29a"}}
                                                        >
                                                            Add Notes
                                                        </Button>
                                                    </div>
                                                </div>
                                            }
                                            <br />
                                            <br />
                                        </div>
                                    }
                                    <h1 style={{color: "#666", fontSize:20}}><strong>Overview</strong></h1>
                                    {/* Render sanitized HTML tags if present in description */}
                                    {listing.description && this.checkHTML(listing.description) &&
                                        <div id="dangListingDescription" dangerouslySetInnerHTML={{ __html: he.decode(listing.description.trim())}}></div>
                                    }
                                    {/* Re-style plain text if no HTML tags present */}
                                    {listing.description && !this.checkHTML(listing.description) &&
                                        <p id="listingDescription" style={{color: "#444", fontWeight: 400, maxWidth: 800, lineHeight: 1.75}}>
                                            {he.decode(listing.description.trim())}
                                        </p>
                                    }
                                    {!listing.description &&
                                        <p id="listingDescription">
                                            No description has been provided for this listing. Please refer to the documentation (View Original) or contact us to learn more about this program.
                                        </p>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }    
                {!localStorage.getItem('user') &&
                    <div id="userBlock" 
                        style={{height: "100%", width: "100%", position: "fixed", top: 0, left: 0}}
                        onClick={this.openModal}>
                    </div>
                }
                <div id="listingPreview">
                    <Modal id="listingPreviewModal"
                        isOpen={this.state.isOpen} 
                        fade={false}
                    >
                        <ModalHeader>
                            <h1 id="modalTitle">opengrants.io</h1>
                        </ModalHeader>
                        <ModalBody>
                            <h2>Welcome!</h2>
                            <hr />
                            <p>
                                To view this listing, sign up below to create an account. 
                                You can get started for free, 
                                and we'll bring you right back here once you're done.
                            </p>
                            <br />
                            <p>
                                Already signed up? Visit the login page to access your account.
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <Button 
                                id="listingModalPreviewRegister" 
                                color={whitelabel ? whitelabel.button_Color : "29a"}
                                onClick={this.handleRegister}
                                style={{minWidth: 200}}
                            >
                                Sign me up!
                            </Button>
                            <Button 
                                id="listingModalPreviewLogin" 
                                color="secondary"
                                onClick={this.handleLogin}
                                style={{minWidth: 200}}
                            >
                                Login
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { listing, setUser, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        listing,
        whitelabel
    };
}

const connectedListing = connect(mapStateToProps)(Listing);
export { connectedListing as Listing }; 