import React from 'react';
import { connect } from 'react-redux';
import { quickstartActions } from '../../../_actions';
import { quickstartService } from '../../../_services';
import { Loader } from '../../Shared/Loader';
import '../../../Styles/QuickstartDashboard.css';
import "react-step-progress-bar/styles.css";

class QuickstartDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user,
            values: [5],
            wait: false,
            // loading: true,
            programNames: null,
        };
    }

    async componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'));

        await this.props.dispatch(quickstartActions.getPrograms(this.props.user));
        let programNames = await quickstartService.getProgramInfo(user.id);

        console.log(programNames);
        this.setState({
            programNames
        });

        setTimeout(() => {
            this.setState({
                wait: true,
            })
        }, 1000);
        setTimeout(() => {
            this.setState({
                loading: false,
            })
        }, 750);
    }

    abbreviateNumber(value) {
        var newValue = value;
        if (value >= 1000) {
            var suffixes = ["", "k", "m", "b","t"];
            var suffixNum = Math.floor( (""+value).length/3 );
            var shortValue = '';
            for (var precision = 2; precision >= 1; precision--) {
                shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
                var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
                if (dotLessShortValue.length <= 2) { break; }
            }
            if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
            newValue = shortValue+suffixes[suffixNum];
        }
        return newValue;
    }

    goToProducts() {
        window.location.href="/quickstart/products"
    }

    async resetQuickstart() {
        const user = JSON.parse(localStorage.getItem('user'));
        await quickstartService.resetQuickstartUser(user);

        window.location.href="/quickstart/get-started";

    }

    talkWithPro = () => {
        window.Intercom('showNewMessage');
    }

    render() {
        const { user, getPrograms, whitelabel } = this.props;
        const { loading, programNames } = this.state;

        return (
            <div>
                {loading && <Loader compilingPrograms />}
                <div id="recommendedOpportunitiesDashboard" className="row">
                    {getPrograms.items && getPrograms.items.funding_Amount > 0 &&
                        <div style={{marginTop: whitelabel && whitelabel.organization_Name == "portal.opengrants.io" ? 0 : "5%"}}>
                            <div id="dashFundingAmountArea" style={{display: "block"}}>
                                <h1 id="availableFundingHeader">
                                    Great news { user.name }!
                                </h1>
                                <hr />
                                <div className="row" id="fundingFoundRow">
                                    <div className="col-md-6">
                                        <img id="grantHeroDashIMG" src="https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/open_grants_funding_everywhere.png" />
                                    </div>
                                    <div className="col-md-6">
                                        {programNames && programNames.length > 0 && 
                                            <p id="dashboardDynamicText">
                                                Based on your selections, we believe you're eligible for <strong>${this.abbreviateNumber(getPrograms.items.funding_Amount)}</strong> in potential funding from the following programs and grantors:
                                                <br /><br />
                                                <ul>
                                                    {programNames &&
                                                        programNames.map((program) =>               
                                                            <li>
                                                                <strong>{program.agency_Label}</strong>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                                <br /><br />
                                                There are many more steps on the path to winning this funding, but it doesn't have to be daunting. That's why we're here! Select the next step below that works best for you.
                                            </p>
                                        }
                                        {(!programNames || programNames.length == 0) && 
                                            <p id="dashboardDynamicText">
                                                Based on your selections, we believe you're eligible for <strong>${this.abbreviateNumber(getPrograms.items.funding_Amount)}</strong> in potential funding.
                                                <br /><br />                                                
                                                There are many more steps on the path to winning this funding, but it doesn't have to be daunting. That's why we're here! Select the next step below that works best for you.
                                            </p>
                                        }
                                        <br /><br />
                                        <input type="button" id="goToProducts" value="Show me my options  &rarr;" onClick={this.goToProducts} />
                                        <br />
                                    </div>
                                </div>
                                <hr />
                                <h1 id="dashboardDynamicText" style={{textAlign: "center", fontWeight: 500}}>
                                    Looking to start fresh?
                                </h1>
                                <input type="button" id="goToProducts" value="Reset my inputs  &rarr;" onClick={this.resetQuickstart} />
                            </div>
                        </div>
                    }
                    {((getPrograms.items && getPrograms.items.funding_Amount == 0) || !getPrograms.items) &&
                        <div>
                            <div id="dashFundingAmountArea" style={{display: "block"}}>
                                <h1 id="availableFundingHeader">
                                    { user.name },<br />here's the deal:
                                </h1>
                                <hr />
                                <div className="row" id="fundingFoundRow">
                                    <div className="col-md-6">
                                        <img id="grantHeroDashIMG" src="https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/open_grants_funding_everywhere.png" />
                                    </div>
                                    <div className="col-md-6">
                                    <h4 id="availableFundingSubHeader" style={{marginBottom: 50, marginTop: 50}}>
                                        Based on your selections, we were unable to automatically identify applicable grants for your organization.
                                        <br /><br />However, our team of experts here at OpenGrants has consolidated of the best grant opportunity databases on the planet! So click that button below and let's take a look at some more options.
                                    </h4>
                                        <input type="button" id="goToProducts" value="Talk with a Pro  &rarr;" onClick={this.talkWithPro} />
                                    </div>
                                </div>
                                <hr />
                                <br />
                                <h1 id="dashboardDynamicText" style={{textAlign: "center", fontWeight: 500}}>
                                    Looking to start fresh?
                                </h1>
                                <br />
                                <input type="button" id="goToProducts" value="Reset my inputs  &rarr;" onClick={this.resetQuickstart} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { getPrograms, setUser, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        getPrograms,
        setWhitelabel,
    };
}

const connectedQuickstartDashboard = connect(mapStateToProps)(QuickstartDashboard);
export { connectedQuickstartDashboard as QuickstartDashboard };