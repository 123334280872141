import { searchConstants } from '../_constants';
import { searchService } from '../_services';
import { push } from 'react-router-redux';

export const searchActions = {
    getSearchResults,
    getAllCategories,
    getAllAgencies,
    getAllLocations,
};

function getSearchResults(searchGroup, navBar) {
    return async dispatch => {
        dispatch(request(searchGroup));
        await searchService.getSearchResults(searchGroup)
            .then(
                results => { 
                    dispatch(success(results));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: searchConstants.GETSEARCHRESULTS_REQUEST } }
    function success(searchResults) { 
        //localStorage.setItem('activeCategories', JSON.stringify(searchResults.result.listingCategoryTags))
        return { type: searchConstants.GETSEARCHRESULTS_SUCCESS, searchResults } 
    }
    function failure(error) { return { type: searchConstants.GETSEARCHRESULTS_FAILURE, error } }
}

function getAllCategories() {
    return async dispatch => {
        dispatch(request());
        await searchService.getAllCategories()
            .then(
                categoriesList => dispatch(success(categoriesList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: searchConstants.GETALLCATEGORIES_REQUEST } }
    function success(categoriesList) {  return { type: searchConstants.GETALLCATEGORIES_SUCCESS, categoriesList } }
    function failure(error) { return { type: searchConstants.GETALLCATEGORIES_FAILURE, error } }
}

function getAllAgencies() {
    return async dispatch => {
        dispatch(request());
        await searchService.getAllAgencies()
            .then(
                agenciesList => dispatch(success(agenciesList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: searchConstants.GETALLAGENCIES_REQUEST } }
    function success(agenciesList) {  return { type: searchConstants.GETALLAGENCIES_SUCCESS, agenciesList } }
    function failure(error) { return { type: searchConstants.GETALLAGENCIES_FAILURE, error } }
}

function getAllLocations() {
    return async dispatch => {
        dispatch(request());
        await searchService.getAllLocations()
            .then(
                locationsList => dispatch(success(locationsList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: searchConstants.GETALLLOCATIONS_REQUEST } }
    function success(locationsList) {  return { type: searchConstants.GETALLLOCATIONS_SUCCESS, locationsList } }
    function failure(error) { return { type: searchConstants.GETALLLOCATIONS_FAILURE, error } }
}