export const matchesConstants = {
    GETMATCHES_REQUEST: 'MATCHES_GETMATCHES_REQUEST',
    GETMATCHES_SUCCESS: 'MATCHES_GETMATCHES_SUCCESS',
    GETMATCHES_FAILURE: 'MATCHES_GETMATCHES_FAILURE',

    SAVEMATCH_REQUEST: 'MATCH_SAVEMATCH_REQUEST',
    SAVEMATCH_SUCCESS: 'MATCH_SAVEMATCH_SUCCESS',
    SAVEMATCH_FAILURE: 'MATCH_SAVEMATCH_FAILURE',

    DISCARDMATCH_REQUEST: 'MATCH_DISCARDMATCH_REQUEST',
    DISCARDMATCH_SUCCESS: 'MATCH_DISCARDMATCH_SUCCESS',
    DISCARDMATCH_FAILURE: 'MATCH_DISCARDMATCH_FAILURE',
};
