import { authHeader } from '../_helpers';

export const searchService = {
    getSearchResults,
    getAllCategories,
    getAllCategoryTags,
    getAllAgencies,
    getAllLocations,
    getAllStates,
    getTotalListings,
};

function getSearchResults(searchGroup) {
    localStorage.setItem('activeSearch', searchGroup.search);
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(searchGroup)
    };

    return fetch('https://refactor.opengrants.io/api/listings/filter', requestOptions)
    .then(handleResponse)
    .then(searchGroup => {
        localStorage.setItem('searchGroup', JSON.stringify(searchGroup))

        return searchGroup;
    });;
}

function getAllCategories() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/settings/get_categories', requestOptions)
    .then(handleResponse)
}

function getAllCategoryTags() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/settings/get_category_tag_sections', requestOptions)
    .then(handleResponse)
}

function getAllAgencies() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/settings/get_agencies', requestOptions)
    .then(handleResponse)
}

function getAllLocations() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/settings/get_locations', requestOptions)
    .then(handleResponse)
}

function getAllStates() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/settings/get_states', requestOptions)
    .then(handleResponse)
}

function getTotalListings() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/listings/get_total_listings', requestOptions)
    .then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status == "401") {
                window.location.href="/login";
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}