import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import '../../Styles/Listing.css'

export class ShareListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updatedAlert: false
        }

        this.linkCopied = this.linkCopied.bind(this);
    }

    linkCopied = (event) => {
        event.preventDefault();

        this.setState({
            updatedAlert: true,
        });

        setTimeout(() => {
            this.setState({
              updatedAlert: false
            });
          }, 4000);
    }

    render() {
        const listing  = this.props.value;
        const { updatedAlert } = this.state;
        const listing_link = window.location.origin + "/grants/" + listing.listing_Id;
        const partner = localStorage.getItem('partner');
        const partner_listing_link = window.location.origin + "/grants/" + listing.listing_Id + "?partner=" + partner;

        return (
            <div id="shareListing">
                <h4 id="shareListingHeader">SHARE</h4>
                <div id="copyLink">
                    {!partner &&
                        <div>
                            <input id="copyLinkInput" type="text" defaultValue={ listing_link } />
                            <CopyToClipboard text={ listing_link }>
                                <h4 id="copyLink" onClick={this.linkCopied}>Copy link</h4>
                            </CopyToClipboard>
                        </div>
                    }
                    {partner &&
                        <div>
                            <input id="copyLinkInput" type="text" defaultValue={ partner_listing_link } />
                            <CopyToClipboard text={ partner_listing_link }>
                                <h4 id="copyLink" onClick={this.linkCopied}>Copy link</h4>
                            </CopyToClipboard>
                        </div>
                    }
                    { updatedAlert === true &&
                        <div id="linkCopySuccess" className="row">
                            <div className="alert-success">
                                Link copied to clipboard.
                            </div>
                        </div>
                    }
                </div>
                <hr />
            </div>
        );
    }
}