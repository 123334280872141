import { notificationsConstants } from '../_constants';
import { notificationsService } from '../_services';

export const notificationsActions = {
    getAllNotifications,
    markAllNotificationsSeen,
    markNotificationAsRead,

    get_email_notification_settings,
    update_email_notification_settings,
}

function getAllNotifications(userId) {
    console.log("GETTING ALL NOTFICIATIONS FOR " + userId + " ...");
    return dispatch => {
        if (userId) {
            dispatch(request());
            notificationsService.getAllNotifications(userId)
                .then(
                    (data) => {
                        dispatch(success(data));
                    },
                    error => {
                        dispatch(failure(error));
                    }
                )
        } else {
            dispatch(setSeen());
        }
    }

    function request() { return { type: notificationsConstants.NOTIFICATIONS_GETALLNOTIFICATIONS_REQUEST } }
    function success(data) { return { type: notificationsConstants.NOTIFICATIONS_GETALLNOTIFICATIONS_SUCCESS, data } }
    function failure(error) { return { type: notificationsConstants.NOTIFICATIONS_GETALLNOTIFICATIONS_FAILURE, error } }
    function setSeen() { return {type: notificationsConstants.NOTIFICATIONS_GETALLNOTIFICATIONS_SEEN}}
}

function markAllNotificationsSeen(userId, unseenIds) {
    console.log("MARKING ALL NOTFICIATIONS SEEN FOR " + userId + " ..." + unseenIds);
    return dispatch => {
        dispatch(request());
        notificationsService.markAllNotificationsSeen(userId, unseenIds)
            .then(
                () => {
                    dispatch(success())
                    // dispatch(getAllNotifications(userId));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function request() { return { type: notificationsConstants.NOTIFICATIONS_MARKSEEN_REQUEST } }
    function success() { return { type: notificationsConstants.NOTIFICATIONS_MARKSEEN_SUCCESS } }
    function failure(error) { return { type: notificationsConstants.NOTIFICATIONS_MARKSEEN_FAILURE, error } }
}

function markNotificationAsRead(userId, notificationId) {
    console.log("MARKING NOTIFICATION ID, " + notificationId + ", AS READ FOR:" + userId + " ...");
    return dispatch => {
        dispatch(request());
        notificationsService.markNotificationAsRead(userId, notificationId)
            .then(
                () => {
                    dispatch(success())
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function request() { return { type: notificationsConstants.NOTIFICATIONS_MARKREAD_REQUEST } }
    function success() { return { type: notificationsConstants.NOTIFICATIONS_MARKREAD_SUCCESS } }
    function failure(error) { return { type: notificationsConstants.NOTIFICATIONS_MARKREAD_FAILURE, error } }
}

function get_email_notification_settings(user_id) {
    return dispatch => {
        dispatch(request());
        notificationsService.get_email_notification_settings(user_id)
            .then(
                (email_notification_settings) => {
                    dispatch(success(email_notification_settings))
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function request() { return { type: notificationsConstants.NOTIFICATIONS_GETEMAILNOTIFICATIONSETTINGS_REQUEST } }
    function success(email_notification_settings) { return { type: notificationsConstants.NOTIFICATIONS_GETEMAILNOTIFICATIONSETTINGS_SUCCESS, email_notification_settings } }
    function failure(error) { return { type: notificationsConstants.NOTIFICATIONS_GETEMAILNOTIFICATIONSETTINGS_FAILURE, error } }
}

function update_email_notification_settings(user_updated_notifications) {
    return dispatch => {
        dispatch(request());
        notificationsService.update_email_notification_settings(user_updated_notifications)
            .then(
                (email_notification_settings) => {
                    dispatch(success(email_notification_settings))
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function request() { return { type: notificationsConstants.NOTIFICATIONS_UPDATEEMAILNOTIFICATION_REQUEST } }
    function success(email_notification_settings) { return { type: notificationsConstants.NOTIFICATIONS_UPDATEEMAILNOTIFICATION_SUCCESS, email_notification_settings } }
    function failure(error) { return { type: notificationsConstants.NOTIFICATIONS_UPDATEEMAILNOTIFICATION_FAILURE, error } }
}