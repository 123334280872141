import React from "react";
import { connect } from 'react-redux';
import { Loader } from '../Shared/Loader';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import Label from "@material-ui/icons/Label";
import Class from "@material-ui/icons/Class";
import GridItem from "../../_components/dashboardComponents/Grid/GridItem.js";
import GridContainer from "../../_components/dashboardComponents/Grid/GridContainer.js";
import Table from "../../_components/dashboardComponents/Table/Table.js";
import CustomTabs from "../../_components/dashboardComponents/CustomTabs/CustomTabs.js";
import Card from "../../_components/dashboardComponents/Card/Card.js";
import CardHeader from "../../_components/dashboardComponents/Card/CardHeader.js";
import CardBody from "../../_components/dashboardComponents/Card/CardBody.js";
import '../../Styles/Resources.css';

class ProspectSV_Advisors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount() {

    }

    render() {
        const { user, whitelabel } = this.props;

        return (
            <div id="prospectSVResources">
                {!user && <Loader />}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                    <CustomTabs
                        marginRight={25}
                        initialValue={0}
                        headerColor={whitelabel ? whitelabel.dashboard_Color : "#29a"}
                        tabs={[
                        {
                            tabName: "Advisors",
                            tabIcon: Class,
                            tabContent: (
                                <div>
                                    <br />
                                    <p id="prospectSVExpertDesc">These advisors are curated by Prospect Silicon Valley to serve our startups with expert advice as they scale. Advisors set their own rates. Click on their profiles to read more and connect with them.</p>
                                    <p id="prospectSVExpertDesc">Please note that these advisors are available to ProspectSV startups only. If you are NOT a startup and would like to connect with one of these advisors, please get in touch with Doug Davenport or Taylor Grossman.</p>
                                    <iframe 
                                        class="airtable-embed" 
                                        src="https://airtable.com/embed/shrZckQqOSRNwObbQ?backgroundColor=cyan&viewControls=on"     
                                        frameborder="0" 
                                        onmousewheel="" 
                                        width="100%" 
                                        height="533" 
                                        style={{background: "transparent", border: "1px solid #ccc"}}
                                    >
                                    </iframe>
                                </div>
                            )
                        },
                        ]}
                    />
                    </GridItem>
                </GridContainer>
                {/* {user && (whitelabel && whitelabel.features.feature_Expert_Resources) &&
                    <div id="findExperts">
                        
                    </div>
                } */}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        whitelabel
    };
}

const connectedProspectSV_Advisors = connect(mapStateToProps)(ProspectSV_Advisors);
export { connectedProspectSV_Advisors as ProspectSV_Advisors };
