import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ProgressBar, Step } from "react-step-progress-bar";
import { quickstartActions } from '../../../_actions';
import '../../../Styles/QuickstartDashboard.css';
import "react-step-progress-bar/styles.css";
import '../../../Styles/QuickstartCheckout.css'
import { stripeService } from '../../../_services';

class QuickstartProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.props.dispatch(quickstartActions.getPrograms(this.props.user));
    }

    abbreviateNumber(value) {
        var newValue = value;
        if (value >= 1000) {
            var suffixes = ["", "k", "m", "b","t"];
            var suffixNum = Math.floor( (""+value).length/3 );
            var shortValue = '';
            for (var precision = 2; precision >= 1; precision--) {
                shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
                var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
                if (dotLessShortValue.length <= 2) { break; }
            }
            if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
            newValue = shortValue+suffixes[suffixNum];
        }
        return newValue;
    }

    goToGW() {
        window.location.href="/checkout/grantwriter"
    }

    async goToDIY() {
        let user = JSON.parse(window.localStorage.getItem('user'));
        let productGroup = 'diy';
        await stripeService.purchaseProductType(user.id, productGroup);
        window.location.href="/quickstart/diy-resources"
    }

    goToConsulting () {
        window.open("https://calendly.com/opengrants-consultants/meet-with-a-funding-expert", '_blank');
    }

    goToPortal () {
        window.open("https://portal.opengrants.io/portal_standard", '_blank');
    }

    openChat() {        
        window.Intercom('showNewMessage');
    }

    render() {
        const { user, getPrograms, whitelabel } = this.props;
        return (
            <div style={{marginTop: whitelabel && whitelabel.organization_Name == "portal.opengrants.io" ? 0 : "10%"}}>
                <div>
                    <h1 id="getStartedProducts"><strong>{ user.name }, let's get started on your funding!</strong></h1>
                </div>
                <div className="row" id="productSelectRow">
                    <div className="col-md-6" style={{padding: 25}}>
                        <div id="productGW">
                            <p 
                                id="mostPopular"
                                style={{background: whitelabel ? whitelabel.accent_Color : "#29a"}}
                            >
                                MOST POPULAR!
                            </p>
                            <h2 id="productTitleMain">Full Service Grant Writing</h2>
                            <br />
                            <p id="productDesc">Submit a highly competitive grant application.</p>
                            <hr id="productHR" />
                            <h2 id="productPrice">$99 deposit</h2>
                            <p id="productDesc" style={{height: "auto", fontSize: 14}}>*This completely refundable deposit lets us know you're serious. After we make sure you're a good fit, you'll choose a payment plan that best fits your needs.</p>
                            <hr id="productHR" />
                            <p id="productDescIncluded" style={{marginBottom: 25}}>What's included:</p>
                            <ul style={{height: "auto"}}>
                                <li id="productListItem">
                                    <i id="productCheckmark" className="material-icons">
                                        done
                                    </i>
                                    <p id="productCheckP">End-to-end service including grant writing, application submission, and award management.</p>
                                </li>
                                <li id="productListItem">
                                <i id="productCheckmark" className="material-icons">
                                        done
                                    </i>
                                    <p id="productCheckP">Convenient financing options including monthly payment plans.</p>
                                </li>
                                <li id="productListItem">
                                <i id="productCheckmark" className="material-icons">
                                        done
                                    </i>
                                    <p id="productCheckP">Improved chance of winning by working with curated freelance grant writers to develop the most competitive proposal possible.</p>
                                </li>
                                <li id="productListItem">
                                <i id="productCheckmark" className="material-icons">
                                        done
                                    </i>
                                    <p id="productCheckP">Option to include SAM / DUNS registration services.</p>
                                </li>
                            </ul>
                            <input 
                                type="button" 
                                id="productButtonGW" 
                                value="Let's do this." 
                                onClick={this.goToGW}
                                style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                            />
                        </div>
                    </div>
                    <div className="col-md-6" style={{padding: 25}}>
                        <div id="productDIY">
                            <h2 id="productTitleMain">DIY Resources</h2>
                            <br />
                            <p id="productDesc">Everything you need to apply for funding on your own.</p>
                            <hr id="productHR" />
                            <h2 id="productPriceFree">Free</h2>
                            <hr id="productHR" />
                            <p id="productDescIncluded" style={{marginBottom: 25}}>What's included:</p>
                            <ul style={{height: "auto"}}>
                                <li id="productListItem">
                                    <i id="productCheckmark" className="material-icons">
                                        done
                                    </i>
                                    <p id="productCheckP">A comprehensive guide to applying for each of the relevant programs OpenGrants has matched you with.</p>
                                </li>
                                <li id="productListItem">
                                <i id="productCheckmark" className="material-icons">
                                        done
                                    </i>
                                    <p id="productCheckP">Access to templates, worksheets, and our curated list of preferred tools to give you every competitive advantage possible.</p>
                                </li> 
                                <li id="productListItem">
                                <i id="productCheckmark" className="material-icons">
                                        done
                                    </i>
                                    <p id="productCheckP">Access to monthly training calls with our experts to learn how the grant process works.</p>
                                </li>                             
                            </ul>
                            <input type="button" id="productButtonDIY" value="Let's do this." onClick={this.goToDIY} />
                        </div>
                    </div>
                </div>
                <hr style={{marginTop: 100}}/>
                <div className="row" id="needHelpProductsRow">
                    <div className="col-md-6">
                        <img id="grantHeroIMG" src="https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/grant_writing_superheroes.png" />
                    </div>
                    <div className="col-md-6" id="getHelpChoosingDiv">
                        <h1>Not sure which package to choose?</h1>
                        <br />
                        <p>Our team of funding experts are here to help! We'll guide you to best way to get you and your business funded.</p>
                        <input type="button" id="getHelpButton" value="Talk to a Pro." onClick={this.openChat} />
                    </div>
                </div>
                {getPrograms.items && getPrograms.items.funding_Range.low_End !== 0 && getPrograms.items.funding_Range.high_End !== 0 &&
                    <div>
                        <hr style={{marginTop: 100}}/>
                        <div id="maximizeAwardDiv">
                            <h1 id="maximizeFundingH1">${this.abbreviateNumber(getPrograms.items.funding_Range.low_End)}  &nbsp;&nbsp;-&nbsp;&nbsp; ${this.abbreviateNumber(getPrograms.items.funding_Range.high_End)}</h1>
                            <p id="didYouKnow">Did you know that working with a professional grant writer dramatically increases your chances of submitting a winning application?</p>
                            <p id="fundingRangeLikeYou">That range displayed above are typical awards granted to businesses just like yours.</p>
                            <input 
                                type="button" 
                                id="getGrantWriterButton" 
                                value="Hire a grant writer." 
                                onClick={this.goToGW} 
                                style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, getPrograms, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        getPrograms,
        whitelabel,
    };
}

const connectedQuickstartProducts = connect(mapStateToProps)(QuickstartProducts);
export { connectedQuickstartProducts as QuickstartProducts };