import React from 'react';
import { connect } from 'react-redux';
import { settingsActions } from '../../_actions';
import { authenticateActions } from '../../_actions';
import { Loader } from '../Shared/Loader';
import Select from 'react-select';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import '../../Styles/SettingsStyles/Settings.css';

const options = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
];

class Advanced extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            updatedAlert: false,
            user: JSON.parse(localStorage.getItem('user'))
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    handleSelectionChange(value, name) {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value.value
            }
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        const { dispatch } = this.props;

        await dispatch(settingsActions.updateAdvanced(this.state.user));
        await this.props.dispatch(authenticateActions.setUser(localStorage.getItem('user')));

        this.setState({
            submitted: true,
            updatedAlert: true,
        });

        setTimeout(() => {
            this.setState({
              updatedAlert: false
            });
          }, 4000);
    }

    render() {
        const user = this.state.user;
        const { advanced } = this.props;

        return (
            <div>
                <form id="advancedForm" className="settingsForm" onSubmit={this.handleSubmit}>
                    <div id="mainSettings">
                        {/* {advanced.loading && <Loader />} */}
                        <div className="row" style={{marginTop: "5%"}}>
                            <h1 id="dashHeader">Advanced Settings</h1>
                            <div className="col-lg-6">
                                <div className="settingsSelectOption">
                                    <h2>Are You Registered in SAM?</h2>
                                    <div>
                                        <Select 
                                            name="saM_Registration"
                                            isClearable={false}
                                            isSearchable={false}
                                            defaultValue={user.saM_Registration === true ? { label: "Yes", value: true } : { label: "No", value: false }}
                                            onChange={(value) => this.handleSelectionChange(value, "saM_Registration")}
                                            options={options}
                                        />
                                    </div>
                                </div>
                                <div className="settingsSelectOption">
                                    <h2>Woman Owned Business?</h2>
                                    <div>
                                        <Select 
                                            name="woman_Owned"
                                            isClearable={false}
                                            isSearchable={false}
                                            defaultValue={user.woman_Owned === true ? { label: "Yes", value: true } : { label: "No", value: false }}
                                            onChange={(value) => this.handleSelectionChange(value, "women_Owned")}
                                            options={options}
                                        />
                                    </div>
                                </div>
                                <div className="settingsSelectOption">
                                    <h2>Certified Veteran Owned Business</h2>
                                    <div>
                                        <Select 
                                            name="veteran_Owned"
                                            isClearable={false}
                                            isSearchable={false}
                                            defaultValue={user.veteran_Owned === true ? { label: "Yes", value: true } : { label: "No", value: false }}
                                            onChange={(value) => this.handleSelectionChange(value, "veteran_Owned")}
                                            options={options}
                                        />
                                    </div>
                                </div>
                                <div className="settingsSelectOption">
                                    <h2>Are You Located in a Hubzone?</h2>
                                    <div>
                                        <Select 
                                            name="hubzone_Owned"
                                            isClearable={false}
                                            isSearchable={false}
                                            defaultValue={user.hubzone_Owned === true ? { label: "Yes", value: true } : { label: "No", value: false }}
                                            onChange={(value) => this.handleSelectionChange(value, "hubzone_Owned")}
                                            options={options}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div id="advancedSettingsTextAreas" className="col-lg-6">
                                <div style={{paddingBottom: 20}}>
                                    <h2>DUNS Number</h2>
                                    <input name="dunS_Number" value={ user.dunS_Number } onChange={this.handleChange} />
                                </div>
                                <div style={{paddingBottom: 20}}>
                                    <h2>EIN</h2>
                                    <input name="eiN_Number" value={ user.eiN_Number } onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                        {/* <div id="editOrgButtons">
                            <button className="saveForm" id="saveAdvanced">Save Advanced</button>
                        </div> */}
                        <hr style={{marginTop: 35}}/>
                        <h4 style={{fontWeight: 500}}>
                            <Button color="info" onClick={this.handleSubmit}>
                                Update Billing
                                <ArrowForwardIos style={{float: "right", fontSize:32, marginTop: 0, marginLeft:10, marginRight:-10}}/>
                            </Button>
                        </h4>
                        <br />
                    </div>
                    {this.state.updatedAlert &&
                        <div>
                            <br /><br />
                            <div id="settingsUpdatedSuccess" className={`alert-success`}>Advanced settings have been updated.</div>
                        </div>
                    }
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, advanced } = state;
    const { user } = setUser;
    return {
        user,
        advanced
    };
}

const connectedRegisterPage = connect(mapStateToProps)(Advanced);
export { connectedRegisterPage as Advanced };