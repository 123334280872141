import React from 'react';
import '../../Styles/Listing.css'

export class ListingTags extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            themeColor: localStorage.getItem('themeColor')
        }
    }

    moreInfo() {
        window.open('https://experience.opengrants.io/learn-grants');
    }

    render() {
        const listing  = this.props.value;
        return (
            <div>
                <h4 
                    id="taggedUnder"
                    style={{color: this.state.themeColor}}
                >
                    TAGGED UNDER
                </h4>
                <div id="taggedUnderRow" className="row">
                    <div>
                        { listing.listing_Tags && listing.listing_Tags.map((tag) =>
                            <h6 key={ tag.listing_Id +"_tag_" + tag.category_Tag }>
                                { tag.category_Tag }
                            </h6>
                        )}
                        {/* { listing.category && 
                            <h6 key={listing.listing_Id + "_tag_" + listing.category}>
                                    { listing.category }
                            </h6>
                        } */}
                        <h6 key={ listing.agency.listing_Id +"_tag_" + listing.agency }>
                            { listing.agency }
                        </h6>
                    </div>
                </div>
            </div>
        );
    }
}