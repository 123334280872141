import { connect } from 'react-redux';
import { stripeActions } from '../../_actions';
import React, {Component} from 'react';
import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';
import { stripeService } from '../../_services';
import '../../Styles/SettingsStyles/Billings.css';

import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import Class from "@material-ui/icons/Class";
import { makeStyles } from "@material-ui/core/styles";
import { Loader } from '../Shared/Loader';

const styles = {
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    }
  };

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

class _CardForm extends Component {
    cardEl = React.createRef();

  state = {
    errorMessage: '',

    name: '',
    address_line1: '',
    address_city: '',
    address_state: '',
    zipcode: '',

    nameError: false,
    address_line1Error: false,
    address_cityError: false,
    address_stateError: false,
    zipcodeError: false,

    submitting: false,
  };

  handleInputChange = (e) => {
      const {id, value} = e.target;
      this.setState({
          [id]: value,
          [id+"Error"]: false
      })
  }
  
  resetInputs = () => {
      this.setState({
          name: '',
          address_line1: '',
          address_city: '',
          address_state: '',
          zipcode: ''
      });
      this.cardEl.current._element.clear();
      document.getElementById("cardForm").getElementsByClassName("error")[0].innerHTML = "";
  }

  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  handleSubmit = (evt) => {
    this.setState({
      submitting: true,
    });

    evt.preventDefault();
    var name = document.getElementById("name").value;
    var address = document.getElementById("address_line1").value;
    var city = document.getElementById("address_city").value;
    var state = document.getElementById("address_state").value;
    var zipcode = document.getElementById("zipcode").value;
    const {billingAddress} = this.props;

    var formIsValid = this.formIsValid(name, address, city, state, zipcode);
    var addressExists = false;
    if (!formIsValid) {
      addressExists = this.addressExists(billingAddress, name, address, city, state, zipcode);
    }

    if (formIsValid || addressExists) {
        if (this.props.stripe) {
          var options = {};
          if (formIsValid) {
            options = {
              "name": name,
              "address_line1": address,
              "address_city": city,
              "address_state": state,
              "zipcode": zipcode,
              }
          } else if (addressExists) {
            options = {
              "name": billingAddress["cardholderName"],
              "address_line1": billingAddress["address"],
              "address_city": billingAddress["city"],
              "address_state": billingAddress["state"],
              "zipcode": billingAddress["zipcode"],
              }
          }
          
          this.props.stripe.createToken(options).then(({token}) =>
                {
                  if (!token) {
                    return;
                  }
                  let stripeToken = token.id;
                  this.props.handleResult(stripeToken);
                  this.resetInputs();
                });
        }
    }
  };

  formIsValid = (name, address, city, state, zipcode) => {
    if (name !== ""
    || address !== ""
    || city !== ""
    || state !== ""
    || zipcode !== ""
    ) {
      return this.showErrors(name, address, city, state, zipcode);
    } 
    return false;
  }

  addressExists = (billingAddress, name, address, city, state, zipcode) => {
    if (billingAddress 
    && billingAddress["cardholderName"]
    && billingAddress["address"]
    && billingAddress["city"]
    && billingAddress["state"]
    && billingAddress["zipCode"]
    ) {
      return true;
    }
    return this.showErrors(name, address, city, state, zipcode);
  }

  showErrors = (name, address, city, state, zipcode) => {
    let nameError = false;
    let address_line1Error = false;
    let address_cityError = false;
    let address_stateError = false;
    let zipcodeError = false;

    if (name === "") {
      nameError = true;
    }

    if (address === "") {
      address_line1Error = true;
    }

    if (city === "") {
      address_cityError = true;
    }

    if (state === "") {
      address_stateError = true;
    }

    if (zipcode === "") {
      zipcodeError = true;
    }

    this.setState({
      nameError,
      address_line1Error,
      address_cityError,
      address_stateError,
      zipcodeError
    });

    return !nameError && !address_line1Error && !address_cityError && !address_stateError && !zipcodeError;
  }

  render() {
    const {nameError, address_line1Error, address_cityError, address_stateError, zipcodeError, submitting} = this.state;
    const {billingAddress} = this.props;
    
    return (
      <div id="payment-form">
        {submitting && <Loader />}
        {billingAddress && billingAddress.lastFour && billingAddress.expMonth && billingAddress.expYear &&
            <div className="billingAddress">
                <h2>Current Billing Details:</h2>
                <div>
                    <p>Card Number: XXXX XXXX XXXX {billingAddress["lastFour"]}</p>
                    <p>Expiration Date: {billingAddress["expMonth"]} / {billingAddress["expYear"]}</p>
                </div>
                {billingAddress["cardholderName"] &&
                <div>
                    <p>{billingAddress["cardholderName"]}</p>
                    <p>{billingAddress["address"]}</p>
                    <p>
                        <span>{billingAddress["city"]},</span>
                        <span> {billingAddress["state"]}</span>
                        <span> {billingAddress["zipcode"]}</span>
                    </p>
                </div>}
            </div>
        }
        <form id="cardForm" className="billingAddress" onSubmit={this.handleSubmit.bind(this)}>
          <h2>
            Card details
          </h2>
            <div id="billingNumbers">
              <CardElement
                onChange={this.handleChange}
                ref={this.cardEl}
                {...createOptions()}
              />
            </div>
          <div className="error" role="alert">
            {this.state.errorMessage}
          </div>
        <div className="billingAddress">
            <h2>Billing Address</h2>
            <div>
                <input id="name" name="CardholderName" placeholder="Cardholder Name" value={this.state.name} onChange={this.handleInputChange} required />
                {nameError && <span className="error">This field is required.</span>}
            </div>
            <div>
                <input id="address_line1" name="Address" placeholder="Address" value={this.state.address_line1} onChange={this.handleInputChange} required />
                {address_line1Error && <span className="error">This field is required.</span>}
            </div>
            <div>
                <input id="address_city" name="City" placeholder="City" value={this.state.address_city} onChange={this.handleInputChange} required />
                {address_cityError && <span className="error">This field is required.</span>}
            </div>
            <div>
                <input id="address_state" name="State" placeholder="State" value={this.state.address_state} onChange={this.handleInputChange} required />
                {address_stateError && <span className="error">This field is required.</span>}
            </div>
            <div>
                <input id="zipcode" name="Zipcode" placeholder="ZipCode" value={this.state.zipcode} onChange={this.handleInputChange} required />
                {zipcodeError && <span className="error">This field is required.</span>}
            </div>
            {/* <div>
                <input id="address_country" hidden placeholder="Country" required />
            </div> */}
        </div>
          {/* <button>Update</button> */}
          <hr style={{marginTop: 35}}/>
          <h4 style={{fontWeight: 500}}>
              <Button color="info">
                  Update Billing
                  <ArrowForwardIos style={{float: "right", fontSize:32, marginTop: 0, marginLeft:10, marginRight:-10}}/>
              </Button>
          </h4>
          <br />
        </form>
      </div>
    );
  }
}

const CardForm = injectStripe(_CardForm);

class Billing extends Component {
  constructor() {
    super();
    this.state = {stripe: null};
  }
  
  async componentDidMount() {
      this.getBillingAddress();
      if (window.Stripe) {
        this.setState({stripe: window.Stripe('pk_live_tiXsu1gfHlco4B3DSsgbkJpi000k1rpuwr')});
      } else {
        document.querySelector('#stripe-js').addEventListener('load', () => {
          // Create Stripe instance once Stripe.js loads
          this.setState({stripe: window.Stripe('pk_live_tiXsu1gfHlco4B3DSsgbkJpi000k1rpuwr')});
        });
      }
  }

  getBillingAddress = () => {
      this.props.dispatch(stripeActions.getBillingAddress(this.props.user["id"]));
  }

  handleResult = (stripeToken) => {
      const billingAddress = {
          "stripeToken": stripeToken,
          "userId": this.props.user["id"]
      };
      this.props.dispatch(stripeActions.handleStripeToken(billingAddress));
  }

  render() {
    const {getBillingAddress} = this.props;
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <CardForm billingAddress={getBillingAddress.billingAddress} handleResult={this.handleResult} />
        </Elements>
      </StripeProvider>
    );
  }
}

function mapStateToProps(state) {
    const { setUser, handleStripeToken, getBillingAddress } = state;
    const { user } = setUser;
    return {
        user,
        handleStripeToken,
        getBillingAddress
    };
}

const connectedBilling = connect(mapStateToProps)(Billing);
export { connectedBilling as Billing }; 