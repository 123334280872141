export const stripeConstants = {
    CONNECT_REQUEST: 'STRIPE_CONNECT_REQUEST',
    CONNECT_SUCCESS: 'STRIPE_CONNECT_SUCCESS',
    CONNECT_FAILURE: 'STRIPE_CONNECT_FAILURE',
    
    TOKEN_REQUEST: 'STRIPE_TOKEN_REQUEST',
    TOKEN_SUCCESS: 'STRIPE_TOKEN_SUCCESS',
    TOKEN_FAILURE: 'STRIPE_TOKEN_FAILURE',
    
    BILLING_ADDRESS_REQUEST: 'STRIPE_BILLING_ADDRESS_REQUEST',
    BILLING_ADDRESS_SUCCESS: 'STRIPE_BILLING_ADDRESS_SUCCESS',
    BILLING_ADDRESS_FAILURE: 'STRIPE_BILLING_ADDRESS_FAILURE',

    PURCHASE_PRODUCT_REQUEST: 'STRIPE_PURCHASE_PRODUCT_REQUEST',
    PURCHASE_PRODUCT_SUCCESS: 'STRIPE_PURCHASE_PRODUCT_SUCCESS',
    PURCHASE_PRODUCT_FAILURE: 'STRIPE_PURCHASE_PRODUCT_FAILURE',
    PURCHASE_PRODUCT_RESET: 'STRIPE_PURCHASE_PRODUCT_RESET',

    UPDATE_BILLING_REQUEST: 'STRIPE_UPDATE_BILLING_REQUEST',
    UPDATE_BILLING_SUCCESS: 'STRIPE_UPDATE_BILLING_SUCCESS',
    UPDATE_BILLING_FAILURE: 'STRIPE_UPDATE_BILLING_FAILURE',
    UPDATE_BILLING_RESET: 'STRIPE_UPDATE_BILLING_RESET',

    CANCEL_SUBSCRIPTION_REQUEST: 'STRIPE_CANCEL_SUBSCRIPTION_REQUEST',
    CANCEL_SUBSCRIPTION_SUCCESS: 'STRIPE_CANCEL_SUBSCRIPTION_SUCCESS',
    CANCEL_SUBSCRIPTION_FAILURE: 'STRIPE_CANCEL_SUBSCRIPTION_FAILURE',
    CANCEL_SUBSCRIPTION_RESET: 'STRIPE_CANCEL_SUBSCRIPTION_RESET',

    UPDATE_SUBSCRIPTION_REQUEST: 'STRIPE_UPDATE_SUBSCRIPTION_REQUEST',
    UPDATE_SUBSCRIPTION_SUCCESS: 'STRIPE_UPDATE_SUBSCRIPTION_SUCCESS',
    UPDATE_SUBSCRIPTION_FAILURE: 'STRIPE_UPDATE_SUBSCRIPTION_FAILURE',
    UPDATE_SUBSCRIPTION_RESET: 'STRIPE_UPDATE_SUBSCRIPTION_RESET',

    PAYWALL_ALERT: 'STRIPE_PAYWALL_ALERT'
}