import { authHeader } from '../_helpers';

export const personalprofileService = {
    getAllProfiles,
    getProfile,
    setPublicProfile
};

function getAllProfiles(page) {
    if (!page) page = 0;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/personalprofile/get_all_profiles?page='+page, requestOptions).then(handleResponse);
}

function getProfile(username) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/personalprofile/get_profile?username='+username, requestOptions).then(handleResponse);
}

function setPublicProfile(user_id, public_profile) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(public_profile)
    };

    return fetch('https://refactor.opengrants.io/api/personalprofile/set_public_profile?user_id='+user_id, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok ) {
            if (response.status == "401") {
                window.location.href="/login";
            }
            
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}