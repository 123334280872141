import React from 'react';
import '../../../Styles/StripeProduct.css';

export class Registration_Products extends React.Component {
    constructor(props) {
        super(props);
    }

    redirectTo = (page) => {
        const {updatPlanSelection} = this.props;
        if (updatPlanSelection) {
            updatPlanSelection(page);
        } else {
            window.location.href = page;
        }
    }

    render() {
        return (
            <div className="center">
                <div className="col-md-12 background-white padding-lg">
                    <div>
                        <h1 className="empty-style">Select a Plan</h1>
                    </div>
                    <div className="col-md-6 inline align-top">
                        <div className="gap-all border-og border">
                            <div className="gap-all">
                                <h1 className="gap-top gap-bottom">Standard Plan</h1>
                                <p>Some description here</p>
                                <div className="padding-md">
                                    <p>Starting at</p>
                                    <p className="font-lg bold">$25</p>
                                    <p>Per month, billed annually or $35 billed monthly</p>
                                </div>
                                <button className="button-padded background-og-color gap-bottom" onClick={() => this.redirectTo('portal_standard')}>Try 14 days for $1</button>
                                <p className="gap-bottom">Other information here</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 inline align-top">
                        <div className="gap-all border-grey">
                            <div className="gap-all">
                                <h1 className="gap-top gap-bottom">Professional Plan</h1>
                                <p>Some description here</p>
                                <div className="padding-md">
                                    <p>Starting at</p>
                                    <p className="font-lg bold">$500</p>
                                    <p>per year or $750 billed monthly</p>
                                </div>
                                {/* <button className="button-padded border-og background-white og-color gap-bottom" onClick={() => this.redirectTo('portal_professional')}>Select</button> */}
                                <button className="button-padded border-og background-white og-color gap-bottom" onClick={() => window.location.href='https://airtable.com/shrAJSQvdde9fxNkv'}>Select</button>
                                <p className="gap-bottom">Other information here</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}