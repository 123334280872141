import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import '../../../Styles/Organization.css';

class EditOrganizationSB extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { org, options } = this.props;

        return (
            <div className="settingsSelectOption">
                <h2>Are You a Small Business?</h2>
                <div className="profileSelectOption">
                    <Select 
                        name="small_Business"
                        isClearable={false}
                        isSearchable={false}
                        defaultValue={org.organization.small_Business === true ? { label: "Yes", value: true } : { label: "No", value: false }}
                        onChange={(value) => this.props.handleSelectionChange(value, "small_Business")}
                        options={options}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        organization,
        whitelabel,
    };
}

const connectedEditOrganizationSB = connect(mapStateToProps)(EditOrganizationSB);
export { connectedEditOrganizationSB as EditOrganizationSB };