import { organizationConstants } from '../_constants';

export const organizationActions = {
    organizationAlert
};

function organizationAlert(type) {
    return dispatch => {
        switch (type) {
            case "created_organization":
                dispatch(created_organization_alert());
                break;
            case "no_organization":
                dispatch(no_organization_alert());
                dispatch(remove_message_block(false));
                break
            case "remove_message_block":
                dispatch(remove_message_block(true));
                break;
                
            default: return;
        }
    };

    function no_organization_alert() { return {type: organizationConstants.NO_ORGANIZATION_ALERT}}
    function created_organization_alert() { return {type: organizationConstants.CREATE_ORGANIZATION_SUCCESS}}
    function remove_message_block(remove_message_block) { return {type: organizationConstants.REMOVE_MESSAGE_BLOCK, remove_message_block}}
}