import { registrationConstants, stripeConstants } from '../_constants';
import { registrationService } from '../_services';
import { alertActions } from '.';

export const registrationActions = {
  registerRequest,
  register,
  confirmEmail,
};

function registerRequest() {
  return dispatch => {
    dispatch(request());
  }

  function request() { return { type: registrationConstants.REGISTER_REQUEST } }
}

function register(user, server, whitelabel_Org) {
  return dispatch => {
      dispatch(request(user));
      if (whitelabel_Org == "localhost" || whitelabel_Org == "portal.opengrants.io")
          registrationService.register(user, server)
          .then(
              user => {
                  var userCopy = Object.assign({}, user);
                  userCopy.partnerClient = "";
                  localStorage.setItem('user', JSON.stringify(userCopy));
                  dispatch(success(user));
                  dispatch(alertActions.registrationSuccess('Registration successful'));
              },
              error => {
                  dispatch(failure(error));
                  dispatch(alertActions.registrationError(error));
              }
          );
      else
          registrationService.registerWhitelabel(user, whitelabel_Org)
      .then(
          user => {
              var userCopy = Object.assign({}, user);
              userCopy.partnerClient = "";
              localStorage.setItem('user', JSON.stringify(userCopy));
              dispatch(success(user));
              // history.push('/confirm_email');
              // dispatch(registrationComplete());
              dispatch(alertActions.registrationSuccess('Registration successful'));
          },
          error => {
              dispatch(failure(error));
              dispatch(alertActions.registrationError(error));
          }
      );
  };

    function registrationComplete() {return {type: registrationConstants.CONFIRMEMAIL_REGISTRATIONCOMPLETE}}
    function request(user) { return { type: registrationConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: registrationConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: registrationConstants.REGISTER_FAILURE, error } }
}

function confirmEmail(emailConfirmation, server) {
  return dispatch => {
    dispatch(request(emailConfirmation));
    registrationService.confirmEmail(emailConfirmation, server)
      .then(
        (user) => {
          dispatch(success());
        },
        error => {
          dispatch(failure(error));
        }
      );
  };

  function request(user) { return { type: registrationConstants.CONFIRMEMAIL_REQUEST, user } }
  function success(user) { return { type: registrationConstants.CONFIRMEMAIL_SUCCESS, user } }
  function failure(error) { return { type: registrationConstants.CONFIRMEMAIL_FAILURE, error } }
}