import React from 'react';
import { Redirect } from 'react-router-dom';

export class UserRedirect extends React.Component {

    render() {
        return (
            <div>
                {localStorage.getItem('user') === null && 
                 <Redirect to="/login" />
                }
                {localStorage.getItem('user') !== null &&
                 <Redirect to="/dashboard" />
                }
            </div>
        );
    }
}