import { connect } from 'react-redux';
import { stripeActions } from '../../../_actions';
import React, {Component} from 'react';
import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';
import '../../../Styles/SettingsStyles/Billings.css';
import { stripeService } from '../../../_services';
import { Loader } from '../../Shared/Loader';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#999',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};

class _CardForm extends Component {
  cardEl = React.createRef();

  state = {
    card: {
        cardNumber: '', //sources -> data -> last4
        expDate: '', //sources -> data -> exp_month, exp_year
    },
    address: {
        cardHolderName: '', //sources -> data -> name
        address: '', //sources -> data -> address_line1
        city: '', //source -> data -> address_city
        state: '', //sources -> data -> address_state
        zipCode: '' //sources -> data -> address_zip
    },

    subscriptions: {
    },

    updateBillingInfo: false,
    updateCard: false,
    updateAddress: true,

    updatePlan: false,

    errorMessage: '',
    coupon: '',
    couponId: '',
    status: '',

    name: '',
    email: '',
    address_line1: '',
    address_city: '',
    address_state: '',
    zipcode: '',
    emailAgreement_billing: localStorage.getItem("user") ? true : false,
    termsAndPrivacyAgreement: true,

    nameError: false,
    address_line1Error: false,
    address_cityError: false,
    address_stateError: false,
    zipcodeError: false,

    emailError: false,
    emailAgreement_billingError: false,
    termsAndPrivacyAgreementError: false,

    loading: true
  };

  async componentDidMount() {
      var user = JSON.parse(localStorage.getItem('user'));
      this.getBillingInfo(user);
  }

  getBillingInfo = async (user) => {
    var data = await stripeService.getBillingInfo(user.id);

    if (data && data.billingInfo) {
      this.setState({
          address: data.billingInfo.billingAddress,
          card: data.billingInfo.card,
          // subscriptions: data.billingInfo.subscriptions,
          loading: false
      });
    }
    else {
      this.setState({
        loading: false
      });
    }
  }

  //handle billing input
  handleInputChange = (e) => {
      const {id, value} = e.target;
      this.setState({
          [id]: value,
          [id+"Error"]: false
      });
  }

  //clear card and billing info
  resetInputs = () => {
      this.setState({
          name: '',
          address_line1: '',
          address_city: '',
          address_state: '',
          zipcode: ''
      });
      this.cardEl.current._element.clear();
      document.getElementById("cardForm").getElementsByClassName("error")[0].innerHTML = "";
  }

  //handle card error message
  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  //purchase prodcut
  handleSubmit = (evt) => {
    evt.preventDefault();
    document.getElementById('placeOrder') ? document.getElementById('placeOrder').disabled = true : document.getElementById('updateButton').disabled = true;
    var name = document.getElementById("name").value;
    var address = document.getElementById("address_line1").value;
    var city = document.getElementById("address_city").value;
    var state = document.getElementById("address_state").value;
    var zipcode = document.getElementById("zipcode").value;
    var user = JSON.parse(localStorage.getItem("user"));
    var email = document.getElementById("email") ?
      document.getElementById("email").value : user.email ?
        user.email : user.Email;
    var emailAgreement = this.state.emailAgreement;
    var termsAndPrivacyAgreement = this.state.termsAndPrivacyAgreement;
    const {billingAddress} = this.props;

      //verify billing address is filled or exists
      var formIsValid = this.formIsValid(name, address, city, state, zipcode, email);
      var addressExists = false;
      // if (!formIsValid) {
      //   addressExists = this.addressExists(billingAddress, name, address, city, state, zipcode);
      // }

      if (!formIsValid && !addressExists) {
        var paymentForm = document.getElementById("cardForm").offsetTop;
        if (document.getElementsByClassName("stripe-product").length > 0) document.getElementsByClassName("stripe-product")[0].scrollTo(0,paymentForm);
        document.getElementById('placeOrder') ? document.getElementById('placeOrder').disabled = false : document.getElementById('updateButton').disabled = false;
      }

      //create payment method and purchase product
      // if ((formIsValid || addressExists) && emailAgreement) {
      if ((formIsValid || addressExists) && termsAndPrivacyAgreement) {
        //clear payment info error messages
        const error = {
          message: ""
        }
        this.handleChange({error});

        //get billing address
        if (this.props.stripe) {
          var options = {};
          if (formIsValid) {
            options = {
              "name": name,
              "address_line1": address,
              "address_city": city,
              "address_state": state,
              "zipcode": zipcode,
              }
          } else if (addressExists) {
            options = {
              "name": billingAddress["cardHolderName"],
              "address_line1": billingAddress["address"],
              "address_city": billingAddress["city"],
              "address_state": billingAddress["state"],
              "zipcode": billingAddress["zipcode"],
              }
          }

          //create payment method
          const cardElement = this.props.elements.getElement("card");
          this.props.stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
              'name': options.name,
              'address': {
                'city': options.address_city,
                'country': 'US',
                'line1': options.address_line1,
                'postal_code': options.zipcode,
                'state': options.address_state
              }
            }
          }).then((result) => {
            if (result.error) {
              this.handleChange(result);
              var paymentForm = document.getElementById("cardForm").offsetTop;
              if (document.getElementsByClassName("stripe-product").length > 0) document.getElementsByClassName("stripe-product")[0].scrollTo(0,paymentForm);
              document.getElementById('placeOrder') ? document.getElementById('placeOrder').disabled = false : document.getElementById('updateButton').disabled = false;
            } else {
              const user = JSON.parse(localStorage.getItem('user'));
              const user_Id = user ? user.id : null;

              //set user details
              const userDetails = {
                user_Id,
                name: options.name,
                email,
                email_Agreement: user ? document.getElementById('emailAgreement_summary') ? document.getElementById('emailAgreement_summary').checked : false : document.getElementById('emailAgreement_billing') ? document.getElementById('emailAgreement_billing').checked : false,
                termsAndPrivacy_Agreement: document.getElementById('termsAndPrivacyAgreement') ? document.getElementById('termsAndPrivacyAgreement').checked : true
              }

              const paymentDetails = {
                paymentMethodId: result.paymentMethod.id,
                coupon: this.state.couponId
              }
              //purchase product
              this.props.purchaseProductWithMethod(paymentDetails, userDetails);
            }
          });
        }
      } else {
        this.showErrors(name, address, city, state, zipcode, email, emailAgreement, termsAndPrivacyAgreement);
        document.getElementById('placeOrder') ? document.getElementById('placeOrder').disabled = false : document.getElementById('updateButton').disabled = false;
      }
  };

  placeOrder = () => {
    document.getElementById('placeOrder') ? document.getElementById('placeOrder').disabled = true : document.getElementById('updateButton').disabled = true;
    var termsAndPrivacyAgreement = this.state.termsAndPrivacyAgreement;
    if (termsAndPrivacyAgreement) {
      const user = JSON.parse(localStorage.getItem('user'));
      const user_Id = user ? user.id : null;

      //set user details
      const userDetails = {
        user_Id,
        email_Agreement: user ? document.getElementById('emailAgreement_summary') ? document.getElementById('emailAgreement_summary').checked : false : document.getElementById('emailAgreement_billing') ? document.getElementById('emailAgreement_billing').checked : false,
        termsAndPrivacy_Agreement: document.getElementById('termsAndPrivacyAgreement') ? document.getElementById('termsAndPrivacyAgreement').checked : true
      }

      const paymentDetails = {
        paymentMethodId: "ok",
        coupon: this.state.couponId
      }
      //purchase product
      this.props.purchaseProductWithMethod(paymentDetails, userDetails);
    } 
    else {
      this.showErrors(null, null, null, null, null, null, null, termsAndPrivacyAgreement);
      document.getElementById('placeOrder') ? document.getElementById('placeOrder').disabled = false : document.getElementById('updateButton').disabled = false;
    }
  }

  //returns true if billing address is filled
  formIsValid = (name, address, city, state, zipcode, email) => {
    if (name !== ""
    && address !== ""
    && city !== ""
    && state !== ""
    && zipcode !== ""
    && email !== ""
    ) {
      return true;
    }
    // return false;
    return false;

  }

  //returns true if billing address exists
  addressExists = (billingAddress, name, address, city, state, zipcode) => {
    if (billingAddress
    && billingAddress["cardHolderName"]
    && billingAddress["address"]
    && billingAddress["city"]
    && billingAddress["state"]
    && billingAddress["zipCode"]
    ) {
      return true;
    }
    return false;
  }

  //show input, agreement error
  showErrors = (name, address, city, state, zipcode, email, emailAgreement, termsAndPrivacyAgreement) => {
    let nameError = false;
    let address_line1Error = false;
    let address_cityError = false;
    let address_stateError = false;
    let zipcodeError = false;
    let emailError = false;
    let {emailAgreementError, termsAndPrivacyAgreementError} = this.state;

    if (name === "") {
      nameError = true;
    }

    if (address === "") {
      address_line1Error = true;
    }

    if (city === "") {
      address_cityError = true;
    }

    if (state === "") {
      address_stateError = true;
    }

    if (zipcode === "") {
      zipcodeError = true;
    }

    if (email === "") {
      emailError = true;
    }

    if (termsAndPrivacyAgreement == false) {
      termsAndPrivacyAgreementError = true;
    }

    this.setState({
      nameError,
      address_line1Error,
      address_cityError,
      address_stateError,
      zipcodeError,
      emailError,
      emailAgreementError,
      termsAndPrivacyAgreementError
    });

    return !nameError && !address_line1Error && !address_cityError && !address_stateError && !zipcodeError && !emailError && !termsAndPrivacyAgreementError;
  }

  //toggle agreement click
  handleAgreement = (e) => {
    const {id} = e.target;
    this.setState({
      [id]: e.target.checked,
      [id+"Error"]: !e.target.checked
    });
  }

  handleCoupon = async (e) => {
    e.preventDefault();
    if (this.state.coupon !== "") {
      var processed = await this.props.handleCoupon(this.state.coupon, null);
      if (processed.discountStatus.status == "success") {
        const couponId = this.state.coupon;
        this.setState({
          coupon: '',
          couponId
        });
      } else {
        this.setState({
          coupon: '',
          couponId: '',
        });
      }
    }
  }

  scrollToPage = (id) => {
    document.getElementById(id).scrollIntoView();
  }

  updateBillingInfo = (type) => {
      if (type === 'card') {
          this.setState({
              updateBillingInfo: true,
              updateCard: true
          });
      }
      else if (type === 'address') {
          this.setState({
              updateBillingInfo: true,
              updateAddress: false
          });
      } else {
          this.setState({
              updateBillingInfo: false,
          });
      }
  }

  render() {
    const path = window.location.pathname;
    const {nameError,
      address_line1Error, address_cityError, address_stateError, zipcodeError, emailError,
      emailAgreement,
      emailAgreementError, termsAndPrivacyAgreementError, stripeError} = this.state;
    const {card, address, subscriptions,
        updateBillingInfo,
        // updateCard,
        // updateAddress,
        updatePlan,
        loading
    } = this.state;
    const {billingAddress, errorMessage, invoiceId, updateButton, updateCard, updateAddress,
      updateBilling,
    } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));

    if (updateBilling.status === true) {
      this.getBillingInfo(user);
    }

    return (
      <div id="payment-form" className="payment-form">

        {(loading && !card.cardNumber) && <Loader />}
        {card.cardNumber && !updateCard &&
            <div className="background-white pop gap-bottom">
                <div>
                    <h2 style={{display: "inline-block"}}>Card Information</h2>
                    <Button 
                        style={{float: "right"}} 
                        onClick={() => this.props.updateBillingInfo('card')}
                        color="#999"
                      >
                        Update
                    </Button>
                    <div style={{marginLeft: "30px"}}>
                      <p>Card Number: xxxx-xxxx-xxxx-{card.cardNumber}</p>
                      <p>Exp: {card.expDate}</p>
                    </div>
                <div>
                  <h2 style={{display: "inline-block"}}>Billing Address</h2>
                  <div style={{marginLeft: "30px"}}>
                    <p>{address.address}</p>
                    <p>{address.city}, {address.state}, {address.zipCode}</p>
                  </div>
                </div>
              </div>
            <div hidden className="center gap-bottom">
              <button id="cardFormSubmit" onClick={this.placeOrder}>Submit</button>
            </div>
          </div>
        }

        {!updateCard && !card.cardNumber &&
          <div className="background-white pop gap-bottom">
            <h2>Card Information</h2>
            <Button
              onClick={() => {this.props.updateBillingInfo('card')}}
            >
              Add a Payment Method
            </Button>
          </div>
        }

        {/* {(updateCard || !card.cardNumber) && !loading && */}
        {updateCard && !loading &&
          <div>
            {card.cardNumber && 
              <Button 
                style={{float: "none"}} 
                onClick={() => {this.props.updateBillingInfo()}}
              >
                {updateButton ? "<<< Return To Billing" : "Cancel Payment Update"}
              </Button>}
            <form id="cardForm" onSubmit={this.handleSubmit.bind(this)}>
              <div>
                <div className="background-white pop gap-bottom">
                  <h2 style={{marginTop: 50}}>Payment Information</h2>
                  <div id="billingNumbers">
                    <CardElement
                      onChange={this.handleChange}
                      ref={this.cardEl}
                      {...createOptions()}
                    />
                  </div>
                  {errorMessage != "" && this.state.errorMessage == "" ? <div className="error" role="alert">{errorMessage}</div> : null}
                  <div className="error" role="alert">
                    {this.state.errorMessage}
                  </div>
                </div>
              </div>

            <div className="gap-bottom">
              <div className="background-white pop width-full">
                <h2>Billing Address</h2>
                <div>
                    <input id="name" name="CardholderName" placeholder="Cardholder Name" type="text" value={this.state.name} onChange={this.handleInputChange} required />
                    {nameError && <span className="error">This field is required.</span>}
                </div>
                <div>
                    <input id="address_line1" name="Address" placeholder="Address" type="text" value={this.state.address_line1} onChange={this.handleInputChange} required />
                    {address_line1Error && <span className="error">This field is required.</span>}
                </div>
                <div>
                    <input id="address_city" name="City" placeholder="City" type="text" value={this.state.address_city} onChange={this.handleInputChange} required />
                    {address_cityError && <span className="error">This field is required.</span>}
                </div>
                <div>
                    <input id="address_state" name="State" placeholder="State" type="text" value={this.state.address_state} onChange={this.handleInputChange} required />
                    {address_stateError && <span className="error">This field is required.</span>}
                </div>
                <div>
                    <input id="zipcode" name="Zipcode" placeholder="ZipCode" type="text" value={this.state.zipcode} onChange={this.handleInputChange} required />
                    {zipcodeError && <span className="error">This field is required.</span>}
                </div>
              </div>
            </div>

            {((!user || !user.email) && path.toLowerCase() == "/portal_standard") && <div className="gap-bottom">
                <div className="background-white pop width-full">
                  <h2>Email Address</h2>
                  <hr className="line-md-theme gap-bottom" />
                  <div>
                      <input id="email" name="email" placeholder="name@example.com" type="text" value={this.state.email} onChange={this.handleInputChange} required />
                      {emailError && <span className="error">This field is required.</span>}
                  </div>
                  <div className="checkbox-elements">
                    <input type="checkbox" id="emailAgreement_billing" checked={emailAgreement} onChange={this.handleAgreement} />
                    <label>I agree to receive updates, helpful tips and news from OpenGrants.</label>
                  </div>
                </div>
            </div>}

            <div hidden className="center gap-bottom">
              <button id="cardFormSubmit">Submit</button>
            </div>
          </form>
        </div>}

        {/* {(updateButton || !card.cardNumber) && */}
        {updateButton &&
          <div style={{overflow: "auto", marginBottom: "20px"}}>
            <Button 
              id="updateButton" 
              onClick={this.handleSubmit} 
              style={{float: "right", marginRight: 15, background: "teal"}}
            >
              Update
          </Button>
          {/* <button style={{display: "inline-block", marginLeft: "15px"}} onClick={() => {this.props.updateBillingInfo()}}>{"Cancel"}</button> */}
        </div>
        }
      </div>
    );
  }
}

const CardForm = injectStripe(_CardForm);

class StripeBilling extends Component {
  constructor() {
    super();
    this.state = {stripe: null};
  }

  async componentDidMount() {
      // this.getBillingAddress();

      if (window.Stripe) {
        this.setState({stripe: window.Stripe('pk_live_tiXsu1gfHlco4B3DSsgbkJpi000k1rpuwr')});
      } else {
         document.querySelector('#stripe-js').addEventListener('load', () => {
         this.setState({stripe: window.Stripe('pk_live_tiXsu1gfHlco4B3DSsgbkJpi000k1rpuwr')});
        });
        document.querySelector('#stripe-js').addEventListener('error', () => {
         console.log("STRIPE ERROR");
         this.props.updateStripeError(true);
       });
      }
  }

  getBillingAddress = () => {
      const {user} = this.props;
      if (user) {
        this.props.dispatch(stripeActions.getBillingAddress(this.props.user["id"]));
      }
  }

  handleResult = (stripeToken) => {
      const billingAddress = {
          "stripeToken": stripeToken,
          "userId": this.props.user["id"]
      };
      this.props.dispatch(stripeActions.handleStripeToken(billingAddress));
  }

  render() {
    const {
      getBillingAddress,
      invoiceId,
      updateButton,
      updateCard,
      updateAddress,
      updateBillingInfo,
      updateBilling,
    } = this.props;
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <CardForm billingAddress={this.props.billingAddress}
            handleResult={this.handleResult}
            purchaseProductWithMethod={this.props.purchaseProductWithMethod}
            errorMessage={this.props.errorMessage}
            handleCoupon={this.props.handleCoupon}
            discount={this.props.discount}
            discountStatus={this.props.discountStatus}
            invoiceId={invoiceId}
            purchaseProduct={this.purchaseProduct}
            updateButton={updateButton}
            updateCard={updateCard}
            updateBillingInfo={updateBillingInfo}
            updateAddress={updateAddress}
            updateBilling={updateBilling} />
        </Elements>
      </StripeProvider>
    );
  }
}

function mapStateToProps(state) {
    const { setUser, handleStripeToken, getBillingAddress } = state;
    const { user } = setUser;
    let updateBilling = state.updateBilling;
    return {
        user,
        handleStripeToken,
        getBillingAddress,
        updateBilling,
    };
}

const connectedBilling = connect(mapStateToProps)(StripeBilling);
export { connectedBilling as StripeBilling };
