import React from 'react';
import { connect } from 'react-redux';
import '../../Styles/Listing.css'

class ListingTopBar extends React.Component {
    constructor(props) {
        super(props);
        this.toggleClose = this.toggleClose.bind(this);
        this.openListing = this.openListing.bind(this);
        this.state = {
            themeColor: localStorage.getItem('themeColor')
        }
    }

    toggleClose() {
        this.props.dispatch({type: "InvokeParent"});
    }

    openListing = () => {
        const listing_Id = this.props.id.replace('topbar_', '');
        window.open('/grants/' + listing_Id);
    }

    render() {
        return (
            <div className="listingTopBar">
                <div className="backArrow">
                    <i 
                        className="material-icons" 
                        type="button"
                        onClick = {this.props.invokeParent}
                        style={{color: this.state.themeColor}}
                    >
                        keyboard_arrow_left
                    </i>
                </div>
                <div className="openListing" onClick={this.openListing}>
                    <i 
                        className="material-icons" 
                        type="button"
                        style={{color: this.state.themeColor}}
                    >
                        open_in_new_window
                    </i>
                    <h4 
                        style={{color: this.state.themeColor}}
                    >
                        Open Listing in New Window
                    </h4>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const connectedTopBar = connect(mapStateToProps)(ListingTopBar);
export { connectedTopBar as ListingTopBar }; 