import { settingsConstants } from '../_constants';

export function profile(state = {}, action) {
  switch (action.type) {
  case settingsConstants.UPDATEPROFILE_REQUEST:
    return {
      loading: true
    };
  case settingsConstants.UPDATEPROFILE_SUCCESS:
    return {
      items: action.user
    };
  case settingsConstants.UPDATEPROFILE_FAILURE:
    return { 
      error: "There was an error processing this request."
    };
  default:
    return state
  }
}

export function focus(state = {}, action) {
  switch (action.type) {
  case settingsConstants.UPDATEFOCUS_REQUEST:
    return {
      loading: true
    };
  case settingsConstants.UPDATEFOCUS_SUCCESS:
    return {
      items: action.user
    };
  case settingsConstants.UPDATEFOCUS_FAILURE:
    return { 
      error: "There was an error processing this request."
    };
  default:
    return state
  }
}

export function organization(state = {}, action) {
  switch (action.type) {
  case settingsConstants.UPDATEORGANIZATION_REQUEST:
    return {
      loading: true
    };
  case settingsConstants.UPDATEORGANIZATION_SUCCESS:
    return {
      items: action.user
    };
  case settingsConstants.UPDATEORGANIZATION_FAILURE:
    return { 
      error: "There was an error processing this request."
    };
  default:
    return state
  }
}

export function advanced(state = {}, action) {
  switch (action.type) {
  case settingsConstants.UPDATEADVANCED_REQUEST:
    return {
      loading: true
    };
  case settingsConstants.UPDATEADVANCED_SUCCESS:
    return {
      items: action.user
    };
  case settingsConstants.UPDATEADVANCED_FAILURE:
    return { 
      error: "There was an error processing this request."
    };
  default:
    return state
  }
}