import React from 'react';
import '../../../Styles/StripeProduct.css';
import { stripeService } from '../../../_services';

export class QuickstartFunding extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            prices: null
        }
    }

    componentDidMount = async () => {
        var user = JSON.parse(window.localStorage.getItem('user'));
        var prices = await stripeService.getPurchasedPrices(user.id);

        this.setState({
            prices
        });
    }

    openChat(message) {
        window.Intercom('showNewMessage');
    }

    render() {
        const {prices} = this.state;
        console.log(prices);
        let displayProducts = null;

        if (prices) {
            var i = 0;
            displayProducts = prices.map((price) => {
                var priceTime = Object.keys(price);
                var priceData = Object.values(price);
                console.log(priceTime);
                console.log(priceData);
                i += 1;
                return (
                    <div key={"data_" + i}>
                        <p className="font-medium bold-none line-height-sm">{priceTime[0].split("T")[0]}</p>
                        <p className="font-medium bold-none line-height-sm gap-bottom">{priceData[0].product.name}</p>
                    </div>
                )
            });
        }

        return (
            <div className="font-poppins">
                <div className="col-md-6 padding-lg">
                    <h1 className="blue-color heading-bolded gap-bottom">Welcome To OpenGrants</h1>
                    {/* <h3 className="gap-bottom">This is your funding dashboard</h3> */}
                    <div>
                        <h3 className="gap-bottom">Purchase History</h3>
                        {displayProducts}
                        <a className="font-medium bold-none line-height-sm a-og-color bold" href="/managebilling?returnUrl=funding-dashboard">See Billing Info</a>
                    </div>

                    <div className="float-right inline">
                        <br/>
                        <br/>
                        <p className="font-medium bold-none line-height-sm center">Questions?</p>
                        <button className="button-padded background-og-color font-medium" onClick={this.openChat}>Contact Us</button>
                        <br/>
                        <br/>
                    </div>

                </div>
                <div className="col-md-6 padding-lg">
                    <img id="grantHeroIMG" src="https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/opengrants_funding_resource_guides.png"></img>
                </div>
            </div>
        );
    }
}