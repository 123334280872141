import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../../_helpers';
import { ResetPasswordConfirmation } from './ResetPasswordConfirmation';
import ReactPasswordStrength from 'react-password-strength';
import queryString from 'query-string';
import '../../../Styles/ForgotPassword.css';
import '../../../Styles/ConfirmEmail.css';
import { authenticateActions } from '../../../_actions';

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            resetPassword: {
                email: '',
                password: '',
                confirmPassword: '',
                userId: '',
                code: ''
            },
            submitted: false,
            confirm: false,
            isValid: false,
        };

        console.log(this.props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { location: { search } } = this.props;
        const values = queryString.parse(search);
        const code = values.code.replace(" ", "+");
        this.setState({
            resetPassword: {
            userId: values.user_Id,
            code: values.code,
            }
        }, () => {});
    }

    changeCallback = state => {
        this.setState(prevState => ({
            passLength: state.password.length,
            isValid: state.isValid,
            resetPassword: {
                ...prevState.resetPassword,
                password: state.password,
            }
        }));
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState(prevState => ({
            resetPassword: {
                ...prevState.resetPassword,
                [name]: value
            }
        }));
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { resetPassword } = this.state;
        const { dispatch } = this.props;

        if (resetPassword.email && 
            resetPassword.password && 
            resetPassword.confirmPassword &&
            resetPassword.password === resetPassword.confirmPassword) 
        {
            dispatch(authenticateActions.resetPassword(resetPassword));
            this.setState({ confirm: true });

            setTimeout(() => {
                history.push('/login');
            }, 10000);
        }
    }

    render() {
        const { submitted, confirm, resetPassword } = this.state;

        return (
            <div>
                {!confirm &&
                    <div id="forgotPassword">
                        <div id="forgotPassowrdRightLogin">
                            <h1 id="forgotPasswordLogo">opengrants.io</h1>
                            <hr />
                            <br />
                            <h3>Please enter the following information to reset your password.</h3>
                            <form autoComplete="off" id="changePasswordForm" className="settingsForm" onSubmit={this.handleSubmit}>
                            <div id="iHateGoogle">
                                <label name="Email">email</label>
                                <input name="emails" type="email" />
                                <label name="Password">email</label>
                                <input name="passwords" type="password" />
                            </div>
                                <div className={'form-group' + (submitted && !resetPassword.email ? ' has-error' : '')}>
                                    <label name="Email">Email</label>
                                    <input autoComplete="none" type="email" type="text" name="email" className="form-control" onChange={this.handleChange}/>
                                    </div>
                                    {submitted && !resetPassword.email &&
                                        <div className="help-block">Email address is required.</div>
                                    }
                                <div className={'form-group' + (submitted && 
                                    (!resetPassword.password ?? this.state.isValid === false) ? ' has-error' : '')}>
                                    <label>New Password</label>
                                    <ReactPasswordStrength
                                        className="customClass"
                                        minLength={8}
                                        minScore={2}
                                        scoreWords={['poor', 'weak', 'good!', 'strong!', 'stronger!!']}
                                        changeCallback={this.changeCallback}
                                        inputState={{ name: "password", autoComplete: "none", className: "form-control", onChange: this.handleChange }}
                                    />
                                    {submitted && !resetPassword.password &&
                                        <div className="help-block">New password is required</div>
                                    }
                                    {submitted && this.state.isValid === false &&
                                        <div className="help-block">Password is too weak.</div>
                                    }
                                </div>
                                <div 
                                    className={'form-group' + (
                                        (submitted && 
                                            (!resetPassword.confirmPassword || 
                                                resetPassword.confirmPassword !== resetPassword.password)
                                        ) ? ' has-error' : '')}
                                >
                                    <label>Confirm New Password</label>
                                    <input autoComplete="none" type="password" name="confirmPassword" className="form-control" onChange={this.handleChange} />
                                    {submitted && !resetPassword.confirmPassword && !resetPassword.password &&
                                        <div className="help-block">Confirm new password is required.</div>
                                    }
                                    {submitted && (resetPassword.confirmPassword !== resetPassword.password) &&
                                        <div className="help-block">Passwords do not match.</div>
                                    }
                                </div>
                                <button id="manageFormButton" type="submit" className="btn btn-default">Update password</button>
                            </form>
                            <div id="forgotPasswordIssues">
                                <h3>
                                    If you continue to experience any issues, please contact us for support at support@opengrants.io.
                                </h3>
                            </div>
                        </div>
                        <div className="resetPasswordBottomLinks">
                            <div className="resetPasswordLinks">
                                <a href="https://www.opengrants.io/opengrants-legal/">Privacy Policy</a>
                                <a href="https://www.opengrants.io/opengrants-legal/">Terms and Conditions</a>
                                <a href="https://experience.opengrants.io/contact-opengrants">Help</a>
                                <a href="/login">Login</a>
                            </div>
                            <div className="resetPasswordCopyFooter">
                                opengrants.io &#x24B8; {(new Date().getFullYear())} Copyright by Egeria Corporation
                            </div>
                        </div>
                    </div>
                }
                { confirm && 
                    <ResetPasswordConfirmation />
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    //const { forgotPassword } = state;
    return {
        // forgotPassword
    };
}

const connectedResetPassword = connect(mapStateToProps)(ResetPassword);
export { connectedResetPassword as ResetPassword };