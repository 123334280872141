import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { history } from '../../_helpers';
import '../../Styles/Error.css';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";

export class Inside404Page extends React.Component {

    handleViewDashboard() {
        history.push('/dashboard');
    }

    handleEditPreferences() {
        history.push('/settings');
    }

    render() {
        return (
            <div id="pageNotFoundDiv">
                <img 
                    id="pageNotFoundBackground" 
                    src="https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_404_page.jpg"
                />
                <div id="listingPreview">
                        <Modal id="listingPreviewModal"
                            isOpen={true} 
                            // toggle={this.toggle}
                            fade={false}
                        >
                            <ModalHeader>
                                <h1 id="modalTitle">opengrants.io</h1>
                            </ModalHeader>
                            <ModalBody>
                                <h2>Page Not Found</h2>
                                <hr className="pageNotFoundHR" />
                                <p>
                                    Looks like that content does not exist!
                                    <br />
                                    Here are a few links to get you back on track.
                                </p>
                                <br />
                            </ModalBody>
                            <ModalFooter>
                                <Button 
                                    id="listingModalPreviewRegister" 
                                    color="info"
                                    onClick={this.handleViewDashboard}
                                    style={{minWidth:200}}
                                >
                                    View Dashboard
                                </Button>
                                <Button 
                                    id="listingModalPreviewLogin" 
                                    color="secondary"
                                    onClick={this.handleEditPreferences}
                                    style={{minWidth:200}}
                                >
                                    Edit Preferences
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </div>

            </div>
        );
    }
}


