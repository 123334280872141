import { connect } from 'react-redux';
import React from 'react';
import '../../Styles/GrantWriters.css';
import {projectproposalActions} from '../../_actions';
import {projectProposalService} from '../../_services';
import Button from "../../_components/dashboardComponents/CustomButtons/Button.js";
import { Loader } from '../Shared/Loader';
import {ProjectProposalList} from '.';

class ProjectProposal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            records: "",

            recordId: "",

            notes: "",
            upload: "",
            grantwriter: {
                name: "", 
                email: "",
                bookingLink: ""
            },
            costs: {
                hourly: "",
                estimatedEfforts: "",
                total: "",
                montlyCost: ""
            },

            accepted: false,
            acceptedProject: false,

            rejected: false,
            rejectedProject: false,

            purchasedGrantWriting: false,

            loading: true,

            plan: "Pay As You Go",
            planError: false
        }
    }

    componentDidMount() {
        // var recordId = "recj6o9KyyZB8lXrN";
        const path = window.location.pathname;
        const recordId = path.split('/').pop();
        if (recordId.includes("rec")) {
            this.getGrantWritingPurchase(this.getProposal, recordId);
        } else {
            // this.setState({
            //     loading: false
            // });
            this.getGrantWritingPurchase(this.getProposals);
        }
    }

    getGrantWritingPurchase = async(func, recordId) => {
        const user = JSON.parse(localStorage.getItem('user'));
        var purchased = await projectProposalService.getGrantWritingPurchase(user.id);
        if (purchased) {
            func(recordId);
        } else {
            this.setState({
                purchasedGrantWriting: false,
                loading: false
            });
        }
    }

    getProposals = async () => {
        const user_unparsed = localStorage.getItem('user');
        if (user_unparsed) {
            const user = JSON.parse(user_unparsed);
            var result = await projectProposalService.getProjectProposals(user.id);
            console.log(result);

            if (result) {
                this.setState({
                    ...result,
                    purchasedGrantWriting: true,
                    loading: false
                });
            } else {
                this.setState({
                    purchasedGrantWriting: true,
                    loading: false
                });
            }
        }
    }

    getProposal = async (recordId) => {
        console.log("get proposal");
        const user_unparsed = localStorage.getItem('user');
        if (user_unparsed) {
            const user = JSON.parse(user_unparsed);

            var result = await projectProposalService.getProjectProposal(user.id, recordId);
            if (result) {
                this.setState({
                    ...result,
                    recordId,
                    purchasedGrantWriting: true,
                    loading: false
                });
            } 
            else {
                this.getProposals();
                // this.setState({
                //     recordId,
                //     loading: false
                // });
            }
        } 
    }

    acceptProject = async () => {
        this.setState({
            loading: true,
            agreementError: false
        });

        var agreementError = false;
        var el = document.getElementById("agreement");
        if (!el.checked) {
            agreementError = true;
        }

        if (!agreementError && this.state.plan) {
            const user_unparsed = localStorage.getItem('user');
            if (user_unparsed) {
                const user = JSON.parse(user_unparsed);

                var result = await projectProposalService.acceptProjectProposal(user.id, this.state.recordId, this.state.plan);

                this.setState({
                    acceptedProject: true,
                    loading: false,
                    planError: false
                });
            }
        } else {
            // document.body.scrollTo(0,500)

            this.setState({
                loading: false,
                // planError: true,
                agreementError
            });
        }
    }

    rejectProject = async () => {
        this.setState({
            loading: true
        });
        const user_unparsed = localStorage.getItem('user');
        if (user_unparsed) {
            const user = JSON.parse(user_unparsed);

            var result = await projectProposalService.rejectProjectProposal(user.id, this.state.recordId);

            this.setState({
                rejectedProject: true,
                loading: false,
                planError: false
            });
        }
    }

    clickPayment = (id) => {
        document.getElementById(id).click();
    }

    updateSelectionPlan = (val) => {
        this.setState({
            plan: val,
            planError: false
        });
    }

    render() {
        const {
            records,
            recordId, notes, upload, grantwriter, costs, 
            accepted, acceptedProject, 
            rejected, rejectedProject, 
            purchasedGrantWriting, proposalSubmission,
            paymentType, planError, agreementError,
            loading} = this.state;
        console.log(this.state);
        if (!loading && !records && !recordId && !grantwriter.name && !purchasedGrantWriting) {
            window.location.href = "/apply";
        }

        return (
            <div id="projectProposal" className="font-ubuntu">
                {loading && <Loader />}
                {acceptedProject && 
                    <div className='background-transparent center margin-move-left-40'>
                        <div className='adjustable-width-sm background-white pop'>
                        <h2 className="gap-bottom">Lets Get Rolling!</h2>
                        <div>
                            <p>You will hear from your grant writer shortly.</p>
                        </div>
                        <Button
                            id="purchasSuccessButton"
                            color="secondary"
                            onClick={() => {window.location.href = "/dashboard"}}
                            style={{minWidth:200, marginBottom: "20px"}}
                        >
                            Continue to Dashboard
                        </Button>
                        </div>
                    </div>
                }

                {
                <div>
                    {/* {(!recordId || (recordId && !grantwriter.name)) && */}
                    {proposalSubmission &&
                        <div>
                            <h1>Review Proposals</h1>
                            <div style={{marginTop: "40px"}}>
                                <p style={{marginBottom: "20px"}}>Select a proposal to view from the following list: </p>
                                <div style={{marginLeft: "30px"}}>
                                    <ProjectProposalList
                                        records = {records}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {purchasedGrantWriting && !proposalSubmission && !recordId &&
                        <div>
                            <h1>Review Proposals</h1>
                            <div style={{marginTop: "40px"}}>
                                <p style={{marginTop: "20px"}}>
                                    We're finding the perfect Grant Developer to match you to. Please allow 1-2 weeks for an introduction to one (or more) Grant Developer(s). If you have questions, reach out using the chat button in the lower right of this page.
                                </p>
                            </div>
                        </div>
                    }
                    {recordId && grantwriter.name &&
                    <div>
                        <h1>CONFIRM YOUR PROJECT</h1>
                        <div>
                            <div style={{paddingLeft: 0, marginBottom: "40px"}}>
                                <div>
                                    <h2>Review Proposal</h2>
                                    <div style={{marginLeft: 15, background: "white", padding: 30, boxShadow: "0 0 10px -6px", marginBottom: 40}}>
                                        <div>
                                            {notes && <p>{notes}</p>}
                                            {upload && 
                                                <div>
                                                    <p>The Grant Writer has opted to upload a full proposal, please view it below: </p>
                                                    <a href={upload} target="_blank">View Full Propposal</a>
                                                </div>}
                                        </div>
                                        <div>
                                            <h3 style={{fontWeight: "700"}}>Grant Developer Details</h3>
                                            <p style={{margin: 0}}>{grantwriter.name}</p>
                                            <p>{grantwriter.email}</p>
                                            <p>
                                                <a href={grantwriter.bookingLink} target="_blank">Book Time </a>
                                                if you have questions about this scope and would like to discuss them with your Grant Developer.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="planSelection" style={{paddingLeft: 0, marginBottom: "40px"}}>
                                <h2>Pricing Details</h2>
                                {planError &&
                                <div style={{marginBottom: 15}}>
                                    <p style={{color: "red"}}>Please choose a plan</p>
                                </div>
                                }
                                {!accepted && (!rejected && !rejectedProject) && <div style={{marginLeft: 15}}>
                                    {/* <div style={{cursor: "pointer", background: "white", padding: 30, boxShadow: "0 0 10px -6px", marginBottom: 40}} onClick={() => this.clickPayment("payAsYouGo")}> */}
                                    <div style={{cursor: "pointer", background: "white", padding: 30, boxShadow: "0 0 10px -6px", marginBottom: 40}}>
                                        {/* <input id="payAsYouGo" type="radio" name="plan" onChange={() => {this.updateSelectionPlan("Pay As You Go")}} style={{verticalAlign: "top", marginRight: 25, height: 20, width: 20}} /> */}
                                        <div style={{marginLeft: 15}}>
                                            <h3 style={{fontWeight: 500}}>Pay As You Go</h3>
                                            <div style={{marginTop: 15}}>
                                                <div style={{marginBottom: 40}}>
                                                    <p>You will be billed throughout the project whenever the grant writer submits new invoices. The timing of these payments may vary, and is determined by the grant writer’s billing schedule.</p>
                                                </div>
                                                <div style={{textAlign: "center"}}>
                                                    <div>
                                                        <p>Hourly Rate...{costs.hourly ? " $" + parseInt(costs.hourly).toLocaleString() + " / hr" : ""}</p>
                                                        <p>OpenGrants Fee...20%</p>
                                                        <p>Estimated Efforts...{costs.estimatedEfforts ? parseInt(costs.estimatedEfforts).toLocaleString() + " hrs" : ""}</p>
                                                    </div>
                                                    <hr style={{borderTop: "1px solid #444444", maxWidth: "250px"}} />
                                                    <div>
                                                        <h3 style={{fontWeight: "500", marginBottom: 0, fontSize: 20}}>Total...{" $" + (parseInt(costs.hourly*costs.estimatedEfforts)*1.2).toLocaleString()}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div style={{cursor: "pointer", background: "#29a1a755", padding: 30, boxShadow: "0 0 10px -6px", marginBottom: 40}}>
                                        <h3 style={{fontWeight: 500}}>Need Financing?</h3>
                                        <div>
                                            <p>We've partnered with OnDeck to provide fast access to capital. 
                                                OnDeck offers term loans and lines of credit to small businesses nationwide, 
                                                is faster and requires less paperwork than a bank, and is cheaper than a merchant cash advance. 
                                                Terms range from 6 to 36 months with transparent pricing, fixed rates, and fixed daily or weekly payments. 
                                                The application takes a few minutes, and approvals are often turned around in one day. 
                                                Your loan will be directly with OnDeck. 
                                                You can use it to pay for your grant writing project, as well as manage other business expenses. 
                                                <a href="https://airtable.com/shrzrGIVRE8GvK8o8" target="_blank"> Click here to get started</a>, then return to this page to finish checking out after you've been approved.
                                            </p>
                                        </div>
                                        <div style={{textAlign: "center"}}>
                                            <img src="https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/ondeck_logo.png" style={{maxWidth: 250, display: "inline-block", marginTop: 20}} />
                                        </div>
                                    </div> */}
                                    {/* <div style={{cursor: "pointer", background: "white", padding: 30, boxShadow: "0 0 10px -6px", marginBottom: 40}} onClick={() => this.clickPayment("monthlyPayment")}>
                                        <input id="monthlyPayment" type="radio" name="plan" onChange={() => {this.updateSelectionPlan("Pay Monthly")}} style={{verticalAlign: "top", marginRight: 25, height: 20, width: 20}} />
                                        <div style={{marginLeft: 15}}>
                                            <h3 style={{fontWeight: 500}}>12 Month Payment Plan</h3>
                                            <div style={{marginTop: 15}}>
                                                <div style={{marginBottom: 40}}>
                                                    <p>You will be billed equal monthly payments on a 12-month schedule, based on the grant writer’s estimate. This plan helps clients manage their cash flow over time when shouldering the cost of a grant project.</p>
                                                </div>
                                                <div style={{textAlign: "center"}}>
                                                    <div style={{display: "inline-block"}}>
                                                        <p>Hourly Rate...{costs.hourly ? " $" + parseInt(costs.hourly).toLocaleString() + " / hr" : ""}</p>
                                                        <p>OpenGrants Fee...25%</p>
                                                        <p>Estimated Efforts...{costs.estimatedEfforts ? parseInt(costs.estimatedEfforts).toLocaleString() + " hrs" : ""}</p>
                                                    </div>
                                                    <hr style={{borderTop: "1px solid #444444", maxWidth: "250px"}} />
                                                    <div>
                                                        <h3 style={{fontWeight: "500", fontSize: 20}}>Total...{" $" + (parseInt(costs.hourly*costs.estimatedEfforts)*1.25).toLocaleString()}</h3>
                                                        <h3 style={{fontWeight: "500", marginBottom: 0, fontSize: 20}}>Monthly Cost...{" $" + (parseInt(costs.hourly*costs.estimatedEfforts)*1.25/12).toFixed(2).toLocaleString()}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>}
                                {(accepted || rejected || rejectedProject) &&
                                <div>
                                    {/* {paymentType === "Pay Monthly" &&
                                    <div style={{cursor: "pointer", background: "white", padding: 30, boxShadow: "0 0 10px -6px", marginBottom: 40, marginLeft: 15, textAlign: "center"}}>
                                        <h3 style={{fontWeight: 500}}>12 Month Payment Plan</h3>
                                        <div style={{marginTop: 15}}>
                                            <div style={{display: "inline-block"}}>
                                                <p>Hourly Rate...{costs.hourly ? " $" + parseInt(costs.hourly).toLocaleString() + " / hr" : ""}</p>
                                                <p>OpenGrants Fee...25%</p>
                                                <p>Estimated Efforts...{costs.estimatedEfforts ? parseInt(costs.estimatedEfforts).toLocaleString() + " hrs" : ""}</p>
                                            </div>
                                            <hr style={{borderTop: "1px solid #444444", maxWidth: "250px"}} />
                                            <div>
                                                <h3 style={{fontWeight: "500", fontSize: 20}}>Total...{" $" + (parseInt(costs.hourly*costs.estimatedEfforts)*1.25).toLocaleString()}</h3>
                                                <h3 style={{fontWeight: "500", marginBottom: 0, fontSize: 20}}>Monthly Cost...{" $" + (parseInt(costs.hourly*costs.estimatedEfforts)*1.25/12).toFixed(2).toLocaleString()}</h3>
                                            </div>
                                        </div>
                                    </div>} */}
                                    {(!paymentType || paymentType === "Pay As You Go") &&
                                        <div style={{cursor: "pointer", background: "white", padding: 30, boxShadow: "0 0 10px -6px", marginBottom: 40, marginLeft: 15, textAlign: "center"}}>
                                            <h3 style={{fontWeight: 500}}>Pay As You Go</h3>
                                            <div style={{marginTop: 15}}>
                                                <div>
                                                    <p>Hourly Rate...{costs.hourly ? " $" + parseInt(costs.hourly).toLocaleString() + " / hr" : ""}</p>
                                                    <p>OpenGrants Fee...20%</p>
                                                    <p>Estimated Efforts...{costs.estimatedEfforts ? parseInt(costs.estimatedEfforts).toLocaleString() + " hrs" : ""}</p>
                                                </div>
                                                    <hr style={{borderTop: "1px solid #444444", maxWidth: "250px"}} />
                                                <div>
                                                    <h3 style={{fontWeight: "500", marginBottom: 0, fontSize: 20}}>Total...{" $" + (parseInt(costs.hourly*costs.estimatedEfforts)*1.2).toLocaleString()}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                }
                            </div>
                            <div style={{textAlign: "center"}}>
                                {!accepted && (!rejected && !rejectedProject) && 
                                    <div style={{textAlign: "left", margin: "auto", marginBottom: 20, width: "90%"}}>
                                        {agreementError && <p style={{color: "red"}}>This is required.</p>}
                                        <input id="agreement" type="checkbox" style={{marginRight: 15, verticalAlign: "top"}} />
                                        <p style={{fontWeight: "unset", display: "inline-block", width: "90%"}}>
                                            I am aware that this is an estimate, and that, due to the nature of hourly work, 
                                            the actual cost of this project may vary. 
                                            I have, or will have, access to adequate capital to pay for this project in full, 
                                            and on time, as it's invoiced by the service provider.
                                        </p>
                                    </div>
                                }
                                {!accepted && (!rejected && !rejectedProject) && <Button onClick={this.acceptProject} style={{color: "white", fontSize: "30px", backgroundColor: "#23273a", marginRight: "40px"}}>START PROJECT</Button>}
                                {/* {!accepted && (!rejected && !rejectedProject) && <a onClick={this.rejectProject} style={{color: "#444444", display: "block", marginTop:"20px", textDecoration:"underline"}}>Dismiss Proposal</a>} */}
                                {!accepted && (!rejected && !rejectedProject) && <Button onClick={this.rejectProject} style={{color: "white", fontSize: "30px"}}>DISMISS PROJECT</Button>}
                                {accepted && (!rejected && !rejectedProject) && <Button style={{color: "white", fontSize: "30px"}}>Project Accepted</Button>}
                                {(rejected || rejectedProject) && <Button style={{color: "white", fontSize: "30px"}}>Project Dismissed</Button>}
                            </div>
                        </div>
                    </div>
                    }
                </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const connectedProjectProposal = connect(mapStateToProps)(ProjectProposal);
export { connectedProjectProposal as ProjectProposal }; 