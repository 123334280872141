import { authHeader } from '../_helpers';

export const stripeService = {
    createStripeAccount,
    handleStripeToken,
    getBilling,
    getBillingInfo,
    getSubscriptions,
    getBillingAddress,
    getAllProducts,
    purchaseProduct,
    purchaseProductType,
    getPurchasedPrices,
    getCouponDiscount,
    getInvoicePrices,
    redirectToBilling,
    redirectToConnect,
    getCheckoutSuccessMessage,
    getDashboardRedirectUrl,
    getPurchaseHistory,
    updateUserDetails,
    cancelSubscription,
    updateSubscription,
    cancelSubscriptionDowngrade
};

function createStripeAccount(code, state) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code, state })
    };

    return fetch('https://refactor.opengrants.io/api/stripe/stripe_connect', requestOptions)
        .then(handleResponse);
}

function handleStripeToken(billingAddress) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ ...billingAddress })
    };

    return fetch('https://refactor.opengrants.io/api/settings/billing_profile', requestOptions)
        .then(handleResponse);
}

function getBillingAddress(userId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ userId })
    };

    return fetch('https://refactor.opengrants.io/api/settings/billing_address', requestOptions)
        .then(handleResponse);
}

function getBilling(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/stripe/getBilling?userId=' + userId, requestOptions)
        .then(handleResponse);
}

function getBillingInfo(userId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/stripe/getBillingInfo?user_id=' + userId, requestOptions)
        .then(handleResponse);
}

function getSubscriptions(userId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/stripe/getSubscriptions?userId=' + userId, requestOptions)
        .then(handleResponse);
}

function getAllProducts() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/stripe/getStripeProducts', requestOptions)
        .then(handleResponse);
}

function purchaseProduct(productDetails) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(productDetails)
    };

    return fetch('https://refactor.opengrants.io/api/stripe/purchaseProduct', requestOptions)
        .then(handleResponse);
}

function purchaseProductType(userId, productGroup) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(productGroup)
    };

    return fetch('https://refactor.opengrants.io/api/stripe/purchaseProductType?userId=' + userId, requestOptions)
        .then(handleResponse);
}

function getDashboardRedirectUrl(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/stripe/getDashboardRedirectUrl?userId=' + userId, requestOptions)
        .then(handleResponse);
}

function getPurchasedPrices(userId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/stripe/getPurchasedPrices?userId=' + userId, requestOptions)
        .then(handleResponse);
}

function getCouponDiscount(paymentDetails) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(paymentDetails)
    };

    return fetch('https://refactor.opengrants.io/api/stripe/getCouponDiscount', requestOptions)
        .then(handleResponse);
}

function getInvoicePrices(email, invoiceId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/stripe/getInvoicePrices?email=' + email + '&invoiceId=' + invoiceId, requestOptions)
        .then(handleResponse);
}

function redirectToBilling(userId, returnUrl) {
    const server = window.location.protocol + '//' + window.location.host + returnUrl;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/stripe/redirectToBilling?userId=' + userId + '&returnUrl=' + server, requestOptions)
        .then(handleResponse);
}   

function redirectToConnect(userId, returnUrl) {
    const server = window.location.protocol + '//' + window.location.host + returnUrl;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/stripe/redirectToConnect?userId=' + userId + '&returnUrl=' + server, requestOptions)
        .then(handleResponse);
}  

function getCheckoutSuccessMessage(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    
    return fetch('https://refactor.opengrants.io/api/stripe/getCheckoutSuccessMessage?userId=' + userId, requestOptions)
    .then(handleResponse);
}

function getPurchaseHistory(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    
    return fetch('https://refactor.opengrants.io/api/stripe/getPurchaseHistory?userId=' + userId, requestOptions)
    .then(handleResponse);
}

function updateUserDetails(productDetails) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(productDetails)
    };

    return fetch('https://refactor.opengrants.io/api/stripe/updateUserDetails', requestOptions)
        .then(handleResponse);
}

function cancelSubscription(subscriptionId, isFree) {
    var body = {subscriptionId, isFree}
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(subscriptionId)
    };

    return fetch('https://refactor.opengrants.io/api/stripe/cancelSubscription?isFree='+isFree, requestOptions)
        .then(handleResponse);
}

function cancelSubscriptionDowngrade(userId, subscriptionId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(subscriptionId)
    };

    return fetch('https://refactor.opengrants.io/api/stripe/cancelSubscriptionDowngrade?userId='+userId, requestOptions)
        .then(handleResponse);
}

function updateSubscription(subscriptionId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(subscriptionId)
    };

    return fetch('https://refactor.opengrants.io/api/stripe/updateSubscription', requestOptions)
        .then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok ) {
            if (response.status == "401") {
                window.location.href="/login";
            }
            
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}