import { authHeader } from '../_helpers';

export const listingService = {
    getListing
};

function getListing(listing_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/listings/get_listing?listing_Id=' + listing_Id, requestOptions)
    .then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status == "401") {
                window.location.href="/login";
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}