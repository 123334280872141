import { connect } from 'react-redux';
import React from 'react';
import { stripeService } from '../../../_services';
import { stripeActions } from '../../../_actions';
import { Loader } from '../../Shared/Loader';
import '../../../Styles/StripeProduct.css';
import '../../../Styles/StripePricingPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faBullhorn, faBinoculars, faTree } from '@fortawesome/free-solid-svg-icons';
import {StripeCheckout} from '.';
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";

class StripePricingPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            productType: "",
            billedToday: true,
        }
    }
    
    componentDidMount() {
        const { history, location } = window;
         history.pushState(null, null, location.href);
         window.onpopstate = event => {
             event.preventDefault();
             history.go(1);
         };
     }

    selectProduct = async (product) => {
        const {selectedProduct, otherMainGroup, mainSubscriptionId, otherMainSubscriptionId, mainEndDate, otherMainEndDate} = this.props;

        if (otherMainSubscriptionId && mainSubscriptionId) {
            var user = JSON.parse(localStorage.getItem('user'));
            await stripeService.cancelSubscriptionDowngrade(user.id, mainSubscriptionId);
        }

        var plans = {
            "opengrants_free": 0,
            "opengrants_basic": 1,
            "opengrants_premium": 2
        }

        if (product != "opengrants_free") {
            document.body.scrollTo(0,0);
            let billedToday = true;
            if ((!otherMainGroup && selectedProduct && plans[product] < plans[selectedProduct.split(" ")[0]]) ||
                (otherMainGroup  && plans[product] < plans[otherMainGroup.split(" ")[0]])) {
                if (otherMainSubscriptionId)
                    billedToday = otherMainEndDate;
                else 
                    billedToday = mainEndDate
            }
            this.setState({
                productType: product,
                billedToday: billedToday
            });
        } 
        else {
            if (otherMainSubscriptionId) {
                this.props.dispatch(stripeActions.cancelSubscription(otherMainSubscriptionId, true));
            } 
            else if (mainSubscriptionId) {
                this.props.dispatch(stripeActions.cancelSubscription(mainSubscriptionId, true));
            }
            this.props.updateBillingInfo();
        }
    }

    resetProductType = () => {
        this.setState({
            productType: ""
        });
    }
    
    redirectToPage = () => {
        var page_redirect = localStorage.getItem('redirectPageTo');
        if (!page_redirect) {
            page_redirect = '/dashboard';
        }
        window.location.href = page_redirect;
    }

    render () {
        const {selectedProduct, updatePlan, otherMainGroup} = this.props;
        const {productType, billedToday} = this.state;
        var tableWidth = "ubuntu";
        if (!updatePlan) {
            tableWidth += " margin-all";
        }

        return (
            <div className={tableWidth}>
                {!productType &&
                <div>
                    {updatePlan &&
                        <Button 
                            style={{marginBottom: 20, float: "none"}} 
                            onClick={() => {this.props.updateBillingInfo()}}
                        >
                            {"<<< Return To Billing"}
                        </Button>
                    }
                    {updatePlan &&
                        <h1 className="center" style={{fontWeight: 500}}>Please choose your plan. You can change your plan at any time.</h1>
                    }
                    {!updatePlan &&
                        <h1 className="center" style={{marginTop: "50px", fontWeight: 500}}>Please choose your plan. You can change your plan at any time.</h1>
                    }
                    <div className="plan-table"> 
                        <div>
                            <div style={{borderLeft: "1px solid #ddd"}}>
                                {/* <FontAwesomeIcon icon={faCoffee} color="rgb(41, 167, 159)" size="5x" /> */}
                                <h3 className="og-color bold">FREE - $0</h3>
                                {/* <div><hr/></div> */}
                                <div className="padding-bottom">
                                    <div className="color-black">
                                        <p>Search Grants</p>
                                        <p>QuickStart Tool</p>
                                        <p>Hire Grant Writers</p>
                                        <p>Weekly Office Hours</p>
                                        <p>DIY Guides</p>
                                    </div>
                                </div>
                                {selectedProduct && selectedProduct === "opengrants_free" &&
                                <a className="button" style={{background: "lightgrey"}}>Selected</a>
                                }
                                {!selectedProduct &&
                                <a onClick={this.redirectToPage} className="button" style={{background: "teal"}}>Select</a>
                                }
                                {selectedProduct && selectedProduct !== "opengrants_free" && 
                                <a onClick={() => {this.selectProduct('opengrants_free')}} className="button" style={{background: "teal"}}>Select</a>
                                }
                            </div>
                            <div>
                                {/* <FontAwesomeIcon icon={faBullhorn} color="rgb(41, 167, 159)" size="5x" /> */}
                                <h3 className="og-color bold">ALERT - $9</h3>
                                {/* <div><hr/></div> */}
                                <div className="padding-bottom">
                                    <div className="color-black">
                                        <p>Search Grants</p>
                                        <p>QuickStart Tool</p>
                                        <p>Hire Grant Writers</p>
                                        <p>Weekly Office Hours</p>
                                        <p>DIY Guides</p>
                                    </div>
                                    <div><hr/></div>
                                    <div className="color-black">
                                        <p>Flag Grants</p>
                                        <p>Get Email Alerts</p>
                                        <p>Take Notes</p>
                                    </div>
                                </div>
                                {selectedProduct && selectedProduct === "opengrants_basic default" &&
                                    <a className="button" style={{background: "lightgrey"}}>Selected</a>
                                }
                                {otherMainGroup && otherMainGroup === "opengrants_basic default" &&
                                    <a className="button" style={{background: "lightgrey"}}>Canceled</a>
                                }
                                {(!selectedProduct || selectedProduct !== "opengrants_basic default") &&
                                (!otherMainGroup || otherMainGroup !== "opengrants_basic default") &&
                                <   a onClick={() => {this.selectProduct('opengrants_basic')}} className="button" style={{background: "teal"}}>Select</a>
                                }
                            </div>
                            <div>
                                {/* <FontAwesomeIcon icon={faBinoculars} color="rgb(41, 167, 159)" size="5x" /> */}
                                <h3 className="og-color bold">TRACK - $29</h3>
                                {/* <div><hr/></div> */}
                                <div className="padding-bottom">
                                    <div className="color-black">
                                        <p>Search Grants</p>
                                        <p>QuickStart Tool</p>
                                        <p>Hire Grant Writers</p>
                                        <p>Weekly Office Hours</p>
                                        <p>DIY Guides</p>
                                        <p>Flag Grants</p>
                                        <p>Get Email Alerts</p>
                                        <p>Take Notes</p>
                                    </div>
                                    <div><hr/></div>
                                    <div className="color-black">
                                        <p>Power Search</p>
                                        <p>Export Results</p>
                                        <p>Personalized Recommendations</p>
                                    </div>
                                </div>
                                {selectedProduct && selectedProduct === "opengrants_premium default" &&
                                    <a className="button" style={{background: "lightgrey"}}>Selected</a>
                                }
                                {otherMainGroup && otherMainGroup === "opengrants_premium default" &&
                                    <a className="button" style={{background: "lightgrey"}}>Canceled</a>
                                }
                                {(!selectedProduct || selectedProduct !== "opengrants_premium default") &&
                                    (!otherMainGroup || otherMainGroup !== "opengrants_premium default") &&
                                    <a onClick={() => {this.selectProduct('opengrants_premium')}} className="button" style={{background: "teal"}}>Select</a>
                                }
                            </div>
                            {/* <div>
                                <h3 className="og-color bold">CUSTOM</h3>
                                <h3>Varies</h3>
                                <div className="padding-bottom">
                                    <div className="black-color">
                                        <p>Ideal for accelerators and organizations looking to add value for their clients with custom grant search tools.</p>
                                        <p>Visually own 100% of the user experience, customize user actions, enable branded emails and reports, and access grant writing credits.</p>
                                        <p>Schedule a call to learn more.</p>
                                    </div>
                                </div>
                                <a className="button" href='https://airtable.com/shrNeowJStvXjAvN9' target='_blank'>Contact Us</a>
                            </div> */}
                        </div>
                    </div>
                </div>
                }

                {productType && 
                <div>
                    <StripeCheckout 
                        productType={productType}
                        billedToday={billedToday}
                        updatePlan={updatePlan}
                        updateBillingInfo={this.props.updateBillingInfo}
                        resetProductType={this.resetProductType}
                     />
                </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

const connectedBilling = connect(mapStateToProps)(StripePricingPage);
export { connectedBilling as StripePricingPage };