import React from 'react';
import { connect } from 'react-redux';
import '../../Styles/Listing.css'
import {listingNotesService} from '../../_services';
import { Loader as Loading } from '../Shared/Loader';

class ListingNotes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            path: window.location.pathname,
            loading: true
        }
    }

    componentDidMount = () => {
        if (this.state.path.includes("notes")) {
            this.getNotes();
        }
    }

    getNotes = async() => {
        const user = JSON.parse(localStorage.getItem("user"));
        var result = await listingNotesService.getAllListingNotes(user.id);

        if (result) {
            this.setState({
                ...result,
                loading: false
            });
        } 
    }

    deleteNotes = async(listing_id) => {
        this.setState({
            loading: true
        });
        const user = JSON.parse(localStorage.getItem("user"));
        await listingNotesService.deleteListingNotes(user.id, listing_id);
        this.props.getNotes ? this.props.getNotes() : this.getNotes();
    }

    render() {
        const {path, loading} = this.state;
        let user_notes = this.props.user_notes ? this.props.user_notes : this.state.user_notes;

        let user_notes_list = null;
        if (user_notes) {
          user_notes_list = user_notes.map((note) => {
            return (
              <div>
                <hr style={{marginTop: 0}} />
                <a onClick={() => {this.deleteNotes(note.listing_Id)}}>
                    <i class="material-icons" style={{marginRight: -3.5, color: "#3a4570c7"}}>delete</i>
                </a>
                {/* <a href={"/grants/"+note.listing_Id} target="_blank" style={{float: "right"}}>View Grant</a> */}
                <a href={"/grants/"+note.listing_Id} target="_blank">
                    <i class="material-icons" style={{marginRight: "20px", color: "#3a4570c7"}}>visibility</i>
                </a>
                <p>{note.modifiedDate}</p>
                <p>{note.user_Notes}</p>
              </div>
            );
          });
        }
        
        return (
            <div id="listingNotes" style={{marginTop: "5%"}}>
                {path.includes("notes") && loading &&
                    <Loading />
                }
                {path.includes("notes") &&
                    <div>
                        <h1 id="dashboardHeader">Notes</h1>
                    </div>
                }
                {user_notes_list && user_notes_list.length > 0 && user_notes_list}
                {path.includes("notes") &&
                    <hr />
                }
                {(!user_notes_list || (user_notes_list && user_notes_list.length== 0)) &&
                    <h4 style={{fontWeight: 500}}>You do not have any saved notes.</h4>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser } = state;
    const { user } = setUser;
    return {
        user
    };
}

const connectedListingNotes = connect(mapStateToProps)(ListingNotes);
export { connectedListingNotes as ListingNotes };