import React from 'react';
import { connect } from 'react-redux';
import { serviceProviderService, chatService, organizationService } from '../../_services';
import Button from '../../_components/dashboardComponents/CustomButtons/Button';
import { Organization } from '../Settings/OrganizationSettings';
import { organizationActions, chatActions } from '../../_actions';
import { ServiceProvidersChatBox } from '../ServiceProviders'
import { Client as ConversationsClient } from '@twilio/conversations';
import { Loader as Loading } from '../Shared/Loader';
import '../../Styles/Marketplace.css';

class ServiceProvider extends React.Component {

  constructor(props) {
    super(props);
    let service_Provider_Id = window.location.pathname.split('/').pop();
    this.state = {
      service_Provider_Id: service_Provider_Id,
      loading: true,

      display_no_org_alert: true,
      display_created_organization: true,

      conversations: [],
      conversation: null,

      channelMembers: [],
      member: null,

      show_Chat: false,
    }
    this.closeChatBox = this.closeChatBox.bind(this);
  }

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));

    // get service provider info
    const service_Provider_Id = window.location.pathname.split('/').pop();
    const service_Provider = await serviceProviderService.getServiceProviderById(service_Provider_Id);

    this.setState({
      service_Provider: service_Provider,
      loading: false
    });

    // get channels
    let result = await serviceProviderService.getChannelByServiceProvider(user.id, service_Provider.service_Provider_Id);
    await this.setState({
      channelMembers: [result.channel]
    });

    // get organization members
    let orgMembers = await organizationService.getOrganization(service_Provider.organization.organization_Id)
    await this.setState({
      organizationMembers: orgMembers.accepted_profiles,
    });
  }

  update_display_alert = (alert) => {
    this.setState({
      ...alert
    });
  }

  cancel_messaging = () => {
    window.location.reload();
  }

  componentWillUnmount() {
    if (this.conversationsClient)
      this.conversationsClient.shutdown();
  }

  add_participants = async (member) => {
    var user = JSON.parse(localStorage.getItem('user'));
    var chat = {
      participant_1_user_id: user.id,
      participant_2_user_id: member.username,
      channel: member.channel
    }
    await chatService.add_participants(chat);
  }

  create_conversation = async (client_name) => {
    var result = await chatService.create_conversation(client_name);
    var conversation_ch = result.conversation_ch;
    return conversation_ch;
  }

  message_user = async (channel) => {
    var user = JSON.parse(localStorage.getItem('user'));
    var result = await organizationService.getAllOrganizationsByUser(user.id);
    let has_an_org = true;

    if (!result.organizations
      || Object.keys(result.organizations).length === 0) {
      //allow user to create org
      has_an_org = false;
    }

    if (!has_an_org && !this.props.created_organization) {
      this.props.dispatch(organizationActions.organizationAlert("no_organization"));
    }
    this.setState({ show_Chat: true, loading: true });
    let conversation_ch = channel.channel;

    if (!conversation_ch) {
      conversation_ch = await this.create_conversation(user.name + user.surname + "_" + channel.name + channel.surname);
      channel.channel = conversation_ch;
      this.setState({ conversation_ch, channel }, () => this.add_participants(channel));
    }

    if (!this.conversationsClient) {
      let user_id = JSON.parse(localStorage.getItem('user')).id;
      let result = await chatService.generate_token(user_id);
      let token = result.token;
      this.setState({ token: token, conversation_ch, channel }, () => this.initConversations());
    }

    if (conversation_ch && this.conversationsClient) {
      this.setState({ conversation_ch, channel });

      let conversation_list = this.state.conversations.filter(conv => conv.sid === conversation_ch);
      if (conversation_list.length > 0) {
        this.props.dispatch(chatActions.set_conversation(conversation_list[0]));
      }
    }
    this.props.dispatch(chatActions.set_channel(channel));

    this.setState({
      loading: false
    });

  }

  initConversations = async () => {
    this.conversationsClient = await ConversationsClient.create(this.state.token);
    this.setState({ statusString: "Connecting to Twilio…" });

    this.conversationsClient.on("connectionStateChanged", (state) => {
      if (state === "connecting")
        this.setState({
          statusString: "Connecting to Twilio…",
          status: "default"
        });
      if (state === "connected") {
        this.setState({
          statusString: "You are connected.",
          status: "success"
        });
      }
      if (state === "disconnecting")
        this.setState({
          statusString: "Disconnecting from Twilio…",
          conversationsReady: false,
          status: "default"
        });
      if (state === "disconnected")
        this.setState({
          statusString: "Disconnected.",
          conversationsReady: false,
          status: "warning"
        });
      if (state === "denied")
        this.setState({
          statusString: "Failed to connect.",
          conversationsReady: false,
          status: "error"
        });
    });

    this.conversationsClient.on("conversationJoined", (conversation) => {
      if (this.state.conversations.filter(conv => conv.sid === conversation.sid).length === 0) {
        if (conversation.sid === this.state.conversation_ch) {
          this.setState({
            conversations: [...this.state.conversations, conversation],
            conversation
          });
        }
        else if (this.state.conversation) {
          this.setState({
            conversations: [...this.state.conversations, conversation],
          });
        }
      }
      this.setState({ loading: false })
    });

    this.conversationsClient.on("conversationLeft", (thisConversation) => {
      this.setState({
        conversation: null,
        conversations: [...this.state.conversations.filter((it) => it !== thisConversation)]
      });
    });

    this.conversationsClient.on('tokenAboutToExpire', async () => {
      let email = JSON.parse(localStorage.getItem('user')).email;
      let result = await chatService.generate_token(email);
      let token = result.token;
      this.conversationsClient.updateToken(token);
    });
  };

  changeView = (view) => {
    this.setState({
      view
    });
  }

  closeChatBox() {
    this.setState({ show_Chat: false })
  }

  openBootIntercom() {
    window.Intercom('showNewMessage', `Hello, I'm trying to get in contact with ${this.state.service_Provider.organization.name}. Can you help me with this?`);
  }

  render() {
    const { service_Provider, channelMembers, organizationMembers, conversation, show_Chat, loading } = this.state
    const { channel, created_organization, no_organization } = this.props;
    const whitelabel = this.props.whitelabel;
    const navLinkBackground = whitelabel ? whitelabel.menu_Color : "#1215218c";
    const navLinkAccent = whitelabel ? whitelabel.active_Color : "black";
    const WLButton = whitelabel ? whitelabel.button_Color : "black";
    let memberList = null;
    let i = 0;
    if (channelMembers.length > 0) {
      memberList = channelMembers.map((member) => {
        if (i < 1) {
          // return first member of organization as message recipient
          i++;
          return (
            <div key={member.username} className="MessageMeBtn">
              <div className="marketPlaceBtn">
                {service_Provider.organization && service_Provider.currently_Available == true ?
                  <Button
                    // onClick={() => this.message_user(member)}
                    onClick={() => this.openBootIntercom()}
                    style={{ float: "right", backgroundColor: navLinkBackground }}
                  >
                    <i class="icon-message-icon" />Message Me!
                  </Button>
                  :
                  <Button
                    onClick={() => this.message_user(member)}
                    style={{ float: "right" }}
                  >
                    I'm currently unavailable.
                  </Button>
                }
              </div>
            </div>
          )
        }
      });
    }

    return (
      <div>
        {loading &&
          <Loading />
        }
        <strong><a style={{ color: navLinkAccent }} href="/marketplace">« Back to Talent Finder</a></strong>
        <br /><br />
        {service_Provider &&
          <div id="marketplaceProfile">
            <div id="mainSettings">
              <p className="grantConsultantHeader">
                Grant Consultant
              </p>
              <hr style={{ margin: 0 }} />
              <br />
              <div className="row marketPlaceContainer">
                <div className="marketPlaceFlex">
                  <div className="col-md-2" style={{ padding: 0 }}>
                    <img className="marketplaceProfileImg" src={service_Provider.organization.logo_URL ? service_Provider.organization.logo_URL : "https://images.squarespace-cdn.com/content/v1/59b82d7712abd96f8ef174c5/1517767018663-E1U7A0QITIZ23XF2TKM1/ke17ZwdGBToddI8pDm48kHqYAt3UgyjNg-0dlUc4K5hZw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7UnCxNA8dHvmd7460Z7fbKEmJ2gL2qv94i4UWS2y7YfwkXCxk_sn2atIO3dASbw33Q/placeholder.png"} width="75" height="75" />
                  </div>
                  <a className="col-md-7" href={"/organizations/" + service_Provider.organization.organization_Id} style={{ float: 'left', display: 'flex', padding: 0 }} >
                    <p className="marketplaceProfileName">{service_Provider.organization.name}</p>
                  </a>
                  <div className="col-md-3 marginLeftAuto" style={{ padding: 0 }}>
                    {memberList}
                    {conversation && channel && show_Chat == true &&
                      <ServiceProvidersChatBox
                        channel_type="client"
                        client={channel}
                        conversationProxy={conversation}
                        closeChat={this.closeChatBox}
                      />
                    }
                  </div>
                </div>
              </div>
              <br />
              {service_Provider.sP_Roles.map((tag) => { return (tag.role_Type) }).includes("grant_Writer") && <p className="grantConsultantHeader">Grant Writer</p>}
              {service_Provider.sP_Roles.map((tag) => { return (tag.role_Type) }).includes("startup_Advisor") && <p className="grantConsultantHeader">Startup Advisor</p>}
              {service_Provider.sP_Roles.map((tag) => { return (tag.role_Type) }).includes("other_Industry_Expert") && <p className="grantConsultantHeader">Other Industry Expert</p>}
              <hr style={{ margin: 0 }} />
              <div className="row">
                <br />
                <div className="row marketPlaceContainer">
                  <div className="col-md-2 marketPlaceAboutHeader">
                    <strong>About Me</strong>
                  </div>
                  <div className="col-md-9 marketPlaceAboutDescription">
                    <p>{service_Provider.organization.description}</p>
                  </div>
                </div>
                <br />
                <br className="removeBr" />
                <div className="row marketPlaceContainer">
                  <div className="col-md-2 marketPlaceAboutHeader">
                    <strong>Focus Area</strong>
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {service_Provider.sP_Focus && service_Provider.sP_Focus.map(focus => (
                      <div id="wtfTag" className="wtfTagClass">
                        <p style={{ display: "inline", marginRight: 15 }}>{focus.focus_Type.trim()}</p>
                      </div>
                    ))}
                    {service_Provider.sP_Roles.map((tag) => { return (tag.role_Type) }).includes("grant_Writer") &&
                      <>
                        {service_Provider.grant_Types.map((tag) => { return (tag.grant_Type) }).includes("foundation_Grants") &&
                          <div id="wtfTag" className="wtfTagClass">
                            <p style={{ display: "inline", marginRight: 15 }}>Foundation Grants</p>
                          </div>
                        }
                        {service_Provider.grant_Types.map((tag) => { return (tag.grant_Type) }).includes("government_Grants") &&
                          <div id="wtfTag" className="wtfTagClass">
                            <p style={{ display: "inline", marginRight: 15 }}>Government Grants</p>
                          </div>
                        }
                      </>
                    }
                  </div>
                </div>
                <br />
                <br className="removeBr" />
                <div className="row marketPlaceContainer">
                  <div className="col-md-2 marketPlaceAboutHeader">
                    <strong> Services </strong>
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {service_Provider.sP_Services && service_Provider.sP_Services.map(service => (
                      <div id="wtfTag" className="wtfTagClass">
                        <p style={{ display: "inline" }}>{service.service_Type.trim()}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <br />
                <br className="removeBr" />
                {service_Provider &&
                  <div className="row marketPlaceContainer">
                    <div className="col-md-2 marketPlaceAboutHeader">
                      <strong>Hourly Rate</strong>
                    </div>
                    <div className="col-md-9" style={{ padding: 0, color: WLButton }}>
                      <p>${service_Provider.base_Hourly_Rate} USD</p>
                    </div>
                  </div>
                }
                <br />
                <br className="removeBr" />
                <p className="grantConsultantHeader">
                  Service Provider(s)
                </p>
                <br />
                {organizationMembers && organizationMembers.map(member => (
                  <div className="serviceProviders">
                    <div className="col-md-3" style={{ display: "flex", padding: "10px" }}>
                      <img style={{ borderRadius: "50%" }} src={member.headshot ? member.headshot : "https://images.squarespace-cdn.com/content/v1/59b82d7712abd96f8ef174c5/1517767018663-E1U7A0QITIZ23XF2TKM1/ke17ZwdGBToddI8pDm48kHqYAt3UgyjNg-0dlUc4K5hZw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7UnCxNA8dHvmd7460Z7fbKEmJ2gL2qv94i4UWS2y7YfwkXCxk_sn2atIO3dASbw33Q/placeholder.png"} width="75" height="75" />
                      <a href={"/profiles/" + member.username} style={{ color: WLButton, display: "flex", alignItems: "center", marginLeft: "10px", fontWeight: "bold", fontSize: "16px" }}>{member.name} {member.surname}</a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }

        {/* No Organization this message pops up */}
        {no_organization && !created_organization && this.props.block_messaging &&
          <div id="createOrgStepBackground" style={{ zIndex: 1000 }}>
            {this.state.display_no_org_alert &&
              <div id="createOrgStep">
                <p><strong>You must create you organization before messaging this service provider.</strong></p>
                <br />
                <Button color="info" onClick={() => this.update_display_alert({ display_no_org_alert: false })} style={{ marginRight: 20 }}>Continue</Button>
                <Button onClick={this.cancel_messaging}>Cancel</Button>
              </div>
            }
            {!this.state.display_no_org_alert &&
              <div style={{
                background: "white",
                padding: "50px",
                margin: 50
              }}>
                <Organization serviceProviderId={this.state.service_Provider_Id} />
              </div>
            }
          </div>
        }

        {/* Message after creating an Organization */}
        {created_organization && this.state.display_created_organization &&
          <div id="createOrgStepBackground">
            <div id="createOrgStep">
              <p><strong>Thanks for creating an organization! <br />You may now message any service provider that you choose.</strong></p>
              <br />
              <Button color="info" onClick={() => this.update_display_alert({ display_created_organization: false })}>Close</Button>
            </div>
          </div>
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { channel } = state.chatalert_setchannel;
  const { conversation } = state.chatalert_setconversation;

  const { created_organization } = state.organization_createorganizationalert;
  const { no_organization } = state.organization_noorganizationalert;
  const { block_messaging } = state.organization_removemessageblock;
  const { whitelabel } = state.setWhitelabel;

  return {
    channel, conversation,
    created_organization, no_organization, block_messaging,
    whitelabel,
  };
}

const connectedServiceProvider = connect(mapStateToProps)(ServiceProvider);
export { connectedServiceProvider as ServiceProvider };