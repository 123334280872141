import { resourcesConstants } from '../_constants';

export function setExpertsAdvisorsExpanded(state = {}, action) {
  switch (action.type) {
    case resourcesConstants.SETEXPERTSADVISORSEXPANDED_REQUEST:
      return {
        loading: true,
        expertsAdvisorsExpanded: true
      };
    case resourcesConstants.SETEXPERTSADVISORSEXPANDED_SUCCESS:
      return {
        loading: false,
        expertsAdvisorsExpanded: true

      };
    case resourcesConstants.SETEXPERTSADVISORSEXPANDED_FAILURE:
      return{
        error: "There was an error processing your request."
      }
    default:
      return state
  }
};

export function setGrantsExpanded(state = {}, action) {
  switch (action.type) {
    case resourcesConstants.SETGRANTSEXPANDED_REQUEST:
      return {
        loading: true,
        grantsExpanded: true
      };
    case resourcesConstants.SETGRANTSEXPANDED_SUCCESS:
      return {
        loading: false,
        grantsExpanded: true

      };
    case resourcesConstants.SETGRANTSEXPANDED_FAILURE:
      return{
        error: "There was an error processing your request."
      }
    default:
      return state
  }
};