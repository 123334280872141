import React from 'react';
import Autosuggest from 'react-autosuggest';
import { searchService } from '../../../_services';

export class Registration_Location extends React.Component {
    constructor(props) {
        super(props);
        const {user, nav} = this.props;

        let location = '';
        if (user.City != "" || user.State != "") {
            location = user.City + ", " + user.State;
        }

        this.state = {
            user: user,
            nav: nav,
            submitted: false,

            value: location,
            suggestions: [],
            locations: [],
            themeColor: localStorage.getItem('themeColor')
        }
    }

    handleChange = (e) => {
        const {name, value} = e.target;
        let user = Object.assign({}, this.state.user);
        user[name] = value;

        this.setState({
            user
        })
    }
    
    formIsValid = () => {
        const {value, locations} = this.state;
        if (value != "") {
            var location = locations.filter(lang =>
                (lang.city + ", " + lang.state) === value);
            if (location.length == 1) {
                return true;
            }
        }
        return false;
    }	

    getUserLocation = () => {	
        const {value} = this.state;	
        let locationSplit = value.split(", ");
        let city = "";
        let state = "";

        for (var i = 0; i < locationSplit.length; i++) {
            if (i != locationSplit.length - 1) {
                if (city != "") {
                    city += ", ";
                }
                city += locationSplit[i];
            } else {
                state += locationSplit[i];
            }
        }

        return {city, state};
    }

    handleNavigation = (type) => {
        // if (this.formIsValid()) {
            let user = Object.assign({}, this.state.user);
            let {nav} = this.state;
            if (type === 'next') {
                nav++;
            } else if (type === "prev") {
                nav--;
            }

            this.props.handleFormInputs({user, nav});
        // }

        this.setState({
            submitted: true
        });
    }

    getSuggestions = value => {
        // const inputValue = value.trim().toLowerCase();
        const inputLength = value.length;
      
        return inputLength === 0 ? [] : this.state.locations.filter(lang =>
          (lang.city + ", " + lang.state).toLowerCase().slice(0, inputLength) === value
        );
      };
      
    getSuggestionValue = suggestion => (suggestion.city + ", " + suggestion.state);
      
    renderSuggestion = suggestion => (
        <div>
          {suggestion.city + ", " + suggestion.state}
        </div>
      );
      
    renderSuggestion = suggestion => (
        <div>
          {suggestion.city + ", " + suggestion.state}
        </div>
      );

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    handleLocationChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
        // this.addCity(newValue);
    }

    render() {
        const { user } = this.state;
        // let categoryList = locations;
        // let requiredInput = ["City"];
        // let selectedCategories = {"City": [user.City + ", " + user.State]};
        // const inputProps = {
        //     placeholder: this.state.value,
        //     value,
        //     onChange: this.handleLocationChange
        // };
        // console.log(this.state);

        return (
            <div>
                <div id="form_5" className="forms">
                    <h1 className="regHeader" style={{color: this.state.themeColor}}>Where is your organization based?</h1>
                    <hr/>
                    <h2>
                        <strong>These fields are optional.</strong> This information will help us serve better funding recommendations.
                        If you are not ready to answer these questions, please click 'Next'.
                    </h2>
                    <div>
                        <div>
                            <div className="question_section">
                                <label required>Company Address (optional)</label>
                                <input name="Organization_Address" value={user.Organization_Address} onChange={this.handleChange} />
                            </div>
                            <div className="question_section">
                                <label required>City (optional)</label>
                                <input name="City" value={user.City} onChange={this.handleChange} />
                            </div>
                            <div className="question_section">
                                <label>Country (optional)</label>
                                <input name="Country" value={user.Country} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div>
                            {/* <button className="backButton" onClick={() => this.handleNavigation('prev')}>Back</button>
                            <button className="nextButton" onClick={() => this.handleNavigation('next')}>Next</button> */}
                            <button 
                                className="backButton" 
                                onClick={() => this.handleNavigation('prev')}
                                style={{color: this.state.themeColor, borderColor: this.state.themeColor}}
                            >
                                Back
                            </button>
                            <button 
                                className="nextButton" 
                                onClick={() => this.handleNavigation('next')}
                                style={{background: this.state.themeColor, border: this.state.themeColor}}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}