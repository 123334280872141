import React, { Fragment } from 'react';
export class StripeAddOn extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {prices, selectedPriceIds, selectedRecurringPriceIds} = this.props;
        let displayPrices = null;
        let total = 0;
        let selectedPrice = {};
        let productName = "";
        let productDescription = "";

        if (prices.length > 0) displayPrices = prices.map((price) => {
            if (productName == "") productName = price.product.name;
            const description = price.product.metadata && price.product.metadata["description"] ? price.product.metadata["description"] : price.product.description;
            if (description && !productDescription) 
            {
                let i = 0;
                const descriptionSplit = description.split('\\n');
                productDescription = descriptionSplit.map((line) => {
                    if (line.includes('\\b')) {
                        let lineSplit = line.split('\\b');
                        let bulletPoints = lineSplit.map((line) => {
                            if (line != "")
                            {i+=1;
                            return (
                            <li key={"key_bullet_"+i}>
                                {line}
                            </li>
                            )}
                        });
                        i+=1;
                        return (
                            <Fragment key={"key_list_"+i}>
                                <br/>
                                <ul>
                                    {bulletPoints}
                                </ul>
                                <br/>
                            </Fragment>
                        )
                    }
                    i+=1;
                    return (
                        <p key={"key_"+ i}>
                            {line}
                            {/* <br/> */}
                        </p>
                    )
                })
            } 
            const interval = price.recurring ? price.recurring.interval : "";
            const amount = price.unit_amount;
            const productPrice = (amount/100).toFixed(2);
            const productRecurringString = interval != "" ? " / " + interval : "";
            const productPriceString = productPrice + productRecurringString;
            let checked = selectedPriceIds.includes(price.id) || selectedRecurringPriceIds.includes(price.id)

            return (
            <Fragment key={productName+productPrice}>
            <div className="radio-elements">
                {<input 
                    id={productName+productPrice} 
                    type='radio' 
                    defaultChecked={checked} 
                    name={"product_" + price.product.id}  
                    onChange={() => this.props.updateSelectedProduct(price)} />}
                {amount !== 0 ? 
                    <label htmlFor={productName+productPrice}>${productPriceString}</label>
                    : <label htmlFor={productName+productPrice}>Pending Scoping</label>}
            </div>
            </Fragment>
            )
        });

        return (
            <div id="orderSummary" className="payment-form">
                <div className="gap-bottom">
                <div className="overflow">
                    <div className="col-md-8">
                        <h3>{productName}</h3>
                        <p className="gap-bottom">{productDescription}</p>
                    </div>
                    <div className="float-right">
                        {displayPrices}
                    </div>
                </div>
                <hr className="line-sm" />
                </div>
            </div>
        );
    }
}