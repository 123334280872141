import React from 'react';
import { history } from '../../_helpers';

export class NoFavoritesResults extends React.Component {
    constructor(props) {
        super(props);
    }

    goToFavorites() {
        history.push('/search');
    }

    render() {
        
        return (
                <div id="noSavedListings">
                    <img src="https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrants_404_page.jpg" width="250" align="center"/>
                    <br /><br /><br />
                    <h3 className="noSavedFavoritesText">None of your saved opportunities matached your search parameters.</h3>
                    <br /><br />
                    <button id="noFavoritesSearchButton" onClick={this.goToFavorites}>
                        Search current funding opportunities.
                    </button>
            </div>
        );
    }
}

