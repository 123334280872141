import React from 'react';
import { format, parseISO } from "date-fns";
import '../../Styles/Listing.css';
import { TruncateFunding } from '../Shared/Listing';

export class ListingDates extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const listing  = this.props.value;
        let due_date = null;
        if (listing.due_Date !==  null) 
        {
            due_date = format(parseISO(listing.due_Date), "MMMM do, yyyy");
        }
        else
        {
            due_date = "Ongoing";
        }

        return (
            <div className="row" id="appDates">
                <div className="dueDates">
                    <div id="dueDatesColumn" className="col-md-6">
                        <h4 id="applicationDue">
                            APPLICATION DUE
                        </h4>
                        <hr />
                        <i className="material-icons">date_range</i>
                        <p>{ due_date }</p>
                    </div>
                    <div id="closeDateColumn" className="col-md-6">
                        <h4>
                            FUNDING AMOUNT
                        </h4>
                        <hr />
                        <i className="material-icons">date_range</i>
                        {/* <p>{ listing.funding_Amount === null ? "Varies" : "$" + listing.funding_Amount }</p> */}
                        {TruncateFunding(listing.funding_Amount)}
                    </div>
                </div>
            </div>
        );
    }
}