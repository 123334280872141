import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../../_helpers';
import '../../../Styles/ConfirmEmail.css';
import '../../../Styles/ForgotPassword.css';

class ForgotPasswordConfirmation extends React.Component {

    constructor(props) {
        super(props);
    }

    redirectToLogin() {
        history.push("/login")
    }

    render() {

        return (
            <div id="forgotPasswordConfirmation">
                <div id="forgotPassowrdRightLogin">
                    <h1 id="forgotPasswordLogo">opengrants.io</h1>
                    <hr />
                    <br />
                    <h1 id="passwordResetLinkHeader">A password reset link has been emailed to you.</h1>
                </div>
                <div className="forgotPasswordBottomLinks">
                    <div className="forgotPasswordLinks">
                        <a href="https://www.opengrants.io/opengrants-legal/">Privacy Policy</a>
                        <a href="https://www.opengrants.io/opengrants-legal/">Terms and Conditions</a>
                        <a href="https://experience.opengrants.io/contact-opengrants">Help</a>
                        <a href="/login">Login</a>
                    </div>
                    <div className="forgotPasswordCopyFooter">
                        opengrants.io &#x24B8; {(new Date().getFullYear())} Copyright by Egeria Corporation
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    //const { forgotPassword } = state;
    return {
        // forgotPassword
    };
}

const connectedForgotPasswordConfirmation = connect(mapStateToProps)(ForgotPasswordConfirmation);
export { connectedForgotPasswordConfirmation as ForgotPasswordConfirmation };