import { authHeader } from '../_helpers';

export const settingsService = {
    updateProfile,
    updateFocus,
    updateOrganization,
    updateBilling,
    updatePassword,
    getAllCategories,
    getAllLocations,
    updateEmail,
    checkProfileComplete,
    checkOrganizationComplete,
};

function getAllCategories() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/settings/get_categories', requestOptions)
    .then(handleResponse)
}

function getAllLocations() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch('https://refactor.opengrants.io/api/settings/get_locations', requestOptions)
    .then(handleResponse)
}

async function checkProfileComplete(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/settings/check_profile_complete', requestOptions)
    .then(handleResponse)
}

async function checkOrganizationComplete(user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };

    return fetch('https://refactor.opengrants.io/api/settings/check_organization_complete', requestOptions)
    .then(handleResponse)
}

async function updateProfile(updateUser) {
    console.log(updateUser);
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(updateUser)
    };

    return fetch('https://refactor.opengrants.io/api/settings/update_client_profile', requestOptions)
    .then(handleResponse)
    .then(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));

        return user;
    });
}

async function updateFocus(updateUser) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(updateUser)
    };

    return fetch('https://refactor.opengrants.io/api/settings/update_client_focus', requestOptions)
    .then(handleResponse)
    .then(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));

        return user;
    });
}

async function updateOrganization(updateUser) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(updateUser)
    };

    return fetch('https://refactor.opengrants.io/api/settings/update_client_organization', requestOptions)
    .then(handleResponse)
    .then(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));

        return user;
    });
}

async function updateBilling(updateUser) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(updateUser)
    };

    return fetch('https://refactor.opengrants.io/api/settings/billing_profile', requestOptions)
    .then(handleResponse)
    .then(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));

        return user;
    });
}

async function updatePassword(oldpassword, newpassword, user) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({oldpassword, newpassword})
    };

    return fetch('https://refactor.opengrants.io/api/manage/password_profile?user_id=' + user.id, requestOptions)
    .then(handleResponse)
    // .then(user => {
    //     // store user details and jwt token in local storage to keep user logged in between page refreshes
    //     localStorage.setItem('user', JSON.stringify(user));

    //     return user;
    // });
}

async function updateEmail(user_id, email) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(email)
    };

    return fetch('https://refactor.opengrants.io/api/manage/update_email?user_id=' + user_id, requestOptions)
    .then(handleResponse)
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok ) {
            if (response.status == "401") {
                window.location.href="/login";
            }
            
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}