import { chatConstants } from '../_constants';

export const chatActions = {
    update_conversations,
    set_conversation,
    set_channel,
    
    message_user_request
};

function update_conversations(conversations) {
    return dispatch => {
        dispatch(alert(conversations));
    };

    function alert(conversations) {return {type: chatConstants.UPDATE_CONVERSATIONS_ALERT, conversations}}
}

function set_conversation(conversation) {
    return dispatch => {
        dispatch(alert(conversation));
    };

    function alert(conversation) {return {type: chatConstants.SET_CONVERSATION_ALERT, conversation}}
}

function set_channel(channel) {
    return dispatch => {
        dispatch(alert(channel));
    };

    function alert(channel) {return {type: chatConstants.SET_CHANNEL_ALERT, channel}}
}

function message_user_request(organization_Id) {
    return dispatch => {
        dispatch(request(organization_Id))
    };

    function request(organization_Id) {return {type: chatConstants.MESSAGE_USER_REQUEST, organization_Id}}
}