import React from 'react';
import { stripeService } from '../../../_services';
import { Loader } from '../../Shared/Loader';
import '../../../Styles/StripeProduct.css';

export class StripeManageBilling extends React.Component {
    componentDidMount() {
        this.redirectToBilling();
    }

    redirectToBilling = async () => {
        // e.preventDefault();
        var user = JSON.parse(localStorage.getItem("user"));
        var returnUrl = "";
        if (this.props && this.props.location) {
            returnUrl = this.props.location.search;
            returnUrl = returnUrl.split("=")[1];
        } 
        if (returnUrl === "") {
            returnUrl = "/settings"
        }

        let redirectUrl = "";
        if (user && user.partner === "grantwriter") {
            redirectUrl = await stripeService.redirectToConnect(user.id, returnUrl);
        } else {
            redirectUrl = await stripeService.redirectToBilling(user.id, returnUrl);
        }
        window.location.href = redirectUrl.url;
    }

    render () {
        return (
            <div>
                {<Loader />}

                {/* <button className="button-padded background-og-color" onClick={this.redirectToBilling}>Manage billing</button> */}
            </div>
        )
    }
}