import React from 'react';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import '../../../Styles/SearchResults.css';

const override = css`
  display: block;
  margin: auto;
  top: 45%;
  left: 45%;
  position: absolute;
`;

export class Loader extends React.Component {
    constructor(props) {
        super(props);
    }

render() {
    return (
        <div className="sweet-loading">
            <ClipLoader
            css={override}
            size={75}
            color={"#123abc"}
            //loading={this.props.searchResult.loading}
            />
        </div>
        )
    }
}
