import React, { Component } from 'react';
import { NavItem } from 'reactstrap';
import { connect } from 'react-redux';
import { Mixpanel } from '../Mixpanel';
import '../../../Styles/NavMenu.css';

class MobileNavMenuProspectSV extends Component {
    constructor(props) {
        super(props);
    }

    goToLink = (url) => {
        var user = JSON.parse(localStorage.getItem('user'));
        Mixpanel.track('Navigation', {
            'user_id': user.id,
            'user_email': user.email,
            'page_origin_url': window.location.pathname,
            'page_redirect_url': url
        });
        window.location.href = url;
    }

    render() {
        const { user, whitelabel } = this.props;

        return (
            <div>
                {user &&
                <div hidden id="mobile-nav" className="nav navbar-nav">
                    <ul>
                        <NavItem onClick={() => this.goToLink("/dashboard")} className="mobileLI">
                            <i className="material-icons">dashboard</i>
                            <a>Get Started</a>
                        </NavItem>
                        {whitelabel && whitelabel.features.feature_Basic_Search &&
                            <NavItem onClick={() => this.goToLink("/search")} className="mobileLI">
                                <i className="material-icons">search</i>
                                <a>Search Grants</a>
                            </NavItem>
                        }
                        {whitelabel && whitelabel.features.feature_Matches &&
                            <NavItem onClick={() => this.goToLink('/matches')}className="mobileLI">
                                <i className="material-icons">thumb_up</i>
                                <a>Matched Grants</a>
                            </NavItem>
                        }
                        {whitelabel && whitelabel.features.feature_Favorites &&
                            <NavItem onClick={() => this.goToLink('/favorites')}className="mobileLI">
                                <i className="material-icons">library_add_check</i>
                                <a>Favorite Grants</a>
                            </NavItem>
                        }
                        <NavItem onClick={() => this.goToLink('/grantwriters')}className="mobileLI">
                            <i className="material-icons">fact_check</i>
                            <a>Find a Grantwriter</a>
                        </NavItem>
                        <NavItem onClick={() => this.goToLink('/ev-investors')}className="mobileLI">
                            <i className="material-icons">monetization_on</i>
                            <a>EV/Charging Investors</a>
                        </NavItem>
                        <NavItem onClick={() => this.goToLink('/advisors')}className="mobileLI">
                            <i className="material-icons">person_search</i>
                            <a>Advisors</a>
                        </NavItem>
                        <NavItem onClick={() => this.goToLink('/service-providers')}className="mobileLI">
                            <i className="material-icons">people</i>
                            <a>Service Providers</a>
                        </NavItem>
                        <NavItem onClick={() => this.goToLink('/settings')}className="mobileLI">
                            <i className="material-icons">settings</i>
                            <a>Settings</a>
                        </NavItem>
                        <NavItem onClick={() => this.goToLink('/login')} className="mobileLI">
                            <i className="material-icons">exit_to_app</i>
                            <a>Logout</a>
                        </NavItem>
                    </ul>
                </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        whitelabel
    };
}

const connectedMobileNavMenuProspectSV = connect(mapStateToProps)(MobileNavMenuProspectSV);
export { connectedMobileNavMenuProspectSV as MobileNavMenuProspectSV };