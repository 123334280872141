import React from 'react';
import { registrationService } from '../../../_services';
import ReactPasswordStrength from 'react-password-strength';

export class Registration_Account extends React.Component {
    constructor(props) {
        super(props);
        const {user, nav} = this.props;

        this.state = {
            user: user,
            nav: nav,
            submitted: false,
            emailExists: false,
            emailFormat: false,
            confirmPasswordValid: false,
            password: '',
            isValid: false,
            themeColor: ''
        }
    }

    componentDidMount() {
        this.setState({
            themeColor: localStorage.getItem('themeColor')
        })
    }

    changeCallback = state => {
        this.setState(prevState => ({
            passLength: state.password.length,
            isValid: state.isValid,
            user: {
                ...prevState.user,
                Password: state.password,
            }
        }), () => {});
    }

    handleChange = (e) => {
        const {name, value} = e.target;
        let {user} = this.state;
        user[name] = value;
        this.setState({
            user: user,
            confirmPasswordValid: true
        })
    }

    handleNextForm = async(e) => {
        let {user, nav} = this.state;
        if (await this.formIsValid(user)) {
            nav++;
            let inputs = {
                user,
                nav
            }
            this.props.handleFormInputs(inputs);
        }
        this.setState({ submitted: true });
    }

    async formIsValid(user) {
        const user_email = user.Email ? user.Email : "";

        let valid = true;
        let emailFormat = true;
        let emailExists = false;
        let confirmPasswordValid = true;

        if (user.Email === "" || 
            user.Password === "" 
            ) {
            valid = false;
        } 

        if (this.state.isValid === false)
        {
            valid = false;
        }
        
        if (valid === true) {
            //email
            let emailState = await registrationService.checkEmail(user);

            if (!emailState.valid) {
                if (emailState.type === "format") {
                    emailFormat = false;
                }
            } 
        }

        this.setState({
            ...this.state,
            emailExists: emailExists,
            emailFormat: emailFormat,
            confirmPasswordValid: confirmPasswordValid
        })

        return valid && !emailExists && emailFormat && confirmPasswordValid;
    }

    render() {
        const {user, 
            submitted, 
            emailExists, 
            emailFormat, 
            confirmPasswordValid} = this.state;
        const {registrationAlert} = this.props;
        
        return (
            <div id="form_0" className="forms">
                {
                registrationAlert && registrationAlert.type === 'alert-danger' &&
                <label className="registrationError">Registration Unsuccessful</label>
                }
                <h1 className="regHeader" style={{color: this.state.themeColor}}>Let's get started.</h1>
                <hr/>
                <div>
                    <div>
                        <div className="question_section">
                            <label required> Email<span className="registerationRequired"> *</span></label>
                            <input name="Email" value={user.Email} onChange={this.handleChange} />
                            {submitted && !user.Email &&
                            <p className="error align-left">This field is required</p>}
                            {submitted && !emailFormat &&
                            <p className="error align-left">This email has an invalid format.</p>}
                            {submitted && emailExists &&
                            <p className="error align-left">This email is already in use.</p>}
                        </div>
                        <div className="question_section">
                            <label required>Password<span className="registerationRequired"> *</span></label>
                                <ReactPasswordStrength
                                    className="customClass"
                                    minLength={8}
                                    minScore={2}
                                    scoreWords={['poor', 'weak', 'good!', 'strong!', 'stronger!!']}
                                    changeCallback={this.changeCallback}
                                    inputState={{ name: "Password", autoComplete: "off", className: "form-control", onChange: this.handleChange }}
                                />
                            {submitted && !user.Password &&
                            <p className="error align-left">This field is required.</p>}
                            {submitted && this.state.isValid === false &&
                            <p className="error align-left">Password is too weak.</p>}
                        </div>
                        {/* <div className="question_section">
                            <label required>Confirm Password<span className="registerationRequired"> *</span></label>
                            <input name="Password_Confirm" value={user.Password_Confirm} type="password" onChange={this.handleChange} />
                            {submitted && !user.Password_Confirm &&
                            <p className="error">This field is required</p>}
                            {submitted && user.Password_Confirm && !confirmPasswordValid &&
                            <p className="error">Password Mismatch</p>}
                        </div> */}
                    </div>
                    <div>
                        <button 
                            className="nextButton" 
                            onClick={this.handleNextForm} 
                            style={{background: this.state.themeColor, border: this.state.themeColor}}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}