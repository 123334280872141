import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from "reactstrap"
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../../Styles/LoginPartialDropdown.css';

class LoginPartialDropdown extends Component {
    state = {
        dropDownOpen: false,
    }

    componentWillReceiveProps(nextProps) {
        this.setState({user: nextProps.user});
    }

    goToConsultants = () => {
        window.open('https://www.opengrants.io/featured-consultants/', '_blank');
    }

    toggle = () => {
        this.setState({
        dropDownOpen: !this.state.dropDownOpen,
        })
    };

    render(){
        const { user } = this.props;
        const partner = localStorage.getItem('partner');
        const partnerLogout = '/login/' + partner;

        var username = "";
        if (user.userName && user.userName != user.email) {
            username = user.userName;
        } else {
            if (user.name && user.name != user.email) {
                username += user.name;
            } 
            if (user.surname && user.surname != user.email) {
                username += username ? "-" + user.surname : user.surname;
            } 
            username += username != "" ? "-" + user.people_Id : user.people_Id;
        }

        return(
            <ButtonDropdown >
                <Dropdown id="userMenuDropdown" isOpen={this.state.dropDownOpen} toggle={this.toggle} >
                    <DropdownToggle caret>
                        {user && user.name && user.surname && 
                            <p id="loginPartialNameDropdown">
                                <i id="loginPartialExpand" className="material-icons">expand_more</i>
                            </p>
                        }
                    </DropdownToggle>
                    <DropdownMenu className="login-partial-dropdown">
                        <div className="arrow-up"></div>
                        <DropdownItem id="accountInfoDropdownHeader">Account Info</DropdownItem>
                        <DropdownItem className="accountDropdownLink" tag={Link} to={"/profiles/"+username}>Your Profile</DropdownItem>
                        <DropdownItem className="accountDropdownLink" tag={Link} to="/settings">Your Settings</DropdownItem>
                        {/* <DropdownItem className="accountDropdownLink" tag={Link} onClick={this.goToConsultants}>Grant Writers</DropdownItem> */}
                        {!partner && 
                            <DropdownItem className="accountDropdownLink" tag={Link} to="/login">Logout</DropdownItem>
                        }
                        {partner && 
                            <DropdownItem className="accountDropdownLink" tag={Link} to={partnerLogout}>Logout</DropdownItem>
                        }
                    </DropdownMenu>
                </Dropdown>
            </ButtonDropdown>
        )
    }
}

function mapStateToProps(state) {
    const { setUser } = state;
    const { user, loading } = setUser;
    return {
        loading,
        user
    };
}

const connectedHomePage = connect(mapStateToProps)(LoginPartialDropdown);
export { connectedHomePage as LoginPartialDropdown };
