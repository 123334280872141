import React from 'react';
import '../../Styles/Listing.css'

export class ListingEligibility extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            themeColor: localStorage.getItem('themeColor')
        }
    }

    moreInfo() {
        window.open('https://experience.opengrants.io/learn-grants');
    }

    render() {
        const listing  = this.props.value;
        console.log("eligibility")
        console.log(listing);
        return (
            <div>
                <h4 
                    id="taggedUnder"
                    style={{color: this.state.themeColor}}
                >
                    ELIGIBILITY
                </h4>
                <div id="taggedUnderRow" className="row">
                    <div>                        
                        {listing.listing_Eligibility_Tags && listing.listing_Eligibility_Tags.map((tag) =>
                            <h6 key={listing.listing_Id + "_tag_" + tag.eligibility_Group}>{tag.eligibility_Group}</h6>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}