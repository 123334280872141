import React from 'react';
import { Registration_AutoSuggestion } from '.';

export class Registration_Focus extends React.Component {
    constructor(props) {
        super(props);
        const {user, nav, categories} = this.props;

        this.state = {
            user: user,
            nav: nav,
            submitted: false,
            categories,

            categoryInput: user.category,
            category_TagsInput: "",

            categoryError: false,
            category_TagsError: false,

            themeColor: '',
        }
    }

    componentDidMount() {
        this.setState({
            themeColor: localStorage.getItem('themeColor')
        })
    }

    handleCategorySelection = (categories) => {
        const {user} = this.state;
        this.setState({
            user: {
                ...user,
                ...categories
            }
        })
    }

    handleCategoryInput = (categoryInputs) => {
        this.setState(
            categoryInputs
        )
    }

    handleNavigation = (type) => {
        var {nav, categories, categoryInput, category_TagsInput} = this.state;
        var user = Object.assign({}, this.state.user);
        var valid = true;
        if (type === 'next') {
            var isValid = this.formIsValid(user);
            var categoryError = false;
            var category_TagsError = false;

            if (categoryInput) {
                categoryError = true;
            }
            if (category_TagsInput) {
                category_TagsError = true;
            }

            if (categories["category"].includes(categoryInput)) {
                user.category = categoryInput;
                categoryError = false;
            }

            if (categories["category_Tags"].includes(category_TagsInput) && !user.category_Tags.includes(category_TagsInput)) {
                user.category_Tags.push(category_TagsInput);
                category_TagsError = false;
            }

            if (isValid && !categoryError && !category_TagsError) {
                nav++;
            } else {
                this.setState({
                    submitted: true,
                    categoryError,
                    category_TagsError, 
                });
                valid = false;
            }
        } else if (type === "prev") {
            nav--;
        }

        if (valid) {
            this.props.handleFormInputs({user, nav});
        }
    }

    formIsValid = (user) => {
        const user_email = user.Email;
        var valid = false;
        if (user.category != undefined && user.category != "") {
            valid = true;
        }
        return valid;
    }

    render() {
        const { user, submitted, categories, categoryError, category_TagsError } = this.state;
        var userCategory = user.category != "" ? [user.category] : [];
        
        return (
            <div id="form_3" className="forms">
                <h1 className="regHeader" style={{color: this.state.themeColor}}>What are you working on?</h1>
                <hr/>
                <div>
                    <div>
                        <div>
                            <h2>We'll provide you with custom recommendations and connect you with expert grant-writers in your field.</h2>
                            <label required>Choose at least one focus area relevant to your company.<span className="registerationRequired"> *</span></label>

                            <div className="question_section">
                                <label>Category<span className="registerationRequired"> *</span></label>
                                <Registration_AutoSuggestion 
                                    categories={categories} 
                                    categoryName="category"
                                    currentSelectedCategories={userCategory}
                                    submitted={submitted}
                                    requiredInput={true}
                                    errorInput={categoryError}
                                    handleCategorySelection={this.handleCategorySelection}
                                    handleCategoryInput={this.handleCategoryInput}/>
                            </div>
                            
                            <div className="question_section">
                                <label>Tag</label>
                                <Registration_AutoSuggestion 
                                    categories={categories} 
                                    categoryName="category_Tags"
                                    currentSelectedCategories={user.category_Tags}
                                    multiple={true}
                                    addTags={true}
                                    submitted={submitted}
                                    errorInput={category_TagsError}
                                    handleCategorySelection={this.handleCategorySelection}
                                    handleCategoryInput={this.handleCategoryInput}/>
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* <button className="backButton" onClick={() => this.handleNavigation('prev')}>Back</button>
                        <button className="nextButton" onClick={() => this.handleNavigation('next')}>Next</button> */}
                        <button 
                            className="backButton" 
                            onClick={() => this.handleNavigation('prev')}
                            style={{color: this.state.themeColor, borderColor: this.state.themeColor}}
                            >
                                Back
                            </button>
                        <button 
                            className="nextButton" 
                            onClick={() => this.handleNavigation('next')}
                            style={{background: this.state.themeColor, border: this.state.themeColor}}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}