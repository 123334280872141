import {notificationsConstants} from '../_constants';

export function getAllNotifications(state={status:"", loading:true}, action) {
    switch (action.type) {
        case notificationsConstants.NOTIFICATIONS_GETALLNOTIFICATIONS_REQUEST:
            return {status: "request", loading: true};
        case notificationsConstants.NOTIFICATIONS_GETALLNOTIFICATIONS_SUCCESS:
            return {status: "success", loading: false, userNotifications: action.data.userNotifications, count: action.data.count, unseenIds: action.data.unseenIds};
        case notificationsConstants.NOTIFICATIONS_GETALLNOTIFICATIONS_FAILURE:
            return {status: "failure", loading: false};
        case notificationsConstants.NOTIFICATIONS_GETALLNOTIFICATIONS_SEEN:
            return {...state, status: "success", count: 0}
        default:
            return state;
    }
};

export function markAllNotificationsSeen(state={status:"", loading:true}, action) {
    switch (action.type) {
        case notificationsConstants.NOTIFICATIONS_MARKSEEN_REQUEST:
            return {status: "request", loading: true};
        case notificationsConstants.NOTIFICATIONS_MARKSEEN_SUCCESS:
            return {status: "success", loading: false};
        case notificationsConstants.NOTIFICATIONS_MARKSEEN_FAILURE:
            return {status: "failure", loading: false};
        default:
            return state;
    }
};

export function get_email_notification_settings(state={}, action) {
    switch (action.type) {
        case notificationsConstants.NOTIFICATIONS_GETALLNOTIFICATIONS_REQUEST:
            return {};
        case notificationsConstants.NOTIFICATIONS_GETEMAILNOTIFICATIONSETTINGS_SUCCESS:
            return {email_notification_settings: action.email_notification_settings};
        case notificationsConstants.NOTIFICATIONS_GETEMAILNOTIFICATIONSETTINGS_FAILURE:
            return {};
        default:
            return state;
    }
};

export function update_email_notification_settings(state={}, action) {
    switch (action.type) {
        case notificationsConstants.NOTIFICATIONS_UPDATEEMAILNOTIFICATION_REQUEST:
            return {};
        case notificationsConstants.NOTIFICATIONS_UPDATEEMAILNOTIFICATION_SUCCESS:
            return {email_notification_settings: action.email_notification_settings};
        case notificationsConstants.NOTIFICATIONS_UPDATEEMAILNOTIFICATION_FAILURE:
            return {};
        default:
            return state;
    }
};