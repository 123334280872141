import { chatConstants } from '../_constants';

export function chatalert_updateconversations(state = {}, action) {
  switch (action.type) {
    case chatConstants.UPDATE_CONVERSATIONS_ALERT:
      return {
        conversations: action.conversations
      };
    default:
      return state
  }
}
export function chatalert_setconversation(state = {}, action) {
  switch (action.type) {
    case chatConstants.SET_CONVERSATION_ALERT:
      return {
        conversation: action.conversation
      };
    default:
      return state
  }
}
export function chatalert_setchannel(state = {}, action) {
  switch (action.type) {
    case chatConstants.SET_CHANNEL_ALERT:
      return {
        channel: action.channel
      };
    default:
      return state
  }
}

export function chat_messageuserrequest(state = {}, action) {
  // alert("reducer");
  switch (action.type) {
    case chatConstants.MESSAGE_USER_REQUEST:
      return {
        message_user: action.organization_Id
      }
    default:
      return state
  }
}