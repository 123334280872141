export const settingsConstants = {
    UPDATEPROFILE_REQUEST: 'SETTINGS_UPDATEPROFILE_REQUEST',
    UPDATEPROFILE_SUCCESS: 'SETTINGS_UPDATEPROFILE_SUCCESS',
    UPDATEPROFILE_FAILURE: 'SETTINGS_UPDATEPROFILE_FAILURE',

    UPDATEFOCUS_REQUEST: 'SETTINGS_UPDATEFOCUS_REQUEST',
    UPDATEFOCUS_SUCCESS: 'SETTINGS_UPDATEFOCUS_SUCCESS',
    UPDATEFOCUS_FAILURE: 'SETTINGS_UPDATEFOCUS_FAILURE',

    UPDATEORGANIZATION_REQUEST: 'SETTINGS_UPDATEORGANIZATION_REQUEST',
    UPDATEORGANIZATION_SUCCESS: 'SETTINGS_UPDATEORGANIZATION_SUCCESS',
    UPDATEORGANIZATION_FAILURE: 'SETTINGS_UPDATEPORGANIZATION_FAILURE',

    UPDATEADVANCED_REQUEST: 'SETTINGS_UPDATEADVANCED_REQUEST',
    UPDATEADVANCED_SUCCESS: 'SETTINGS_UPDATEADVANCED_SUCCESS',
    UPDATEADVANCED_FAILURE: 'SETTINGS_UPDATEADVANCED_FAILURE',
};
