import React, {Fragment} from 'react';
import {StripeItem} from '.';
import '../../../Styles/SettingsStyles/Billings.css';
import {StripeCoupon} from './StripeCoupon';

export class StripeCart extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            allowRemove,
            discount,
            discountStatus,
            invoiceDiscount,
            selectedPrices,
            consolidatedProducts} = this.props;
        let displayDiscount = null;
        let {total} = this.props;

        //set cart items
        var prodsKeys = Object.keys(selectedPrices);
        var selectedPris = null;
        if (prodsKeys.length > 0) {
            selectedPris = prodsKeys.map((key) => {
                var pri = consolidatedProducts[key];
                if (pri) 
                    {
                    var allowRemove = !(pri[0].product.metadata["group"]).includes(this.props.productType + " default");
                    return (
                        <Fragment key={"keys_"+key}>
                            <StripeItem 
                                allowRemove={allowRemove}
                                selectedPrices={selectedPrices}
                                prices={pri} 
                                removeFromCart={this.props.removeFromCart}
                                updateSelectedProduct={this.props.updateSelectedProduct}
                                consolidatedProducts={this.props.consolidatedProducts}
                                invoiceDiscount={invoiceDiscount}
                                discount={discount}
                                productType={this.props.productType} />
                        </Fragment>
                    )}
                else return null
            });
        }

        //set discount
        // if (discount && !displayDiscount) {
        //     //general discount
        //     if (!discount.product || discount.product === "") {
        //         let discountTotal = "";


        //         if (discount.type == "percent") {
        //             discountTotal = (total*discount.amount*0.01);
        //         } else if (discount.type == "amount") {
        //             discountTotal = discount.amount;
        //         }
                
        //         if (discountTotal < total) {
        //             total = total-discountTotal;
        //             let discountString = !discount.name.includes("off") ? 
        //                 discount.type === "amount" ? 
        //                 "Discount - " + discount.name + " ($" + discount.amount/100 + " off)" + ": " :
        //                 "Discount - " + discount.name + " (" + discount.amount + "% off)" + ": "
        //                 : "Discount - " + discount.name + ":";
        //                 displayDiscount = 
        //             <div className="radio-elements">
        //                 <label className="col-md-6">
        //                     {discountString}
        //                 </label>
        //                 <label className="col-md-6">
        //                     {"-$"+discountTotal/100}
        //                 </label>
        //             </div>
        //         } 
        //     } else {
        //         let prices = Object.values(selectedPrices);
        //         prices.filter((price) => {
        //             if (!(discount.product.includes('opengrants_test_coupon')) || (selectedPrices[price.product.id].id !== price.id)) return
        //             let amount = price.unit_amount;
        //             if ((discount.product.includes('opengrants_test_coupon')) || (discount.product && discount.product !== "" && discount.product.includes(price.id))) {
        //                 let discountTotal = "";

        //                 if (discount.type == "percent") {
        //                     discountTotal = (amount*discount.amount*0.01);
        //                 } else if (discount.type == "amount") {
        //                     discountTotal = discount.amount;
        //                 }
                        
        //                 if (discountTotal <= amount) {
        //                     total = total - discountTotal;
                        
        //                     let discountString = !discount.name.includes("off") ? 
        //                         discount.type === "amount" ? 
        //                         "Discount - " + discount.name + " ($" + discount.amount/100 + " off)" + ": " :
        //                         "Discount - " + discount.name + " (" + discount.amount + "% off)" + ": "
        //                         : "Discount - " + discount.name + ":";
        //                         displayDiscount = 
        //                     <div className="radio-elements">
        //                         <label className="col-md-6">
        //                             {discountString}
        //                         </label>
        //                         <label className="col-md-6">
        //                             {"-$"+discountTotal/100}
        //                         </label>
        //                     </div>
        //                 } 
        //             }
        //             // total+=amount;
        //         });
        //     }
        // } else if (invoiceDiscount && !displayDiscount) {
        //     let discountString = !invoiceDiscount.name.includes("off") ? 
        //     invoiceDiscount.type === "amount" ? 
        //         "Discount - " + invoiceDiscount.name + " ($" + invoiceDiscount.amount/100 + " off)" + ": " :
        //         "Discount - " + invoiceDiscount.name + " (" + invoiceDiscount.amount + "% off)" + ": "
        //         : "Discount - " + invoiceDiscount.name + ":";
        //     displayDiscount = 
        //     <div className="radio-elements">
        //         <label className="col-md-6">
        //             {discountString}
        //         </label>
        //         <label className="col-md-6">
        //             {"-$"+invoiceDiscount.amount/100}
        //         </label>
        //     </div>
        // }

        return (
            <div id="orderSummary" className="payment-form gap-bottom">
                <div className="background-white pop">
                    <h2 id="serviceDetails">Service Details</h2>
                    {selectedPris}
                    <StripeCoupon 
                        handleCoupon={this.props.handleCoupon}
                        discount={discount}
                        discountStatus={discountStatus}
                        selectedPrices={selectedPrices}
                        consolidatedProducts={this.props.consolidatedProducts} />
                </div>
            </div>
        );
    }
}