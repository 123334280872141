import React from "react";
import DashboardView from "./DashboardView";
import DashboardView_ProspectSV from "./DashboardView_ProspectSV";
import DashboardView_CMC from "./DashboardView_CMC";
import { connect } from 'react-redux';
import { favoritesService, dashboardService, projectProposalService, listingNotesService, settingsService } from '../../_services';
import { dashboardActions, resourcesActions } from '../../_actions';
import { Loader } from '../Shared/Loader';
import '../../Styles/Dashboard.css';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGroup: {
                search: "",
                user: this.props.user,
                page: 1,
                totalResults: 0,
            },

            records: "",
            loading: true,
            user_notes: [],
            profileComplete: false,
            organizationComplete: false,
        };

        this.expandGrantsMenu = this.expandGrantsMenu.bind(this);
        this.expandExpertsAdvisorsMenu = this.expandExpertsAdvisorsMenu.bind(this);
    }

    async componentDidMount() {
        this.props.dispatch(dashboardActions.loadDashboard(this.props.user));
        favoritesService.getFavorites(this.props.user);

        this.setState({
            user: {
                ...this.props.user,
                parent_Categories: await dashboardService.getUserCategories(this.props.user),
                profileComplete: true,//settingsService.checkProfileComplete(this.props.user),
                // organizationComplete: true//settingsService.checkOrganizationComplete(this.props.user),
            }
        });

        this.getGrantWritingPurchase();
        this.getNotes();
    }

    getGrantWritingPurchase = async() => {
        const user = JSON.parse(localStorage.getItem('user'));
        var purchased = await projectProposalService.getGrantWritingPurchase(user.id);
        if (purchased) {
            this.getProposals();
        } else {
            this.setState({
                purchasedGrantWriting: false,
                loading: false
            });
        }
    }

    getProposals = async () => {
        const user_unparsed = localStorage.getItem('user');
        if (user_unparsed) {
            const user = JSON.parse(user_unparsed);
            var result = await projectProposalService.getProjectProposals(user.id);
            console.log(result);

            if (result) {
                this.setState({
                    ...result,
                    purchasedGrantWriting: true,
                    loading: false
                });
            } else {
                this.setState({
                    purchasedGrantWriting: true,
                    loading: false
                });
            }
        }
    }

    getNotes = async() => {
        const user = JSON.parse(localStorage.getItem("user"));
        var result = await listingNotesService.getAllListingNotes(user.id);

        if (result) {
            this.setState({
                ...result,
            });
        } 
    }

    expandGrantsMenu = () => {
        this.props.dispatch(resourcesActions.setGrantsExpanded());
    }

    expandExpertsAdvisorsMenu = () => {
        this.props.dispatch(resourcesActions.setExpertsAdvisorsExpanded());
    }

    render() {
        const { dashboard, whitelabel } = this.props;
        const { user, 
            purchasedGrantWriting, records, proposalSubmission,
            loading, 
            user_notes,
            profileComplete, organizationComplete } = this.state;
        console.log(this.state);

        return (
            <div id="portalDashboard" style={{marginTop: "3%"}}>
                {/* {whitelabel && whitelabel.features.feature_Opengrants_Dashboard &&
                    <h1 id="dashboardHeader">DASHBOARD</h1>
                } */}
                {!user && <Loader />}
                {user && (whitelabel && whitelabel.features.feature_Opengrants_Dashboard) &&
                    <DashboardView
                        dashboard={dashboard}
                        user = {user}
                        purchasedGrantWriting = {purchasedGrantWriting}
                        proposalSubmission = {proposalSubmission}
                        records = {records}
                        loading={loading}
                        user_notes = {user_notes}
                        getNotes = {this.getNotes}
                        whitelabel = {whitelabel}
                        profileComplete = {profileComplete}
                        organizationComplete = {organizationComplete}
                    />
                }
                {user && (whitelabel && whitelabel.features.feature_Custom_Dashboard_Primary) &&
                    <DashboardView_ProspectSV
                        dashboard={dashboard}
                        user = {user}
                        purchasedGrantWriting = {purchasedGrantWriting}
                        proposalSubmission = {proposalSubmission}
                        records = {records}
                        loading={loading}
                        user_notes = {user_notes}
                        getNotes = {this.getNotes}
                        whitelabel = {whitelabel}
                        expandExpertsAdvisorsMenu = {this.expandExpertsAdvisorsMenu}
                        expandGrantsMenu = {this.expandGrantsMenu}
                    />
                }
                {user && (whitelabel && whitelabel.organinzation_Name == 'grants.californiamobilitycenter.org') &&
                    <div>
                    <h1 style={{fontFamily: "open sans"}}>DASHBOARDS</h1>
                    <br />
                    <DashboardView_CMC
                        dashboard={dashboard}
                        user = {user}
                        purchasedGrantWriting = {purchasedGrantWriting}
                        proposalSubmission = {proposalSubmission}
                        records = {records}
                        loading={loading}
                        user_notes = {user_notes}
                        getNotes = {this.getNotes}
                        whitelabel = {whitelabel}
                        expandExpertsAdvisorsMenu = {this.expandExpertsAdvisorsMenu}
                        expandGrantsMenu = {this.expandGrantsMenu}
                    />
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, setWhitelabel, dashboard } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        dashboard,
        whitelabel
    };
}

const connectedStore = connect(mapStateToProps)(Dashboard);
export { connectedStore as Dashboard };
