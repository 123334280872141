import { whitelabelConstants } from '../_constants';

export function setWhitelabel(state = {}, action) {
  switch (action.type) {
    case whitelabelConstants.SETWHITELABEL_REQUEST:
      return {
        loading: true,
        whitelabel: action.whitelabel
      };
    case whitelabelConstants.SETWHITELABEL_SUCCESS:
      return {
        loading: false,
        whitelabel: action.whitelabel
      };
    case whitelabelConstants.SETWHITELABEL_FAILURE:
      return{
        error: "There was an error processing your request."
      }
    default:
      return state
  }
};