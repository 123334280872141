import React, { useReducer } from 'react';
// import { Registration_AutoSuggestion } from '../../RegisterPage/Registration_AutoSuggestion';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '../../../Shared/Loader';
import { history } from '../../../../_helpers';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import ProgressBar from 'react-bootstrap/ProgressBar';

// const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

class SBIRFocusAreas extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            focus_areas: JSON.parse(localStorage.getItem('focus_areas')),
            nav: localStorage.getItem('nav'),
            loading: true
        }
    }

    componentDidMount() {
        this.setState({
            focus_areas: {
                ...this.state.focus_areas,
                advanced_manufacturing: false,
                advanced_materials: false,
                artificial_intelligence: false,
                biotechnology: false,
                blockchain: false,
                robotics: false,
                photonics: false,
                electronics_semiconductors: false,
                quantum_computing: false,
                agriculture: false,
                life_sciences: false,
                chemistry: false,
                aerospace: false,
                green_energy: false
            }
        });
        
        document.body.scrollTop = 0;
    
        setTimeout(() => {
            this.setState({
            loading: false
            });
        }, 500);
    }

    useStyles = makeStyles((theme) => ({
            root: {
              display: 'flex',
            },
            formControl: {
              margin: theme.spacing(3),
            },
    }));


    CheckboxesGroup() {
        const classes = this.useStyles();
    };
          
    handleChange = (event) => {
        const category = [event.target.name];

        this.setState({ 
            ...this.state, 
            [event.target.name]: event.target.checked,
            focus_areas: {
                ...this.state.focus_areas,
                [category]: event.target.checked 
            }
        });
    };
    
    handleNavigation = async (type) => {
        var {user, focus_areas} = this.state;
      
        await localStorage.setItem('user', JSON.stringify(user));
        await localStorage.setItem('focus_areas', JSON.stringify(focus_areas));

        if (user.US_State == 'California')
        {
            history.push('/quickstart/info-second');
        }
        else
        {
            history.push('/quickstart/finished');
        }
    }

    render() {     
        const { loading } = this.state;
        const { whitelabel } = this.props;
        const progressBarColor = whitelabel ? whitelabel.accent_Color : "#29a";
        const now = 80;
        const progressInstance = <ProgressBar now={now} label={`${now}%`} />;

        return (
            <div>
                <style>{"\
                    .progress-bar{\
                    background:" + progressBarColor + ";\
                    }\
                    .#NSF_Category_Selector .MuiSvgIcon-root{\
                        fill:" + progressBarColor + ";\
                    }\
                "}</style>
                {loading && <Loader />}
                <div>{progressInstance}</div>
                <div id="fundingSlider1">
                    <h1 id="fundingSliderHeader">
                        Let's look at some specific areas of operation.
                    </h1>
                </div>
                <div id="NSF_Category_Selector">
                    <FormControl component="fieldset" className="">
                        <FormGroup>
                            <div id="focusNSFArea" className="row">
                                <h3 id="checkAllH3">Check all that apply</h3>
                                <hr />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.advanced_manufacturing} 
                                                    onChange={this.handleChange} 
                                                    name="advanced_manufacturing" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Advanced Manufacturing"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.advanced_materials} 
                                                    onChange={this.handleChange} 
                                                    name="advanced_materials" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Advanced Materials"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.artificial_intelligence} 
                                                    onChange={this.handleChange} 
                                                    name="artificial_intelligence" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Artificial Intelligence (AI)"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.biotechnology} 
                                                    onChange={this.handleChange} 
                                                    name="biotechnology" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Biotechnology"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.blockchain} 
                                                    onChange={this.handleChange} 
                                                    name="blockchain" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Blockchain / Distributed Ledger"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.robotics} 
                                                    onChange={this.handleChange} 
                                                    name="robotics" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Robotics"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.photonics} 
                                                    onChange={this.handleChange} 
                                                    name="photonics" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Photonics"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.electronics_semiconductors} 
                                                    onChange={this.handleChange} 
                                                    name="electronics_semiconductors" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Electronics / Semiconductors"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.quantum_computing} 
                                                    onChange={this.handleChange} 
                                                    name="quantum_computing" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Quantum Computing"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.agriculture} 
                                                    onChange={this.handleChange} 
                                                    name="agriculture" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Agriculture"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.life_sciences} 
                                                    onChange={this.handleChange} 
                                                    name="life_sciences" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Life Sciences"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.chemistry} 
                                                    onChange={this.handleChange} 
                                                    name="chemistry" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Chemistry"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.aerospace} 
                                                    onChange={this.handleChange} 
                                                    name="aerospace" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Aerospace"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    checked={this.state.focus_areas.green_energy} 
                                                    onChange={this.handleChange} 
                                                    name="green_energy" 
                                                    style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                                />}
                                        label="Green Energy"
                                    />
                                <div id="SBIRFocusContinueButton" className="continueButton">
                                    <button 
                                        className="signUpContinue"
                                        onClick={() => this.handleNavigation('next')}
                                        style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                                    >
                                        Continue  →
                                    </button>
                                </div>
                            </div>
                        </FormGroup>
                    </FormControl>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering, nav } = state.registration;
    const { whitelabel } = state.setWhitelabel;
    const { registrationAlert } = state;
    
    return {
        registering, 
        nav, 
        registrationAlert,
        whitelabel,
    };
}

const connectedSBIRFocusAreas = withRouter(connect(mapStateToProps)(SBIRFocusAreas));
export { connectedSBIRFocusAreas as SBIRFocusAreas };