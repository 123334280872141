import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarToggler } from 'reactstrap';
import { connect } from 'react-redux';
import { HeaderTags } from '../HeaderTags';
import { MobileNavMenu } from './MobileNavMenu';
import { DesktopNavMenu } from './DesktopNavMenu';
import { DesktopNavMenuProspectSV } from './DesktopNavMenuProspectSV';
import { MobileNavMenuProspectSV } from './MobileNavMenuProspectSV';
import '../../../Styles/NavMenu.css';

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
        if (this.state.collapsed === true)
        {
            // document.body.style.overflow = "hidden";
            // document.body.style.height = "100vh";
            // document.getElementById('listingMain').style.display = "none";
        }
        else
        {
            // document.body.style.overflow = "auto";
            // document.body.style.height = "auto";
            // document.getElementById('listingMain').style.display = "block";
        }
    }

    render() {
        const { user, whitelabel } = this.props;
        const partner = localStorage.getItem('partner');

        return (
            <header>
                <HeaderTags />
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                {/* {user && <LoginPartial /> } */}
                    <Container id="navContainer" className="container">
                        {whitelabel && whitelabel.organization_Name == "portal.opengrants.io" &&
                            <div className="navbar-header" id="navbarHeaderDiv">
                                    <div id="desktop-title">
                                        <img src="https://opengrants-prerender-webbucket-wk1itbt6df8u.s3.amazonaws.com/opengrants_images/opengrantsSM_white.png"  style={{width: 45, display: "inline"}} />
                                        <h1 id="portal">PORTAL</h1>
                                    </div>
                                    <h1 id="portalPoweredByMobile">Powered by OpenGrants</h1>
                                <NavbarToggler onClick={this.toggleNavbar} id="mainToggle" type="button" className="navbar-toggle" data-toggle="collapse" data-target="#landing-navbar-collapse-main">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </NavbarToggler>
                            </div>
                        }
                        {whitelabel && whitelabel.organization_Name == "portal.prospectsv.org" &&
                            <div className="navbar-header" id="navbarHeaderDiv" style={{background: whitelabel ? whitelabel.menu_Color : "#666", marginBottom: "-5%"}}>
                                <img src={whitelabel ? whitelabel.logo_URL : ""} height="40" style={{marginLeft: "7.5%", marginTop: 12}} />
                                <NavbarToggler onClick={this.toggleNavbar} id="mainToggle" type="button" className="navbar-toggle" data-toggle="collapse" data-target="#landing-navbar-collapse-main" style={{backgroundColor:"#333!important"}}>
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar" style={{background: "#fff"}}></span>
                                    <span className="icon-bar" style={{background: "#fff"}}></span>
                                    <span className="icon-bar" style={{background: "#fff"}}></span>
                                </NavbarToggler>
                            </div>
                        }
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar id="landing-navbar-collapse-main">
                            {whitelabel && whitelabel.organization_Name != 'portal.prospectsv.org' &&
                                <div>
                                    <DesktopNavMenu />
                                    <MobileNavMenu />
                                </div>
                            }
                            {whitelabel && whitelabel.organization_Name == 'portal.prospectsv.org' &&
                                <div>
                                    <DesktopNavMenuProspectSV />
                                    <MobileNavMenuProspectSV />
                                </div>
                            }
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel
    return {
        user,
        whitelabel,
    };
}

const connectedHomePage = connect(mapStateToProps)(NavMenu);
export { connectedHomePage as NavMenu };