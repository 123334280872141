import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import 'react-awesome-slider/dist/styles.css';
import "react-step-progress-bar/styles.css";
import '../../../../Styles/Dashboard.css';
import '../../../../Styles/HowItWorks.css';

class HowItWorks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            submitted: false,
            loading: true,
        }        
    }
    
    loadSignUp = () => {            
        window.location.href="/quickstart/basic-questions";
    }

    render() {
        const { whitelabel } = this.props;
        const slider = (
            <AwesomeSlider>
              <div id="howItWorksPanel#1">
                  <div className="row">
                      <div className="col-md-6">
                          <div id="howItWorksLeft1">
                              <h1 id="howItWorksMidHeader">
                                  Here's the deal:
                              </h1>
                              {/* <hr id="dashMidHR" /> */}
                              <h2 id="howItWorksH2">
                                  <strong><span style={{color: whitelabel ? whitelabel.accent_Color : "#29a", fontSize: 32}}> $721 billion. </span></strong> That's the amount of available non-dillutive funding opportunities in the United States.
                              </h2>
                              <h2 id="howItWorksH2">
                                  <strong>That's a good chunk of cash!</strong>
                              </h2>
                          </div>
                      </div>
                      <div className="col-md-6" id="howItWorksRight1">
                          {/* <img id="howItWorks1IMG" align="bottom" src="https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/opengrants_funding_parachute.png"/> */}
                      </div>
                  </div>
              </div>
              <div id="howItWorksPanel2">
                  <div className="row" style={{width: "100%"}}>
                      <div className="col-md-6">
                          <div id="howItWorksLeft2">
                              <h1 id="howItWorksMidHeader">
                                  To win these grants, <br />
                                  all you need to do is...
                              </h1>
                              <ul>
                                  <li id="HIWproductListItem">
                                      <i id="productCheckmark" className="material-icons">
                                          done
                                      </i>
                                      <p id="howItWorksChecklist">Read the fine print on thousands of RFP's to try and understand what you're eligible for.</p>
                                  </li>
                                  <li id="HIWproductListItem">
                                  <i id="productCheckmark" className="material-icons">
                                          done
                                      </i>
                                      <p id="howItWorksChecklist">Manage numerous (and sometimes variable) deadlines.</p>
                                  </li>
                                  <li id="HIWproductListItem">
                                  <i id="productCheckmark" className="material-icons">
                                          done
                                      </i>
                                      <p id="howItWorksChecklist">Find an expert grant writer in your industry that can craft an award winning proposal.</p>
                                  </li>
                                  <li id="HIWproductListItem">
                                  <i id="productCheckmark" className="material-icons">
                                          done
                                      </i>
                                      <p id="howItWorksChecklist">Flawlessly navigate the hazards of award distribution and audit tracking.</p>
                                  </li>
                              </ul>
                              <h2 id="howItWorksChecklistH2">
                                  Or better yet, <strong><span style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}> we can do all of that for you.</span></strong>
                              </h2>
                          </div>
                      </div>
                      <div className="col-md-6" id="howItWorksRight2">
                          {/* <img id="howItWorks1IMG" align="bottom" src="https://s3-us-west-1.amazonaws.com/opengrants.io/opengrants_images/opengrants_funding_parachute.png"/> */}
                      </div>
                  </div>
              </div>
              <div id="howItWorksPanel#3">
                  <div className="row" style={{width: "100%"}}>
                      <div className="col-md-6">
                          <div id="howItWorksLeft3">
                              <h1 id="howItWorksMidHeader">
                                  Here's how it works:
                              </h1>
                              {/* <hr id="dashMidHR" /> */}
                              <h2 id="howItWorksH231" style={{fontSize:22}}>
                                  Start by taking five minutes to complete our simple and secure registration process.
                                  <br /><br />
                                  Our algorithms will then match you with the funding opportunities that best fit your business.
                              </h2>
                              <h2 id="howItWorksH232" style={{color: whitelabel ? whitelabel.accent_Color : "#29a"}}>
                                  <strong>Sounds easy, right?</strong>
                              </h2>
                          </div>
                      </div>
                      <div className="col-lg-6" id="howItWorksRight3"></div>
                  </div>
              </div>
              <div id="howItWorksPanel#4">
                  <div className="row" style={{width: "100%"}}>
                      <div className="col-md-6">
                          <div id="howItWorksLeft4">
                              <h1 id="howItWorksMidHeader">
                                  Supercharge your application.
                              </h1>
                              <h2 id="howItWorksH241">
                                  We'll connect you with an expert grant writer in your industry to develop an outstanding submission.
                              </h2>
                              <h2 id="howItWorksH242">
                                  <input 
                                    type="button" 
                                    id="howItWorksLetsGo" 
                                    value="Let's get started &rarr;" 
                                    onClick={this.loadSignUp} 
                                    style={{background: whitelabel ? whitelabel.accent_Color : "#29a"}}
                                />
                              </h2>
                          </div>
                      </div>
                      <div className="col-lg-6" id="howItWorksRight4">
                      </div>
                  </div>
              </div>
            </AwesomeSlider>
          );

        return (
            <div>
                <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700;800;900&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css?family=Open+Sans:300;400;500;600;700;800;900&display=swap" rel="stylesheet" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                {slider}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { whitelabel } = state.setWhitelabel;
    
    return {
        whitelabel,
    };
}

const connectedHowItWorks = withRouter(connect(mapStateToProps)(HowItWorks));
export { connectedHowItWorks as HowItWorks };