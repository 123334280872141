import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../_helpers';
import { PrivateRoute } from '../_components';
import { Inside404Page, Outside404Page } from '../_pages/Error';
import { UserRedirect } from '../_pages/Landing';
import { Dashboard } from '../_pages/Dashboard';
import { Matches } from '../_pages/Matches';
import { Layout } from '../_pages/Shared/Layout';
import { Listing, ListingNotes } from '../_pages/Listing';
import { LoginPage, ForgotPassword, ResetPassword } from '../_pages/Account/LoginPage';
import { RegisterPage, ConfirmEmail } from '../_pages/Account/RegisterPage';
import { RegisterPage_Partner, Onboarding_Partner } from '../_pages/Account/RegisterPage_Partner';
import { Favorites } from '../_pages/Favorites';
import { SearchListings } from '../_pages/SearchListings';
import { Settings, ProfileView } from '../_pages/Settings';
import { OrganizationView, OrganizationRequest, OrganizationInvitation } from '../_pages/Settings/OrganizationSettings';
import { GrantWriters, ProjectProposal } from '../_pages/GrantWriters';
import { StripeConnect, StripeCheckout, StripeManageBilling, StripePricingPage } from '../_pages/Stripe/Stripe_Copy';
import { ServiceProviders, ServiceProvider } from '../_pages/ServiceProviders';
import { Products } from '../_pages/Dashboard/Products';
import { MarketplaceSearch } from '../_pages/Marketplace'
import { Modal } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { whitelabelActions } from '../_actions/';
import { whitelabel_Organization } from './Whitelabel';
import { ProspectSV_Advisors, ProspectSV_Resources, ProspectSV_Grantwriters, ProspectSV_EV_Investors } from '../_pages/Resources';
import { GetStarted } from '../_pages/Quickstart/Survey/InfoPages/GetStarted';
import { LearnMore } from '../_pages/Quickstart/Survey/InfoPages/LearnMore';
import { BasicQuestions } from '../_pages/Quickstart/Survey/InfoPages/BasicQuestions';
import { EntityType } from '../_pages/Quickstart/Survey/BusinessType/EntityType';
import { FundingTimeline } from '../_pages/Quickstart/Survey/FundingTimeline';
import { NumberEmployees } from '../_pages/Quickstart/Survey/BusinessType/NumberEmployees';
import { SmallBusiness } from '../_pages/Quickstart/Survey/BusinessType/SmallBusiness';
import { USOwned } from '../_pages/Quickstart/Survey/BusinessType/USOwned';
import { BusinessLocation } from '../_pages/Quickstart/Survey/BusinessType/BusinessLocation';
import { BusinessFocusInfo } from '../_pages/Quickstart/Survey/InfoPages/BusinessFocusInfo';
import { BusinessLocationInfo } from '../_pages/Quickstart/Survey/InfoPages/BusinessLocationInfo';
import { ExtraCategoriesInfoFirst } from '../_pages/Quickstart/Survey/InfoPages/ExtraCategoriesInfoFirst';
import { ExtraCategoriesInfoSecond } from '../_pages/Quickstart/Survey/InfoPages/ExtraCategoriesInfoSecond';
import { ImpactFocusAreas } from '../_pages/Quickstart/Survey/FocusAreas/ImpactFocusAreas';
import { ImpactFocusGroups } from '../_pages/Quickstart/Survey/FocusAreas/ImpactFocusGroups';
import { NSFFocusAreas } from '../_pages/Quickstart/Survey/FocusAreas/NSFFocusAreas';
import { SBIRFocusAreas } from '../_pages/Quickstart/Survey/FocusAreas/SBIRFocusAreas';
import { CECFocusAreas } from '../_pages/Quickstart/Survey/FocusAreas/CECFocusAreas';
import { Finished } from '../_pages/Quickstart/Survey/InfoPages/Finished';
import { HowItWorks } from '../_pages/Quickstart/Survey/InfoPages/HowItWorks';
import { QuickstartDashboard, QuickstartProducts, QuickstartProgram, QuickstartFunding } from '../_pages/Quickstart/Programs';
import '../Styles/Main.css';

/** Hotfix css-modal */
Modal.prototype.componentWillUnmount = function () {
    if (this.props.onExit) {
        this.props.onExit();
    }

    if (this._element) {
        this.destroy();
        if (this.props.isOpen || this.state.isOpen) {
            this.close();
        }
    }

    this._isMounted = false;
};

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            history: { history },
            platform_Settings: null
        }
    }

    componentDidMount() {
        this.props.dispatch(whitelabelActions.setWhitelabel(whitelabel_Organization));
    }

    render() {
        const server = window.location.protocol + '//' + window.location.host;
        const { whitelabel } = this.props;

        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{whitelabel && whitelabel.meta_Title}</title>
                    <link rel="icon" href={whitelabel && whitelabel.meta_Favicon} type="image/x-icon" />
                    <meta property="og:type" content="website"></meta>
                    <meta name="og:description" content={whitelabel && whitelabel.meta_Description} />
                    <meta name="description" content={whitelabel && whitelabel.meta_Description} />
                    <meta property="og:title" content={whitelabel && whitelabel.meta_Title} />
                    <meta property="og:url" content={server} />
                    <meta property="og:image" content={whitelabel && whitelabel.meta_Image} />
                    <meta name="twitter:title" content={whitelabel && whitelabel.meta_Title} />
                    <meta name="twitter:description" content={whitelabel && whitelabel.meta_Description} />
                    <meta name="twitter:image" content={whitelabel && whitelabel.meta_Image} />
                    <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                <Router history={history}>
                    <Switch>
                        {/* Normal routing for non auth users/ */}
                        <Route exact path="/login" component={LoginPage} />
                        <Route exact path="/forgot_password" component={ForgotPassword} />
                        <Route exact path="/reset_password" component={ResetPassword} />
                        <Route exact path="/register/partner/:partnerClient" component={RegisterPage_Partner} />
                        <Route exact path="/register/grantwriter" component={RegisterPage_Partner} />
                        <Route exact path="/onboarding/:partnerClient" component={Onboarding_Partner} />
                        <Route exact path="/register/:grant?" component={RegisterPage} />
                        <Route exact path="/confirm_email" component={ConfirmEmail} />
                        <Redirect from="/cl/:string" to="/:string" />
                        <Redirect from="/profile" to="/settings" />
                        <Redirect from="/listings/grant/:string" to="grants/:string" />
                        <Redirect from="/account/:string" to="/:string" />
                        <Redirect from="/registerclient" to="/register" />
                        <Redirect from="/listings" to="/search" />
                        <Route exact path="/pricing" component={StripePricingPage} />
                        {!localStorage.getItem('user') && <Route exact path="/academy" component={StripeCheckout} />}
                        {localStorage.getItem('user') && <Route exact path="/academy" component={StripeCheckout} />}

                        {/* Partner Routing */}
                        <Route exact path="/login/:string" component={LoginPage} />

                        {/* Redirect non-auth users to landing (private routes) */}
                        {!localStorage.getItem('user') &&
                            <Layout>
                                <Route exact path="/" component={UserRedirect} />
                                <Route exact path="/grants/:listing_Id" component={Listing} />
                                <PrivateRoute exact path="/grantwriters" component={GrantWriters} />
                                <PrivateRoute exact path="/serviceproviders" component={ServiceProviders} />
                                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                                <PrivateRoute exact path="/matches" component={Matches} />
                                <PrivateRoute exact path="/favorites" component={Favorites} />
                                <PrivateRoute exact path="/search" component={SearchListings} />
                                <PrivateRoute exact path="/settings" component={Settings} />
                                <PrivateRoute exact path="/settings/:area" component={Settings} />
                                <PrivateRoute exact path="/projectproposal/:rec?" component={ProjectProposal} />
                                <Route path='*' component={Outside404Page} />
                            </Layout>
                        }
                    </Switch>
                </Router>

                {/* Normal routing for authenticated users */}
                <Router history={history}>
                    {localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).token &&
                        (!window.location.pathname.includes('/opengrants_')) &&
                        (!window.location.pathname.includes('/portal_')) &&
                        (!window.location.pathname.includes('/pricing')) &&
                        (!window.location.pathname.includes('/onboarding')) &&
                        <Layout>
                            <Switch>
                                <Route exact path="/" component={UserRedirect} />
                                <Redirect from="/register" to="/login" />
                                <Route exact path="/grants/:listing_Id" component={Listing} />
                                {whitelabel && whitelabel.platform_URL == 'portal.opengrants.io' &&
                                    <PrivateRoute exact path="/grantwriters" component={GrantWriters} />
                                }
                                <PrivateRoute exact path="/serviceproviders" component={ServiceProviders} />
                                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                                <PrivateRoute exact path="/matches" component={Matches} />
                                <PrivateRoute exact path="/favorites" component={Favorites} />
                                <PrivateRoute exact path="/search" component={SearchListings} />
                                <PrivateRoute exact path="/settings" component={Settings} />
                                <PrivateRoute exact path="/settings/:area" component={Settings} />
                                <PrivateRoute exact path="/managebilling" component={StripeManageBilling} />
                                <PrivateRoute exact path="/apply" component={Products} />

                                <PrivateRoute exact path="/academy" component={StripeCheckout} />
                                <PrivateRoute exact path="/checkout/:group" component={StripeCheckout} />

                                <PrivateRoute exact path="/notes" component={ListingNotes} />

                                <PrivateRoute exact path="/projectproposal/:rec?" component={ProjectProposal} />

                                <PrivateRoute exact path="/profiles/:username?" component={ProfileView} />

                                <PrivateRoute exact path="/organizations/:organizationId?" component={OrganizationView} />
                                <PrivateRoute exact path="/organization_requests" component={OrganizationRequest} />
                                <PrivateRoute exact path="/organization_invitations" component={OrganizationInvitation} />

                                <PrivateRoute exact path="/quickstart/get-started" component={GetStarted} />
                                <PrivateRoute exact path="/quickstart/learn-more" component={LearnMore} />
                                <PrivateRoute exact path="/quickstart/how-it-works" component={HowItWorks} />
                                <PrivateRoute exact path="/quickstart/basic-questions" component={BasicQuestions} />
                                <PrivateRoute exact path="/quickstart/entity-type" component={EntityType} />
                                <PrivateRoute exact path="/quickstart/funding-timeline" component={FundingTimeline} />
                                <PrivateRoute exact path="/quickstart/small-business" component={SmallBusiness} />
                                <PrivateRoute exact path="/quickstart/number-employees" component={NumberEmployees} />
                                <PrivateRoute exact path="/quickstart/business-location-info" component={BusinessLocationInfo} />
                                <PrivateRoute exact path="/quickstart/us-owned" component={USOwned} />
                                <PrivateRoute exact path="/quickstart/business-location" component={BusinessLocation} />
                                <PrivateRoute exact path="/quickstart/business-focus" component={BusinessFocusInfo} />
                                <PrivateRoute exact path="/quickstart/impact-focus" component={ImpactFocusAreas} />
                                <PrivateRoute exact path="/quickstart/impact-groups" component={ImpactFocusGroups} />
                                <PrivateRoute exact path="/quickstart/nsf-focus" component={NSFFocusAreas} />
                                <PrivateRoute exact path="/quickstart/info-first" component={ExtraCategoriesInfoFirst} />
                                <PrivateRoute exact path="/quickstart/sbir-focus" component={SBIRFocusAreas} />
                                <PrivateRoute exact path="/quickstart/info-second" component={ExtraCategoriesInfoSecond} />
                                <PrivateRoute exact path="/quickstart/cec-focus" component={CECFocusAreas} />
                                <PrivateRoute exact path="/quickstart/finished" component={Finished} />
                                <PrivateRoute exact path="/quickstart/diy-resources" component={QuickstartProgram} />
                                <PrivateRoute exact path="/quickstart/dashboard" component={QuickstartDashboard} />
                                <PrivateRoute exact path="/quickstart/funding-dashboard" component={QuickstartFunding} />
                                <PrivateRoute exact path="/quickstart/products" component={QuickstartProducts} />
                                <PrivateRoute exact path="/marketplace" component={MarketplaceSearch} />
                                <PrivateRoute exact path="/service-provider/:serviceProviderId" component={ServiceProvider} />

                                {whitelabel && whitelabel.platform_URL == 'portal.prospectsv.org' &&
                                    <PrivateRoute exact path="/advisors" component={ProspectSV_Advisors} />
                                }
                                {whitelabel && whitelabel.platform_URL == 'portal.prospectsv.org' &&
                                    <PrivateRoute exact path="/service-providers" component={ProspectSV_Resources} />
                                }
                                {whitelabel && whitelabel.platform_URL == 'portal.prospectsv.org' &&
                                    <PrivateRoute exact path="/grantwriters" component={ProspectSV_Grantwriters} />
                                }
                                {whitelabel && whitelabel.platform_URL == 'portal.prospectsv.org' &&
                                    <PrivateRoute exact path="/ev-investors" component={ProspectSV_EV_Investors} />
                                }
                                <Route exact path='*' component={Inside404Page} />
                            </Switch>
                        </Layout>
                    }
                </Router>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        whitelabel
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
