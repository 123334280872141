import { createConnector } from "react-instantsearch-dom";

const connectClearRefinements = createConnector({
  displayName: "ClearRefinements",

  getProvidedProps() {
    return {
      currentRefinement: {}
    };
  },
  refine() {
    return {};
  },

});

export default connectClearRefinements;
