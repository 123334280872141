import { authHeader } from '../_helpers';
import { API_Route } from '../App/API_Route';

export const notificationsService = {
    getAllNotifications,
    markAllNotificationsSeen,
    markNotificationAsRead,

    get_email_notification_settings,
    update_email_notification_settings,
};

function getAllNotifications(userId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/notification/get_all_notifications?userId=' + userId, requestOptions)
        .then(handleResponse);
}

function markAllNotificationsSeen(userId, unseenIds) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({"UnseenIds": unseenIds})
    };

    return fetch('https://refactor.opengrants.io/api/notification/mark_all_notifications_seen?userId=' + userId, requestOptions)
        .then(handleResponse);
}

function markNotificationAsRead(userId, notificationId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch('https://refactor.opengrants.io/api/notification/mark_notification_as_read?userId=' + userId + '&notificationId=' + notificationId, requestOptions)
        .then(handleResponse);
}

function get_email_notification_settings(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/notification/get_email_notification_settings?user_id='+user_id, requestOptions)
        .then(handleResponse);
}

function update_email_notification_settings(user_updated_notifications) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user_updated_notifications)
    };

    return fetch(API_Route + '/api/notification/update_email_notification_settings', requestOptions)
        .then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok ) {
            if (response.status == "401") {
                window.location.href="/login";
            }
            
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}