import React from 'react';
export class StripeProductSuccess extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {page, products} = this.props;

        let productSuccess_0 = 
            <>
                <p>Welcome To The OpenGrants Academy! We are excited to welcome you to the community. You will see an email from us shortly with links to relevant communities, course codes and information on next steps.</p>
            </>

        let productSuccess_1 = 
            <>
                <p>Thank you for your purchase of a portal license! You will see any email from our team in the next hour about next steps. Deployment takes about seven business days once we have your logo and branding information.</p>
            </>
        
        let productSuccess_2 = 
            <>
                <p>Thank you for your subscription to Grant DNA. You will receive an email shortly with details on how to access your account.</p>
            </>

        return (
            <div className="background-white pop gap-bottom">
                <h2 className="center gap-bottom">Purchase Successful!</h2>
                <div>
                    {page == products[0] && productSuccess_0}
                    {page == products[1] && productSuccess_1}
                    {page == products[2] && productSuccess_2}                
                    <p>If you have any questions or concerns, email 
                    <a className="og-color" href="mailto:support@opengrants.io"> support@opengrants.io</a>.</p>
                    <p>We look forward to working with you.</p>
                </div>
            </div>
        );
    }
}