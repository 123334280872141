
import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Button from "../../../_components/dashboardComponents/CustomButtons/Button.js";
import '../../../Styles/SettingsStyles/Billings.css';

class StripeOrderSummary extends Component {
    constructor() {
        super();

        const user = localStorage.getItem("user");

        this.state = {
            coupon: '',
            couponId: '',

            emailAgreement_summary: !user ? true : false,
            emailAgreement_summaryError: false,

            termsAndPrivacyAgreement: false,
            termsAndPrivacyAgreementError: false
        };
    }

    placeOrder = (e) => {
        e.preventDefault();
        const {emailAgreement, termsAndPrivacyAgreement} = this.state;
        if (termsAndPrivacyAgreement && document.getElementById("cardFormSubmit")) {
            document.getElementById("cardFormSubmit").click();
        } 
        else if (!termsAndPrivacyAgreement) {
            this.showErrors(termsAndPrivacyAgreement);
        }
        else {
            this.props.updateErrorMessage('Please add a payment method');
            document.body.scroll(0, 0);
        }
    }

    handleAgreement = (e) => {
      const {id} = e.target;
      this.setState({
        [id]: e.target.checked,
        [id+"Error"]: !e.target.checked
      });
    }

    //show input, agreement error
    showErrors = (termsAndPrivacyAgreement) => {
      let {termsAndPrivacyAgreementError} = this.state;
      var user = JSON.parse(localStorage.getItem('user'));
  
      if (termsAndPrivacyAgreement == false) {
        termsAndPrivacyAgreementError = true;
      }
  
      this.setState({
        // emailAgreementError,
        termsAndPrivacyAgreementError
      });
  
    //   return !emailAgreementError && !termsAndPrivacyAgreementError;
      return !termsAndPrivacyAgreementError;
    }

    render() {
        let user = localStorage.getItem("user");
        let {emailAgreement, termsAndPrivacyAgreement, 
            emailAgreementError, termsAndPrivacyAgreementError} = this.state;
        const {total, selectedPrices, discountStatus, discount, invoiceDiscount, whitelabel} = this.props;
        let newTotal = total;
        let displayDiscount = null;

        let displaySelectedPrices = null;
        var prodsKeys = Object.keys(selectedPrices);
        if (prodsKeys && prodsKeys.length > 0) {
            displaySelectedPrices = prodsKeys.map((key) => {
                const price = selectedPrices[key];
                return (
                    <div key={"price_" + price.product.name} className="overflow gap-bottom">
                        <h3 className="col-md-8 font-sm empty-style">{price.product.name}</h3>
                        <p className="col-md-4 empty-style align-right">${(price.unit_amount/100).toLocaleString()}</p>
                    </div>
                )
            });
        }

        let trial = "";
        let trialPrice = 0;
        let trialFullPrice = 0;
        let trialFullPrice_Other = 0;
        let trialInterval = "";
        let discountTotal = "";
        let couponLimit = "";
        let couponLimit_Other = "";
        let annualPrice_Discount = 0;
        let monthlyPrice_Discount = 0;
        let prices = Object.values(selectedPrices);
        let interval = "";
        let fullPrice = 0;
        let priceTotal = 0;
        let annualPrice = 0;
        let monthlyPrice = 0;
        let summary = "";
        
        prices.map((price) => {
            if (selectedPrices[price.product.id].id !== price.id) return;
            priceTotal += price.product.metadata["trialPrice"] ? price.product.metadata["trialPrice"] : 
                price.product.metadata["group"].includes(this.props.productType + " default") 
                && !window.location.href.includes('/pricing') 
                && this.props.billedToday
                && this.props.billedToday != true ? 0 : price.unit_amount;
            fullPrice = price.unit_amount;

            if (price.recurring) {
                interval = price.recurring.interval;
            }

            if (discount) {
                if (!(interval === "year" && parseInt(discount.repeat) < 12) && 
                !(interval !== "month" && interval !== "year" && discount.repeat && discount.repeat != "once")) {
                let priceString = [];
                if (discount.product.includes("prod")) {
                    if (discount.product.includes(",")) {
                        var productsSplit = discount.product.split(", ");
                        productsSplit.map((prod) => {
                            if (this.props.consolidatedProducts && this.props.consolidatedProducts[prod])
                                this.props.consolidatedProducts[prod].map((pri) => {
                                    priceString.push(pri)
                                });
                        });
                    } else {
                        priceString = this.props.consolidatedProducts[discount.product];
                    }

                    if (priceString.length > 0) {
                        discount.product = "";
                        priceString.map((price_id) => {
                            discount.product += price_id.id;
                        });
                    }
                }
                        
                if ((!discount.product || discount.product == "") || (discount.product && ((discount.product.includes('opengrants_test_coupon')) || (discount.product.includes(price.id))))) {
                    let discountTotal = 0;

                    if (discount.type == "percent") {
                        discountTotal = (fullPrice*discount.amount*0.01);
                    } else if (discount.type == "amount") {
                        discountTotal = discount.amount;
                    }
                    
                    if (parseInt(discountTotal) <= parseInt(fullPrice)) {
                        if (interval == "month") {
                            monthlyPrice_Discount += fullPrice;
                        } else 
                        if (interval == "year") {
                            annualPrice_Discount += fullPrice;
                        }
                        fullPrice = fullPrice-discountTotal;
                        let discountString = !discount.name.includes("off") ? 
                            discount.type === "amount" ? 
                            "Discount - " + discount.name + " ($" + discount.amount/100 + " off)" + ": " :
                            "Discount - " + discount.name + " (" + discount.amount + "% off)" + ": "
                            : "Discount - " + discount.name + ":";
                            displayDiscount = 
                        <div className="radio-elements">
                            <label className="col-md-6">
                                {discountString}
                            </label>
                            <label className="col-md-6">
                                {"-$"+discountTotal/100}
                            </label>
                        </div>

                        // if (discount.repeat) {
                        //     if (parseInt(discount.repeat) >= 12) {
                        //         couponLimit = parseInt(discount.repeat)/12 + (parseInt(discount.repeat)/12 > 1 ? " years": " year");
                        //         couponLimit_Other = couponLimit;
                        //     } else {
                        //         couponLimit = discount.repeat + (parseInt(discount.repeat) > 1 ? " months": " month");
                        //     }
                        // }
                            
                        if (discount.repeat) {
                            if (discount.repeat === "once") {
                                if (interval === "month") couponLimit = "1 month";
                                else {couponLimit = "1 year"; couponLimit_Other = "1 year";}
                            }
                            else if (parseInt(discount.repeat) >= 12) {
                                couponLimit = parseInt(discount.repeat)/12 + (parseInt(discount.repeat)/12 > 1 ? " years": " year");
                                couponLimit_Other = couponLimit;
                            } else {
                                couponLimit = discount.repeat + (parseInt(discount.repeat) > 1 ? " months": " month");
                                couponLimit_Other = "1 year";
                            }
                        }

                        if (!price.product.metadata["trialPrice"]) {
                            if (this.props.billedToday == undefined || this.props.billedToday == true) {
                                priceTotal -= discountTotal;
                            }
                        } else {
                            if (discount.metadata && discount.metadata["trial_waiver"]) {
                                if (discount.metadata["trial_waiver"] === "all") {
                                    priceTotal -= price.product.metadata["trialPrice"];
                                } else {
                                    priceTotal -= discount.metadata["trial_waiver"];
                                }
                            }
                        }
                    } 
                }
            }
            }

            if (price.product.metadata["trial"]) {
                trial = price.product.metadata["trial"];
                trialPrice = price.product.metadata["trialPrice"];
                trialInterval = interval;
                trialFullPrice = fullPrice;
                trialFullPrice_Other = price.unit_amount;
                let discountTotal = 0;

                if (discount && ((!discount.product || discount.product === "") || (discount.product.includes('opengrants_test_coupon')) || (discount.product && discount.product !== "" && discount.product.includes(price.id)))) {
                
                    if (discount.type == "percent") {
                        if (!parseInt(discount.repeat) || parseInt(discount.repeat) >= 12 || interval == "month")
                            discountTotal = (trialFullPrice_Other*discount.amount*0.01);
                        else if (!(interval === "year" && parseInt(discount.repeat) < 12) && 
                        !(interval !== "month" && interval !== "year" && discount.repeat && discount.repeat != "once")) {
                            discountTotal = (trialFullPrice_Other/12*discount.amount*0.01*parseInt(discount.repeat));
                        }
                    } else if (discount.type == "amount" && !(interval === "year" && parseInt(discount.repeat) < 12)) {
                        discountTotal = discount.amount;
                    }
                    trialFullPrice_Other = trialFullPrice_Other - discountTotal;
                }

                return (<div> 
                        <p>{"After " + trial + " days: US$" + (fullPrice/100).toFixed(2) + " / month"}</p>
                        <p>{"Billed annually: US$" + (fullPrice/100).toFixed(2) + " / year"}</p> 
                    </div>);
            }

            else if (interval == "year") {
                annualPrice += fullPrice;
                return (<div> <p>{"Billed annually: US$" + (fullPrice/100).toFixed(2) + " / year"}</p> </div>);
            }

            else if (interval == "month") {
                monthlyPrice += fullPrice;
                return (<div> <p>{"Billed monthly: US$" + (fullPrice/100).toFixed(2) + " / month"}</p> </div>);
            }
        });

        var billedToday = "";
        if (this.props.billedToday && this.props.billedToday != true) {
            billedToday = " starting on " + this.props.billedToday;
        }

        if (trial !== "") {
            if (trialInterval == "year") {
                summary += "After " + trial + " days: US$" + (((((trialFullPrice+annualPrice)/12/100).toFixed(2)*12) === ((trialFullPrice+annualPrice)/100)) ? ((trialFullPrice+annualPrice)/12/100).toFixed(2) + " / month " : ((trialFullPrice+annualPrice)/100).toFixed(2) + " / year ") + (couponLimit ? " for " + couponLimit : "") + billedToday;
                if (((((trialFullPrice+annualPrice)/12/100).toFixed(2)*12) === ((trialFullPrice+annualPrice)/100))) summary += ", Billed annually: US$" + ((trialFullPrice_Other+annualPrice)/100).toFixed(2) + " / year " + (couponLimit_Other ? " for " + couponLimit_Other : "") + billedToday;
            } else {
                summary += "After " + trial + " days: US$" + (trialFullPrice/100).toFixed(2) + " / month " + (couponLimit ? " for " + couponLimit : "") + billedToday;
            }
        } else {
            if (annualPrice > 0) {
                if (summary != "") summary += ", ";
                summary += "Billed annually: US$" + ((annualPrice)/100).toFixed(2) + " / year " + (couponLimit_Other ? " for " + couponLimit_Other : "") + billedToday;
            }
    
            if (monthlyPrice > 0) {
                if (summary != "") summary += ", ";
                summary += "Billed monthly: US$" + ((monthlyPrice)/100).toFixed(2) + " / month " + (couponLimit ? " for " + couponLimit : "") + billedToday;
            }
        }

        if (couponLimit !== "") {
            if (monthlyPrice_Discount > 0) {
                if (summary != "") summary += ", ";
                summary += "After " + couponLimit + ": US$" + ((monthlyPrice_Discount)/100).toFixed(2) + " / month";
            }
            if (annualPrice_Discount > 0) {
                if (summary != "") summary += ", ";
                summary += "After " + couponLimit + ": " + (((annualPrice_Discount)/12/100).toFixed(2)*12 === ((annualPrice_Discount)/100) ? "US$" + ((annualPrice_Discount)/12/100).toFixed(2) + " / month" +  ", Billed annually for US$" + ((annualPrice_Discount)/100).toFixed(2) + " / year" : "Billed annually for US$" + ((annualPrice_Discount)/100).toFixed(2) + " / year" );
                // summary += ", Billed annually: US$" + ((annualPrice_Discount)/100).toFixed(2) + " / year";
            }
        }

        // if (annualPrice > 0) {
        //     if (summary != "") summary += ", ";
        //     summary += "Billed annually: US$" + (annualPrice/100).toFixed(2) + " / year " + (couponLimit_Other ? " for " + couponLimit_Other : "");
        // }

        // if (monthlyPrice > 0) {
        //     if (summary != "") summary += ", ";
        //     summary += "Billed monthly: US$" + (monthlyPrice/100).toFixed(2) + " / month " + (couponLimit ? " for " + couponLimit : "");
        // }

        // if (couponLimit !== "") {
        //     if (annualPrice_Discount > 0) {
        //         if (summary != "") summary += ", ";
        //         summary += (couponLimit_Other ? "After " + couponLimit_Other + ": Billed annually for US$" + (annualPrice_Discount/100).toFixed(2) + " / year" : "Billed annually: US$" + (annualPrice_Discount/100).toFixed(2) + " / year");
        //     }
        // }

        let summaryDiv = null;
        if (summary != "") {
            let summarySplit = summary.split(", ");
            summaryDiv = summarySplit.map((summarySentence, idx) => {
                return (<p key={idx}>{summarySentence}</p>)
            })
        }

        return (
            <div className="background-white pop gap-bottom">
                <h2 className="font-medium empty-style">Order Summary</h2>
                {/* {displaySelectedPrices} */}
                {/* <div className="gap-bottom">
                    <div className="overflow gap-bottom-sm">
                        <h3 className="col-md-8 font-sm empty-style">Subtotal ({prices.length} {prices.length > 1 ? " items": " item"}):</h3>
                        <p className="col-md-4 empty-style align-right">${(total/100).toFixed(2)}</p>
                    </div>
                    {discountTotal && <div className="overflow gap-bottom-sm">
                        <div className="col-md-8 empty-style">
                            <h3 className="font-sm empty-style">Discount:</h3>
                            <p>{this.props.discountStatus.message}</p>
                        </div>
                        <p className="col-md-4 empty-style align-right">{discountTotal ? "-$"+(discountTotal/100).toFixed(2) : "$0.00"}</p>
                    </div>}
                </div> */}
                {/* <StripeCoupon 
                    handleCoupon={this.props.handleCoupon}
                    discount={discount}
                    discountStatus={discountStatus} /> */}
                <hr className="line-sm" />
                <div className="overflow">
                    {<p className="float-left bold">Billed Today (USD):</p>}
                    {/* {(total > 0 && Object.keys(selectedPrices).length > 0) || (Object.keys(selectedPrices).length === 0 && total === 0) ? 
                        <p className="float-right bold og-color"> US${(priceTotal/100).toFixed(2)} {trial != "" ? "for "+trial+" days" : ""}</p>
                        : <p className="float-right bold"> Pending Scoping</p>} */}
                    <p className="float-right bold og-color" style={{color: "#777", float: "left"}}> US$ {(priceTotal/100).toFixed(2)} {trial != "" ? "for "+trial+" days" : ""}</p>
                </div>
                <div>
                    {summaryDiv}
                </div> 

                <div className="checkbox-elements gap-top gap-bottom">
                    {user && <div className="overflow gap-bottom-sm">
                        <div>
                            <input type="checkbox" id="emailAgreement_summary" checked={emailAgreement} className="empty-style" onChange={this.handleAgreement} />
                            <label className="empty-style">I agree to receive updates, helpful tips and news from OpenGrants..</label>
                        </div>
                        {/* {emailAgreementError && <label className="error">This field is required.</label>} */}
                    </div>}
                    <div className="overflow">
                        <input type="checkbox" id="termsAndPrivacyAgreement" checked={termsAndPrivacyAgreement} className="empty-style" onChange={this.handleAgreement} />
                        <label className="empty-style">I agree to the 
                        <a href="https://www.opengrants.io/opengrants-legal/" target="_blank"> Terms of Use </a>
                        and 
                        <a href="https://www.opengrants.io/opengrants-legal/" target="_blank"> Privacy Policy</a>.
                        </label>
                    </div>
                    {termsAndPrivacyAgreementError && <label className="error">This field is required.</label>}
                </div>

                <div className="center gap-bottom">
                <Button 
                    id="placeOrder" 
                    // className="button-padded background-og-color" 
                    onClick={this.placeOrder}
                    style={{background: whitelabel ? whitelabel.button_Color : "#29a", width: "100%"}}

                    >
                        Place Order
                    </Button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { whitelabel } = state.setWhitelabel;
    
    return {
        whitelabel,
    };
}

const connectedStripeOrderSummary = withRouter(connect(mapStateToProps)(StripeOrderSummary));
export { connectedStripeOrderSummary as StripeOrderSummary };