import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Loader } from '../../../Shared/Loader';
import { quickstartService } from '../../../../_services';
import { history } from '../../../../_helpers';
import ProgressBar from 'react-bootstrap/ProgressBar';
import '../../../../Styles/QuickstartSurvey.css';

class BusinessLocation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            submitted: false,
            loading: true,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        document.body.scrollTop = 0;
        // alert(JSON.stringify(this.state.user.zipcode))

        setTimeout(() => {
            this.setState({
            loading: false
            });
        }, 500);
    }

    handleChange(e) {
        const {name, value} = e.target;
        let {user} = this.state;
        user[name] = value;
        this.setState({
            user: user
        })
    }

    handleNavigation = async (type) => {
        var {user} = this.state;

        const user_state = await quickstartService.checkState(user, user.zipcode);
        user.US_State = user_state;

        this.setState({
            submitted: true,
        });


        if (this.formIsValid(user.zipcode))
        {
            await localStorage.setItem('user', JSON.stringify(user));
            history.push('/quickstart/business-focus');
        }
    }

    formIsValid(zipcode) {
        let isValid = false;
        if (zipcode != "" && zipcode && (zipcode.length == 5 || (zipcode >= 10000 && zipcode <= 99999))) {
            isValid = true;
        } 

        return isValid;
    }

    useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '100ch',
            },
        },
    }));   

    render() {
        const { zipcode, submitted, user, loading } = this.state;
        const { whitelabel } = this.props;
        const progressBarColor = whitelabel ? whitelabel.accent_Color : "#29a";
        const now = 60;
        const progressInstance = <ProgressBar now={now} label={`${now}%`} />;

        return (
            <div>
                <style>{"\
                    .progress-bar{\
                    background:" + progressBarColor + ";\
                    }\
                "}</style>
                {loading && <Loader />}
                <div>{progressInstance}</div>
                <div id="fundingSlider9">
                    <h1 id="fundingSliderHeader">
                        In which zipcode is your business headquartered in?
                    </h1>
                    <br />
                    <br />
                    <div id="zipInput">
                        <TextField 
                            required 
                            id="standard-basic" 
                            name="zipcode"
                            label="ZIPCODE"
                            value={user.zipcode} 
                            onChange={this.handleChange} 
                            style={{marginBottom: "0px!important"}}
                        />
                        {submitted && !zipcode &&
                            <p className="error">* valid zipcode required</p>
                        }
                    </div>
                </div>
                <div id="BusinessLocationContinueButton" className="continueButton">
                    <button 
                        className="signUpContinue"
                        onClick={() => this.handleNavigation('next')}
                        style={{background: whitelabel ? whitelabel.button_Color : "#29a"}}
                    >
                        Continue  →
                    </button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering, nav } = state.registration;
    const { whitelabel } = state.setWhitelabel;
    const { registrationAlert } = state;
    
    return {
        registering, 
        nav, 
        registrationAlert,
        whitelabel,
    };
}

const connectedBusinessLocation = connect(mapStateToProps)(BusinessLocation);
export { connectedBusinessLocation as BusinessLocation };