import React from 'react';
import { connect } from 'react-redux';
import { organizationService } from '../../../_services';
import { Registration_AutoSuggestion } from '../../Account/RegisterPage';
import '../../../Styles/Organization.css';

class EditOrganizationTechnologies extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            org: this.props.org,
            industries: [],
        }
    }

    componentDidMount() {
        this.getTechnologies();
    }

    getTechnologies = async() => {
        const { user } = this.props;
        var technologies_Used = await organizationService.getTechnologies(user.id);

        this.setState({
            technologies_Used
        });
    }

    render() {
        const { technologies_Used }  = this.state;
        const { org } = this.props;

        let technologies_Used_Cat = {"technologies_Used": technologies_Used};

        return (
            <div style={{paddingBottom: 20}}>
                <h2>Technologies</h2>
                <Registration_AutoSuggestion 
                    categories={technologies_Used_Cat} 
                    categoryName="technologies_Used"
                    currentSelectedCategories={org.technologies_Used}
                    // submitted={submitted}
                    handleCategorySelection={this.props.handleCategorySelection}
                    addTags={true}
                    multiple={true}
                    handleCategoryInput={this.props.handleCategoryInput}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { setUser, organization, setWhitelabel } = state;
    const { user } = setUser;
    const { whitelabel } = setWhitelabel;

    return {
        user,
        organization,
        whitelabel,
    };
}

const connectedEditOrganizationTechnologies = connect(mapStateToProps)(EditOrganizationTechnologies);
export { connectedEditOrganizationTechnologies as EditOrganizationTechnologies };