import { authHeader } from '../_helpers';
import { API_Route } from '../App/API_Route';

export const serviceProviderService = {
    registerServiceProvider,
    createServiceProviderOrganization,
    getServiceProvidersByUser,
  
    getServiceProvider,
    getServiceProviderById,
    getServiceProviders,
    updateSPAvailability,

    getAllClients,
    getClient,
    getClients,
    
    getAllChannels,
    getAllOrganizationChannels,
    getOrganizationChannels,
    getChannelByServiceProvider,
    getChannelByOrgId,

    createProposal,
    updateProposalStatus,
    updateProposalView,

    getProjects,
    getProject,
    createProject,
    updateProject,
    getAllClientProjectsAndProposals,
    getAllSPProjectsAndProposals,

    createWorkSegment,
    editWorkSegment,
    deleteWorkSegment,

    getServiceProviderCount
};

function registerServiceProvider(service_Provider) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(service_Provider)
    };

    return fetch(API_Route + '/api/serviceprovider/register_service_provider', requestOptions)
    .then(handleResponse)
}

function createServiceProviderOrganization(email, organization) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(organization)
    };

    return fetch(API_Route + '/api/organization/create_service_provider_organization?email=' + email, requestOptions).then(handleResponse);
}

function getServiceProvidersByUser(user_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_service_providers_by_user?user_id=' + user_Id, requestOptions)
    .then(handleResponse)
}

function getServiceProvider(provider) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(provider)
    };

    return fetch(API_Route + '/api/serviceprovider/get_service_provider', requestOptions).then(handleResponse);
}

function getServiceProviderById(provider_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_service_provider_by_id?service_Provider_Id=' + provider_Id, requestOptions).then(handleResponse);
}

function getServiceProviders(client_organization_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_service_providers?client_organization_id='+client_organization_id, requestOptions).then(handleResponse);
}

function updateSPAvailability(provider) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(provider)
    };

    return fetch(API_Route + '/api/serviceprovider/update_service_provider_availability', requestOptions).then(handleResponse);
}


function getAllClients(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_all_clients?user_id='+user_id, requestOptions).then(handleResponse);
}


function getClient(client) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(client)
    };

    return fetch(API_Route + '/api/serviceprovider/get-client', requestOptions).then(handleResponse);
}


function getClients(provider_organization_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_clients?provider_organization_id='+provider_organization_id, requestOptions).then(handleResponse);
}


function getAllChannels(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_all_channels?user_id='+user_id, requestOptions).then(handleResponse);
}


function getAllOrganizationChannels(chat) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(chat)
    };

    return fetch(API_Route + '/api/serviceprovider/get_all_organization_channels', requestOptions).then(handleResponse);
}


function getOrganizationChannels(chat) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(chat)
    };

    return fetch(API_Route + '/api/serviceprovider/get_organization_channels', requestOptions).then(handleResponse);
}


function getChannelByServiceProvider(user_id, provider_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/serviceprovider/get_channel_by_service_provider?user_id='+user_id+'&provider_id='+provider_id, requestOptions).then(handleResponse);
}


function getChannelByOrgId(user_id, org_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(API_Route + '/api/serviceprovider/get_channel_by_org_id?user_id='+user_id+'&org_id='+org_id, requestOptions).then(handleResponse);
}

function createProposal(proposal) {
    // alert(JSON.stringify(proposal))
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(proposal)
    };

    return fetch(API_Route + '/api/serviceprovider/create_proposal', requestOptions).then(handleResponse);
}

function updateProposalStatus(proposal) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(proposal)
    };

    return fetch(API_Route + '/api/serviceprovider/update_proposal_status', requestOptions).then(handleResponse);
}

function updateProposalView(proposal) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(proposal)
    };

    return fetch(API_Route + '/api/serviceprovider/update_proposal_view', requestOptions).then(handleResponse);
}

function getProjects(provider_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_projects?provider_id=' + provider_id, requestOptions).then(handleResponse);
}

function getProject(project_Id, user_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_project?project_Id=' + project_Id + "&user_Id=" + user_Id, requestOptions).then(handleResponse);
}

function createProject(project) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(project)
    };

    return fetch(API_Route + '/api/serviceprovider/create_project', requestOptions).then(handleResponse);
}

function updateProject(project) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(project)
    };

    return fetch(API_Route + '/api/serviceprovider/update_project', requestOptions).then(handleResponse);
}

function getAllClientProjectsAndProposals(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_all_client_projects?user_id='+user_id, requestOptions).then(handleResponse);
}

function getAllSPProjectsAndProposals(user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_all_service_provider_projects?user_id='+user_id, requestOptions).then(handleResponse);
}

function createWorkSegment(segment) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(segment)
    };

    return fetch(API_Route + '/api/serviceprovider/create_work_segment', requestOptions).then(handleResponse);
}

function editWorkSegment(segment) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(segment)
    };

    return fetch(API_Route + '/api/serviceprovider/edit_work_segment', requestOptions).then(handleResponse);
}

function deleteWorkSegment(segment_Id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/delete_work_segment?segment_id='+segment_Id, requestOptions).then(handleResponse);
}

function getServiceProviderCount() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(API_Route + '/api/serviceprovider/get_service_provider_count', requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok ) {
            if (response.status == "401") {
                sessionStorage.setItem('redirect', window.location.href);
                window.location.href="/login";
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        console.log('handleSubmitSRV', data);

        return data;
    });
}