import React from 'react';
import { connect } from 'react-redux';
import { Registration } from '../RegisterPage';
import { Loader } from '../../Shared/Loader';
import '../../../Styles/Registration.css';
import {authenticateService} from '../../../_services';
import { Segment } from '../../../_pages/Shared/Segment';

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                email: '',
                password: ''
            },
            linked_Listing_Id: null,
        }
    }

    componentDidMount() {
        const path = window.location.pathname;

        if (path.toLowerCase() != "/login" && path != "/login/null")
        {
            let pathSplit = path.split('/').pop();
            if (pathSplit != "organization_invitations"){
                pathSplit = "/grants/"+pathSplit;
            } else {
                pathSplit = "/"+pathSplit;
                localStorage.setItem("redirectPageTo", pathSplit);
            }
            this.setState({
                linked_Listing_Id: pathSplit
            });
        }
    }

    updateUserInfo = (user) => {
        this.setState({
            user
        })
    }

    logInUser = async() => {
        const {user} = this.state;
        Segment.register(user);
        Segment.track(user, 'Register',
            {
                user_type: 'user',
            }
        );

        const listing_Id = this.state.linked_Listing_Id;
        var result = await authenticateService.login(user.email, user.password, listing_Id);
        if (result.token) {
            // Segment.track(user, 'Login');

            const { whitelabel } = this.props;

            if (whitelabel && whitelabel.features.feature_Subscriptions) {
                window.location.href = "/pricing";
            }
            else {
                window.location.href = "/dashboard";
            }
        }
    }

    render() {
        const {registering, whitelabel} = this.props;
        let windowState = (window.innerWidth >= 1000) ? true : false;

        if (registering == "success") {
            this.logInUser();
        }

        return (
            <div>
                {windowState === false && (whitelabel && whitelabel.organization_Name && whitelabel.organization_Name == "portal.opengrants.io") &&
                    <h1 className="logo">opengrants.io</h1>
                }
                {(registering === "request" || registering === "success") && <Loader />}
                <div id="clientRegistration">
                    <Registration updateUserInfo={this.updateUserInfo}></Registration>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { registering } = state.registration;
    const { whitelabel } = state.setWhitelabel;
    // const {registrationAlert, purchaseProduct} = state;
    return {
        registering,
        whitelabel,
        // , nav, registrationAlert, purchaseProduct
    };
}

const connectedRegisterPage = connect(mapStateToProps)(RegisterPage);
export { connectedRegisterPage as RegisterPage };
