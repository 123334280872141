import React from 'react';
import Autosuggest from 'react-autosuggest';
import { Tagging } from '.';

export class Registration_AutoSuggestion extends React.Component {
    constructor(props) {
        super(props);
        const {categories, currentSelectedCategories, multiple} = this.props;
        let value = "";
        if (!multiple && currentSelectedCategories.length > 0) {
            value = currentSelectedCategories[0];
        }

        this.state = {
            categories,
            value: value,
            suggestions: [],
        }
    }

    onSuggestionsFetchRequested = ({ value, reason }) => {
        // if (value.length == 0) {
        //     const {categoryName, handleCategorySelection, multiple} = this.props;
        //     if (!multiple) {
        //         let categories = {};
        //         categories[categoryName] = [];
        //         handleCategorySelection(categories);
        //     }
        // }
        
        var suggestions = [];
        if (reason != 'suggestion-selected') {
            suggestions = this.getSuggestions(value);
        } else if (this.props.addTags) {
            value = "";
        }

        // var orgTypeList_height = document.getElementById("organizationType").getElementsByTagName("ul")[0].offsetHeight;
        // var orgTypeElement = document.getElementById("organizationType");
        // var orgTypeElement_bottomPosition = orgTypeElement.offsetTop + orgTypeElement.offsetHeight + orgTypeList_height + 20;
        // var formElement = document.getElementById("clientRegistration");
        // var formElement_height = formElement.offsetHeight;
        // if ((orgTypeElement_bottomPosition-formElement_height) > 0)
        //     formElement_height.style.height = (formElement_height + (orgTypeElement_bottomPosition-formElement_height)) + "px";

        this.setState({
            value,
            suggestions
        });
    };

    getSuggestions = value => {
        const {categoryName} = this.props;
        // const inputValue = value.trim().toLowerCase();
        const inputLength = value.length;
      
        return inputLength === 0 ? this.props.categories[categoryName] : this.props.categories[categoryName].filter(lang =>
          lang ? (lang).toLowerCase().slice(0, inputLength) === value.toLowerCase() : false
        );
      };
      
    getSuggestionValue = suggestion => (suggestion);
      
    renderSuggestion = suggestion => (
        <div>
          {suggestion}
        </div>
      );

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    handleLocationChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
        });

        const {categoryName, handleCategoryInput} = this.props;
        let categoryInput = {}
        categoryInput[categoryName+"Input"] = newValue;
        handleCategoryInput(categoryInput);
    }

    onSuggestionSelected = (event, { suggestion, suggestionValue, index, method }) => {
        event.preventDefault();
        const {categoryName, handleCategorySelection, handleCategoryInput, multiple, addTags} = this.props;
        let currentSelectedCategories = this.props.currentSelectedCategories.slice();
        if (!currentSelectedCategories.includes(suggestion)) {
            if (addTags) {
                if (multiple) {
                    currentSelectedCategories.push(suggestion);
                    let categories = {};
                    categories[categoryName] = currentSelectedCategories;
                    handleCategorySelection(categories);
                } else {
                    let categories = {};
                    categories[categoryName] = [suggestion];
                    handleCategorySelection(categories);
                }
            
                let categoryInput = {}
                categoryInput[categoryName+"Input"] = "";
                handleCategoryInput(categoryInput);
            } else {
                let categories = {};
                categories[categoryName] = suggestion;
                handleCategorySelection(categories);
            }
        } else if (addTags) {
            let categoryInput = {}
            categoryInput[categoryName+"Input"] = "";
            handleCategoryInput(categoryInput);
        }
    }

    //remove option tag
    removeOptionTag = (optionValue) => {
        const {categoryName, handleCategorySelection} = this.props;
        let currentSelectedCategories = this.props.currentSelectedCategories.slice();
        currentSelectedCategories = currentSelectedCategories.filter(category => {
            return (category !== optionValue);
        });
        let categories = {};
        categories[categoryName] = currentSelectedCategories;
        handleCategorySelection(categories);
    }

    clearTags = () => {
        const {categoryName, handleCategorySelection} = this.props;
        let categories = {};
        categories[categoryName] = [];
        handleCategorySelection(categories);
    }

    render() {
        // console.log(this.props);
        // console.log(this.state);
        const {addTags, currentSelectedCategories, submitted, requiredInput, errorInput} = this.props;
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: "Start typing to select...",
            value,
            onChange: this.handleLocationChange
        };

        //create tag for each inputs
        let tags = null;
        if (currentSelectedCategories) {
            tags = currentSelectedCategories.map((category) => {
                return (
                    <div key={"tags_" + category} className="categoryTag">
                        {category}
                        <div className="removeTag" onClick={() => this.removeOptionTag(category)}>X</div>
                    </div>
                )
            });
        }
        
        return (
            <>
            <div className="align-left">
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSuggestionSelected}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                alwaysRenderSuggestions={true}
            /> 
            </div>
            {submitted && requiredInput && value.length == 0 &&
             <p className="error align-left">This field is required</p>}
            {submitted && errorInput &&
            <p className="error align-left">Please select a value from the dropdown</p>}
                
            {addTags && currentSelectedCategories.length > 0 && 
            <div className="align-left">
                <a id="clearAll" className="showClearAll" onClick={this.clearTags}>Clear</a>
                <div id="tags">{tags}</div>
            </div>
            }
            </>    
        );
    }
}